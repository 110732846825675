export const LTCpriceHistory = [
  {
    Date: "17.09.2014",
    price: 5.08589,
  },
  {
    Date: "18.09.2014",
    price: 5.06543,
  },
  {
    Date: "19.09.2014",
    price: 4.68729,
  },
  {
    Date: "20.09.2014",
    price: 4.3292,
  },
  {
    Date: "21.09.2014",
    price: 4.26307,
  },
  {
    Date: "22.09.2014",
    price: 4.24593,
  },
  {
    Date: "23.09.2014",
    price: 4.23999,
  },
  {
    Date: "24.09.2014",
    price: 4.7442,
  },
  {
    Date: "25.09.2014",
    price: 4.66588,
  },
  {
    Date: "26.09.2014",
    price: 4.50993,
  },
  {
    Date: "27.09.2014",
    price: 4.43439,
  },
  {
    Date: "28.09.2014",
    price: 4.42462,
  },
  {
    Date: "29.09.2014",
    price: 4.23763,
  },
  {
    Date: "30.09.2014",
    price: 4.25219,
  },
  {
    Date: "01.10.2014",
    price: 4.47096,
  },
  {
    Date: "02.10.2014",
    price: 4.43015,
  },
  {
    Date: "03.10.2014",
    price: 4.31936,
  },
  {
    Date: "04.10.2014",
    price: 4.17313,
  },
  {
    Date: "05.10.2014",
    price: 3.94207,
  },
  {
    Date: "06.10.2014",
    price: 3.70305,
  },
  {
    Date: "07.10.2014",
    price: 3.81333,
  },
  {
    Date: "08.10.2014",
    price: 3.85547,
  },
  {
    Date: "09.10.2014",
    price: 3.90862,
  },
  {
    Date: "10.10.2014",
    price: 3.89886,
  },
  {
    Date: "11.10.2014",
    price: 3.79592,
  },
  {
    Date: "12.10.2014",
    price: 3.77658,
  },
  {
    Date: "13.10.2014",
    price: 3.84644,
  },
  {
    Date: "14.10.2014",
    price: 3.86452,
  },
  {
    Date: "15.10.2014",
    price: 4.07699,
  },
  {
    Date: "16.10.2014",
    price: 4.05774,
  },
  {
    Date: "17.10.2014",
    price: 3.99144,
  },
  {
    Date: "18.10.2014",
    price: 3.99163,
  },
  {
    Date: "19.10.2014",
    price: 4.05643,
  },
  {
    Date: "20.10.2014",
    price: 3.98357,
  },
  {
    Date: "21.10.2014",
    price: 3.9118,
  },
  {
    Date: "22.10.2014",
    price: 3.94095,
  },
  {
    Date: "23.10.2014",
    price: 3.84697,
  },
  {
    Date: "24.10.2014",
    price: 3.73949,
  },
  {
    Date: "25.10.2014",
    price: 3.74873,
  },
  {
    Date: "26.10.2014",
    price: 3.66895,
  },
  {
    Date: "27.10.2014",
    price: 3.72406,
  },
  {
    Date: "28.10.2014",
    price: 3.81227,
  },
  {
    Date: "29.10.2014",
    price: 3.85288,
  },
  {
    Date: "30.10.2014",
    price: 3.7345,
  },
  {
    Date: "31.10.2014",
    price: 3.78465,
  },
  {
    Date: "01.11.2014",
    price: 3.75666,
  },
  {
    Date: "02.11.2014",
    price: 3.57688,
  },
  {
    Date: "03.11.2014",
    price: 3.58591,
  },
  {
    Date: "04.11.2014",
    price: 3.58042,
  },
  {
    Date: "05.11.2014",
    price: 3.59031,
  },
  {
    Date: "06.11.2014",
    price: 3.64214,
  },
  {
    Date: "07.11.2014",
    price: 3.64967,
  },
  {
    Date: "08.11.2014",
    price: 3.55665,
  },
  {
    Date: "09.11.2014",
    price: 3.55484,
  },
  {
    Date: "10.11.2014",
    price: 3.63216,
  },
  {
    Date: "11.11.2014",
    price: 3.69851,
  },
  {
    Date: "12.11.2014",
    price: 3.67973,
  },
  {
    Date: "13.11.2014",
    price: 4.13299,
  },
  {
    Date: "14.11.2014",
    price: 4.0855,
  },
  {
    Date: "15.11.2014",
    price: 3.99226,
  },
  {
    Date: "16.11.2014",
    price: 3.84119,
  },
  {
    Date: "17.11.2014",
    price: 3.88056,
  },
  {
    Date: "18.11.2014",
    price: 3.87515,
  },
  {
    Date: "19.11.2014",
    price: 3.77996,
  },
  {
    Date: "20.11.2014",
    price: 3.80696,
  },
  {
    Date: "21.11.2014",
    price: 3.6143,
  },
  {
    Date: "22.11.2014",
    price: 3.50114,
  },
  {
    Date: "23.11.2014",
    price: 3.52278,
  },
  {
    Date: "24.11.2014",
    price: 3.59464,
  },
  {
    Date: "25.11.2014",
    price: 3.62498,
  },
  {
    Date: "26.11.2014",
    price: 3.56891,
  },
  {
    Date: "27.11.2014",
    price: 3.56507,
  },
  {
    Date: "28.11.2014",
    price: 3.56079,
  },
  {
    Date: "29.11.2014",
    price: 3.60471,
  },
  {
    Date: "30.11.2014",
    price: 3.58596,
  },
  {
    Date: "01.12.2014",
    price: 3.57559,
  },
  {
    Date: "02.12.2014",
    price: 3.57799,
  },
  {
    Date: "03.12.2014",
    price: 3.628,
  },
  {
    Date: "04.12.2014",
    price: 3.6117,
  },
  {
    Date: "05.12.2014",
    price: 3.59409,
  },
  {
    Date: "06.12.2014",
    price: 3.61596,
  },
  {
    Date: "07.12.2014",
    price: 3.6156,
  },
  {
    Date: "08.12.2014",
    price: 3.70837,
  },
  {
    Date: "09.12.2014",
    price: 3.65502,
  },
  {
    Date: "10.12.2014",
    price: 3.52384,
  },
  {
    Date: "11.12.2014",
    price: 3.49393,
  },
  {
    Date: "12.12.2014",
    price: 3.4789,
  },
  {
    Date: "13.12.2014",
    price: 3.48657,
  },
  {
    Date: "14.12.2014",
    price: 3.4752,
  },
  {
    Date: "15.12.2014",
    price: 3.48111,
  },
  {
    Date: "16.12.2014",
    price: 3.45184,
  },
  {
    Date: "17.12.2014",
    price: 3.01056,
  },
  {
    Date: "18.12.2014",
    price: 2.94443,
  },
  {
    Date: "19.12.2014",
    price: 2.80528,
  },
  {
    Date: "20.12.2014",
    price: 2.8671,
  },
  {
    Date: "21.12.2014",
    price: 2.91463,
  },
  {
    Date: "22.12.2014",
    price: 2.86025,
  },
  {
    Date: "23.12.2014",
    price: 2.88732,
  },
  {
    Date: "24.12.2014",
    price: 2.8777,
  },
  {
    Date: "25.12.2014",
    price: 2.73595,
  },
  {
    Date: "26.12.2014",
    price: 2.7007,
  },
  {
    Date: "27.12.2014",
    price: 2.77958,
  },
  {
    Date: "28.12.2014",
    price: 2.719,
  },
  {
    Date: "29.12.2014",
    price: 2.7338,
  },
  {
    Date: "30.12.2014",
    price: 2.69617,
  },
  {
    Date: "31.12.2014",
    price: 2.70384,
  },
  {
    Date: "01.01.2015",
    price: 2.72421,
  },
  {
    Date: "02.01.2015",
    price: 2.69743,
  },
  {
    Date: "03.01.2015",
    price: 2.66646,
  },
  {
    Date: "04.01.2015",
    price: 2.11022,
  },
  {
    Date: "05.01.2015",
    price: 1.95803,
  },
  {
    Date: "06.01.2015",
    price: 2.08434,
  },
  {
    Date: "07.01.2015",
    price: 2.1181,
  },
  {
    Date: "08.01.2015",
    price: 2.11561,
  },
  {
    Date: "09.01.2015",
    price: 2.01002,
  },
  {
    Date: "10.01.2015",
    price: 1.96999,
  },
  {
    Date: "11.01.2015",
    price: 1.6536,
  },
  {
    Date: "12.01.2015",
    price: 1.71768,
  },
  {
    Date: "13.01.2015",
    price: 1.7102,
  },
  {
    Date: "14.01.2015",
    price: 1.54961,
  },
  {
    Date: "15.01.2015",
    price: 1.15324,
  },
  {
    Date: "16.01.2015",
    price: 1.27719,
  },
  {
    Date: "17.01.2015",
    price: 1.3978,
  },
  {
    Date: "18.01.2015",
    price: 1.29603,
  },
  {
    Date: "19.01.2015",
    price: 1.32857,
  },
  {
    Date: "20.01.2015",
    price: 1.34099,
  },
  {
    Date: "21.01.2015",
    price: 1.3239,
  },
  {
    Date: "22.01.2015",
    price: 1.37427,
  },
  {
    Date: "23.01.2015",
    price: 1.41231,
  },
  {
    Date: "24.01.2015",
    price: 1.43068,
  },
  {
    Date: "25.01.2015",
    price: 1.84205,
  },
  {
    Date: "26.01.2015",
    price: 2.24549,
  },
  {
    Date: "27.01.2015",
    price: 2.07418,
  },
  {
    Date: "28.01.2015",
    price: 2.09127,
  },
  {
    Date: "29.01.2015",
    price: 1.87663,
  },
  {
    Date: "30.01.2015",
    price: 1.91446,
  },
  {
    Date: "31.01.2015",
    price: 1.89613,
  },
  {
    Date: "01.02.2015",
    price: 1.87878,
  },
  {
    Date: "02.02.2015",
    price: 1.81281,
  },
  {
    Date: "03.02.2015",
    price: 1.87418,
  },
  {
    Date: "04.02.2015",
    price: 1.82085,
  },
  {
    Date: "05.02.2015",
    price: 1.79474,
  },
  {
    Date: "06.02.2015",
    price: 1.74033,
  },
  {
    Date: "07.02.2015",
    price: 1.75956,
  },
  {
    Date: "08.02.2015",
    price: 1.78679,
  },
  {
    Date: "09.02.2015",
    price: 1.78527,
  },
  {
    Date: "10.02.2015",
    price: 1.73325,
  },
  {
    Date: "11.02.2015",
    price: 1.76081,
  },
  {
    Date: "12.02.2015",
    price: 1.73081,
  },
  {
    Date: "13.02.2015",
    price: 1.75509,
  },
  {
    Date: "14.02.2015",
    price: 1.82293,
  },
  {
    Date: "15.02.2015",
    price: 1.94003,
  },
  {
    Date: "16.02.2015",
    price: 1.84448,
  },
  {
    Date: "17.02.2015",
    price: 1.79412,
  },
  {
    Date: "18.02.2015",
    price: 1.85666,
  },
  {
    Date: "19.02.2015",
    price: 1.82521,
  },
  {
    Date: "20.02.2015",
    price: 1.84202,
  },
  {
    Date: "21.02.2015",
    price: 1.84896,
  },
  {
    Date: "22.02.2015",
    price: 1.84988,
  },
  {
    Date: "23.02.2015",
    price: 1.80305,
  },
  {
    Date: "24.02.2015",
    price: 1.81356,
  },
  {
    Date: "25.02.2015",
    price: 1.83,
  },
  {
    Date: "26.02.2015",
    price: 1.8081,
  },
  {
    Date: "27.02.2015",
    price: 1.80117,
  },
  {
    Date: "28.02.2015",
    price: 1.84918,
  },
  {
    Date: "01.03.2015",
    price: 1.84576,
  },
  {
    Date: "02.03.2015",
    price: 1.89242,
  },
  {
    Date: "03.03.2015",
    price: 1.9422,
  },
  {
    Date: "04.03.2015",
    price: 1.95605,
  },
  {
    Date: "05.03.2015",
    price: 1.92448,
  },
  {
    Date: "06.03.2015",
    price: 1.92033,
  },
  {
    Date: "07.03.2015",
    price: 1.89359,
  },
  {
    Date: "08.03.2015",
    price: 1.91353,
  },
  {
    Date: "09.03.2015",
    price: 1.8925,
  },
  {
    Date: "10.03.2015",
    price: 1.944,
  },
  {
    Date: "11.03.2015",
    price: 2.00824,
  },
  {
    Date: "12.03.2015",
    price: 2.02358,
  },
  {
    Date: "13.03.2015",
    price: 2.04211,
  },
  {
    Date: "14.03.2015",
    price: 2.00409,
  },
  {
    Date: "15.03.2015",
    price: 1.99462,
  },
  {
    Date: "16.03.2015",
    price: 2.02176,
  },
  {
    Date: "17.03.2015",
    price: 2.03447,
  },
  {
    Date: "18.03.2015",
    price: 1.98265,
  },
  {
    Date: "19.03.2015",
    price: 1.74365,
  },
  {
    Date: "20.03.2015",
    price: 1.76328,
  },
  {
    Date: "21.03.2015",
    price: 1.76692,
  },
  {
    Date: "22.03.2015",
    price: 1.74583,
  },
  {
    Date: "23.03.2015",
    price: 1.79968,
  },
  {
    Date: "24.03.2015",
    price: 1.77551,
  },
  {
    Date: "25.03.2015",
    price: 1.6546,
  },
  {
    Date: "26.03.2015",
    price: 1.67272,
  },
  {
    Date: "27.03.2015",
    price: 1.6912,
  },
  {
    Date: "28.03.2015",
    price: 1.68267,
  },
  {
    Date: "29.03.2015",
    price: 1.70459,
  },
  {
    Date: "30.03.2015",
    price: 1.65646,
  },
  {
    Date: "31.03.2015",
    price: 1.66961,
  },
  {
    Date: "01.04.2015",
    price: 1.65103,
  },
  {
    Date: "02.04.2015",
    price: 1.66689,
  },
  {
    Date: "03.04.2015",
    price: 1.67682,
  },
  {
    Date: "04.04.2015",
    price: 1.69983,
  },
  {
    Date: "05.04.2015",
    price: 1.68251,
  },
  {
    Date: "06.04.2015",
    price: 1.70164,
  },
  {
    Date: "07.04.2015",
    price: 1.68598,
  },
  {
    Date: "08.04.2015",
    price: 1.66827,
  },
  {
    Date: "09.04.2015",
    price: 1.61959,
  },
  {
    Date: "10.04.2015",
    price: 1.58526,
  },
  {
    Date: "11.04.2015",
    price: 1.52283,
  },
  {
    Date: "12.04.2015",
    price: 1.45879,
  },
  {
    Date: "13.04.2015",
    price: 1.47176,
  },
  {
    Date: "14.04.2015",
    price: 1.37585,
  },
  {
    Date: "15.04.2015",
    price: 1.37596,
  },
  {
    Date: "16.04.2015",
    price: 1.38324,
  },
  {
    Date: "17.04.2015",
    price: 1.42154,
  },
  {
    Date: "18.04.2015",
    price: 1.40984,
  },
  {
    Date: "19.04.2015",
    price: 1.40987,
  },
  {
    Date: "20.04.2015",
    price: 1.38429,
  },
  {
    Date: "21.04.2015",
    price: 1.4081,
  },
  {
    Date: "22.04.2015",
    price: 1.4322,
  },
  {
    Date: "23.04.2015",
    price: 1.45531,
  },
  {
    Date: "24.04.2015",
    price: 1.45821,
  },
  {
    Date: "25.04.2015",
    price: 1.42041,
  },
  {
    Date: "26.04.2015",
    price: 1.3995,
  },
  {
    Date: "27.04.2015",
    price: 1.32185,
  },
  {
    Date: "28.04.2015",
    price: 1.38264,
  },
  {
    Date: "29.04.2015",
    price: 1.36257,
  },
  {
    Date: "30.04.2015",
    price: 1.36098,
  },
  {
    Date: "01.05.2015",
    price: 1.44285,
  },
  {
    Date: "02.05.2015",
    price: 1.40725,
  },
  {
    Date: "03.05.2015",
    price: 1.4198,
  },
  {
    Date: "04.05.2015",
    price: 1.43461,
  },
  {
    Date: "05.05.2015",
    price: 1.41488,
  },
  {
    Date: "06.05.2015",
    price: 1.40633,
  },
  {
    Date: "07.05.2015",
    price: 1.38973,
  },
  {
    Date: "08.05.2015",
    price: 1.4549,
  },
  {
    Date: "09.05.2015",
    price: 1.48048,
  },
  {
    Date: "10.05.2015",
    price: 1.4483,
  },
  {
    Date: "11.05.2015",
    price: 1.43852,
  },
  {
    Date: "12.05.2015",
    price: 1.43881,
  },
  {
    Date: "13.05.2015",
    price: 1.44381,
  },
  {
    Date: "14.05.2015",
    price: 1.45004,
  },
  {
    Date: "15.05.2015",
    price: 1.44784,
  },
  {
    Date: "16.05.2015",
    price: 1.4547,
  },
  {
    Date: "17.05.2015",
    price: 1.44647,
  },
  {
    Date: "18.05.2015",
    price: 1.45364,
  },
  {
    Date: "19.05.2015",
    price: 1.44678,
  },
  {
    Date: "20.05.2015",
    price: 1.45352,
  },
  {
    Date: "21.05.2015",
    price: 1.46075,
  },
  {
    Date: "22.05.2015",
    price: 1.47949,
  },
  {
    Date: "23.05.2015",
    price: 1.8313,
  },
  {
    Date: "24.05.2015",
    price: 1.78738,
  },
  {
    Date: "25.05.2015",
    price: 1.83341,
  },
  {
    Date: "26.05.2015",
    price: 1.8027,
  },
  {
    Date: "27.05.2015",
    price: 1.81612,
  },
  {
    Date: "28.05.2015",
    price: 1.847,
  },
  {
    Date: "29.05.2015",
    price: 1.8406,
  },
  {
    Date: "30.05.2015",
    price: 1.83017,
  },
  {
    Date: "31.05.2015",
    price: 1.81209,
  },
  {
    Date: "01.06.2015",
    price: 1.63856,
  },
  {
    Date: "02.06.2015",
    price: 1.61608,
  },
  {
    Date: "03.06.2015",
    price: 1.6705,
  },
  {
    Date: "04.06.2015",
    price: 1.68667,
  },
  {
    Date: "05.06.2015",
    price: 1.66929,
  },
  {
    Date: "06.06.2015",
    price: 1.76106,
  },
  {
    Date: "07.06.2015",
    price: 1.7585,
  },
  {
    Date: "08.06.2015",
    price: 1.73182,
  },
  {
    Date: "09.06.2015",
    price: 1.78491,
  },
  {
    Date: "10.06.2015",
    price: 1.79665,
  },
  {
    Date: "11.06.2015",
    price: 1.75915,
  },
  {
    Date: "12.06.2015",
    price: 1.7896,
  },
  {
    Date: "13.06.2015",
    price: 1.80274,
  },
  {
    Date: "14.06.2015",
    price: 1.86078,
  },
  {
    Date: "15.06.2015",
    price: 2.00235,
  },
  {
    Date: "16.06.2015",
    price: 2.01804,
  },
  {
    Date: "17.06.2015",
    price: 2.89642,
  },
  {
    Date: "18.06.2015",
    price: 2.89033,
  },
  {
    Date: "19.06.2015",
    price: 3.09533,
  },
  {
    Date: "20.06.2015",
    price: 2.8102,
  },
  {
    Date: "21.06.2015",
    price: 3.03519,
  },
  {
    Date: "22.06.2015",
    price: 3.02145,
  },
  {
    Date: "23.06.2015",
    price: 3.02777,
  },
  {
    Date: "24.06.2015",
    price: 2.97423,
  },
  {
    Date: "25.06.2015",
    price: 2.77278,
  },
  {
    Date: "26.06.2015",
    price: 2.84674,
  },
  {
    Date: "27.06.2015",
    price: 2.86143,
  },
  {
    Date: "28.06.2015",
    price: 3.09358,
  },
  {
    Date: "29.06.2015",
    price: 3.18773,
  },
  {
    Date: "30.06.2015",
    price: 3.74412,
  },
  {
    Date: "01.07.2015",
    price: 4.086,
  },
  {
    Date: "02.07.2015",
    price: 3.92664,
  },
  {
    Date: "03.07.2015",
    price: 4.06233,
  },
  {
    Date: "04.07.2015",
    price: 4.11832,
  },
  {
    Date: "05.07.2015",
    price: 4.1269,
  },
  {
    Date: "06.07.2015",
    price: 4.947,
  },
  {
    Date: "07.07.2015",
    price: 5.43707,
  },
  {
    Date: "08.07.2015",
    price: 5.24678,
  },
  {
    Date: "09.07.2015",
    price: 6.35645,
  },
  {
    Date: "10.07.2015",
    price: 7.52701,
  },
  {
    Date: "11.07.2015",
    price: 4.47577,
  },
  {
    Date: "12.07.2015",
    price: 4.38751,
  },
  {
    Date: "13.07.2015",
    price: 5.29992,
  },
  {
    Date: "14.07.2015",
    price: 4.68284,
  },
  {
    Date: "15.07.2015",
    price: 4.64896,
  },
  {
    Date: "16.07.2015",
    price: 4.19748,
  },
  {
    Date: "17.07.2015",
    price: 3.72079,
  },
  {
    Date: "18.07.2015",
    price: 3.78299,
  },
  {
    Date: "19.07.2015",
    price: 4.00438,
  },
  {
    Date: "20.07.2015",
    price: 3.77032,
  },
  {
    Date: "21.07.2015",
    price: 3.93053,
  },
  {
    Date: "22.07.2015",
    price: 3.78298,
  },
  {
    Date: "23.07.2015",
    price: 3.82554,
  },
  {
    Date: "24.07.2015",
    price: 3.78946,
  },
  {
    Date: "25.07.2015",
    price: 4.59776,
  },
  {
    Date: "26.07.2015",
    price: 4.63255,
  },
  {
    Date: "27.07.2015",
    price: 4.68313,
  },
  {
    Date: "28.07.2015",
    price: 4.67001,
  },
  {
    Date: "29.07.2015",
    price: 5.04962,
  },
  {
    Date: "30.07.2015",
    price: 4.79262,
  },
  {
    Date: "31.07.2015",
    price: 4.5822,
  },
  {
    Date: "01.08.2015",
    price: 4.63908,
  },
  {
    Date: "02.08.2015",
    price: 4.08738,
  },
  {
    Date: "03.08.2015",
    price: 4.16947,
  },
  {
    Date: "04.08.2015",
    price: 4.18342,
  },
  {
    Date: "05.08.2015",
    price: 4.37433,
  },
  {
    Date: "06.08.2015",
    price: 4.349,
  },
  {
    Date: "07.08.2015",
    price: 4.06334,
  },
  {
    Date: "08.08.2015",
    price: 4.22099,
  },
  {
    Date: "09.08.2015",
    price: 3.84339,
  },
  {
    Date: "10.08.2015",
    price: 3.9008,
  },
  {
    Date: "11.08.2015",
    price: 3.94874,
  },
  {
    Date: "12.08.2015",
    price: 4.14334,
  },
  {
    Date: "13.08.2015",
    price: 3.97227,
  },
  {
    Date: "14.08.2015",
    price: 3.88069,
  },
  {
    Date: "15.08.2015",
    price: 4.02249,
  },
  {
    Date: "16.08.2015",
    price: 3.93203,
  },
  {
    Date: "17.08.2015",
    price: 3.96332,
  },
  {
    Date: "18.08.2015",
    price: 3.98368,
  },
  {
    Date: "19.08.2015",
    price: 3.47965,
  },
  {
    Date: "20.08.2015",
    price: 3.47081,
  },
  {
    Date: "21.08.2015",
    price: 3.60023,
  },
  {
    Date: "22.08.2015",
    price: 3.56256,
  },
  {
    Date: "23.08.2015",
    price: 3.51328,
  },
  {
    Date: "24.08.2015",
    price: 3.39258,
  },
  {
    Date: "25.08.2015",
    price: 2.98477,
  },
  {
    Date: "26.08.2015",
    price: 2.94969,
  },
  {
    Date: "27.08.2015",
    price: 2.94614,
  },
  {
    Date: "28.08.2015",
    price: 2.84239,
  },
  {
    Date: "29.08.2015",
    price: 2.91616,
  },
  {
    Date: "30.08.2015",
    price: 2.87156,
  },
  {
    Date: "31.08.2015",
    price: 2.85323,
  },
  {
    Date: "01.09.2015",
    price: 2.84742,
  },
  {
    Date: "02.09.2015",
    price: 2.81981,
  },
  {
    Date: "03.09.2015",
    price: 2.79669,
  },
  {
    Date: "04.09.2015",
    price: 2.63814,
  },
  {
    Date: "05.09.2015",
    price: 2.70205,
  },
  {
    Date: "06.09.2015",
    price: 2.91782,
  },
  {
    Date: "07.09.2015",
    price: 3.039,
  },
  {
    Date: "08.09.2015",
    price: 3.04737,
  },
  {
    Date: "09.09.2015",
    price: 3.04828,
  },
  {
    Date: "10.09.2015",
    price: 2.92465,
  },
  {
    Date: "11.09.2015",
    price: 2.94878,
  },
  {
    Date: "12.09.2015",
    price: 2.96257,
  },
  {
    Date: "13.09.2015",
    price: 2.82479,
  },
  {
    Date: "14.09.2015",
    price: 2.81492,
  },
  {
    Date: "15.09.2015",
    price: 2.8442,
  },
  {
    Date: "16.09.2015",
    price: 2.82994,
  },
  {
    Date: "17.09.2015",
    price: 2.80622,
  },
  {
    Date: "18.09.2015",
    price: 2.91062,
  },
  {
    Date: "19.09.2015",
    price: 2.95491,
  },
  {
    Date: "20.09.2015",
    price: 2.87507,
  },
  {
    Date: "21.09.2015",
    price: 2.85372,
  },
  {
    Date: "22.09.2015",
    price: 2.79847,
  },
  {
    Date: "23.09.2015",
    price: 2.85505,
  },
  {
    Date: "24.09.2015",
    price: 2.87151,
  },
  {
    Date: "25.09.2015",
    price: 2.93324,
  },
  {
    Date: "26.09.2015",
    price: 2.89472,
  },
  {
    Date: "27.09.2015",
    price: 2.88899,
  },
  {
    Date: "28.09.2015",
    price: 2.88133,
  },
  {
    Date: "29.09.2015",
    price: 3.10265,
  },
  {
    Date: "30.09.2015",
    price: 2.96609,
  },
  {
    Date: "01.10.2015",
    price: 3.0139,
  },
  {
    Date: "02.10.2015",
    price: 2.99683,
  },
  {
    Date: "03.10.2015",
    price: 2.99867,
  },
  {
    Date: "04.10.2015",
    price: 3.03292,
  },
  {
    Date: "05.10.2015",
    price: 3.02106,
  },
  {
    Date: "06.10.2015",
    price: 3.02012,
  },
  {
    Date: "07.10.2015",
    price: 3.14897,
  },
  {
    Date: "08.10.2015",
    price: 3.06134,
  },
  {
    Date: "09.10.2015",
    price: 3.06913,
  },
  {
    Date: "10.10.2015",
    price: 3.11235,
  },
  {
    Date: "11.10.2015",
    price: 3.10868,
  },
  {
    Date: "12.10.2015",
    price: 3.14903,
  },
  {
    Date: "13.10.2015",
    price: 3.13909,
  },
  {
    Date: "14.10.2015",
    price: 3.18153,
  },
  {
    Date: "15.10.2015",
    price: 3.14995,
  },
  {
    Date: "16.10.2015",
    price: 3.10864,
  },
  {
    Date: "17.10.2015",
    price: 3.11124,
  },
  {
    Date: "18.10.2015",
    price: 3.07411,
  },
  {
    Date: "19.10.2015",
    price: 3.03062,
  },
  {
    Date: "20.10.2015",
    price: 3.05258,
  },
  {
    Date: "21.10.2015",
    price: 3.09344,
  },
  {
    Date: "22.10.2015",
    price: 3.08079,
  },
  {
    Date: "23.10.2015",
    price: 3.12043,
  },
  {
    Date: "24.10.2015",
    price: 3.11266,
  },
  {
    Date: "25.10.2015",
    price: 3.10972,
  },
  {
    Date: "26.10.2015",
    price: 3.0711,
  },
  {
    Date: "27.10.2015",
    price: 3.10703,
  },
  {
    Date: "28.10.2015",
    price: 3.0993,
  },
  {
    Date: "29.10.2015",
    price: 3.10248,
  },
  {
    Date: "30.10.2015",
    price: 3.76857,
  },
  {
    Date: "31.10.2015",
    price: 4.08356,
  },
  {
    Date: "01.11.2015",
    price: 3.81509,
  },
  {
    Date: "02.11.2015",
    price: 3.98289,
  },
  {
    Date: "03.11.2015",
    price: 4.17497,
  },
  {
    Date: "04.11.2015",
    price: 4.5799,
  },
  {
    Date: "05.11.2015",
    price: 4.19955,
  },
  {
    Date: "06.11.2015",
    price: 3.94524,
  },
  {
    Date: "07.11.2015",
    price: 3.55124,
  },
  {
    Date: "08.11.2015",
    price: 3.61987,
  },
  {
    Date: "09.11.2015",
    price: 3.43286,
  },
  {
    Date: "10.11.2015",
    price: 3.40039,
  },
  {
    Date: "11.11.2015",
    price: 3.11711,
  },
  {
    Date: "12.11.2015",
    price: 2.97215,
  },
  {
    Date: "13.11.2015",
    price: 3.26864,
  },
  {
    Date: "14.11.2015",
    price: 3.22337,
  },
  {
    Date: "15.11.2015",
    price: 3.19864,
  },
  {
    Date: "16.11.2015",
    price: 3.06645,
  },
  {
    Date: "17.11.2015",
    price: 3.19037,
  },
  {
    Date: "18.11.2015",
    price: 3.19891,
  },
  {
    Date: "19.11.2015",
    price: 3.19123,
  },
  {
    Date: "20.11.2015",
    price: 3.13604,
  },
  {
    Date: "21.11.2015",
    price: 3.11744,
  },
  {
    Date: "22.11.2015",
    price: 3.16329,
  },
  {
    Date: "23.11.2015",
    price: 3.14389,
  },
  {
    Date: "24.11.2015",
    price: 3.13036,
  },
  {
    Date: "25.11.2015",
    price: 3.11383,
  },
  {
    Date: "26.11.2015",
    price: 3.35139,
  },
  {
    Date: "27.11.2015",
    price: 3.6014,
  },
  {
    Date: "28.11.2015",
    price: 3.57541,
  },
  {
    Date: "29.11.2015",
    price: 3.53436,
  },
  {
    Date: "30.11.2015",
    price: 3.66437,
  },
  {
    Date: "01.12.2015",
    price: 3.62608,
  },
  {
    Date: "02.12.2015",
    price: 3.43093,
  },
  {
    Date: "03.12.2015",
    price: 3.34672,
  },
  {
    Date: "04.12.2015",
    price: 3.38233,
  },
  {
    Date: "05.12.2015",
    price: 3.36994,
  },
  {
    Date: "06.12.2015",
    price: 3.52538,
  },
  {
    Date: "07.12.2015",
    price: 3.45255,
  },
  {
    Date: "08.12.2015",
    price: 3.64767,
  },
  {
    Date: "09.12.2015",
    price: 3.68282,
  },
  {
    Date: "10.12.2015",
    price: 3.66964,
  },
  {
    Date: "11.12.2015",
    price: 3.67299,
  },
  {
    Date: "12.12.2015",
    price: 3.84252,
  },
  {
    Date: "13.12.2015",
    price: 3.5963,
  },
  {
    Date: "14.12.2015",
    price: 3.63688,
  },
  {
    Date: "15.12.2015",
    price: 3.61842,
  },
  {
    Date: "16.12.2015",
    price: 3.83891,
  },
  {
    Date: "17.12.2015",
    price: 3.70411,
  },
  {
    Date: "18.12.2015",
    price: 3.75132,
  },
  {
    Date: "19.12.2015",
    price: 3.76516,
  },
  {
    Date: "20.12.2015",
    price: 3.74056,
  },
  {
    Date: "21.12.2015",
    price: 3.54621,
  },
  {
    Date: "22.12.2015",
    price: 3.45878,
  },
  {
    Date: "23.12.2015",
    price: 3.43238,
  },
  {
    Date: "24.12.2015",
    price: 3.59019,
  },
  {
    Date: "25.12.2015",
    price: 3.64888,
  },
  {
    Date: "26.12.2015",
    price: 3.64114,
  },
  {
    Date: "27.12.2015",
    price: 3.42032,
  },
  {
    Date: "28.12.2015",
    price: 3.47781,
  },
  {
    Date: "29.12.2015",
    price: 3.47063,
  },
  {
    Date: "30.12.2015",
    price: 3.512,
  },
  {
    Date: "31.12.2015",
    price: 3.4593,
  },
  {
    Date: "01.01.2016",
    price: 3.47952,
  },
  {
    Date: "02.01.2016",
    price: 3.51528,
  },
  {
    Date: "03.01.2016",
    price: 3.504,
  },
  {
    Date: "04.01.2016",
    price: 3.4791,
  },
  {
    Date: "05.01.2016",
    price: 3.492,
  },
  {
    Date: "06.01.2016",
    price: 3.46706,
  },
  {
    Date: "07.01.2016",
    price: 3.45007,
  },
  {
    Date: "08.01.2016",
    price: 3.5968,
  },
  {
    Date: "09.01.2016",
    price: 3.57435,
  },
  {
    Date: "10.01.2016",
    price: 3.55916,
  },
  {
    Date: "11.01.2016",
    price: 3.52627,
  },
  {
    Date: "12.01.2016",
    price: 3.55302,
  },
  {
    Date: "13.01.2016",
    price: 3.48998,
  },
  {
    Date: "14.01.2016",
    price: 3.47093,
  },
  {
    Date: "15.01.2016",
    price: 3.46404,
  },
  {
    Date: "16.01.2016",
    price: 2.96572,
  },
  {
    Date: "17.01.2016",
    price: 3.08732,
  },
  {
    Date: "18.01.2016",
    price: 3.01955,
  },
  {
    Date: "19.01.2016",
    price: 3.04766,
  },
  {
    Date: "20.01.2016",
    price: 3.0148,
  },
  {
    Date: "21.01.2016",
    price: 3.38423,
  },
  {
    Date: "22.01.2016",
    price: 3.23105,
  },
  {
    Date: "23.01.2016",
    price: 3.08203,
  },
  {
    Date: "24.01.2016",
    price: 3.11351,
  },
  {
    Date: "25.01.2016",
    price: 3.18795,
  },
  {
    Date: "26.01.2016",
    price: 3.12537,
  },
  {
    Date: "27.01.2016",
    price: 3.15935,
  },
  {
    Date: "28.01.2016",
    price: 3.29286,
  },
  {
    Date: "29.01.2016",
    price: 3.1384,
  },
  {
    Date: "30.01.2016",
    price: 3.11813,
  },
  {
    Date: "31.01.2016",
    price: 3.09693,
  },
  {
    Date: "01.02.2016",
    price: 3.07343,
  },
  {
    Date: "02.02.2016",
    price: 3.08801,
  },
  {
    Date: "03.02.2016",
    price: 3.08639,
  },
  {
    Date: "04.02.2016",
    price: 3.05753,
  },
  {
    Date: "05.02.2016",
    price: 3.17151,
  },
  {
    Date: "06.02.2016",
    price: 3.14997,
  },
  {
    Date: "07.02.2016",
    price: 3.1096,
  },
  {
    Date: "08.02.2016",
    price: 3.0919,
  },
  {
    Date: "09.02.2016",
    price: 3.07608,
  },
  {
    Date: "10.02.2016",
    price: 3.08898,
  },
  {
    Date: "11.02.2016",
    price: 3.11214,
  },
  {
    Date: "12.02.2016",
    price: 3.09224,
  },
  {
    Date: "13.02.2016",
    price: 3.11812,
  },
  {
    Date: "14.02.2016",
    price: 3.14773,
  },
  {
    Date: "15.02.2016",
    price: 3.23204,
  },
  {
    Date: "16.02.2016",
    price: 3.20227,
  },
  {
    Date: "17.02.2016",
    price: 3.22731,
  },
  {
    Date: "18.02.2016",
    price: 3.2452,
  },
  {
    Date: "19.02.2016",
    price: 3.26045,
  },
  {
    Date: "20.02.2016",
    price: 3.26811,
  },
  {
    Date: "21.02.2016",
    price: 3.45997,
  },
  {
    Date: "22.02.2016",
    price: 3.46592,
  },
  {
    Date: "23.02.2016",
    price: 3.48881,
  },
  {
    Date: "24.02.2016",
    price: 3.40327,
  },
  {
    Date: "25.02.2016",
    price: 3.38611,
  },
  {
    Date: "26.02.2016",
    price: 3.36949,
  },
  {
    Date: "27.02.2016",
    price: 3.42881,
  },
  {
    Date: "28.02.2016",
    price: 3.41416,
  },
  {
    Date: "29.02.2016",
    price: 3.4412,
  },
  {
    Date: "01.03.2016",
    price: 3.44835,
  },
  {
    Date: "02.03.2016",
    price: 3.43436,
  },
  {
    Date: "03.03.2016",
    price: 3.36374,
  },
  {
    Date: "04.03.2016",
    price: 3.3215,
  },
  {
    Date: "05.03.2016",
    price: 3.25172,
  },
  {
    Date: "06.03.2016",
    price: 3.19149,
  },
  {
    Date: "07.03.2016",
    price: 3.22162,
  },
  {
    Date: "08.03.2016",
    price: 3.27141,
  },
  {
    Date: "09.03.2016",
    price: 3.25055,
  },
  {
    Date: "10.03.2016",
    price: 3.27791,
  },
  {
    Date: "11.03.2016",
    price: 3.28632,
  },
  {
    Date: "12.03.2016",
    price: 3.39113,
  },
  {
    Date: "13.03.2016",
    price: 3.28942,
  },
  {
    Date: "14.03.2016",
    price: 3.30909,
  },
  {
    Date: "15.03.2016",
    price: 3.30329,
  },
  {
    Date: "16.03.2016",
    price: 3.30711,
  },
  {
    Date: "17.03.2016",
    price: 3.31136,
  },
  {
    Date: "18.03.2016",
    price: 3.30715,
  },
  {
    Date: "19.03.2016",
    price: 3.17078,
  },
  {
    Date: "20.03.2016",
    price: 3.18153,
  },
  {
    Date: "21.03.2016",
    price: 3.20519,
  },
  {
    Date: "22.03.2016",
    price: 3.20004,
  },
  {
    Date: "23.03.2016",
    price: 3.23384,
  },
  {
    Date: "24.03.2016",
    price: 3.24502,
  },
  {
    Date: "25.03.2016",
    price: 3.22784,
  },
  {
    Date: "26.03.2016",
    price: 3.22772,
  },
  {
    Date: "27.03.2016",
    price: 3.23182,
  },
  {
    Date: "28.03.2016",
    price: 3.30291,
  },
  {
    Date: "29.03.2016",
    price: 3.26176,
  },
  {
    Date: "30.03.2016",
    price: 3.22665,
  },
  {
    Date: "31.03.2016",
    price: 3.22548,
  },
  {
    Date: "01.04.2016",
    price: 3.24575,
  },
  {
    Date: "02.04.2016",
    price: 3.2459,
  },
  {
    Date: "03.04.2016",
    price: 3.26145,
  },
  {
    Date: "04.04.2016",
    price: 3.2613,
  },
  {
    Date: "05.04.2016",
    price: 3.24604,
  },
  {
    Date: "06.04.2016",
    price: 3.25852,
  },
  {
    Date: "07.04.2016",
    price: 3.25701,
  },
  {
    Date: "08.04.2016",
    price: 3.24191,
  },
  {
    Date: "09.04.2016",
    price: 3.22402,
  },
  {
    Date: "10.04.2016",
    price: 3.22186,
  },
  {
    Date: "11.04.2016",
    price: 3.22745,
  },
  {
    Date: "12.04.2016",
    price: 3.24014,
  },
  {
    Date: "13.04.2016",
    price: 3.26295,
  },
  {
    Date: "14.04.2016",
    price: 3.24574,
  },
  {
    Date: "15.04.2016",
    price: 3.24321,
  },
  {
    Date: "16.04.2016",
    price: 3.27985,
  },
  {
    Date: "17.04.2016",
    price: 3.28005,
  },
  {
    Date: "18.04.2016",
    price: 3.26037,
  },
  {
    Date: "19.04.2016",
    price: 3.2489,
  },
  {
    Date: "20.04.2016",
    price: 3.29175,
  },
  {
    Date: "21.04.2016",
    price: 3.28093,
  },
  {
    Date: "22.04.2016",
    price: 3.34679,
  },
  {
    Date: "23.04.2016",
    price: 3.32078,
  },
  {
    Date: "24.04.2016",
    price: 3.34636,
  },
  {
    Date: "25.04.2016",
    price: 3.62286,
  },
  {
    Date: "26.04.2016",
    price: 3.79559,
  },
  {
    Date: "27.04.2016",
    price: 4.04861,
  },
  {
    Date: "28.04.2016",
    price: 3.87886,
  },
  {
    Date: "29.04.2016",
    price: 3.79207,
  },
  {
    Date: "30.04.2016",
    price: 3.81348,
  },
  {
    Date: "01.05.2016",
    price: 3.6575,
  },
  {
    Date: "02.05.2016",
    price: 3.70871,
  },
  {
    Date: "03.05.2016",
    price: 3.68211,
  },
  {
    Date: "04.05.2016",
    price: 3.74184,
  },
  {
    Date: "05.05.2016",
    price: 3.74181,
  },
  {
    Date: "06.05.2016",
    price: 3.71613,
  },
  {
    Date: "07.05.2016",
    price: 3.81952,
  },
  {
    Date: "08.05.2016",
    price: 3.93641,
  },
  {
    Date: "09.05.2016",
    price: 3.93763,
  },
  {
    Date: "10.05.2016",
    price: 4.05797,
  },
  {
    Date: "11.05.2016",
    price: 3.82033,
  },
  {
    Date: "12.05.2016",
    price: 3.88134,
  },
  {
    Date: "13.05.2016",
    price: 3.85527,
  },
  {
    Date: "14.05.2016",
    price: 3.92728,
  },
  {
    Date: "15.05.2016",
    price: 4.01409,
  },
  {
    Date: "16.05.2016",
    price: 4.06308,
  },
  {
    Date: "17.05.2016",
    price: 4.00364,
  },
  {
    Date: "18.05.2016",
    price: 3.9798,
  },
  {
    Date: "19.05.2016",
    price: 4.00244,
  },
  {
    Date: "20.05.2016",
    price: 3.82493,
  },
  {
    Date: "21.05.2016",
    price: 3.88352,
  },
  {
    Date: "22.05.2016",
    price: 3.95659,
  },
  {
    Date: "23.05.2016",
    price: 3.92293,
  },
  {
    Date: "24.05.2016",
    price: 3.96388,
  },
  {
    Date: "25.05.2016",
    price: 3.95081,
  },
  {
    Date: "26.05.2016",
    price: 4.04526,
  },
  {
    Date: "27.05.2016",
    price: 4.08942,
  },
  {
    Date: "28.05.2016",
    price: 4.51095,
  },
  {
    Date: "29.05.2016",
    price: 4.68873,
  },
  {
    Date: "30.05.2016",
    price: 4.63264,
  },
  {
    Date: "31.05.2016",
    price: 4.6876,
  },
  {
    Date: "01.06.2016",
    price: 4.65329,
  },
  {
    Date: "02.06.2016",
    price: 4.74465,
  },
  {
    Date: "03.06.2016",
    price: 4.71958,
  },
  {
    Date: "04.06.2016",
    price: 4.86319,
  },
  {
    Date: "05.06.2016",
    price: 4.79843,
  },
  {
    Date: "06.06.2016",
    price: 4.81446,
  },
  {
    Date: "07.06.2016",
    price: 4.95482,
  },
  {
    Date: "08.06.2016",
    price: 4.77429,
  },
  {
    Date: "09.06.2016",
    price: 4.77788,
  },
  {
    Date: "10.06.2016",
    price: 4.752,
  },
  {
    Date: "11.06.2016",
    price: 4.88234,
  },
  {
    Date: "12.06.2016",
    price: 5.0673,
  },
  {
    Date: "13.06.2016",
    price: 5.41465,
  },
  {
    Date: "14.06.2016",
    price: 5.30518,
  },
  {
    Date: "15.06.2016",
    price: 5.2231,
  },
  {
    Date: "16.06.2016",
    price: 5.25305,
  },
  {
    Date: "17.06.2016",
    price: 5.67243,
  },
  {
    Date: "18.06.2016",
    price: 5.60399,
  },
  {
    Date: "19.06.2016",
    price: 5.56037,
  },
  {
    Date: "20.06.2016",
    price: 5.62519,
  },
  {
    Date: "21.06.2016",
    price: 5.27355,
  },
  {
    Date: "22.06.2016",
    price: 4.82753,
  },
  {
    Date: "23.06.2016",
    price: 3.91814,
  },
  {
    Date: "24.06.2016",
    price: 3.85183,
  },
  {
    Date: "25.06.2016",
    price: 4.2746,
  },
  {
    Date: "26.06.2016",
    price: 4.24301,
  },
  {
    Date: "27.06.2016",
    price: 4.05153,
  },
  {
    Date: "28.06.2016",
    price: 4.13775,
  },
  {
    Date: "29.06.2016",
    price: 4.10169,
  },
  {
    Date: "30.06.2016",
    price: 4.07738,
  },
  {
    Date: "01.07.2016",
    price: 4.19349,
  },
  {
    Date: "02.07.2016",
    price: 4.27777,
  },
  {
    Date: "03.07.2016",
    price: 4.66093,
  },
  {
    Date: "04.07.2016",
    price: 4.27269,
  },
  {
    Date: "05.07.2016",
    price: 4.55779,
  },
  {
    Date: "06.07.2016",
    price: 4.46446,
  },
  {
    Date: "07.07.2016",
    price: 4.49656,
  },
  {
    Date: "08.07.2016",
    price: 4.11567,
  },
  {
    Date: "09.07.2016",
    price: 4.23739,
  },
  {
    Date: "10.07.2016",
    price: 4.12157,
  },
  {
    Date: "11.07.2016",
    price: 4.12118,
  },
  {
    Date: "12.07.2016",
    price: 4.09219,
  },
  {
    Date: "13.07.2016",
    price: 4.16283,
  },
  {
    Date: "14.07.2016",
    price: 4.14098,
  },
  {
    Date: "15.07.2016",
    price: 4.15446,
  },
  {
    Date: "16.07.2016",
    price: 4.15271,
  },
  {
    Date: "17.07.2016",
    price: 4.13772,
  },
  {
    Date: "18.07.2016",
    price: 4.19939,
  },
  {
    Date: "19.07.2016",
    price: 4.15275,
  },
  {
    Date: "20.07.2016",
    price: 4.14999,
  },
  {
    Date: "21.07.2016",
    price: 4.13319,
  },
  {
    Date: "22.07.2016",
    price: 4.12578,
  },
  {
    Date: "23.07.2016",
    price: 4.0426,
  },
  {
    Date: "24.07.2016",
    price: 4.06375,
  },
  {
    Date: "25.07.2016",
    price: 4.07262,
  },
  {
    Date: "26.07.2016",
    price: 4.03719,
  },
  {
    Date: "27.07.2016",
    price: 3.96491,
  },
  {
    Date: "28.07.2016",
    price: 3.96576,
  },
  {
    Date: "29.07.2016",
    price: 3.97479,
  },
  {
    Date: "30.07.2016",
    price: 4.08266,
  },
  {
    Date: "31.07.2016",
    price: 4.07958,
  },
  {
    Date: "01.08.2016",
    price: 4.06291,
  },
  {
    Date: "02.08.2016",
    price: 3.87169,
  },
  {
    Date: "03.08.2016",
    price: 3.63943,
  },
  {
    Date: "04.08.2016",
    price: 3.71197,
  },
  {
    Date: "05.08.2016",
    price: 3.74201,
  },
  {
    Date: "06.08.2016",
    price: 3.72116,
  },
  {
    Date: "07.08.2016",
    price: 3.75868,
  },
  {
    Date: "08.08.2016",
    price: 3.77451,
  },
  {
    Date: "09.08.2016",
    price: 3.78284,
  },
  {
    Date: "10.08.2016",
    price: 3.76176,
  },
  {
    Date: "11.08.2016",
    price: 3.71741,
  },
  {
    Date: "12.08.2016",
    price: 3.73846,
  },
  {
    Date: "13.08.2016",
    price: 3.70867,
  },
  {
    Date: "14.08.2016",
    price: 3.71571,
  },
  {
    Date: "15.08.2016",
    price: 3.64829,
  },
  {
    Date: "16.08.2016",
    price: 3.58503,
  },
  {
    Date: "17.08.2016",
    price: 3.64511,
  },
  {
    Date: "18.08.2016",
    price: 3.6028,
  },
  {
    Date: "19.08.2016",
    price: 3.61098,
  },
  {
    Date: "20.08.2016",
    price: 3.608,
  },
  {
    Date: "21.08.2016",
    price: 3.61941,
  },
  {
    Date: "22.08.2016",
    price: 3.61742,
  },
  {
    Date: "23.08.2016",
    price: 3.66442,
  },
  {
    Date: "24.08.2016",
    price: 3.92881,
  },
  {
    Date: "25.08.2016",
    price: 3.85649,
  },
  {
    Date: "26.08.2016",
    price: 3.80192,
  },
  {
    Date: "27.08.2016",
    price: 3.82494,
  },
  {
    Date: "28.08.2016",
    price: 3.73608,
  },
  {
    Date: "29.08.2016",
    price: 3.73766,
  },
  {
    Date: "30.08.2016",
    price: 3.76752,
  },
  {
    Date: "31.08.2016",
    price: 3.80607,
  },
  {
    Date: "01.09.2016",
    price: 3.79563,
  },
  {
    Date: "02.09.2016",
    price: 3.83675,
  },
  {
    Date: "03.09.2016",
    price: 3.82702,
  },
  {
    Date: "04.09.2016",
    price: 3.8931,
  },
  {
    Date: "05.09.2016",
    price: 4.02154,
  },
  {
    Date: "06.09.2016",
    price: 3.98424,
  },
  {
    Date: "07.09.2016",
    price: 3.98913,
  },
  {
    Date: "08.09.2016",
    price: 3.96945,
  },
  {
    Date: "09.09.2016",
    price: 3.98371,
  },
  {
    Date: "10.09.2016",
    price: 3.97222,
  },
  {
    Date: "11.09.2016",
    price: 3.96853,
  },
  {
    Date: "12.09.2016",
    price: 3.87034,
  },
  {
    Date: "13.09.2016",
    price: 3.8318,
  },
  {
    Date: "14.09.2016",
    price: 3.81881,
  },
  {
    Date: "15.09.2016",
    price: 3.83005,
  },
  {
    Date: "16.09.2016",
    price: 3.81537,
  },
  {
    Date: "17.09.2016",
    price: 3.80028,
  },
  {
    Date: "18.09.2016",
    price: 3.8102,
  },
  {
    Date: "19.09.2016",
    price: 4.04262,
  },
  {
    Date: "20.09.2016",
    price: 3.82936,
  },
  {
    Date: "21.09.2016",
    price: 3.86921,
  },
  {
    Date: "22.09.2016",
    price: 3.85651,
  },
  {
    Date: "23.09.2016",
    price: 3.7652,
  },
  {
    Date: "24.09.2016",
    price: 3.81472,
  },
  {
    Date: "25.09.2016",
    price: 3.79601,
  },
  {
    Date: "26.09.2016",
    price: 3.79999,
  },
  {
    Date: "27.09.2016",
    price: 3.85684,
  },
  {
    Date: "28.09.2016",
    price: 3.85058,
  },
  {
    Date: "29.09.2016",
    price: 3.83783,
  },
  {
    Date: "30.09.2016",
    price: 3.8445,
  },
  {
    Date: "01.10.2016",
    price: 3.84776,
  },
  {
    Date: "02.10.2016",
    price: 3.84629,
  },
  {
    Date: "03.10.2016",
    price: 3.85015,
  },
  {
    Date: "04.10.2016",
    price: 3.83891,
  },
  {
    Date: "05.10.2016",
    price: 3.83212,
  },
  {
    Date: "06.10.2016",
    price: 3.84639,
  },
  {
    Date: "07.10.2016",
    price: 3.83852,
  },
  {
    Date: "08.10.2016",
    price: 3.86397,
  },
  {
    Date: "09.10.2016",
    price: 3.84468,
  },
  {
    Date: "10.10.2016",
    price: 3.82321,
  },
  {
    Date: "11.10.2016",
    price: 3.804,
  },
  {
    Date: "12.10.2016",
    price: 3.82044,
  },
  {
    Date: "13.10.2016",
    price: 3.74645,
  },
  {
    Date: "14.10.2016",
    price: 3.91199,
  },
  {
    Date: "15.10.2016",
    price: 3.90036,
  },
  {
    Date: "16.10.2016",
    price: 3.88257,
  },
  {
    Date: "17.10.2016",
    price: 3.89224,
  },
  {
    Date: "18.10.2016",
    price: 3.95415,
  },
  {
    Date: "19.10.2016",
    price: 3.84618,
  },
  {
    Date: "20.10.2016",
    price: 3.80797,
  },
  {
    Date: "21.10.2016",
    price: 3.79027,
  },
  {
    Date: "22.10.2016",
    price: 3.80969,
  },
  {
    Date: "23.10.2016",
    price: 3.89372,
  },
  {
    Date: "24.10.2016",
    price: 3.9202,
  },
  {
    Date: "25.10.2016",
    price: 3.87133,
  },
  {
    Date: "26.10.2016",
    price: 3.91142,
  },
  {
    Date: "27.10.2016",
    price: 3.96027,
  },
  {
    Date: "28.10.2016",
    price: 4.03587,
  },
  {
    Date: "29.10.2016",
    price: 3.97651,
  },
  {
    Date: "30.10.2016",
    price: 4.09595,
  },
  {
    Date: "31.10.2016",
    price: 4.03708,
  },
  {
    Date: "01.11.2016",
    price: 3.9986,
  },
  {
    Date: "02.11.2016",
    price: 4.09081,
  },
  {
    Date: "03.11.2016",
    price: 4.11298,
  },
  {
    Date: "04.11.2016",
    price: 3.85571,
  },
  {
    Date: "05.11.2016",
    price: 3.87851,
  },
  {
    Date: "06.11.2016",
    price: 3.86028,
  },
  {
    Date: "07.11.2016",
    price: 3.88833,
  },
  {
    Date: "08.11.2016",
    price: 3.83697,
  },
  {
    Date: "09.11.2016",
    price: 3.84603,
  },
  {
    Date: "10.11.2016",
    price: 3.85854,
  },
  {
    Date: "11.11.2016",
    price: 3.83641,
  },
  {
    Date: "12.11.2016",
    price: 3.81437,
  },
  {
    Date: "13.11.2016",
    price: 3.76618,
  },
  {
    Date: "14.11.2016",
    price: 3.87889,
  },
  {
    Date: "15.11.2016",
    price: 3.88883,
  },
  {
    Date: "16.11.2016",
    price: 3.90046,
  },
  {
    Date: "17.11.2016",
    price: 4.05557,
  },
  {
    Date: "18.11.2016",
    price: 3.95862,
  },
  {
    Date: "19.11.2016",
    price: 3.97737,
  },
  {
    Date: "20.11.2016",
    price: 3.96574,
  },
  {
    Date: "21.11.2016",
    price: 3.93212,
  },
  {
    Date: "22.11.2016",
    price: 3.9522,
  },
  {
    Date: "23.11.2016",
    price: 3.96887,
  },
  {
    Date: "24.11.2016",
    price: 3.93211,
  },
  {
    Date: "25.11.2016",
    price: 3.91291,
  },
  {
    Date: "26.11.2016",
    price: 3.92061,
  },
  {
    Date: "27.11.2016",
    price: 3.90792,
  },
  {
    Date: "28.11.2016",
    price: 3.89774,
  },
  {
    Date: "29.11.2016",
    price: 3.89251,
  },
  {
    Date: "30.11.2016",
    price: 3.88781,
  },
  {
    Date: "01.12.2016",
    price: 3.90945,
  },
  {
    Date: "02.12.2016",
    price: 3.91922,
  },
  {
    Date: "03.12.2016",
    price: 3.9496,
  },
  {
    Date: "04.12.2016",
    price: 3.93236,
  },
  {
    Date: "05.12.2016",
    price: 3.88444,
  },
  {
    Date: "06.12.2016",
    price: 3.48905,
  },
  {
    Date: "07.12.2016",
    price: 3.54108,
  },
  {
    Date: "08.12.2016",
    price: 3.60278,
  },
  {
    Date: "09.12.2016",
    price: 3.69109,
  },
  {
    Date: "10.12.2016",
    price: 3.69489,
  },
  {
    Date: "11.12.2016",
    price: 3.68797,
  },
  {
    Date: "12.12.2016",
    price: 3.64073,
  },
  {
    Date: "13.12.2016",
    price: 3.6655,
  },
  {
    Date: "14.12.2016",
    price: 3.65726,
  },
  {
    Date: "15.12.2016",
    price: 3.61289,
  },
  {
    Date: "16.12.2016",
    price: 3.62715,
  },
  {
    Date: "17.12.2016",
    price: 3.62987,
  },
  {
    Date: "18.12.2016",
    price: 3.70389,
  },
  {
    Date: "19.12.2016",
    price: 3.67721,
  },
  {
    Date: "20.12.2016",
    price: 3.65986,
  },
  {
    Date: "21.12.2016",
    price: 3.66452,
  },
  {
    Date: "22.12.2016",
    price: 3.66561,
  },
  {
    Date: "23.12.2016",
    price: 3.71586,
  },
  {
    Date: "24.12.2016",
    price: 4.64649,
  },
  {
    Date: "25.12.2016",
    price: 4.55545,
  },
  {
    Date: "26.12.2016",
    price: 4.34654,
  },
  {
    Date: "27.12.2016",
    price: 4.36857,
  },
  {
    Date: "28.12.2016",
    price: 4.48757,
  },
  {
    Date: "29.12.2016",
    price: 4.57886,
  },
  {
    Date: "30.12.2016",
    price: 4.56249,
  },
  {
    Date: "31.12.2016",
    price: 4.39233,
  },
  {
    Date: "01.01.2017",
    price: 4.32912,
  },
  {
    Date: "02.01.2017",
    price: 4.52045,
  },
  {
    Date: "03.01.2017",
    price: 4.65588,
  },
  {
    Date: "04.01.2017",
    price: 4.63375,
  },
  {
    Date: "05.01.2017",
    price: 4.91437,
  },
  {
    Date: "06.01.2017",
    price: 4.3024,
  },
  {
    Date: "07.01.2017",
    price: 3.86339,
  },
  {
    Date: "08.01.2017",
    price: 3.95912,
  },
  {
    Date: "09.01.2017",
    price: 3.99698,
  },
  {
    Date: "10.01.2017",
    price: 4.30092,
  },
  {
    Date: "11.01.2017",
    price: 4.59716,
  },
  {
    Date: "12.01.2017",
    price: 3.83671,
  },
  {
    Date: "13.01.2017",
    price: 3.94848,
  },
  {
    Date: "14.01.2017",
    price: 3.89258,
  },
  {
    Date: "15.01.2017",
    price: 3.88878,
  },
  {
    Date: "16.01.2017",
    price: 3.92204,
  },
  {
    Date: "17.01.2017",
    price: 3.9057,
  },
  {
    Date: "18.01.2017",
    price: 3.94225,
  },
  {
    Date: "19.01.2017",
    price: 3.8419,
  },
  {
    Date: "20.01.2017",
    price: 3.85918,
  },
  {
    Date: "21.01.2017",
    price: 3.89257,
  },
  {
    Date: "22.01.2017",
    price: 3.90248,
  },
  {
    Date: "23.01.2017",
    price: 3.85613,
  },
  {
    Date: "24.01.2017",
    price: 3.83527,
  },
  {
    Date: "25.01.2017",
    price: 3.79133,
  },
  {
    Date: "26.01.2017",
    price: 3.71444,
  },
  {
    Date: "27.01.2017",
    price: 3.8401,
  },
  {
    Date: "28.01.2017",
    price: 3.85353,
  },
  {
    Date: "29.01.2017",
    price: 3.85244,
  },
  {
    Date: "30.01.2017",
    price: 3.85623,
  },
  {
    Date: "31.01.2017",
    price: 4.0165,
  },
  {
    Date: "01.02.2017",
    price: 4.07604,
  },
  {
    Date: "02.02.2017",
    price: 4.07515,
  },
  {
    Date: "03.02.2017",
    price: 4.08163,
  },
  {
    Date: "04.02.2017",
    price: 4.08451,
  },
  {
    Date: "05.02.2017",
    price: 4.06511,
  },
  {
    Date: "06.02.2017",
    price: 4.05798,
  },
  {
    Date: "07.02.2017",
    price: 4.05348,
  },
  {
    Date: "08.02.2017",
    price: 4.04974,
  },
  {
    Date: "09.02.2017",
    price: 4.02965,
  },
  {
    Date: "10.02.2017",
    price: 3.80109,
  },
  {
    Date: "11.02.2017",
    price: 3.77722,
  },
  {
    Date: "12.02.2017",
    price: 3.79587,
  },
  {
    Date: "13.02.2017",
    price: 3.76823,
  },
  {
    Date: "14.02.2017",
    price: 3.71529,
  },
  {
    Date: "15.02.2017",
    price: 3.76918,
  },
  {
    Date: "16.02.2017",
    price: 3.79676,
  },
  {
    Date: "17.02.2017",
    price: 3.77838,
  },
  {
    Date: "18.02.2017",
    price: 3.828,
  },
  {
    Date: "19.02.2017",
    price: 3.79258,
  },
  {
    Date: "20.02.2017",
    price: 3.76865,
  },
  {
    Date: "21.02.2017",
    price: 3.76362,
  },
  {
    Date: "22.02.2017",
    price: 3.78758,
  },
  {
    Date: "23.02.2017",
    price: 3.80004,
  },
  {
    Date: "24.02.2017",
    price: 3.83092,
  },
  {
    Date: "25.02.2017",
    price: 3.85222,
  },
  {
    Date: "26.02.2017",
    price: 3.81588,
  },
  {
    Date: "27.02.2017",
    price: 3.81343,
  },
  {
    Date: "28.02.2017",
    price: 3.80369,
  },
  {
    Date: "01.03.2017",
    price: 3.77306,
  },
  {
    Date: "02.03.2017",
    price: 3.79529,
  },
  {
    Date: "03.03.2017",
    price: 3.92129,
  },
  {
    Date: "04.03.2017",
    price: 3.91191,
  },
  {
    Date: "05.03.2017",
    price: 3.8631,
  },
  {
    Date: "06.03.2017",
    price: 3.87224,
  },
  {
    Date: "07.03.2017",
    price: 3.89404,
  },
  {
    Date: "08.03.2017",
    price: 3.80758,
  },
  {
    Date: "09.03.2017",
    price: 3.76639,
  },
  {
    Date: "10.03.2017",
    price: 3.82828,
  },
  {
    Date: "11.03.2017",
    price: 3.7588,
  },
  {
    Date: "12.03.2017",
    price: 3.7866,
  },
  {
    Date: "13.03.2017",
    price: 3.84744,
  },
  {
    Date: "14.03.2017",
    price: 4.17381,
  },
  {
    Date: "15.03.2017",
    price: 4.04686,
  },
  {
    Date: "16.03.2017",
    price: 4.14731,
  },
  {
    Date: "17.03.2017",
    price: 4.23317,
  },
  {
    Date: "18.03.2017",
    price: 4.04052,
  },
  {
    Date: "19.03.2017",
    price: 3.9565,
  },
  {
    Date: "20.03.2017",
    price: 3.95048,
  },
  {
    Date: "21.03.2017",
    price: 4.03637,
  },
  {
    Date: "22.03.2017",
    price: 3.98735,
  },
  {
    Date: "23.03.2017",
    price: 3.93174,
  },
  {
    Date: "24.03.2017",
    price: 3.97307,
  },
  {
    Date: "25.03.2017",
    price: 4.07347,
  },
  {
    Date: "26.03.2017",
    price: 4.06615,
  },
  {
    Date: "27.03.2017",
    price: 4.06639,
  },
  {
    Date: "28.03.2017",
    price: 4.06968,
  },
  {
    Date: "29.03.2017",
    price: 4.14237,
  },
  {
    Date: "30.03.2017",
    price: 4.17891,
  },
  {
    Date: "31.03.2017",
    price: 7.01873,
  },
  {
    Date: "01.04.2017",
    price: 6.65316,
  },
  {
    Date: "02.04.2017",
    price: 6.86292,
  },
  {
    Date: "03.04.2017",
    price: 7.74462,
  },
  {
    Date: "04.04.2017",
    price: 8.11843,
  },
  {
    Date: "05.04.2017",
    price: 8.24728,
  },
  {
    Date: "06.04.2017",
    price: 11.1231,
  },
  {
    Date: "07.04.2017",
    price: 10.2235,
  },
  {
    Date: "08.04.2017",
    price: 9.33604,
  },
  {
    Date: "09.04.2017",
    price: 9.88879,
  },
  {
    Date: "10.04.2017",
    price: 8.721,
  },
  {
    Date: "11.04.2017",
    price: 8.91162,
  },
  {
    Date: "12.04.2017",
    price: 8.83101,
  },
  {
    Date: "13.04.2017",
    price: 10.7644,
  },
  {
    Date: "14.04.2017",
    price: 10.1309,
  },
  {
    Date: "15.04.2017",
    price: 10.3421,
  },
  {
    Date: "16.04.2017",
    price: 10.7455,
  },
  {
    Date: "17.04.2017",
    price: 10.5957,
  },
  {
    Date: "18.04.2017",
    price: 10.4189,
  },
  {
    Date: "19.04.2017",
    price: 10.3138,
  },
  {
    Date: "20.04.2017",
    price: 9.63475,
  },
  {
    Date: "21.04.2017",
    price: 10.2915,
  },
  {
    Date: "22.04.2017",
    price: 11.1766,
  },
  {
    Date: "23.04.2017",
    price: 13.0653,
  },
  {
    Date: "24.04.2017",
    price: 13.964,
  },
  {
    Date: "25.04.2017",
    price: 14.9787,
  },
  {
    Date: "26.04.2017",
    price: 15.2059,
  },
  {
    Date: "27.04.2017",
    price: 14.8436,
  },
  {
    Date: "28.04.2017",
    price: 14.5931,
  },
  {
    Date: "29.04.2017",
    price: 14.397,
  },
  {
    Date: "30.04.2017",
    price: 15.7031,
  },
  {
    Date: "01.05.2017",
    price: 15.7081,
  },
  {
    Date: "02.05.2017",
    price: 15.9227,
  },
  {
    Date: "03.05.2017",
    price: 15.8752,
  },
  {
    Date: "04.05.2017",
    price: 21.0445,
  },
  {
    Date: "05.05.2017",
    price: 23.471201,
  },
  {
    Date: "06.05.2017",
    price: 26.466801,
  },
  {
    Date: "07.05.2017",
    price: 28.2015,
  },
  {
    Date: "08.05.2017",
    price: 30.3724,
  },
  {
    Date: "09.05.2017",
    price: 28.964399,
  },
  {
    Date: "10.05.2017",
    price: 33.2981,
  },
  {
    Date: "11.05.2017",
    price: 32.657101,
  },
  {
    Date: "12.05.2017",
    price: 30.931299,
  },
  {
    Date: "13.05.2017",
    price: 27.218901,
  },
  {
    Date: "14.05.2017",
    price: 29.09,
  },
  {
    Date: "15.05.2017",
    price: 28.894501,
  },
  {
    Date: "16.05.2017",
    price: 25.337601,
  },
  {
    Date: "17.05.2017",
    price: 23.242399,
  },
  {
    Date: "18.05.2017",
    price: 25.1654,
  },
  {
    Date: "19.05.2017",
    price: 28.2871,
  },
  {
    Date: "20.05.2017",
    price: 27.681299,
  },
  {
    Date: "21.05.2017",
    price: 27.4762,
  },
  {
    Date: "22.05.2017",
    price: 26.1415,
  },
  {
    Date: "23.05.2017",
    price: 25.303499,
  },
  {
    Date: "24.05.2017",
    price: 32.1217,
  },
  {
    Date: "25.05.2017",
    price: 34.469002,
  },
  {
    Date: "26.05.2017",
    price: 30.3251,
  },
  {
    Date: "27.05.2017",
    price: 23.403099,
  },
  {
    Date: "28.05.2017",
    price: 24.007401,
  },
  {
    Date: "29.05.2017",
    price: 24.3249,
  },
  {
    Date: "30.05.2017",
    price: 25.318899,
  },
  {
    Date: "31.05.2017",
    price: 24.293501,
  },
  {
    Date: "01.06.2017",
    price: 25.343901,
  },
  {
    Date: "02.06.2017",
    price: 28.897301,
  },
  {
    Date: "03.06.2017",
    price: 29.036301,
  },
  {
    Date: "04.06.2017",
    price: 27.650299,
  },
  {
    Date: "05.06.2017",
    price: 28.09,
  },
  {
    Date: "06.06.2017",
    price: 30.8407,
  },
  {
    Date: "07.06.2017",
    price: 30.568701,
  },
  {
    Date: "08.06.2017",
    price: 28.3813,
  },
  {
    Date: "09.06.2017",
    price: 30.1873,
  },
  {
    Date: "10.06.2017",
    price: 29.6845,
  },
  {
    Date: "11.06.2017",
    price: 30.5009,
  },
  {
    Date: "12.06.2017",
    price: 33.230499,
  },
  {
    Date: "13.06.2017",
    price: 29.4916,
  },
  {
    Date: "14.06.2017",
    price: 30.739,
  },
  {
    Date: "15.06.2017",
    price: 30.215401,
  },
  {
    Date: "16.06.2017",
    price: 30.2791,
  },
  {
    Date: "17.06.2017",
    price: 36.183899,
  },
  {
    Date: "18.06.2017",
    price: 47.4422,
  },
  {
    Date: "19.06.2017",
    price: 46.161999,
  },
  {
    Date: "20.06.2017",
    price: 50.409,
  },
  {
    Date: "21.06.2017",
    price: 47.2952,
  },
  {
    Date: "22.06.2017",
    price: 47.7188,
  },
  {
    Date: "23.06.2017",
    price: 47.747799,
  },
  {
    Date: "24.06.2017",
    price: 47.2467,
  },
  {
    Date: "25.06.2017",
    price: 44.4907,
  },
  {
    Date: "26.06.2017",
    price: 44.036999,
  },
  {
    Date: "27.06.2017",
    price: 41.131302,
  },
  {
    Date: "28.06.2017",
    price: 40.816002,
  },
  {
    Date: "29.06.2017",
    price: 42.830101,
  },
  {
    Date: "30.06.2017",
    price: 41.042999,
  },
  {
    Date: "01.07.2017",
    price: 40.404099,
  },
  {
    Date: "02.07.2017",
    price: 38.9492,
  },
  {
    Date: "03.07.2017",
    price: 41.4785,
  },
  {
    Date: "04.07.2017",
    price: 46.562599,
  },
  {
    Date: "05.07.2017",
    price: 55.274899,
  },
  {
    Date: "06.07.2017",
    price: 53.195499,
  },
  {
    Date: "07.07.2017",
    price: 51.101501,
  },
  {
    Date: "08.07.2017",
    price: 46.639301,
  },
  {
    Date: "09.07.2017",
    price: 52.363701,
  },
  {
    Date: "10.07.2017",
    price: 49.908798,
  },
  {
    Date: "11.07.2017",
    price: 46.2742,
  },
  {
    Date: "12.07.2017",
    price: 45.255901,
  },
  {
    Date: "13.07.2017",
    price: 48.7234,
  },
  {
    Date: "14.07.2017",
    price: 46.3592,
  },
  {
    Date: "15.07.2017",
    price: 42.805302,
  },
  {
    Date: "16.07.2017",
    price: 38.921001,
  },
  {
    Date: "17.07.2017",
    price: 41.189201,
  },
  {
    Date: "18.07.2017",
    price: 42.623001,
  },
  {
    Date: "19.07.2017",
    price: 44.347401,
  },
  {
    Date: "20.07.2017",
    price: 40.955399,
  },
  {
    Date: "21.07.2017",
    price: 45.068501,
  },
  {
    Date: "22.07.2017",
    price: 45.772999,
  },
  {
    Date: "23.07.2017",
    price: 46.628201,
  },
  {
    Date: "24.07.2017",
    price: 44.159599,
  },
  {
    Date: "25.07.2017",
    price: 44.482899,
  },
  {
    Date: "26.07.2017",
    price: 42.287998,
  },
  {
    Date: "27.07.2017",
    price: 42.353199,
  },
  {
    Date: "28.07.2017",
    price: 42.216202,
  },
  {
    Date: "29.07.2017",
    price: 40.558899,
  },
  {
    Date: "30.07.2017",
    price: 41.184502,
  },
  {
    Date: "31.07.2017",
    price: 40.340302,
  },
  {
    Date: "01.08.2017",
    price: 43.019299,
  },
  {
    Date: "02.08.2017",
    price: 43.074902,
  },
  {
    Date: "03.08.2017",
    price: 42.193001,
  },
  {
    Date: "04.08.2017",
    price: 42.893501,
  },
  {
    Date: "05.08.2017",
    price: 43.486,
  },
  {
    Date: "06.08.2017",
    price: 46.845001,
  },
  {
    Date: "07.08.2017",
    price: 45.1717,
  },
  {
    Date: "08.08.2017",
    price: 45.898102,
  },
  {
    Date: "09.08.2017",
    price: 48.5867,
  },
  {
    Date: "10.08.2017",
    price: 48.0494,
  },
  {
    Date: "11.08.2017",
    price: 46.4049,
  },
  {
    Date: "12.08.2017",
    price: 47.050598,
  },
  {
    Date: "13.08.2017",
    price: 46.568699,
  },
  {
    Date: "14.08.2017",
    price: 45.832298,
  },
  {
    Date: "15.08.2017",
    price: 45.813499,
  },
  {
    Date: "16.08.2017",
    price: 43.371899,
  },
  {
    Date: "17.08.2017",
    price: 44.178799,
  },
  {
    Date: "18.08.2017",
    price: 43.897202,
  },
  {
    Date: "19.08.2017",
    price: 47.054298,
  },
  {
    Date: "20.08.2017",
    price: 45.376202,
  },
  {
    Date: "21.08.2017",
    price: 46.1898,
  },
  {
    Date: "22.08.2017",
    price: 47.895199,
  },
  {
    Date: "23.08.2017",
    price: 46.7136,
  },
  {
    Date: "24.08.2017",
    price: 52.824402,
  },
  {
    Date: "25.08.2017",
    price: 50.172298,
  },
  {
    Date: "26.08.2017",
    price: 51.165798,
  },
  {
    Date: "27.08.2017",
    price: 51.804798,
  },
  {
    Date: "28.08.2017",
    price: 61.3634,
  },
  {
    Date: "29.08.2017",
    price: 62.3946,
  },
  {
    Date: "30.08.2017",
    price: 63.163799,
  },
  {
    Date: "31.08.2017",
    price: 64.158203,
  },
  {
    Date: "01.09.2017",
    price: 70.856102,
  },
  {
    Date: "02.09.2017",
    price: 85.829903,
  },
  {
    Date: "03.09.2017",
    price: 78.979897,
  },
  {
    Date: "04.09.2017",
    price: 76.732101,
  },
  {
    Date: "05.09.2017",
    price: 65.251297,
  },
  {
    Date: "06.09.2017",
    price: 71.777298,
  },
  {
    Date: "07.09.2017",
    price: 79.877998,
  },
  {
    Date: "08.09.2017",
    price: 78.452797,
  },
  {
    Date: "09.09.2017",
    price: 67.2127,
  },
  {
    Date: "10.09.2017",
    price: 65.802803,
  },
  {
    Date: "11.09.2017",
    price: 61.646599,
  },
  {
    Date: "12.09.2017",
    price: 66.2388,
  },
  {
    Date: "13.09.2017",
    price: 64.321602,
  },
  {
    Date: "14.09.2017",
    price: 61.6422,
  },
  {
    Date: "15.09.2017",
    price: 41.685001,
  },
  {
    Date: "16.09.2017",
    price: 48.1334,
  },
  {
    Date: "17.09.2017",
    price: 48.2304,
  },
  {
    Date: "18.09.2017",
    price: 48.530201,
  },
  {
    Date: "19.09.2017",
    price: 55.683601,
  },
  {
    Date: "20.09.2017",
    price: 52.7449,
  },
  {
    Date: "21.09.2017",
    price: 51.663601,
  },
  {
    Date: "22.09.2017",
    price: 46.652699,
  },
  {
    Date: "23.09.2017",
    price: 48.157101,
  },
  {
    Date: "24.09.2017",
    price: 49.250099,
  },
  {
    Date: "25.09.2017",
    price: 47.6199,
  },
  {
    Date: "26.09.2017",
    price: 51.687901,
  },
  {
    Date: "27.09.2017",
    price: 51.576199,
  },
  {
    Date: "28.09.2017",
    price: 56.2122,
  },
  {
    Date: "29.09.2017",
    price: 54.109299,
  },
  {
    Date: "30.09.2017",
    price: 52.626499,
  },
  {
    Date: "01.10.2017",
    price: 55.1362,
  },
  {
    Date: "02.10.2017",
    price: 54.546902,
  },
  {
    Date: "03.10.2017",
    price: 53.3904,
  },
  {
    Date: "04.10.2017",
    price: 52.284302,
  },
  {
    Date: "05.10.2017",
    price: 51.401402,
  },
  {
    Date: "06.10.2017",
    price: 51.636002,
  },
  {
    Date: "07.10.2017",
    price: 52.137501,
  },
  {
    Date: "08.10.2017",
    price: 52.4935,
  },
  {
    Date: "09.10.2017",
    price: 53.0825,
  },
  {
    Date: "10.10.2017",
    price: 50.154202,
  },
  {
    Date: "11.10.2017",
    price: 50.7533,
  },
  {
    Date: "12.10.2017",
    price: 50.8867,
  },
  {
    Date: "13.10.2017",
    price: 60.551899,
  },
  {
    Date: "14.10.2017",
    price: 59.405602,
  },
  {
    Date: "15.10.2017",
    price: 64.126602,
  },
  {
    Date: "16.10.2017",
    price: 65.7183,
  },
  {
    Date: "17.10.2017",
    price: 64.673203,
  },
  {
    Date: "18.10.2017",
    price: 59.586899,
  },
  {
    Date: "19.10.2017",
    price: 60.8218,
  },
  {
    Date: "20.10.2017",
    price: 59.809799,
  },
  {
    Date: "21.10.2017",
    price: 60.303799,
  },
  {
    Date: "22.10.2017",
    price: 58.2719,
  },
  {
    Date: "23.10.2017",
    price: 56.770699,
  },
  {
    Date: "24.10.2017",
    price: 54.959499,
  },
  {
    Date: "25.10.2017",
    price: 55.951698,
  },
  {
    Date: "26.10.2017",
    price: 56.468601,
  },
  {
    Date: "27.10.2017",
    price: 55.6731,
  },
  {
    Date: "28.10.2017",
    price: 55.3843,
  },
  {
    Date: "29.10.2017",
    price: 54.6698,
  },
  {
    Date: "30.10.2017",
    price: 56.840199,
  },
  {
    Date: "31.10.2017",
    price: 56.365002,
  },
  {
    Date: "01.11.2017",
    price: 55.7407,
  },
  {
    Date: "02.11.2017",
    price: 53.5592,
  },
  {
    Date: "03.11.2017",
    price: 54.578201,
  },
  {
    Date: "04.11.2017",
    price: 56.2159,
  },
  {
    Date: "05.11.2017",
    price: 55.0257,
  },
  {
    Date: "06.11.2017",
    price: 54.759201,
  },
  {
    Date: "07.11.2017",
    price: 54.987701,
  },
  {
    Date: "08.11.2017",
    price: 61.112999,
  },
  {
    Date: "09.11.2017",
    price: 62.489601,
  },
  {
    Date: "10.11.2017",
    price: 64.436203,
  },
  {
    Date: "11.11.2017",
    price: 59.3489,
  },
  {
    Date: "12.11.2017",
    price: 62.3092,
  },
  {
    Date: "13.11.2017",
    price: 59.1758,
  },
  {
    Date: "14.11.2017",
    price: 61.642899,
  },
  {
    Date: "15.11.2017",
    price: 62.7752,
  },
  {
    Date: "16.11.2017",
    price: 63.809101,
  },
  {
    Date: "17.11.2017",
    price: 70.448402,
  },
  {
    Date: "18.11.2017",
    price: 67.405197,
  },
  {
    Date: "19.11.2017",
    price: 69.511902,
  },
  {
    Date: "20.11.2017",
    price: 71.413002,
  },
  {
    Date: "21.11.2017",
    price: 72.1968,
  },
  {
    Date: "22.11.2017",
    price: 70.182198,
  },
  {
    Date: "23.11.2017",
    price: 71.975098,
  },
  {
    Date: "24.11.2017",
    price: 73.141899,
  },
  {
    Date: "25.11.2017",
    price: 77.908699,
  },
  {
    Date: "26.11.2017",
    price: 88.939301,
  },
  {
    Date: "27.11.2017",
    price: 85.889702,
  },
  {
    Date: "28.11.2017",
    price: 91.723999,
  },
  {
    Date: "29.11.2017",
    price: 96.012497,
  },
  {
    Date: "30.11.2017",
    price: 86.246696,
  },
  {
    Date: "01.12.2017",
    price: 88.003502,
  },
  {
    Date: "02.12.2017",
    price: 98.941498,
  },
  {
    Date: "03.12.2017",
    price: 100.195,
  },
  {
    Date: "04.12.2017",
    price: 101.396004,
  },
  {
    Date: "05.12.2017",
    price: 104.277,
  },
  {
    Date: "06.12.2017",
    price: 102.477997,
  },
  {
    Date: "07.12.2017",
    price: 99.9841,
  },
  {
    Date: "08.12.2017",
    price: 98.328102,
  },
  {
    Date: "09.12.2017",
    price: 127.478996,
  },
  {
    Date: "10.12.2017",
    price: 155.626999,
  },
  {
    Date: "11.12.2017",
    price: 148.257996,
  },
  {
    Date: "12.12.2017",
    price: 212.464005,
  },
  {
    Date: "13.12.2017",
    price: 315.362,
  },
  {
    Date: "14.12.2017",
    price: 301.197998,
  },
  {
    Date: "15.12.2017",
    price: 280.967987,
  },
  {
    Date: "16.12.2017",
    price: 299.337006,
  },
  {
    Date: "17.12.2017",
    price: 301.783997,
  },
  {
    Date: "18.12.2017",
    price: 318.953003,
  },
  {
    Date: "19.12.2017",
    price: 359.125,
  },
  {
    Date: "20.12.2017",
    price: 349.604004,
  },
  {
    Date: "21.12.2017",
    price: 314.660004,
  },
  {
    Date: "22.12.2017",
    price: 319.256989,
  },
  {
    Date: "23.12.2017",
    price: 269.266998,
  },
  {
    Date: "24.12.2017",
    price: 291.007996,
  },
  {
    Date: "25.12.2017",
    price: 277.493988,
  },
  {
    Date: "26.12.2017",
    price: 273.108002,
  },
  {
    Date: "27.12.2017",
    price: 286.588989,
  },
  {
    Date: "28.12.2017",
    price: 272.126007,
  },
  {
    Date: "29.12.2017",
    price: 250.352005,
  },
  {
    Date: "30.12.2017",
    price: 245.087006,
  },
  {
    Date: "31.12.2017",
    price: 216.975006,
  },
  {
    Date: "01.01.2018",
    price: 231.666,
  },
  {
    Date: "02.01.2018",
    price: 228.990005,
  },
  {
    Date: "03.01.2018",
    price: 255.695007,
  },
  {
    Date: "04.01.2018",
    price: 245.475006,
  },
  {
    Date: "05.01.2018",
    price: 241.033997,
  },
  {
    Date: "06.01.2018",
    price: 249.768005,
  },
  {
    Date: "07.01.2018",
    price: 296.053009,
  },
  {
    Date: "08.01.2018",
    price: 287.725006,
  },
  {
    Date: "09.01.2018",
    price: 254.606003,
  },
  {
    Date: "10.01.2018",
    price: 248.072998,
  },
  {
    Date: "11.01.2018",
    price: 251.522003,
  },
  {
    Date: "12.01.2018",
    price: 229.787003,
  },
  {
    Date: "13.01.2018",
    price: 236.882004,
  },
  {
    Date: "14.01.2018",
    price: 260.57901,
  },
  {
    Date: "15.01.2018",
    price: 237.289001,
  },
  {
    Date: "16.01.2018",
    price: 232.817001,
  },
  {
    Date: "17.01.2018",
    price: 190.145004,
  },
  {
    Date: "18.01.2018",
    price: 186.949005,
  },
  {
    Date: "19.01.2018",
    price: 191.192001,
  },
  {
    Date: "20.01.2018",
    price: 194.378006,
  },
  {
    Date: "21.01.2018",
    price: 211.235001,
  },
  {
    Date: "22.01.2018",
    price: 191.789993,
  },
  {
    Date: "23.01.2018",
    price: 180.279007,
  },
  {
    Date: "24.01.2018",
    price: 178.425003,
  },
  {
    Date: "25.01.2018",
    price: 181.563004,
  },
  {
    Date: "26.01.2018",
    price: 179.819,
  },
  {
    Date: "27.01.2018",
    price: 176.660995,
  },
  {
    Date: "28.01.2018",
    price: 181.421005,
  },
  {
    Date: "29.01.2018",
    price: 193.751007,
  },
  {
    Date: "30.01.2018",
    price: 181.445007,
  },
  {
    Date: "31.01.2018",
    price: 165.296005,
  },
  {
    Date: "01.02.2018",
    price: 163.682007,
  },
  {
    Date: "02.02.2018",
    price: 142.561996,
  },
  {
    Date: "03.02.2018",
    price: 131.401001,
  },
  {
    Date: "04.02.2018",
    price: 160.380997,
  },
  {
    Date: "05.02.2018",
    price: 148.727005,
  },
  {
    Date: "06.02.2018",
    price: 127.521004,
  },
  {
    Date: "07.02.2018",
    price: 142.481995,
  },
  {
    Date: "08.02.2018",
    price: 138.335999,
  },
  {
    Date: "09.02.2018",
    price: 149.729996,
  },
  {
    Date: "10.02.2018",
    price: 163.488007,
  },
  {
    Date: "11.02.2018",
    price: 155.391998,
  },
  {
    Date: "12.02.2018",
    price: 150.104004,
  },
  {
    Date: "13.02.2018",
    price: 161.774994,
  },
  {
    Date: "14.02.2018",
    price: 159.578995,
  },
  {
    Date: "15.02.2018",
    price: 212.347,
  },
  {
    Date: "16.02.2018",
    price: 225.5,
  },
  {
    Date: "17.02.2018",
    price: 229.501999,
  },
  {
    Date: "18.02.2018",
    price: 230.509003,
  },
  {
    Date: "19.02.2018",
    price: 215.414993,
  },
  {
    Date: "20.02.2018",
    price: 223.087006,
  },
  {
    Date: "21.02.2018",
    price: 230.947998,
  },
  {
    Date: "22.02.2018",
    price: 212.841003,
  },
  {
    Date: "23.02.2018",
    price: 194.192993,
  },
  {
    Date: "24.02.2018",
    price: 210.636993,
  },
  {
    Date: "25.02.2018",
    price: 208.132004,
  },
  {
    Date: "26.02.2018",
    price: 222.138,
  },
  {
    Date: "27.02.2018",
    price: 221.710007,
  },
  {
    Date: "28.02.2018",
    price: 217.279007,
  },
  {
    Date: "01.03.2018",
    price: 203.119003,
  },
  {
    Date: "02.03.2018",
    price: 209.554993,
  },
  {
    Date: "03.03.2018",
    price: 213.837997,
  },
  {
    Date: "04.03.2018",
    price: 210.235992,
  },
  {
    Date: "05.03.2018",
    price: 213.740005,
  },
  {
    Date: "06.03.2018",
    price: 210.513,
  },
  {
    Date: "07.03.2018",
    price: 196.826004,
  },
  {
    Date: "08.03.2018",
    price: 185.697998,
  },
  {
    Date: "09.03.2018",
    price: 176.481003,
  },
  {
    Date: "10.03.2018",
    price: 188.113007,
  },
  {
    Date: "11.03.2018",
    price: 177.513,
  },
  {
    Date: "12.03.2018",
    price: 189.016006,
  },
  {
    Date: "13.03.2018",
    price: 177.597,
  },
  {
    Date: "14.03.2018",
    price: 175.936996,
  },
  {
    Date: "15.03.2018",
    price: 161.201996,
  },
  {
    Date: "16.03.2018",
    price: 164.817001,
  },
  {
    Date: "17.03.2018",
    price: 165.188004,
  },
  {
    Date: "18.03.2018",
    price: 152.537003,
  },
  {
    Date: "19.03.2018",
    price: 155.475006,
  },
  {
    Date: "20.03.2018",
    price: 161.520996,
  },
  {
    Date: "21.03.2018",
    price: 169.557007,
  },
  {
    Date: "22.03.2018",
    price: 168.723007,
  },
  {
    Date: "23.03.2018",
    price: 163.576996,
  },
  {
    Date: "24.03.2018",
    price: 168.533997,
  },
  {
    Date: "25.03.2018",
    price: 158.863998,
  },
  {
    Date: "26.03.2018",
    price: 160.164993,
  },
  {
    Date: "27.03.2018",
    price: 148.960999,
  },
  {
    Date: "28.03.2018",
    price: 135.490005,
  },
  {
    Date: "29.03.2018",
    price: 131.925003,
  },
  {
    Date: "30.03.2018",
    price: 114.804001,
  },
  {
    Date: "31.03.2018",
    price: 118.637001,
  },
  {
    Date: "01.04.2018",
    price: 116.904999,
  },
  {
    Date: "02.04.2018",
    price: 115.237,
  },
  {
    Date: "03.04.2018",
    price: 119.509003,
  },
  {
    Date: "04.04.2018",
    price: 133.908005,
  },
  {
    Date: "05.04.2018",
    price: 118.209,
  },
  {
    Date: "06.04.2018",
    price: 118.815002,
  },
  {
    Date: "07.04.2018",
    price: 113.135002,
  },
  {
    Date: "08.04.2018",
    price: 116.291,
  },
  {
    Date: "09.04.2018",
    price: 117.782997,
  },
  {
    Date: "10.04.2018",
    price: 115.212997,
  },
  {
    Date: "11.04.2018",
    price: 114.289001,
  },
  {
    Date: "12.04.2018",
    price: 118.292,
  },
  {
    Date: "13.04.2018",
    price: 129.274002,
  },
  {
    Date: "14.04.2018",
    price: 125.223999,
  },
  {
    Date: "15.04.2018",
    price: 126.668999,
  },
  {
    Date: "16.04.2018",
    price: 131.968002,
  },
  {
    Date: "17.04.2018",
    price: 128.710007,
  },
  {
    Date: "18.04.2018",
    price: 132.376999,
  },
  {
    Date: "19.04.2018",
    price: 140.041,
  },
  {
    Date: "20.04.2018",
    price: 146.501007,
  },
  {
    Date: "21.04.2018",
    price: 155.684998,
  },
  {
    Date: "22.04.2018",
    price: 148.608002,
  },
  {
    Date: "23.04.2018",
    price: 146.671997,
  },
  {
    Date: "24.04.2018",
    price: 152.574997,
  },
  {
    Date: "25.04.2018",
    price: 165.087006,
  },
  {
    Date: "26.04.2018",
    price: 144.699005,
  },
  {
    Date: "27.04.2018",
    price: 153.444,
  },
  {
    Date: "28.04.2018",
    price: 145.742004,
  },
  {
    Date: "29.04.2018",
    price: 152.164993,
  },
  {
    Date: "30.04.2018",
    price: 153.647003,
  },
  {
    Date: "01.05.2018",
    price: 148.343994,
  },
  {
    Date: "02.05.2018",
    price: 148.445007,
  },
  {
    Date: "03.05.2018",
    price: 151.858994,
  },
  {
    Date: "04.05.2018",
    price: 160.468994,
  },
  {
    Date: "05.05.2018",
    price: 168.350006,
  },
  {
    Date: "06.05.2018",
    price: 178.020996,
  },
  {
    Date: "07.05.2018",
    price: 171.759003,
  },
  {
    Date: "08.05.2018",
    price: 164.830002,
  },
  {
    Date: "09.05.2018",
    price: 159.617004,
  },
  {
    Date: "10.05.2018",
    price: 157.151001,
  },
  {
    Date: "11.05.2018",
    price: 149.445007,
  },
  {
    Date: "12.05.2018",
    price: 137.257004,
  },
  {
    Date: "13.05.2018",
    price: 141.798004,
  },
  {
    Date: "14.05.2018",
    price: 144.623993,
  },
  {
    Date: "15.05.2018",
    price: 147.488007,
  },
  {
    Date: "16.05.2018",
    price: 139.649994,
  },
  {
    Date: "17.05.2018",
    price: 139.559006,
  },
  {
    Date: "18.05.2018",
    price: 132.703995,
  },
  {
    Date: "19.05.2018",
    price: 135.964996,
  },
  {
    Date: "20.05.2018",
    price: 135.272003,
  },
  {
    Date: "21.05.2018",
    price: 139.643997,
  },
  {
    Date: "22.05.2018",
    price: 134.259995,
  },
  {
    Date: "23.05.2018",
    price: 128.807999,
  },
  {
    Date: "24.05.2018",
    price: 119.195,
  },
  {
    Date: "25.05.2018",
    price: 122.558998,
  },
  {
    Date: "26.05.2018",
    price: 119.349998,
  },
  {
    Date: "27.05.2018",
    price: 118.503998,
  },
  {
    Date: "28.05.2018",
    price: 117.93,
  },
  {
    Date: "29.05.2018",
    price: 111.844002,
  },
  {
    Date: "30.05.2018",
    price: 119.540001,
  },
  {
    Date: "31.05.2018",
    price: 117.318001,
  },
  {
    Date: "01.06.2018",
    price: 118.028,
  },
  {
    Date: "02.06.2018",
    price: 119.828003,
  },
  {
    Date: "03.06.2018",
    price: 123.285004,
  },
  {
    Date: "04.06.2018",
    price: 125.210999,
  },
  {
    Date: "05.06.2018",
    price: 119.896004,
  },
  {
    Date: "06.06.2018",
    price: 121.888,
  },
  {
    Date: "07.06.2018",
    price: 121.338997,
  },
  {
    Date: "08.06.2018",
    price: 121.515999,
  },
  {
    Date: "09.06.2018",
    price: 120.108002,
  },
  {
    Date: "10.06.2018",
    price: 117.561996,
  },
  {
    Date: "11.06.2018",
    price: 106.541,
  },
  {
    Date: "12.06.2018",
    price: 107.428001,
  },
  {
    Date: "13.06.2018",
    price: 100.799004,
  },
  {
    Date: "14.06.2018",
    price: 93.993698,
  },
  {
    Date: "15.06.2018",
    price: 101.114998,
  },
  {
    Date: "16.06.2018",
    price: 96.5784,
  },
  {
    Date: "17.06.2018",
    price: 97.717598,
  },
  {
    Date: "18.06.2018",
    price: 95.773499,
  },
  {
    Date: "19.06.2018",
    price: 98.727303,
  },
  {
    Date: "20.06.2018",
    price: 98.956299,
  },
  {
    Date: "21.06.2018",
    price: 97.881203,
  },
  {
    Date: "22.06.2018",
    price: 96.941101,
  },
  {
    Date: "23.06.2018",
    price: 85.093002,
  },
  {
    Date: "24.06.2018",
    price: 82.739098,
  },
  {
    Date: "25.06.2018",
    price: 80.3666,
  },
  {
    Date: "26.06.2018",
    price: 81.815201,
  },
  {
    Date: "27.06.2018",
    price: 76.931999,
  },
  {
    Date: "28.06.2018",
    price: 80.625702,
  },
  {
    Date: "29.06.2018",
    price: 74.808899,
  },
  {
    Date: "30.06.2018",
    price: 78.946602,
  },
  {
    Date: "01.07.2018",
    price: 81.503998,
  },
  {
    Date: "02.07.2018",
    price: 80.2677,
  },
  {
    Date: "03.07.2018",
    price: 85.387802,
  },
  {
    Date: "04.07.2018",
    price: 85.172798,
  },
  {
    Date: "05.07.2018",
    price: 85.653198,
  },
  {
    Date: "06.07.2018",
    price: 83.837303,
  },
  {
    Date: "07.07.2018",
    price: 83.404297,
  },
  {
    Date: "08.07.2018",
    price: 86.262299,
  },
  {
    Date: "09.07.2018",
    price: 82.544098,
  },
  {
    Date: "10.07.2018",
    price: 80.782303,
  },
  {
    Date: "11.07.2018",
    price: 76.129898,
  },
  {
    Date: "12.07.2018",
    price: 78.594101,
  },
  {
    Date: "13.07.2018",
    price: 77.292099,
  },
  {
    Date: "14.07.2018",
    price: 76.973297,
  },
  {
    Date: "15.07.2018",
    price: 76.704597,
  },
  {
    Date: "16.07.2018",
    price: 78.959602,
  },
  {
    Date: "17.07.2018",
    price: 84.301498,
  },
  {
    Date: "18.07.2018",
    price: 89.821503,
  },
  {
    Date: "19.07.2018",
    price: 86.898201,
  },
  {
    Date: "20.07.2018",
    price: 86.681999,
  },
  {
    Date: "21.07.2018",
    price: 82.658302,
  },
  {
    Date: "22.07.2018",
    price: 84.264503,
  },
  {
    Date: "23.07.2018",
    price: 83.231499,
  },
  {
    Date: "24.07.2018",
    price: 82.682999,
  },
  {
    Date: "25.07.2018",
    price: 88.657204,
  },
  {
    Date: "26.07.2018",
    price: 86.277199,
  },
  {
    Date: "27.07.2018",
    price: 83.730003,
  },
  {
    Date: "28.07.2018",
    price: 84.536903,
  },
  {
    Date: "29.07.2018",
    price: 84.0541,
  },
  {
    Date: "30.07.2018",
    price: 84.153999,
  },
  {
    Date: "31.07.2018",
    price: 82.689301,
  },
  {
    Date: "01.08.2018",
    price: 80.3908,
  },
  {
    Date: "02.08.2018",
    price: 77.714203,
  },
  {
    Date: "03.08.2018",
    price: 76.301804,
  },
  {
    Date: "04.08.2018",
    price: 77.422302,
  },
  {
    Date: "05.08.2018",
    price: 73.379097,
  },
  {
    Date: "06.08.2018",
    price: 75.0616,
  },
  {
    Date: "07.08.2018",
    price: 74.003098,
  },
  {
    Date: "08.08.2018",
    price: 68.162804,
  },
  {
    Date: "09.08.2018",
    price: 62.473,
  },
  {
    Date: "10.08.2018",
    price: 63.974998,
  },
  {
    Date: "11.08.2018",
    price: 59.487598,
  },
  {
    Date: "12.08.2018",
    price: 58.217499,
  },
  {
    Date: "13.08.2018",
    price: 59.4837,
  },
  {
    Date: "14.08.2018",
    price: 57.004799,
  },
  {
    Date: "15.08.2018",
    price: 54.679001,
  },
  {
    Date: "16.08.2018",
    price: 54.535999,
  },
  {
    Date: "17.08.2018",
    price: 55.329399,
  },
  {
    Date: "18.08.2018",
    price: 61.556801,
  },
  {
    Date: "19.08.2018",
    price: 57.222698,
  },
  {
    Date: "20.08.2018",
    price: 57.908401,
  },
  {
    Date: "21.08.2018",
    price: 53.771599,
  },
  {
    Date: "22.08.2018",
    price: 56.6591,
  },
  {
    Date: "23.08.2018",
    price: 55.193699,
  },
  {
    Date: "24.08.2018",
    price: 57.604198,
  },
  {
    Date: "25.08.2018",
    price: 57.923599,
  },
  {
    Date: "26.08.2018",
    price: 58.051601,
  },
  {
    Date: "27.08.2018",
    price: 57.2593,
  },
  {
    Date: "28.08.2018",
    price: 60.226002,
  },
  {
    Date: "29.08.2018",
    price: 63.029202,
  },
  {
    Date: "30.08.2018",
    price: 61.765598,
  },
  {
    Date: "31.08.2018",
    price: 60.422798,
  },
  {
    Date: "01.09.2018",
    price: 61.7388,
  },
  {
    Date: "02.09.2018",
    price: 65.984802,
  },
  {
    Date: "03.09.2018",
    price: 65.817596,
  },
  {
    Date: "04.09.2018",
    price: 65.319901,
  },
  {
    Date: "05.09.2018",
    price: 67.6679,
  },
  {
    Date: "06.09.2018",
    price: 58.320999,
  },
  {
    Date: "07.09.2018",
    price: 57.342201,
  },
  {
    Date: "08.09.2018",
    price: 56.126701,
  },
  {
    Date: "09.09.2018",
    price: 53.2747,
  },
  {
    Date: "10.09.2018",
    price: 54.993698,
  },
  {
    Date: "11.09.2018",
    price: 54.6068,
  },
  {
    Date: "12.09.2018",
    price: 52.389,
  },
  {
    Date: "13.09.2018",
    price: 51.883301,
  },
  {
    Date: "14.09.2018",
    price: 54.647598,
  },
  {
    Date: "15.09.2018",
    price: 56.336899,
  },
  {
    Date: "16.09.2018",
    price: 56.581001,
  },
  {
    Date: "17.09.2018",
    price: 57.0201,
  },
  {
    Date: "18.09.2018",
    price: 52.2938,
  },
  {
    Date: "19.09.2018",
    price: 54.017502,
  },
  {
    Date: "20.09.2018",
    price: 54.372799,
  },
  {
    Date: "21.09.2018",
    price: 56.7827,
  },
  {
    Date: "22.09.2018",
    price: 60.814602,
  },
  {
    Date: "23.09.2018",
    price: 60.548302,
  },
  {
    Date: "24.09.2018",
    price: 61.339298,
  },
  {
    Date: "25.09.2018",
    price: 58.021,
  },
  {
    Date: "26.09.2018",
    price: 57.4492,
  },
  {
    Date: "27.09.2018",
    price: 57.450001,
  },
  {
    Date: "28.09.2018",
    price: 63.171501,
  },
  {
    Date: "29.09.2018",
    price: 61.643501,
  },
  {
    Date: "30.09.2018",
    price: 61.625301,
  },
  {
    Date: "01.10.2018",
    price: 61.098598,
  },
  {
    Date: "02.10.2018",
    price: 60.301201,
  },
  {
    Date: "03.10.2018",
    price: 59.5755,
  },
  {
    Date: "04.10.2018",
    price: 57.5215,
  },
  {
    Date: "05.10.2018",
    price: 58.331501,
  },
  {
    Date: "06.10.2018",
    price: 58.875702,
  },
  {
    Date: "07.10.2018",
    price: 57.934898,
  },
  {
    Date: "08.10.2018",
    price: 58.2318,
  },
  {
    Date: "09.10.2018",
    price: 59.4454,
  },
  {
    Date: "10.10.2018",
    price: 58.664101,
  },
  {
    Date: "11.10.2018",
    price: 57.976398,
  },
  {
    Date: "12.10.2018",
    price: 50.874599,
  },
  {
    Date: "13.10.2018",
    price: 53.4561,
  },
  {
    Date: "14.10.2018",
    price: 53.509201,
  },
  {
    Date: "15.10.2018",
    price: 52.173801,
  },
  {
    Date: "16.10.2018",
    price: 54.965099,
  },
  {
    Date: "17.10.2018",
    price: 54.2649,
  },
  {
    Date: "18.10.2018",
    price: 53.7169,
  },
  {
    Date: "19.10.2018",
    price: 52.552299,
  },
  {
    Date: "20.10.2018",
    price: 53.067001,
  },
  {
    Date: "21.10.2018",
    price: 53.4347,
  },
  {
    Date: "22.10.2018",
    price: 52.784599,
  },
  {
    Date: "23.10.2018",
    price: 52.237,
  },
  {
    Date: "24.10.2018",
    price: 52.456501,
  },
  {
    Date: "25.10.2018",
    price: 52.488098,
  },
  {
    Date: "26.10.2018",
    price: 52.219601,
  },
  {
    Date: "27.10.2018",
    price: 52.1278,
  },
  {
    Date: "28.10.2018",
    price: 52.052299,
  },
  {
    Date: "29.10.2018",
    price: 51.832699,
  },
  {
    Date: "30.10.2018",
    price: 48.996498,
  },
  {
    Date: "31.10.2018",
    price: 49.337799,
  },
  {
    Date: "01.11.2018",
    price: 49.562302,
  },
  {
    Date: "02.11.2018",
    price: 50.3395,
  },
  {
    Date: "03.11.2018",
    price: 51.2976,
  },
  {
    Date: "04.11.2018",
    price: 50.986198,
  },
  {
    Date: "05.11.2018",
    price: 53.5933,
  },
  {
    Date: "06.11.2018",
    price: 53.702499,
  },
  {
    Date: "07.11.2018",
    price: 56.043999,
  },
  {
    Date: "08.11.2018",
    price: 54.192402,
  },
  {
    Date: "09.11.2018",
    price: 52.3647,
  },
  {
    Date: "10.11.2018",
    price: 51.9268,
  },
  {
    Date: "11.11.2018",
    price: 52.318901,
  },
  {
    Date: "12.11.2018",
    price: 51.4771,
  },
  {
    Date: "13.11.2018",
    price: 50.634899,
  },
  {
    Date: "14.11.2018",
    price: 49.578899,
  },
  {
    Date: "15.11.2018",
    price: 43.727699,
  },
  {
    Date: "16.11.2018",
    price: 43.961102,
  },
  {
    Date: "17.11.2018",
    price: 42.4888,
  },
  {
    Date: "18.11.2018",
    price: 42.0882,
  },
  {
    Date: "19.11.2018",
    price: 42.339401,
  },
  {
    Date: "20.11.2018",
    price: 36.572498,
  },
  {
    Date: "21.11.2018",
    price: 33.065201,
  },
  {
    Date: "22.11.2018",
    price: 34.692799,
  },
  {
    Date: "23.11.2018",
    price: 32.022202,
  },
  {
    Date: "24.11.2018",
    price: 32.259701,
  },
  {
    Date: "25.11.2018",
    price: 29.226801,
  },
  {
    Date: "26.11.2018",
    price: 30.8687,
  },
  {
    Date: "27.11.2018",
    price: 29.409599,
  },
  {
    Date: "28.11.2018",
    price: 31.116899,
  },
  {
    Date: "29.11.2018",
    price: 34.71944,
  },
  {
    Date: "30.11.2018",
    price: 33.799175,
  },
  {
    Date: "01.12.2018",
    price: 32.130596,
  },
  {
    Date: "02.12.2018",
    price: 34.262409,
  },
  {
    Date: "03.12.2018",
    price: 33.810291,
  },
  {
    Date: "04.12.2018",
    price: 31.005577,
  },
  {
    Date: "05.12.2018",
    price: 31.215851,
  },
  {
    Date: "06.12.2018",
    price: 29.248985,
  },
  {
    Date: "07.12.2018",
    price: 26.907946,
  },
  {
    Date: "08.12.2018",
    price: 25.128456,
  },
  {
    Date: "09.12.2018",
    price: 24.803595,
  },
  {
    Date: "10.12.2018",
    price: 25.911686,
  },
  {
    Date: "11.12.2018",
    price: 24.600491,
  },
  {
    Date: "12.12.2018",
    price: 23.795937,
  },
  {
    Date: "13.12.2018",
    price: 24.530657,
  },
  {
    Date: "14.12.2018",
    price: 23.646481,
  },
  {
    Date: "15.12.2018",
    price: 23.462881,
  },
  {
    Date: "16.12.2018",
    price: 23.787989,
  },
  {
    Date: "17.12.2018",
    price: 25.682781,
  },
  {
    Date: "18.12.2018",
    price: 29.266506,
  },
  {
    Date: "19.12.2018",
    price: 30.327751,
  },
  {
    Date: "20.12.2018",
    price: 29.604042,
  },
  {
    Date: "21.12.2018",
    price: 32.732635,
  },
  {
    Date: "22.12.2018",
    price: 30.82662,
  },
  {
    Date: "23.12.2018",
    price: 31.992167,
  },
  {
    Date: "24.12.2018",
    price: 33.473583,
  },
  {
    Date: "25.12.2018",
    price: 33.401596,
  },
  {
    Date: "26.12.2018",
    price: 31.093464,
  },
  {
    Date: "27.12.2018",
    price: 30.962173,
  },
  {
    Date: "28.12.2018",
    price: 28.300598,
  },
  {
    Date: "29.12.2018",
    price: 32.558426,
  },
  {
    Date: "30.12.2018",
    price: 31.423141,
  },
  {
    Date: "31.12.2018",
    price: 31.977234,
  },
  {
    Date: "01.01.2019",
    price: 30.458393,
  },
  {
    Date: "02.01.2019",
    price: 32.021229,
  },
  {
    Date: "03.01.2019",
    price: 33.353573,
  },
  {
    Date: "04.01.2019",
    price: 32.016525,
  },
  {
    Date: "05.01.2019",
    price: 32.345547,
  },
  {
    Date: "06.01.2019",
    price: 34.847218,
  },
  {
    Date: "07.01.2019",
    price: 39.279751,
  },
  {
    Date: "08.01.2019",
    price: 37.918991,
  },
  {
    Date: "09.01.2019",
    price: 39.446453,
  },
  {
    Date: "10.01.2019",
    price: 38.987137,
  },
  {
    Date: "11.01.2019",
    price: 33.683475,
  },
  {
    Date: "12.01.2019",
    price: 32.456207,
  },
  {
    Date: "13.01.2019",
    price: 32.395824,
  },
  {
    Date: "14.01.2019",
    price: 30.378098,
  },
  {
    Date: "15.01.2019",
    price: 32.384205,
  },
  {
    Date: "16.01.2019",
    price: 31.438589,
  },
  {
    Date: "17.01.2019",
    price: 31.850113,
  },
  {
    Date: "18.01.2019",
    price: 31.91345,
  },
  {
    Date: "19.01.2019",
    price: 31.480209,
  },
  {
    Date: "20.01.2019",
    price: 32.490044,
  },
  {
    Date: "21.01.2019",
    price: 31.188656,
  },
  {
    Date: "22.01.2019",
    price: 31.213305,
  },
  {
    Date: "23.01.2019",
    price: 31.669024,
  },
  {
    Date: "24.01.2019",
    price: 32.042973,
  },
  {
    Date: "25.01.2019",
    price: 32.820435,
  },
  {
    Date: "26.01.2019",
    price: 32.96796,
  },
  {
    Date: "27.01.2019",
    price: 33.081963,
  },
  {
    Date: "28.01.2019",
    price: 32.517124,
  },
  {
    Date: "29.01.2019",
    price: 31.235544,
  },
  {
    Date: "30.01.2019",
    price: 31.066559,
  },
  {
    Date: "31.01.2019",
    price: 31.890856,
  },
  {
    Date: "01.02.2019",
    price: 31.719522,
  },
  {
    Date: "02.02.2019",
    price: 32.817982,
  },
  {
    Date: "03.02.2019",
    price: 34.43121,
  },
  {
    Date: "04.02.2019",
    price: 33.464359,
  },
  {
    Date: "05.02.2019",
    price: 34.009163,
  },
  {
    Date: "06.02.2019",
    price: 34.347305,
  },
  {
    Date: "07.02.2019",
    price: 32.905396,
  },
  {
    Date: "08.02.2019",
    price: 33.235271,
  },
  {
    Date: "09.02.2019",
    price: 43.021866,
  },
  {
    Date: "10.02.2019",
    price: 44.763634,
  },
  {
    Date: "11.02.2019",
    price: 46.881832,
  },
  {
    Date: "12.02.2019",
    price: 43.168873,
  },
  {
    Date: "13.02.2019",
    price: 43.83334,
  },
  {
    Date: "14.02.2019",
    price: 41.875496,
  },
  {
    Date: "15.02.2019",
    price: 41.643276,
  },
  {
    Date: "16.02.2019",
    price: 42.759766,
  },
  {
    Date: "17.02.2019",
    price: 43.396259,
  },
  {
    Date: "18.02.2019",
    price: 43.88195,
  },
  {
    Date: "19.02.2019",
    price: 48.136169,
  },
  {
    Date: "20.02.2019",
    price: 47.820896,
  },
  {
    Date: "21.02.2019",
    price: 51.835323,
  },
  {
    Date: "22.02.2019",
    price: 49.132931,
  },
  {
    Date: "23.02.2019",
    price: 49.671822,
  },
  {
    Date: "24.02.2019",
    price: 51.68029,
  },
  {
    Date: "25.02.2019",
    price: 44.575947,
  },
  {
    Date: "26.02.2019",
    price: 46.17646,
  },
  {
    Date: "27.02.2019",
    price: 45.498642,
  },
  {
    Date: "28.02.2019",
    price: 45.616497,
  },
  {
    Date: "01.03.2019",
    price: 46.235413,
  },
  {
    Date: "02.03.2019",
    price: 47.481236,
  },
  {
    Date: "03.03.2019",
    price: 49.021946,
  },
  {
    Date: "04.03.2019",
    price: 48.334953,
  },
  {
    Date: "05.03.2019",
    price: 46.523563,
  },
  {
    Date: "06.03.2019",
    price: 53.020226,
  },
  {
    Date: "07.03.2019",
    price: 55.837769,
  },
  {
    Date: "08.03.2019",
    price: 57.435509,
  },
  {
    Date: "09.03.2019",
    price: 56.001781,
  },
  {
    Date: "10.03.2019",
    price: 58.09853,
  },
  {
    Date: "11.03.2019",
    price: 57.187702,
  },
  {
    Date: "12.03.2019",
    price: 55.413017,
  },
  {
    Date: "13.03.2019",
    price: 57.075665,
  },
  {
    Date: "14.03.2019",
    price: 55.860012,
  },
  {
    Date: "15.03.2019",
    price: 56.541637,
  },
  {
    Date: "16.03.2019",
    price: 58.994133,
  },
  {
    Date: "17.03.2019",
    price: 61.881493,
  },
  {
    Date: "18.03.2019",
    price: 61.284283,
  },
  {
    Date: "19.03.2019",
    price: 60.224297,
  },
  {
    Date: "20.03.2019",
    price: 60.677914,
  },
  {
    Date: "21.03.2019",
    price: 60.867493,
  },
  {
    Date: "22.03.2019",
    price: 59.302612,
  },
  {
    Date: "23.03.2019",
    price: 59.791531,
  },
  {
    Date: "24.03.2019",
    price: 61.242916,
  },
  {
    Date: "25.03.2019",
    price: 60.302681,
  },
  {
    Date: "26.03.2019",
    price: 59.508308,
  },
  {
    Date: "27.03.2019",
    price: 59.407898,
  },
  {
    Date: "28.03.2019",
    price: 62.222458,
  },
  {
    Date: "29.03.2019",
    price: 61.167595,
  },
  {
    Date: "30.03.2019",
    price: 61.254097,
  },
  {
    Date: "31.03.2019",
    price: 60.638481,
  },
  {
    Date: "01.04.2019",
    price: 60.769836,
  },
  {
    Date: "02.04.2019",
    price: 60.704704,
  },
  {
    Date: "03.04.2019",
    price: 76.256302,
  },
  {
    Date: "04.04.2019",
    price: 85.173927,
  },
  {
    Date: "05.04.2019",
    price: 85.037949,
  },
  {
    Date: "06.04.2019",
    price: 88.707771,
  },
  {
    Date: "07.04.2019",
    price: 92.421371,
  },
  {
    Date: "08.04.2019",
    price: 92.327896,
  },
  {
    Date: "09.04.2019",
    price: 89.497734,
  },
  {
    Date: "10.04.2019",
    price: 86.77433,
  },
  {
    Date: "11.04.2019",
    price: 88.393532,
  },
  {
    Date: "12.04.2019",
    price: 79.632645,
  },
  {
    Date: "13.04.2019",
    price: 78.946709,
  },
  {
    Date: "14.04.2019",
    price: 78.204674,
  },
  {
    Date: "15.04.2019",
    price: 83.014893,
  },
  {
    Date: "16.04.2019",
    price: 79.038223,
  },
  {
    Date: "17.04.2019",
    price: 81.352409,
  },
  {
    Date: "18.04.2019",
    price: 79.392204,
  },
  {
    Date: "19.04.2019",
    price: 82.098816,
  },
  {
    Date: "20.04.2019",
    price: 82.550385,
  },
  {
    Date: "21.04.2019",
    price: 81.522018,
  },
  {
    Date: "22.04.2019",
    price: 77.294411,
  },
  {
    Date: "23.04.2019",
    price: 76.8535,
  },
  {
    Date: "24.04.2019",
    price: 74.753876,
  },
  {
    Date: "25.04.2019",
    price: 73.245316,
  },
  {
    Date: "26.04.2019",
    price: 71.363304,
  },
  {
    Date: "27.04.2019",
    price: 73.213371,
  },
  {
    Date: "28.04.2019",
    price: 72.120308,
  },
  {
    Date: "29.04.2019",
    price: 69.784256,
  },
  {
    Date: "30.04.2019",
    price: 67.950584,
  },
  {
    Date: "01.05.2019",
    price: 74.303352,
  },
  {
    Date: "02.05.2019",
    price: 73.682755,
  },
  {
    Date: "03.05.2019",
    price: 73.76445,
  },
  {
    Date: "04.05.2019",
    price: 79.307938,
  },
  {
    Date: "05.05.2019",
    price: 78.004234,
  },
  {
    Date: "06.05.2019",
    price: 76.028984,
  },
  {
    Date: "07.05.2019",
    price: 74.979942,
  },
  {
    Date: "08.05.2019",
    price: 74.647369,
  },
  {
    Date: "09.05.2019",
    price: 74.593979,
  },
  {
    Date: "10.05.2019",
    price: 74.18959,
  },
  {
    Date: "11.05.2019",
    price: 77.16275,
  },
  {
    Date: "12.05.2019",
    price: 89.539299,
  },
  {
    Date: "13.05.2019",
    price: 84.632576,
  },
  {
    Date: "14.05.2019",
    price: 88.053047,
  },
  {
    Date: "15.05.2019",
    price: 91.661552,
  },
  {
    Date: "16.05.2019",
    price: 101.573143,
  },
  {
    Date: "17.05.2019",
    price: 95.632607,
  },
  {
    Date: "18.05.2019",
    price: 89.010017,
  },
  {
    Date: "19.05.2019",
    price: 86.675331,
  },
  {
    Date: "20.05.2019",
    price: 95.339371,
  },
  {
    Date: "21.05.2019",
    price: 91.572083,
  },
  {
    Date: "22.05.2019",
    price: 91.505104,
  },
  {
    Date: "23.05.2019",
    price: 88.197609,
  },
  {
    Date: "24.05.2019",
    price: 88.973946,
  },
  {
    Date: "25.05.2019",
    price: 100.04554,
  },
  {
    Date: "26.05.2019",
    price: 102.700592,
  },
  {
    Date: "27.05.2019",
    price: 111.435295,
  },
  {
    Date: "28.05.2019",
    price: 117.891891,
  },
  {
    Date: "29.05.2019",
    price: 114.925446,
  },
  {
    Date: "30.05.2019",
    price: 115.140594,
  },
  {
    Date: "31.05.2019",
    price: 108.69622,
  },
  {
    Date: "01.06.2019",
    price: 114.466255,
  },
  {
    Date: "02.06.2019",
    price: 112.710167,
  },
  {
    Date: "03.06.2019",
    price: 114.668709,
  },
  {
    Date: "04.06.2019",
    price: 107.372932,
  },
  {
    Date: "05.06.2019",
    price: 102.119499,
  },
  {
    Date: "06.06.2019",
    price: 103.806831,
  },
  {
    Date: "07.06.2019",
    price: 110.975151,
  },
  {
    Date: "08.06.2019",
    price: 117.278076,
  },
  {
    Date: "09.06.2019",
    price: 118.58606,
  },
  {
    Date: "10.06.2019",
    price: 114.99556,
  },
  {
    Date: "11.06.2019",
    price: 129.26413,
  },
  {
    Date: "12.06.2019",
    price: 136.085831,
  },
  {
    Date: "13.06.2019",
    price: 135.682114,
  },
  {
    Date: "14.06.2019",
    price: 131.314011,
  },
  {
    Date: "15.06.2019",
    price: 133.17981,
  },
  {
    Date: "16.06.2019",
    price: 138.148788,
  },
  {
    Date: "17.06.2019",
    price: 137.129944,
  },
  {
    Date: "18.06.2019",
    price: 134.472092,
  },
  {
    Date: "19.06.2019",
    price: 135.152664,
  },
  {
    Date: "20.06.2019",
    price: 136.60228,
  },
  {
    Date: "21.06.2019",
    price: 135.508514,
  },
  {
    Date: "22.06.2019",
    price: 139.036957,
  },
  {
    Date: "23.06.2019",
    price: 141.94516,
  },
  {
    Date: "24.06.2019",
    price: 136.920868,
  },
  {
    Date: "25.06.2019",
    price: 135.580948,
  },
  {
    Date: "26.06.2019",
    price: 136.477005,
  },
  {
    Date: "27.06.2019",
    price: 131.223007,
  },
  {
    Date: "28.06.2019",
    price: 114.41111,
  },
  {
    Date: "29.06.2019",
    price: 120.155098,
  },
  {
    Date: "30.06.2019",
    price: 133.72464,
  },
  {
    Date: "01.07.2019",
    price: 122.114136,
  },
  {
    Date: "02.07.2019",
    price: 122.556335,
  },
  {
    Date: "03.07.2019",
    price: 119.334221,
  },
  {
    Date: "04.07.2019",
    price: 122.502754,
  },
  {
    Date: "05.07.2019",
    price: 119.837662,
  },
  {
    Date: "06.07.2019",
    price: 118.378716,
  },
  {
    Date: "07.07.2019",
    price: 118.026009,
  },
  {
    Date: "08.07.2019",
    price: 120.283493,
  },
  {
    Date: "09.07.2019",
    price: 123.247101,
  },
  {
    Date: "10.07.2019",
    price: 119.500999,
  },
  {
    Date: "11.07.2019",
    price: 108.570404,
  },
  {
    Date: "12.07.2019",
    price: 102.827324,
  },
  {
    Date: "13.07.2019",
    price: 105.286636,
  },
  {
    Date: "14.07.2019",
    price: 101.106796,
  },
  {
    Date: "15.07.2019",
    price: 89.857735,
  },
  {
    Date: "16.07.2019",
    price: 90.90007,
  },
  {
    Date: "17.07.2019",
    price: 79.407326,
  },
  {
    Date: "18.07.2019",
    price: 89.694733,
  },
  {
    Date: "19.07.2019",
    price: 101.08194,
  },
  {
    Date: "20.07.2019",
    price: 98.456924,
  },
  {
    Date: "21.07.2019",
    price: 100.506561,
  },
  {
    Date: "22.07.2019",
    price: 99.557892,
  },
  {
    Date: "23.07.2019",
    price: 95.406654,
  },
  {
    Date: "24.07.2019",
    price: 90.461143,
  },
  {
    Date: "25.07.2019",
    price: 94.35981,
  },
  {
    Date: "26.07.2019",
    price: 93.411346,
  },
  {
    Date: "27.07.2019",
    price: 94.316109,
  },
  {
    Date: "28.07.2019",
    price: 88.885887,
  },
  {
    Date: "29.07.2019",
    price: 89.771797,
  },
  {
    Date: "30.07.2019",
    price: 90.471451,
  },
  {
    Date: "31.07.2019",
    price: 90.471001,
  },
  {
    Date: "01.08.2019",
    price: 98.566689,
  },
  {
    Date: "02.08.2019",
    price: 99.199585,
  },
  {
    Date: "03.08.2019",
    price: 95.016815,
  },
  {
    Date: "04.08.2019",
    price: 94.598244,
  },
  {
    Date: "05.08.2019",
    price: 93.085281,
  },
  {
    Date: "06.08.2019",
    price: 96.889603,
  },
  {
    Date: "07.08.2019",
    price: 93.038872,
  },
  {
    Date: "08.08.2019",
    price: 90.911568,
  },
  {
    Date: "09.08.2019",
    price: 90.089195,
  },
  {
    Date: "10.08.2019",
    price: 84.390991,
  },
  {
    Date: "11.08.2019",
    price: 85.450752,
  },
  {
    Date: "12.08.2019",
    price: 89.702393,
  },
  {
    Date: "13.08.2019",
    price: 85.746429,
  },
  {
    Date: "14.08.2019",
    price: 84.420349,
  },
  {
    Date: "15.08.2019",
    price: 76.13327,
  },
  {
    Date: "16.08.2019",
    price: 76.315247,
  },
  {
    Date: "17.08.2019",
    price: 74.90509,
  },
  {
    Date: "18.08.2019",
    price: 72.8396,
  },
  {
    Date: "19.08.2019",
    price: 76.337112,
  },
  {
    Date: "20.08.2019",
    price: 77.298386,
  },
  {
    Date: "21.08.2019",
    price: 75.244278,
  },
  {
    Date: "22.08.2019",
    price: 72.950768,
  },
  {
    Date: "23.08.2019",
    price: 73.577332,
  },
  {
    Date: "24.08.2019",
    price: 75.407806,
  },
  {
    Date: "25.08.2019",
    price: 73.470039,
  },
  {
    Date: "26.08.2019",
    price: 72.320229,
  },
  {
    Date: "27.08.2019",
    price: 73.933868,
  },
  {
    Date: "28.08.2019",
    price: 72.902771,
  },
  {
    Date: "29.08.2019",
    price: 67.355339,
  },
  {
    Date: "30.08.2019",
    price: 64.083733,
  },
  {
    Date: "31.08.2019",
    price: 64.359093,
  },
  {
    Date: "01.09.2019",
    price: 64.558609,
  },
  {
    Date: "02.09.2019",
    price: 66.113625,
  },
  {
    Date: "03.09.2019",
    price: 66.990768,
  },
  {
    Date: "04.09.2019",
    price: 69.176498,
  },
  {
    Date: "05.09.2019",
    price: 67.308739,
  },
  {
    Date: "06.09.2019",
    price: 65.386726,
  },
  {
    Date: "07.09.2019",
    price: 65.236877,
  },
  {
    Date: "08.09.2019",
    price: 69.016068,
  },
  {
    Date: "09.09.2019",
    price: 70.619392,
  },
  {
    Date: "10.09.2019",
    price: 70.132103,
  },
  {
    Date: "11.09.2019",
    price: 70.876144,
  },
  {
    Date: "12.09.2019",
    price: 69.958572,
  },
  {
    Date: "13.09.2019",
    price: 69.233208,
  },
  {
    Date: "14.09.2019",
    price: 69.021957,
  },
  {
    Date: "15.09.2019",
    price: 70.587219,
  },
  {
    Date: "16.09.2019",
    price: 70.323318,
  },
  {
    Date: "17.09.2019",
    price: 72.591072,
  },
  {
    Date: "18.09.2019",
    price: 74.228523,
  },
  {
    Date: "19.09.2019",
    price: 77.919273,
  },
  {
    Date: "20.09.2019",
    price: 76.95784,
  },
  {
    Date: "21.09.2019",
    price: 75.181816,
  },
  {
    Date: "22.09.2019",
    price: 73.509254,
  },
  {
    Date: "23.09.2019",
    price: 72.613213,
  },
  {
    Date: "24.09.2019",
    price: 66.844116,
  },
  {
    Date: "25.09.2019",
    price: 55.834206,
  },
  {
    Date: "26.09.2019",
    price: 57.657761,
  },
  {
    Date: "27.09.2019",
    price: 55.566452,
  },
  {
    Date: "28.09.2019",
    price: 56.416576,
  },
  {
    Date: "29.09.2019",
    price: 55.706615,
  },
  {
    Date: "30.09.2019",
    price: 54.314339,
  },
  {
    Date: "01.10.2019",
    price: 56.089233,
  },
  {
    Date: "02.10.2019",
    price: 56.013378,
  },
  {
    Date: "03.10.2019",
    price: 56.53479,
  },
  {
    Date: "04.10.2019",
    price: 56.523712,
  },
  {
    Date: "05.10.2019",
    price: 56.809544,
  },
  {
    Date: "06.10.2019",
    price: 56.835426,
  },
  {
    Date: "07.10.2019",
    price: 55.342026,
  },
  {
    Date: "08.10.2019",
    price: 57.793385,
  },
  {
    Date: "09.10.2019",
    price: 57.420483,
  },
  {
    Date: "10.10.2019",
    price: 59.401573,
  },
  {
    Date: "11.10.2019",
    price: 57.769424,
  },
  {
    Date: "12.10.2019",
    price: 56.404388,
  },
  {
    Date: "13.10.2019",
    price: 55.941875,
  },
  {
    Date: "14.10.2019",
    price: 56.718159,
  },
  {
    Date: "15.10.2019",
    price: 57.00732,
  },
  {
    Date: "16.10.2019",
    price: 54.986629,
  },
  {
    Date: "17.10.2019",
    price: 52.897232,
  },
  {
    Date: "18.10.2019",
    price: 54.934757,
  },
  {
    Date: "19.10.2019",
    price: 53.465134,
  },
  {
    Date: "20.10.2019",
    price: 54.063122,
  },
  {
    Date: "21.10.2019",
    price: 54.916088,
  },
  {
    Date: "22.10.2019",
    price: 54.928261,
  },
  {
    Date: "23.10.2019",
    price: 53.753723,
  },
  {
    Date: "24.10.2019",
    price: 49.683434,
  },
  {
    Date: "25.10.2019",
    price: 50.114281,
  },
  {
    Date: "26.10.2019",
    price: 56.945976,
  },
  {
    Date: "27.10.2019",
    price: 56.802624,
  },
  {
    Date: "28.10.2019",
    price: 59.9198,
  },
  {
    Date: "29.10.2019",
    price: 58.051361,
  },
  {
    Date: "30.10.2019",
    price: 59.865326,
  },
  {
    Date: "31.10.2019",
    price: 58.363445,
  },
  {
    Date: "01.11.2019",
    price: 58.746315,
  },
  {
    Date: "02.11.2019",
    price: 58.579086,
  },
  {
    Date: "03.11.2019",
    price: 58.592102,
  },
  {
    Date: "04.11.2019",
    price: 58.595737,
  },
  {
    Date: "05.11.2019",
    price: 61.577427,
  },
  {
    Date: "06.11.2019",
    price: 63.345425,
  },
  {
    Date: "07.11.2019",
    price: 64.239006,
  },
  {
    Date: "08.11.2019",
    price: 61.877556,
  },
  {
    Date: "09.11.2019",
    price: 60.486755,
  },
  {
    Date: "10.11.2019",
    price: 61.897144,
  },
  {
    Date: "11.11.2019",
    price: 63.779156,
  },
  {
    Date: "12.11.2019",
    price: 61.933453,
  },
  {
    Date: "13.11.2019",
    price: 61.370422,
  },
  {
    Date: "14.11.2019",
    price: 61.077152,
  },
  {
    Date: "15.11.2019",
    price: 59.480595,
  },
  {
    Date: "16.11.2019",
    price: 57.7883,
  },
  {
    Date: "17.11.2019",
    price: 58.604748,
  },
  {
    Date: "18.11.2019",
    price: 59.570045,
  },
  {
    Date: "19.11.2019",
    price: 56.804165,
  },
  {
    Date: "20.11.2019",
    price: 55.63377,
  },
  {
    Date: "21.11.2019",
    price: 55.284191,
  },
  {
    Date: "22.11.2019",
    price: 50.879673,
  },
  {
    Date: "23.11.2019",
    price: 47.62233,
  },
  {
    Date: "24.11.2019",
    price: 48.714859,
  },
  {
    Date: "25.11.2019",
    price: 44.769817,
  },
  {
    Date: "26.11.2019",
    price: 45.814106,
  },
  {
    Date: "27.11.2019",
    price: 47.164562,
  },
  {
    Date: "28.11.2019",
    price: 48.002125,
  },
  {
    Date: "29.11.2019",
    price: 47.091339,
  },
  {
    Date: "30.11.2019",
    price: 48.912785,
  },
  {
    Date: "01.12.2019",
    price: 47.463898,
  },
  {
    Date: "02.12.2019",
    price: 47.65147,
  },
  {
    Date: "03.12.2019",
    price: 45.775471,
  },
  {
    Date: "04.12.2019",
    price: 45.399948,
  },
  {
    Date: "05.12.2019",
    price: 45.142643,
  },
  {
    Date: "06.12.2019",
    price: 45.28791,
  },
  {
    Date: "07.12.2019",
    price: 45.673733,
  },
  {
    Date: "08.12.2019",
    price: 45.68227,
  },
  {
    Date: "09.12.2019",
    price: 45.899914,
  },
  {
    Date: "10.12.2019",
    price: 45.012054,
  },
  {
    Date: "11.12.2019",
    price: 44.610558,
  },
  {
    Date: "12.12.2019",
    price: 43.922695,
  },
  {
    Date: "13.12.2019",
    price: 44.011265,
  },
  {
    Date: "14.12.2019",
    price: 44.44957,
  },
  {
    Date: "15.12.2019",
    price: 43.84594,
  },
  {
    Date: "16.12.2019",
    price: 43.784161,
  },
  {
    Date: "17.12.2019",
    price: 40.358624,
  },
  {
    Date: "18.12.2019",
    price: 37.116276,
  },
  {
    Date: "19.12.2019",
    price: 40.793453,
  },
  {
    Date: "20.12.2019",
    price: 39.939907,
  },
  {
    Date: "21.12.2019",
    price: 40.304817,
  },
  {
    Date: "22.12.2019",
    price: 40.010098,
  },
  {
    Date: "23.12.2019",
    price: 41.954311,
  },
  {
    Date: "24.12.2019",
    price: 40.862877,
  },
  {
    Date: "25.12.2019",
    price: 40.73457,
  },
  {
    Date: "26.12.2019",
    price: 40.608425,
  },
  {
    Date: "27.12.2019",
    price: 40.261745,
  },
  {
    Date: "28.12.2019",
    price: 41.165157,
  },
  {
    Date: "29.12.2019",
    price: 42.797741,
  },
  {
    Date: "30.12.2019",
    price: 43.753933,
  },
  {
    Date: "31.12.2019",
    price: 42.766113,
  },
  {
    Date: "01.01.2020",
    price: 41.326534,
  },
  {
    Date: "02.01.2020",
    price: 42.018085,
  },
  {
    Date: "03.01.2020",
    price: 39.863129,
  },
  {
    Date: "04.01.2020",
    price: 42.383526,
  },
  {
    Date: "05.01.2020",
    price: 43.291382,
  },
  {
    Date: "06.01.2020",
    price: 43.603893,
  },
  {
    Date: "07.01.2020",
    price: 45.83868,
  },
  {
    Date: "08.01.2020",
    price: 46.448353,
  },
  {
    Date: "09.01.2020",
    price: 45.622269,
  },
  {
    Date: "10.01.2020",
    price: 45.21154,
  },
  {
    Date: "11.01.2020",
    price: 48.633858,
  },
  {
    Date: "12.01.2020",
    price: 49.319157,
  },
  {
    Date: "13.01.2020",
    price: 51.122311,
  },
  {
    Date: "14.01.2020",
    price: 49.697361,
  },
  {
    Date: "15.01.2020",
    price: 58.390266,
  },
  {
    Date: "16.01.2020",
    price: 58.047962,
  },
  {
    Date: "17.01.2020",
    price: 57.642967,
  },
  {
    Date: "18.01.2020",
    price: 61.302834,
  },
  {
    Date: "19.01.2020",
    price: 59.445709,
  },
  {
    Date: "20.01.2020",
    price: 57.781528,
  },
  {
    Date: "21.01.2020",
    price: 57.233521,
  },
  {
    Date: "22.01.2020",
    price: 57.639591,
  },
  {
    Date: "23.01.2020",
    price: 58.350388,
  },
  {
    Date: "24.01.2020",
    price: 54.643814,
  },
  {
    Date: "25.01.2020",
    price: 54.564056,
  },
  {
    Date: "26.01.2020",
    price: 53.687065,
  },
  {
    Date: "27.01.2020",
    price: 56.275341,
  },
  {
    Date: "28.01.2020",
    price: 58.768562,
  },
  {
    Date: "29.01.2020",
    price: 60.663769,
  },
  {
    Date: "30.01.2020",
    price: 60.013916,
  },
  {
    Date: "31.01.2020",
    price: 68.179039,
  },
  {
    Date: "01.02.2020",
    price: 67.908516,
  },
  {
    Date: "02.02.2020",
    price: 70.802345,
  },
  {
    Date: "03.02.2020",
    price: 69.992584,
  },
  {
    Date: "04.02.2020",
    price: 69.769485,
  },
  {
    Date: "05.02.2020",
    price: 68.114609,
  },
  {
    Date: "06.02.2020",
    price: 72.532379,
  },
  {
    Date: "07.02.2020",
    price: 73.467918,
  },
  {
    Date: "08.02.2020",
    price: 74.395378,
  },
  {
    Date: "09.02.2020",
    price: 76.449097,
  },
  {
    Date: "10.02.2020",
    price: 77.143051,
  },
  {
    Date: "11.02.2020",
    price: 74.346077,
  },
  {
    Date: "12.02.2020",
    price: 76.493523,
  },
  {
    Date: "13.02.2020",
    price: 81.229721,
  },
  {
    Date: "14.02.2020",
    price: 80.375511,
  },
  {
    Date: "15.02.2020",
    price: 83.025452,
  },
  {
    Date: "16.02.2020",
    price: 76.729309,
  },
  {
    Date: "17.02.2020",
    price: 74.968086,
  },
  {
    Date: "18.02.2020",
    price: 73.185623,
  },
  {
    Date: "19.02.2020",
    price: 77.075516,
  },
  {
    Date: "20.02.2020",
    price: 70.981949,
  },
  {
    Date: "21.02.2020",
    price: 69.570442,
  },
  {
    Date: "22.02.2020",
    price: 73.485863,
  },
  {
    Date: "23.02.2020",
    price: 75.074013,
  },
  {
    Date: "24.02.2020",
    price: 79.559517,
  },
  {
    Date: "25.02.2020",
    price: 75.495399,
  },
  {
    Date: "26.02.2020",
    price: 71.180382,
  },
  {
    Date: "27.02.2020",
    price: 61.027927,
  },
  {
    Date: "28.02.2020",
    price: 61.437935,
  },
  {
    Date: "29.02.2020",
    price: 59.748737,
  },
  {
    Date: "01.03.2020",
    price: 58.530544,
  },
  {
    Date: "02.03.2020",
    price: 57.984009,
  },
  {
    Date: "03.03.2020",
    price: 61.020573,
  },
  {
    Date: "04.03.2020",
    price: 61.143494,
  },
  {
    Date: "05.03.2020",
    price: 60.402779,
  },
  {
    Date: "06.03.2020",
    price: 62.049889,
  },
  {
    Date: "07.03.2020",
    price: 63.022015,
  },
  {
    Date: "08.03.2020",
    price: 60.579117,
  },
  {
    Date: "09.03.2020",
    price: 51.638126,
  },
  {
    Date: "10.03.2020",
    price: 50.479828,
  },
  {
    Date: "11.03.2020",
    price: 50.263767,
  },
  {
    Date: "12.03.2020",
    price: 48.44923,
  },
  {
    Date: "13.03.2020",
    price: 31.004513,
  },
  {
    Date: "14.03.2020",
    price: 37.536198,
  },
  {
    Date: "15.03.2020",
    price: 34.409904,
  },
  {
    Date: "16.03.2020",
    price: 36.445911,
  },
  {
    Date: "17.03.2020",
    price: 32.903088,
  },
  {
    Date: "18.03.2020",
    price: 33.555664,
  },
  {
    Date: "19.03.2020",
    price: 33.721718,
  },
  {
    Date: "20.03.2020",
    price: 39.108093,
  },
  {
    Date: "21.03.2020",
    price: 38.077866,
  },
  {
    Date: "22.03.2020",
    price: 38.371738,
  },
  {
    Date: "23.03.2020",
    price: 35.577431,
  },
  {
    Date: "24.03.2020",
    price: 38.747993,
  },
  {
    Date: "25.03.2020",
    price: 40.479458,
  },
  {
    Date: "26.03.2020",
    price: 39.172966,
  },
  {
    Date: "27.03.2020",
    price: 40.183334,
  },
  {
    Date: "28.03.2020",
    price: 38.862179,
  },
  {
    Date: "29.03.2020",
    price: 38.86528,
  },
  {
    Date: "30.03.2020",
    price: 37.264854,
  },
  {
    Date: "31.03.2020",
    price: 39.119137,
  },
  {
    Date: "01.04.2020",
    price: 39.284687,
  },
  {
    Date: "02.04.2020",
    price: 39.36348,
  },
  {
    Date: "03.04.2020",
    price: 39.88763,
  },
  {
    Date: "04.04.2020",
    price: 40.344578,
  },
  {
    Date: "05.04.2020",
    price: 40.690037,
  },
  {
    Date: "06.04.2020",
    price: 40.658787,
  },
  {
    Date: "07.04.2020",
    price: 44.402138,
  },
  {
    Date: "08.04.2020",
    price: 44.678623,
  },
  {
    Date: "09.04.2020",
    price: 46.348034,
  },
  {
    Date: "10.04.2020",
    price: 46.400341,
  },
  {
    Date: "11.04.2020",
    price: 42.256741,
  },
  {
    Date: "12.04.2020",
    price: 42.320835,
  },
  {
    Date: "13.04.2020",
    price: 42.499252,
  },
  {
    Date: "14.04.2020",
    price: 41.222858,
  },
  {
    Date: "15.04.2020",
    price: 41.080093,
  },
  {
    Date: "16.04.2020",
    price: 39.643715,
  },
  {
    Date: "17.04.2020",
    price: 42.675053,
  },
  {
    Date: "18.04.2020",
    price: 42.751598,
  },
  {
    Date: "19.04.2020",
    price: 44.348003,
  },
  {
    Date: "20.04.2020",
    price: 42.674625,
  },
  {
    Date: "21.04.2020",
    price: 40.809624,
  },
  {
    Date: "22.04.2020",
    price: 40.993271,
  },
  {
    Date: "23.04.2020",
    price: 41.877735,
  },
  {
    Date: "24.04.2020",
    price: 42.994709,
  },
  {
    Date: "25.04.2020",
    price: 44.815205,
  },
  {
    Date: "26.04.2020",
    price: 44.719917,
  },
  {
    Date: "27.04.2020",
    price: 44.677433,
  },
  {
    Date: "28.04.2020",
    price: 44.738171,
  },
  {
    Date: "29.04.2020",
    price: 46.091522,
  },
  {
    Date: "30.04.2020",
    price: 49.147854,
  },
  {
    Date: "01.05.2020",
    price: 46.714264,
  },
  {
    Date: "02.05.2020",
    price: 47.770359,
  },
  {
    Date: "03.05.2020",
    price: 49.464958,
  },
  {
    Date: "04.05.2020",
    price: 48.243027,
  },
  {
    Date: "05.05.2020",
    price: 47.450291,
  },
  {
    Date: "06.05.2020",
    price: 46.768917,
  },
  {
    Date: "07.05.2020",
    price: 46.019604,
  },
  {
    Date: "08.05.2020",
    price: 47.307724,
  },
  {
    Date: "09.05.2020",
    price: 47.839458,
  },
  {
    Date: "10.05.2020",
    price: 47.260593,
  },
  {
    Date: "11.05.2020",
    price: 42.358269,
  },
  {
    Date: "12.05.2020",
    price: 41.739216,
  },
  {
    Date: "13.05.2020",
    price: 42.074535,
  },
  {
    Date: "14.05.2020",
    price: 43.094921,
  },
  {
    Date: "15.05.2020",
    price: 43.945839,
  },
  {
    Date: "16.05.2020",
    price: 42.85136,
  },
  {
    Date: "17.05.2020",
    price: 43.180321,
  },
  {
    Date: "18.05.2020",
    price: 43.643074,
  },
  {
    Date: "19.05.2020",
    price: 45.21331,
  },
  {
    Date: "20.05.2020",
    price: 45.387444,
  },
  {
    Date: "21.05.2020",
    price: 44.156235,
  },
  {
    Date: "22.05.2020",
    price: 42.73037,
  },
  {
    Date: "23.05.2020",
    price: 44.208248,
  },
  {
    Date: "24.05.2020",
    price: 44.018593,
  },
  {
    Date: "25.05.2020",
    price: 42.405674,
  },
  {
    Date: "26.05.2020",
    price: 42.9272,
  },
  {
    Date: "27.05.2020",
    price: 42.409096,
  },
  {
    Date: "28.05.2020",
    price: 43.871845,
  },
  {
    Date: "29.05.2020",
    price: 44.776535,
  },
  {
    Date: "30.05.2020",
    price: 44.632866,
  },
  {
    Date: "31.05.2020",
    price: 47.53928,
  },
  {
    Date: "01.06.2020",
    price: 45.57423,
  },
  {
    Date: "02.06.2020",
    price: 48.094391,
  },
  {
    Date: "03.06.2020",
    price: 45.929836,
  },
  {
    Date: "04.06.2020",
    price: 47.20063,
  },
  {
    Date: "05.06.2020",
    price: 47.674435,
  },
  {
    Date: "06.06.2020",
    price: 47.066727,
  },
  {
    Date: "07.06.2020",
    price: 46.744019,
  },
  {
    Date: "08.06.2020",
    price: 46.659168,
  },
  {
    Date: "09.06.2020",
    price: 46.471443,
  },
  {
    Date: "10.06.2020",
    price: 46.069267,
  },
  {
    Date: "11.06.2020",
    price: 46.683582,
  },
  {
    Date: "12.06.2020",
    price: 43.500725,
  },
  {
    Date: "13.06.2020",
    price: 44.793709,
  },
  {
    Date: "14.06.2020",
    price: 45.103828,
  },
  {
    Date: "15.06.2020",
    price: 44.084526,
  },
  {
    Date: "16.06.2020",
    price: 43.908958,
  },
  {
    Date: "17.06.2020",
    price: 43.882442,
  },
  {
    Date: "18.06.2020",
    price: 44.032356,
  },
  {
    Date: "19.06.2020",
    price: 43.556366,
  },
  {
    Date: "20.06.2020",
    price: 42.646275,
  },
  {
    Date: "21.06.2020",
    price: 43.337345,
  },
  {
    Date: "22.06.2020",
    price: 43.109253,
  },
  {
    Date: "23.06.2020",
    price: 44.194988,
  },
  {
    Date: "24.06.2020",
    price: 44.188141,
  },
  {
    Date: "25.06.2020",
    price: 42.862835,
  },
  {
    Date: "26.06.2020",
    price: 42.564304,
  },
  {
    Date: "27.06.2020",
    price: 43.280907,
  },
  {
    Date: "28.06.2020",
    price: 41.555561,
  },
  {
    Date: "29.06.2020",
    price: 41.427532,
  },
  {
    Date: "30.06.2020",
    price: 41.743965,
  },
  {
    Date: "01.07.2020",
    price: 41.43354,
  },
  {
    Date: "02.07.2020",
    price: 41.736717,
  },
  {
    Date: "03.07.2020",
    price: 41.471855,
  },
  {
    Date: "04.07.2020",
    price: 41.173149,
  },
  {
    Date: "05.07.2020",
    price: 42.02216,
  },
  {
    Date: "06.07.2020",
    price: 41.598076,
  },
  {
    Date: "07.07.2020",
    price: 43.963493,
  },
  {
    Date: "08.07.2020",
    price: 43.318398,
  },
  {
    Date: "09.07.2020",
    price: 45.188488,
  },
  {
    Date: "10.07.2020",
    price: 44.505367,
  },
  {
    Date: "11.07.2020",
    price: 44.250656,
  },
  {
    Date: "12.07.2020",
    price: 44.687767,
  },
  {
    Date: "13.07.2020",
    price: 44.636101,
  },
  {
    Date: "14.07.2020",
    price: 43.969219,
  },
  {
    Date: "15.07.2020",
    price: 43.790375,
  },
  {
    Date: "16.07.2020",
    price: 43.263828,
  },
  {
    Date: "17.07.2020",
    price: 42.041271,
  },
  {
    Date: "18.07.2020",
    price: 41.908268,
  },
  {
    Date: "19.07.2020",
    price: 42.425865,
  },
  {
    Date: "20.07.2020",
    price: 42.551159,
  },
  {
    Date: "21.07.2020",
    price: 41.973995,
  },
  {
    Date: "22.07.2020",
    price: 43.606789,
  },
  {
    Date: "23.07.2020",
    price: 44.939594,
  },
  {
    Date: "24.07.2020",
    price: 44.850105,
  },
  {
    Date: "25.07.2020",
    price: 44.107262,
  },
  {
    Date: "26.07.2020",
    price: 48.836586,
  },
  {
    Date: "27.07.2020",
    price: 47.973759,
  },
  {
    Date: "28.07.2020",
    price: 53.060986,
  },
  {
    Date: "29.07.2020",
    price: 55.591705,
  },
  {
    Date: "30.07.2020",
    price: 55.100842,
  },
  {
    Date: "31.07.2020",
    price: 56.65485,
  },
  {
    Date: "01.08.2020",
    price: 57.998138,
  },
  {
    Date: "02.08.2020",
    price: 61.259525,
  },
  {
    Date: "03.08.2020",
    price: 56.624901,
  },
  {
    Date: "04.08.2020",
    price: 58.462353,
  },
  {
    Date: "05.08.2020",
    price: 57.576206,
  },
  {
    Date: "06.08.2020",
    price: 58.986931,
  },
  {
    Date: "07.08.2020",
    price: 59.1483,
  },
  {
    Date: "08.08.2020",
    price: 57.205868,
  },
  {
    Date: "09.08.2020",
    price: 58.228046,
  },
  {
    Date: "10.08.2020",
    price: 57.116756,
  },
  {
    Date: "11.08.2020",
    price: 58.288845,
  },
  {
    Date: "12.08.2020",
    price: 54.36639,
  },
  {
    Date: "13.08.2020",
    price: 54.988716,
  },
  {
    Date: "14.08.2020",
    price: 57.701302,
  },
  {
    Date: "15.08.2020",
    price: 56.72636,
  },
  {
    Date: "16.08.2020",
    price: 59.942123,
  },
  {
    Date: "17.08.2020",
    price: 63.826542,
  },
  {
    Date: "18.08.2020",
    price: 67.05616,
  },
  {
    Date: "19.08.2020",
    price: 65.554649,
  },
  {
    Date: "20.08.2020",
    price: 62.019966,
  },
  {
    Date: "21.08.2020",
    price: 62.905972,
  },
  {
    Date: "22.08.2020",
    price: 59.363056,
  },
  {
    Date: "23.08.2020",
    price: 60.262802,
  },
  {
    Date: "24.08.2020",
    price: 60.62326,
  },
  {
    Date: "25.08.2020",
    price: 62.215099,
  },
  {
    Date: "26.08.2020",
    price: 58.442039,
  },
  {
    Date: "27.08.2020",
    price: 58.298702,
  },
  {
    Date: "28.08.2020",
    price: 56.103962,
  },
  {
    Date: "29.08.2020",
    price: 57.438873,
  },
  {
    Date: "30.08.2020",
    price: 57.207737,
  },
  {
    Date: "31.08.2020",
    price: 62.844059,
  },
  {
    Date: "01.09.2020",
    price: 61.105076,
  },
  {
    Date: "02.09.2020",
    price: 62.871326,
  },
  {
    Date: "03.09.2020",
    price: 58.013084,
  },
  {
    Date: "04.09.2020",
    price: 47.779217,
  },
  {
    Date: "05.09.2020",
    price: 50.619232,
  },
  {
    Date: "06.09.2020",
    price: 47.596558,
  },
  {
    Date: "07.09.2020",
    price: 48.033924,
  },
  {
    Date: "08.09.2020",
    price: 48.805126,
  },
  {
    Date: "09.09.2020",
    price: 47.377357,
  },
  {
    Date: "10.09.2020",
    price: 47.923195,
  },
  {
    Date: "11.09.2020",
    price: 48.716854,
  },
  {
    Date: "12.09.2020",
    price: 49.057728,
  },
  {
    Date: "13.09.2020",
    price: 50.760929,
  },
  {
    Date: "14.09.2020",
    price: 48.04966,
  },
  {
    Date: "15.09.2020",
    price: 49.117363,
  },
  {
    Date: "16.09.2020",
    price: 48.459633,
  },
  {
    Date: "17.09.2020",
    price: 47.980671,
  },
  {
    Date: "18.09.2020",
    price: 48.828274,
  },
  {
    Date: "19.09.2020",
    price: 48.481586,
  },
  {
    Date: "20.09.2020",
    price: 48.667938,
  },
  {
    Date: "21.09.2020",
    price: 47.129139,
  },
  {
    Date: "22.09.2020",
    price: 43.359463,
  },
  {
    Date: "23.09.2020",
    price: 44.486065,
  },
  {
    Date: "24.09.2020",
    price: 43.046337,
  },
  {
    Date: "25.09.2020",
    price: 45.045551,
  },
  {
    Date: "26.09.2020",
    price: 46.103172,
  },
  {
    Date: "27.09.2020",
    price: 46.091331,
  },
  {
    Date: "28.09.2020",
    price: 46.110626,
  },
  {
    Date: "29.09.2020",
    price: 45.29805,
  },
  {
    Date: "30.09.2020",
    price: 45.806763,
  },
  {
    Date: "01.10.2020",
    price: 46.426956,
  },
  {
    Date: "02.10.2020",
    price: 46.348965,
  },
  {
    Date: "03.10.2020",
    price: 44.905697,
  },
  {
    Date: "04.10.2020",
    price: 45.580982,
  },
  {
    Date: "05.10.2020",
    price: 46.742477,
  },
  {
    Date: "06.10.2020",
    price: 46.356865,
  },
  {
    Date: "07.10.2020",
    price: 46.004665,
  },
  {
    Date: "08.10.2020",
    price: 46.680595,
  },
  {
    Date: "09.10.2020",
    price: 47.216053,
  },
  {
    Date: "10.10.2020",
    price: 47.777119,
  },
  {
    Date: "11.10.2020",
    price: 48.914909,
  },
  {
    Date: "12.10.2020",
    price: 50.615707,
  },
  {
    Date: "13.10.2020",
    price: 50.683041,
  },
  {
    Date: "14.10.2020",
    price: 50.007183,
  },
  {
    Date: "15.10.2020",
    price: 49.825611,
  },
  {
    Date: "16.10.2020",
    price: 49.534931,
  },
  {
    Date: "17.10.2020",
    price: 47.747189,
  },
  {
    Date: "18.10.2020",
    price: 47.059418,
  },
  {
    Date: "19.10.2020",
    price: 47.641457,
  },
  {
    Date: "20.10.2020",
    price: 48.102547,
  },
  {
    Date: "21.10.2020",
    price: 47.062843,
  },
  {
    Date: "22.10.2020",
    price: 53.015892,
  },
  {
    Date: "23.10.2020",
    price: 54.299412,
  },
  {
    Date: "24.10.2020",
    price: 55.238728,
  },
  {
    Date: "25.10.2020",
    price: 59.095585,
  },
  {
    Date: "26.10.2020",
    price: 58.67482,
  },
  {
    Date: "27.10.2020",
    price: 56.74575,
  },
  {
    Date: "28.10.2020",
    price: 57.816078,
  },
  {
    Date: "29.10.2020",
    price: 55.742558,
  },
  {
    Date: "30.10.2020",
    price: 54.765961,
  },
  {
    Date: "31.10.2020",
    price: 53.835037,
  },
  {
    Date: "01.11.2020",
    price: 55.59026,
  },
  {
    Date: "02.11.2020",
    price: 55.587322,
  },
  {
    Date: "03.11.2020",
    price: 53.81741,
  },
  {
    Date: "04.11.2020",
    price: 53.819469,
  },
  {
    Date: "05.11.2020",
    price: 54.501873,
  },
  {
    Date: "06.11.2020",
    price: 58.676193,
  },
  {
    Date: "07.11.2020",
    price: 63.131111,
  },
  {
    Date: "08.11.2020",
    price: 59.143646,
  },
  {
    Date: "09.11.2020",
    price: 61.055016,
  },
  {
    Date: "10.11.2020",
    price: 59.365467,
  },
  {
    Date: "11.11.2020",
    price: 58.237267,
  },
  {
    Date: "12.11.2020",
    price: 59.514549,
  },
  {
    Date: "13.11.2020",
    price: 60.364937,
  },
  {
    Date: "14.11.2020",
    price: 65.457199,
  },
  {
    Date: "15.11.2020",
    price: 63.774155,
  },
  {
    Date: "16.11.2020",
    price: 62.279919,
  },
  {
    Date: "17.11.2020",
    price: 73.501122,
  },
  {
    Date: "18.11.2020",
    price: 75.849861,
  },
  {
    Date: "19.11.2020",
    price: 73.695679,
  },
  {
    Date: "20.11.2020",
    price: 81.683632,
  },
  {
    Date: "21.11.2020",
    price: 82.583153,
  },
  {
    Date: "22.11.2020",
    price: 86.410118,
  },
  {
    Date: "23.11.2020",
    price: 82.704285,
  },
  {
    Date: "24.11.2020",
    price: 89.032753,
  },
  {
    Date: "25.11.2020",
    price: 89.203506,
  },
  {
    Date: "26.11.2020",
    price: 81.875198,
  },
  {
    Date: "27.11.2020",
    price: 70.884369,
  },
  {
    Date: "28.11.2020",
    price: 69.131828,
  },
  {
    Date: "29.11.2020",
    price: 72.699623,
  },
  {
    Date: "30.11.2020",
    price: 79.210464,
  },
  {
    Date: "01.12.2020",
    price: 87.576599,
  },
  {
    Date: "02.12.2020",
    price: 85.437561,
  },
  {
    Date: "03.12.2020",
    price: 88.876465,
  },
  {
    Date: "04.12.2020",
    price: 89.103928,
  },
  {
    Date: "05.12.2020",
    price: 80.007393,
  },
  {
    Date: "06.12.2020",
    price: 83.115913,
  },
  {
    Date: "07.12.2020",
    price: 83.545494,
  },
  {
    Date: "08.12.2020",
    price: 83.61647,
  },
  {
    Date: "09.12.2020",
    price: 76.738403,
  },
  {
    Date: "10.12.2020",
    price: 77.412209,
  },
  {
    Date: "11.12.2020",
    price: 74.825752,
  },
  {
    Date: "12.12.2020",
    price: 72.078712,
  },
  {
    Date: "13.12.2020",
    price: 76.713165,
  },
  {
    Date: "14.12.2020",
    price: 82.029449,
  },
  {
    Date: "15.12.2020",
    price: 82.364372,
  },
  {
    Date: "16.12.2020",
    price: 81.411545,
  },
  {
    Date: "17.12.2020",
    price: 92.799393,
  },
  {
    Date: "18.12.2020",
    price: 100.989052,
  },
  {
    Date: "19.12.2020",
    price: 109.365532,
  },
  {
    Date: "20.12.2020",
    price: 120.492813,
  },
  {
    Date: "21.12.2020",
    price: 114.668312,
  },
  {
    Date: "22.12.2020",
    price: 104.950577,
  },
  {
    Date: "23.12.2020",
    price: 113.67897,
  },
  {
    Date: "24.12.2020",
    price: 101.895035,
  },
  {
    Date: "25.12.2020",
    price: 111.511726,
  },
  {
    Date: "26.12.2020",
    price: 127.079193,
  },
  {
    Date: "27.12.2020",
    price: 129.456619,
  },
  {
    Date: "28.12.2020",
    price: 127.588303,
  },
  {
    Date: "29.12.2020",
    price: 130.033264,
  },
  {
    Date: "30.12.2020",
    price: 129.061859,
  },
  {
    Date: "31.12.2020",
    price: 129.480286,
  },
  {
    Date: "01.01.2021",
    price: 124.672768,
  },
  {
    Date: "02.01.2021",
    price: 126.272964,
  },
  {
    Date: "03.01.2021",
    price: 136.949402,
  },
  {
    Date: "04.01.2021",
    price: 160.271164,
  },
  {
    Date: "05.01.2021",
    price: 154.897552,
  },
  {
    Date: "06.01.2021",
    price: 158.66597,
  },
  {
    Date: "07.01.2021",
    price: 169.068222,
  },
  {
    Date: "08.01.2021",
    price: 169.641525,
  },
  {
    Date: "09.01.2021",
    price: 173.253754,
  },
  {
    Date: "10.01.2021",
    price: 177.477188,
  },
  {
    Date: "11.01.2021",
    price: 171.094833,
  },
  {
    Date: "12.01.2021",
    price: 139.079987,
  },
  {
    Date: "13.01.2021",
    price: 132.658936,
  },
  {
    Date: "14.01.2021",
    price: 147.379745,
  },
  {
    Date: "15.01.2021",
    price: 152.111526,
  },
  {
    Date: "16.01.2021",
    price: 144.188187,
  },
  {
    Date: "17.01.2021",
    price: 144.224838,
  },
  {
    Date: "18.01.2021",
    price: 142.434647,
  },
  {
    Date: "19.01.2021",
    price: 152.197556,
  },
  {
    Date: "20.01.2021",
    price: 153.356094,
  },
  {
    Date: "21.01.2021",
    price: 149.944092,
  },
  {
    Date: "22.01.2021",
    price: 130.073837,
  },
  {
    Date: "23.01.2021",
    price: 137.197693,
  },
  {
    Date: "24.01.2021",
    price: 137.738708,
  },
  {
    Date: "25.01.2021",
    price: 141.438278,
  },
  {
    Date: "26.01.2021",
    price: 137.432922,
  },
  {
    Date: "27.01.2021",
    price: 134.75145,
  },
  {
    Date: "28.01.2021",
    price: 122.941254,
  },
  {
    Date: "29.01.2021",
    price: 138.218521,
  },
  {
    Date: "30.01.2021",
    price: 134.999252,
  },
  {
    Date: "31.01.2021",
    price: 133.41803,
  },
  {
    Date: "01.02.2021",
    price: 129.5746,
  },
  {
    Date: "02.02.2021",
    price: 131.952911,
  },
  {
    Date: "03.02.2021",
    price: 142.47673,
  },
  {
    Date: "04.02.2021",
    price: 155.656479,
  },
  {
    Date: "05.02.2021",
    price: 145.150635,
  },
  {
    Date: "06.02.2021",
    price: 154.85408,
  },
  {
    Date: "07.02.2021",
    price: 155.66954,
  },
  {
    Date: "08.02.2021",
    price: 151.107513,
  },
  {
    Date: "09.02.2021",
    price: 167.213989,
  },
  {
    Date: "10.02.2021",
    price: 181.987457,
  },
  {
    Date: "11.02.2021",
    price: 182.062744,
  },
  {
    Date: "12.02.2021",
    price: 184.387146,
  },
  {
    Date: "13.02.2021",
    price: 197.234329,
  },
  {
    Date: "14.02.2021",
    price: 226.285873,
  },
  {
    Date: "15.02.2021",
    price: 214.324997,
  },
  {
    Date: "16.02.2021",
    price: 207.610443,
  },
  {
    Date: "17.02.2021",
    price: 210.569031,
  },
  {
    Date: "18.02.2021",
    price: 236.949249,
  },
  {
    Date: "19.02.2021",
    price: 226.899292,
  },
  {
    Date: "20.02.2021",
    price: 236.654907,
  },
  {
    Date: "21.02.2021",
    price: 227.519623,
  },
  {
    Date: "22.02.2021",
    price: 227.223816,
  },
  {
    Date: "23.02.2021",
    price: 208.123413,
  },
  {
    Date: "24.02.2021",
    price: 176.966766,
  },
  {
    Date: "25.02.2021",
    price: 181.385422,
  },
  {
    Date: "26.02.2021",
    price: 179.128525,
  },
  {
    Date: "27.02.2021",
    price: 170.431427,
  },
  {
    Date: "28.02.2021",
    price: 172.102188,
  },
  {
    Date: "01.03.2021",
    price: 164.983139,
  },
  {
    Date: "02.03.2021",
    price: 175.209686,
  },
  {
    Date: "03.03.2021",
    price: 177.974365,
  },
  {
    Date: "04.03.2021",
    price: 188.458313,
  },
  {
    Date: "05.03.2021",
    price: 181.039963,
  },
  {
    Date: "06.03.2021",
    price: 180.706329,
  },
  {
    Date: "07.03.2021",
    price: 182.990692,
  },
  {
    Date: "08.03.2021",
    price: 190.771271,
  },
  {
    Date: "09.03.2021",
    price: 191.940048,
  },
  {
    Date: "10.03.2021",
    price: 203.641586,
  },
  {
    Date: "11.03.2021",
    price: 200.449753,
  },
  {
    Date: "12.03.2021",
    price: 201.273666,
  },
  {
    Date: "13.03.2021",
    price: 220.864532,
  },
  {
    Date: "14.03.2021",
    price: 226.505508,
  },
  {
    Date: "15.03.2021",
    price: 215.22728,
  },
  {
    Date: "16.03.2021",
    price: 201.191208,
  },
  {
    Date: "17.03.2021",
    price: 201.064163,
  },
  {
    Date: "18.03.2021",
    price: 206.033142,
  },
  {
    Date: "19.03.2021",
    price: 200.499222,
  },
  {
    Date: "20.03.2021",
    price: 201.033401,
  },
  {
    Date: "21.03.2021",
    price: 200.813843,
  },
  {
    Date: "22.03.2021",
    price: 196.429901,
  },
  {
    Date: "23.03.2021",
    price: 187.069107,
  },
  {
    Date: "24.03.2021",
    price: 187.339081,
  },
  {
    Date: "25.03.2021",
    price: 177.586411,
  },
  {
    Date: "26.03.2021",
    price: 173.022186,
  },
  {
    Date: "27.03.2021",
    price: 184.267288,
  },
  {
    Date: "28.03.2021",
    price: 184.824356,
  },
  {
    Date: "29.03.2021",
    price: 185.01561,
  },
  {
    Date: "30.03.2021",
    price: 194.470825,
  },
  {
    Date: "31.03.2021",
    price: 196.68895,
  },
  {
    Date: "01.04.2021",
    price: 197.524368,
  },
  {
    Date: "02.04.2021",
    price: 204.053619,
  },
  {
    Date: "03.04.2021",
    price: 213.159744,
  },
  {
    Date: "04.04.2021",
    price: 197.109772,
  },
  {
    Date: "05.04.2021",
    price: 204.047775,
  },
  {
    Date: "06.04.2021",
    price: 221.614639,
  },
  {
    Date: "07.04.2021",
    price: 237.561569,
  },
  {
    Date: "08.04.2021",
    price: 219.846619,
  },
  {
    Date: "09.04.2021",
    price: 227.022171,
  },
  {
    Date: "10.04.2021",
    price: 221.716019,
  },
  {
    Date: "11.04.2021",
    price: 256.921326,
  },
  {
    Date: "12.04.2021",
    price: 253.17601,
  },
  {
    Date: "13.04.2021",
    price: 244.712189,
  },
  {
    Date: "14.04.2021",
    price: 267.232483,
  },
  {
    Date: "15.04.2021",
    price: 279.341156,
  },
  {
    Date: "16.04.2021",
    price: 286.28067,
  },
  {
    Date: "17.04.2021",
    price: 309.556641,
  },
  {
    Date: "18.04.2021",
    price: 304.647034,
  },
  {
    Date: "19.04.2021",
    price: 273.645142,
  },
  {
    Date: "20.04.2021",
    price: 261.280304,
  },
  {
    Date: "21.04.2021",
    price: 261.067902,
  },
  {
    Date: "22.04.2021",
    price: 258.059418,
  },
  {
    Date: "23.04.2021",
    price: 253.104797,
  },
  {
    Date: "24.04.2021",
    price: 241.457794,
  },
  {
    Date: "25.04.2021",
    price: 224.921844,
  },
  {
    Date: "26.04.2021",
    price: 223.987137,
  },
  {
    Date: "27.04.2021",
    price: 247.578278,
  },
  {
    Date: "28.04.2021",
    price: 259.790955,
  },
  {
    Date: "29.04.2021",
    price: 258.576996,
  },
  {
    Date: "30.04.2021",
    price: 255.450577,
  },
  {
    Date: "01.05.2021",
    price: 271.14267,
  },
  {
    Date: "02.05.2021",
    price: 276.960419,
  },
  {
    Date: "03.05.2021",
    price: 269.008301,
  },
  {
    Date: "04.05.2021",
    price: 294.774261,
  },
  {
    Date: "05.05.2021",
    price: 305.177399,
  },
  {
    Date: "06.05.2021",
    price: 356.022125,
  },
  {
    Date: "07.05.2021",
    price: 354.506836,
  },
  {
    Date: "08.05.2021",
    price: 345.296875,
  },
  {
    Date: "09.05.2021",
    price: 347.090027,
  },
  {
    Date: "10.05.2021",
    price: 387.869171,
  },
  {
    Date: "11.05.2021",
    price: 359.258087,
  },
  {
    Date: "12.05.2021",
    price: 377.670532,
  },
  {
    Date: "13.05.2021",
    price: 310.102386,
  },
  {
    Date: "14.05.2021",
    price: 317.517212,
  },
  {
    Date: "15.05.2021",
    price: 326.397919,
  },
  {
    Date: "16.05.2021",
    price: 299.817963,
  },
  {
    Date: "17.05.2021",
    price: 296.092438,
  },
  {
    Date: "18.05.2021",
    price: 281.103333,
  },
  {
    Date: "19.05.2021",
    price: 294.605591,
  },
  {
    Date: "20.05.2021",
    price: 186.866272,
  },
  {
    Date: "21.05.2021",
    price: 210.935974,
  },
  {
    Date: "22.05.2021",
    price: 180.538742,
  },
  {
    Date: "23.05.2021",
    price: 169.684509,
  },
  {
    Date: "24.05.2021",
    price: 142.802643,
  },
  {
    Date: "25.05.2021",
    price: 185.037491,
  },
  {
    Date: "26.05.2021",
    price: 183.976318,
  },
  {
    Date: "27.05.2021",
    price: 199.235184,
  },
  {
    Date: "28.05.2021",
    price: 193.99054,
  },
  {
    Date: "29.05.2021",
    price: 177.37822,
  },
  {
    Date: "30.05.2021",
    price: 163.801666,
  },
  {
    Date: "31.05.2021",
    price: 170.668884,
  },
  {
    Date: "01.06.2021",
    price: 188.117554,
  },
  {
    Date: "02.06.2021",
    price: 183.002991,
  },
  {
    Date: "03.06.2021",
    price: 187.41008,
  },
  {
    Date: "04.06.2021",
    price: 193.549042,
  },
  {
    Date: "05.06.2021",
    price: 178.706223,
  },
  {
    Date: "06.06.2021",
    price: 173.146652,
  },
  {
    Date: "07.06.2021",
    price: 176.539307,
  },
  {
    Date: "08.06.2021",
    price: 162.048981,
  },
  {
    Date: "09.06.2021",
    price: 160.891312,
  },
  {
    Date: "10.06.2021",
    price: 172.478577,
  },
  {
    Date: "11.06.2021",
    price: 168.243973,
  },
  {
    Date: "12.06.2021",
    price: 162.977036,
  },
  {
    Date: "13.06.2021",
    price: 161.98259,
  },
  {
    Date: "14.06.2021",
    price: 170.854401,
  },
  {
    Date: "15.06.2021",
    price: 178.633728,
  },
  {
    Date: "16.06.2021",
    price: 175.329391,
  },
  {
    Date: "17.06.2021",
    price: 166.603317,
  },
  {
    Date: "18.06.2021",
    price: 167.234039,
  },
  {
    Date: "19.06.2021",
    price: 156.554199,
  },
  {
    Date: "20.06.2021",
    price: 153.01445,
  },
  {
    Date: "21.06.2021",
    price: 154.971985,
  },
  {
    Date: "22.06.2021",
    price: 124.595276,
  },
  {
    Date: "23.06.2021",
    price: 119.675308,
  },
  {
    Date: "24.06.2021",
    price: 129.060806,
  },
  {
    Date: "25.06.2021",
    price: 134.596222,
  },
  {
    Date: "26.06.2021",
    price: 125.393433,
  },
  {
    Date: "27.06.2021",
    price: 126.893791,
  },
  {
    Date: "28.06.2021",
    price: 132.592697,
  },
  {
    Date: "29.06.2021",
    price: 137.613098,
  },
  {
    Date: "30.06.2021",
    price: 144.050446,
  },
  {
    Date: "01.07.2021",
    price: 144.136642,
  },
  {
    Date: "02.07.2021",
    price: 137.299271,
  },
  {
    Date: "03.07.2021",
    price: 136.930588,
  },
  {
    Date: "04.07.2021",
    price: 140.317993,
  },
  {
    Date: "05.07.2021",
    price: 144.849335,
  },
  {
    Date: "06.07.2021",
    price: 137.951675,
  },
  {
    Date: "07.07.2021",
    price: 138.960159,
  },
  {
    Date: "08.07.2021",
    price: 137.518265,
  },
  {
    Date: "09.07.2021",
    price: 132.17012,
  },
  {
    Date: "10.07.2021",
    price: 134.651337,
  },
  {
    Date: "11.07.2021",
    price: 133.981262,
  },
  {
    Date: "12.07.2021",
    price: 134.291245,
  },
  {
    Date: "13.07.2021",
    price: 133.483734,
  },
  {
    Date: "14.07.2021",
    price: 131.399017,
  },
  {
    Date: "15.07.2021",
    price: 131.132385,
  },
  {
    Date: "16.07.2021",
    price: 125.628967,
  },
  {
    Date: "17.07.2021",
    price: 120.943443,
  },
  {
    Date: "18.07.2021",
    price: 120.080811,
  },
  {
    Date: "19.07.2021",
    price: 119.384407,
  },
  {
    Date: "20.07.2021",
    price: 113.240372,
  },
  {
    Date: "21.07.2021",
    price: 107.334213,
  },
  {
    Date: "22.07.2021",
    price: 117.6325,
  },
  {
    Date: "23.07.2021",
    price: 120.742012,
  },
  {
    Date: "24.07.2021",
    price: 124.218475,
  },
  {
    Date: "25.07.2021",
    price: 126.115936,
  },
  {
    Date: "26.07.2021",
    price: 127.651413,
  },
  {
    Date: "27.07.2021",
    price: 131.232895,
  },
  {
    Date: "28.07.2021",
    price: 134.767212,
  },
  {
    Date: "29.07.2021",
    price: 140.299164,
  },
  {
    Date: "30.07.2021",
    price: 141.47171,
  },
  {
    Date: "31.07.2021",
    price: 145.654297,
  },
  {
    Date: "01.08.2021",
    price: 144.509857,
  },
  {
    Date: "02.08.2021",
    price: 140.542435,
  },
  {
    Date: "03.08.2021",
    price: 141.468506,
  },
  {
    Date: "04.08.2021",
    price: 138.486313,
  },
  {
    Date: "05.08.2021",
    price: 142.627914,
  },
  {
    Date: "06.08.2021",
    price: 143.548004,
  },
  {
    Date: "07.08.2021",
    price: 147.898758,
  },
  {
    Date: "08.08.2021",
    price: 155.958954,
  },
  {
    Date: "09.08.2021",
    price: 149.818512,
  },
  {
    Date: "10.08.2021",
    price: 166.467728,
  },
  {
    Date: "11.08.2021",
    price: 165.408691,
  },
  {
    Date: "12.08.2021",
    price: 170.879288,
  },
  {
    Date: "13.08.2021",
    price: 165.51178,
  },
  {
    Date: "14.08.2021",
    price: 183.382401,
  },
  {
    Date: "15.08.2021",
    price: 182.938919,
  },
  {
    Date: "16.08.2021",
    price: 184.776001,
  },
  {
    Date: "17.08.2021",
    price: 178.152649,
  },
  {
    Date: "18.08.2021",
    price: 168.673431,
  },
  {
    Date: "19.08.2021",
    price: 167.043762,
  },
  {
    Date: "20.08.2021",
    price: 175.432495,
  },
  {
    Date: "21.08.2021",
    price: 183.651459,
  },
  {
    Date: "22.08.2021",
    price: 179.90741,
  },
  {
    Date: "23.08.2021",
    price: 186.132385,
  },
  {
    Date: "24.08.2021",
    price: 187.304749,
  },
  {
    Date: "25.08.2021",
    price: 173.709732,
  },
  {
    Date: "26.08.2021",
    price: 177.890701,
  },
  {
    Date: "27.08.2021",
    price: 167.987854,
  },
  {
    Date: "28.08.2021",
    price: 176.12883,
  },
  {
    Date: "29.08.2021",
    price: 175.527191,
  },
  {
    Date: "30.08.2021",
    price: 174.630814,
  },
  {
    Date: "31.08.2021",
    price: 167.504593,
  },
  {
    Date: "01.09.2021",
    price: 171.465179,
  },
  {
    Date: "02.09.2021",
    price: 180.95639,
  },
  {
    Date: "03.09.2021",
    price: 183.434875,
  },
  {
    Date: "04.09.2021",
    price: 212.964844,
  },
  {
    Date: "05.09.2021",
    price: 211.973526,
  },
  {
    Date: "06.09.2021",
    price: 232.101456,
  },
  {
    Date: "07.09.2021",
    price: 219.524597,
  },
  {
    Date: "08.09.2021",
    price: 178.075943,
  },
  {
    Date: "09.09.2021",
    price: 177.909744,
  },
  {
    Date: "10.09.2021",
    price: 180.448349,
  },
  {
    Date: "11.09.2021",
    price: 174.308334,
  },
  {
    Date: "12.09.2021",
    price: 178.684616,
  },
  {
    Date: "13.09.2021",
    price: 183.242447,
  },
  {
    Date: "14.09.2021",
    price: 179.553421,
  },
  {
    Date: "15.09.2021",
    price: 183.046188,
  },
  {
    Date: "16.09.2021",
    price: 189.029327,
  },
  {
    Date: "17.09.2021",
    price: 185.398529,
  },
  {
    Date: "18.09.2021",
    price: 179.832581,
  },
  {
    Date: "19.09.2021",
    price: 181.248032,
  },
  {
    Date: "20.09.2021",
    price: 175.752609,
  },
  {
    Date: "21.09.2021",
    price: 157.212692,
  },
  {
    Date: "22.09.2021",
    price: 148.241119,
  },
  {
    Date: "23.09.2021",
    price: 161.493057,
  },
  {
    Date: "24.09.2021",
    price: 163.891281,
  },
  {
    Date: "25.09.2021",
    price: 152.227646,
  },
  {
    Date: "26.09.2021",
    price: 151.185623,
  },
  {
    Date: "27.09.2021",
    price: 150.863251,
  },
  {
    Date: "28.09.2021",
    price: 145.220261,
  },
  {
    Date: "29.09.2021",
    price: 140.672012,
  },
  {
    Date: "30.09.2021",
    price: 144.85376,
  },
  {
    Date: "01.10.2021",
    price: 153.293655,
  },
  {
    Date: "02.10.2021",
    price: 166.21225,
  },
  {
    Date: "03.10.2021",
    price: 169.168259,
  },
  {
    Date: "04.10.2021",
    price: 170.602402,
  },
  {
    Date: "05.10.2021",
    price: 167.539154,
  },
  {
    Date: "06.10.2021",
    price: 173.89476,
  },
  {
    Date: "07.10.2021",
    price: 178.683075,
  },
  {
    Date: "08.10.2021",
    price: 178.613983,
  },
  {
    Date: "09.10.2021",
    price: 175.839157,
  },
  {
    Date: "10.10.2021",
    price: 179.779053,
  },
  {
    Date: "11.10.2021",
    price: 175.109528,
  },
  {
    Date: "12.10.2021",
    price: 179.447281,
  },
  {
    Date: "13.10.2021",
    price: 172.65509,
  },
  {
    Date: "14.10.2021",
    price: 177.544495,
  },
  {
    Date: "15.10.2021",
    price: 181.000214,
  },
  {
    Date: "16.10.2021",
    price: 189.498306,
  },
  {
    Date: "17.10.2021",
    price: 186.029968,
  },
  {
    Date: "18.10.2021",
    price: 183.801147,
  },
  {
    Date: "19.10.2021",
    price: 185.623901,
  },
  {
    Date: "20.10.2021",
    price: 188.867249,
  },
  {
    Date: "21.10.2021",
    price: 207.328445,
  },
  {
    Date: "22.10.2021",
    price: 196.945007,
  },
  {
    Date: "23.10.2021",
    price: 190.988831,
  },
  {
    Date: "24.10.2021",
    price: 196.461807,
  },
  {
    Date: "25.10.2021",
    price: 190.570648,
  },
  {
    Date: "26.10.2021",
    price: 195.364334,
  },
  {
    Date: "27.10.2021",
    price: 197.551788,
  },
  {
    Date: "28.10.2021",
    price: 179.328629,
  },
  {
    Date: "29.10.2021",
    price: 189.909271,
  },
  {
    Date: "30.10.2021",
    price: 196.424026,
  },
  {
    Date: "31.10.2021",
    price: 190.443848,
  },
  {
    Date: "01.11.2021",
    price: 192.045029,
  },
  {
    Date: "02.11.2021",
    price: 197.625671,
  },
  {
    Date: "03.11.2021",
    price: 200.718933,
  },
  {
    Date: "04.11.2021",
    price: 207.283096,
  },
  {
    Date: "05.11.2021",
    price: 202.951843,
  },
  {
    Date: "06.11.2021",
    price: 199.298004,
  },
  {
    Date: "07.11.2021",
    price: 197.897263,
  },
  {
    Date: "08.11.2021",
    price: 202.302353,
  },
  {
    Date: "09.11.2021",
    price: 228.876678,
  },
  {
    Date: "10.11.2021",
    price: 262.158417,
  },
  {
    Date: "11.11.2021",
    price: 260.891754,
  },
  {
    Date: "12.11.2021",
    price: 262.605072,
  },
  {
    Date: "13.11.2021",
    price: 251.19339,
  },
  {
    Date: "14.11.2021",
    price: 257.9888,
  },
  {
    Date: "15.11.2021",
    price: 279.240936,
  },
  {
    Date: "16.11.2021",
    price: 263.305054,
  },
  {
    Date: "17.11.2021",
    price: 230.643829,
  },
  {
    Date: "18.11.2021",
    price: 229.753036,
  },
  {
    Date: "19.11.2021",
    price: 204.151947,
  },
  {
    Date: "20.11.2021",
    price: 218.118835,
  },
  {
    Date: "21.11.2021",
    price: 226.940842,
  },
  {
    Date: "22.11.2021",
    price: 221.718384,
  },
  {
    Date: "23.11.2021",
    price: 209.313446,
  },
  {
    Date: "24.11.2021",
    price: 216.362534,
  },
  {
    Date: "25.11.2021",
    price: 212.233459,
  },
  {
    Date: "26.11.2021",
    price: 222.91037,
  },
  {
    Date: "27.11.2021",
    price: 195.557861,
  },
  {
    Date: "28.11.2021",
    price: 195.414597,
  },
  {
    Date: "29.11.2021",
    price: 199.590988,
  },
  {
    Date: "30.11.2021",
    price: 205.733047,
  },
  {
    Date: "01.12.2021",
    price: 207.636612,
  },
  {
    Date: "02.12.2021",
    price: 208.895142,
  },
  {
    Date: "03.12.2021",
    price: 203.568359,
  },
  {
    Date: "04.12.2021",
    price: 188.348557,
  },
  {
    Date: "05.12.2021",
    price: 162.957245,
  },
  {
    Date: "06.12.2021",
    price: 156.511353,
  },
  {
    Date: "07.12.2021",
    price: 161.783752,
  },
  {
    Date: "08.12.2021",
    price: 162.042221,
  },
  {
    Date: "09.12.2021",
    price: 164.959274,
  },
  {
    Date: "10.12.2021",
    price: 151.75444,
  },
  {
    Date: "11.12.2021",
    price: 148.741959,
  },
  {
    Date: "12.12.2021",
    price: 157.925339,
  },
  {
    Date: "13.12.2021",
    price: 159.354431,
  },
  {
    Date: "14.12.2021",
    price: 144.622055,
  },
  {
    Date: "15.12.2021",
    price: 150.611298,
  },
  {
    Date: "16.12.2021",
    price: 153.510849,
  },
  {
    Date: "17.12.2021",
    price: 148.980377,
  },
  {
    Date: "18.12.2021",
    price: 144.264786,
  },
  {
    Date: "19.12.2021",
    price: 148.71048,
  },
  {
    Date: "20.12.2021",
    price: 153.260483,
  },
  {
    Date: "21.12.2021",
    price: 152.505615,
  },
  {
    Date: "22.12.2021",
    price: 155.033981,
  },
  {
    Date: "23.12.2021",
    price: 155.53894,
  },
  {
    Date: "24.12.2021",
    price: 163.552475,
  },
  {
    Date: "25.12.2021",
    price: 161.490829,
  },
  {
    Date: "26.12.2021",
    price: 157.773712,
  },
  {
    Date: "27.12.2021",
    price: 155.884995,
  },
  {
    Date: "28.12.2021",
    price: 155.982071,
  },
  {
    Date: "29.12.2021",
    price: 145.936813,
  },
  {
    Date: "30.12.2021",
    price: 145.585709,
  },
  {
    Date: "31.12.2021",
    price: 148.209534,
  },
  {
    Date: "01.01.2022",
    price: 146.536865,
  },
  {
    Date: "02.01.2022",
    price: 150.685303,
  },
  {
    Date: "03.01.2022",
    price: 151.263397,
  },
  {
    Date: "04.01.2022",
    price: 148.467773,
  },
  {
    Date: "05.01.2022",
    price: 146.811295,
  },
  {
    Date: "06.01.2022",
    price: 136.077316,
  },
  {
    Date: "07.01.2022",
    price: 136.491867,
  },
  {
    Date: "08.01.2022",
    price: 131.494843,
  },
  {
    Date: "09.01.2022",
    price: 130.137344,
  },
  {
    Date: "10.01.2022",
    price: 131.303879,
  },
  {
    Date: "11.01.2022",
    price: 126.82605,
  },
  {
    Date: "12.01.2022",
    price: 131.554688,
  },
  {
    Date: "13.01.2022",
    price: 141.736862,
  },
  {
    Date: "14.01.2022",
    price: 136.646927,
  },
  {
    Date: "15.01.2022",
    price: 144.720245,
  },
  {
    Date: "16.01.2022",
    price: 147.932266,
  },
  {
    Date: "17.01.2022",
    price: 146.534836,
  },
  {
    Date: "18.01.2022",
    price: 151.6539,
  },
  {
    Date: "19.01.2022",
    price: 141.88826,
  },
  {
    Date: "20.01.2022",
    price: 136.896774,
  },
  {
    Date: "21.01.2022",
    price: 130.392288,
  },
  {
    Date: "22.01.2022",
    price: 114.423241,
  },
  {
    Date: "23.01.2022",
    price: 108.462196,
  },
  {
    Date: "24.01.2022",
    price: 112.100815,
  },
  {
    Date: "25.01.2022",
    price: 109.592476,
  },
  {
    Date: "26.01.2022",
    price: 108.062302,
  },
  {
    Date: "27.01.2022",
    price: 107.26136,
  },
  {
    Date: "28.01.2022",
    price: 106.927483,
  },
  {
    Date: "29.01.2022",
    price: 109.431198,
  },
  {
    Date: "30.01.2022",
    price: 110.830025,
  },
  {
    Date: "31.01.2022",
    price: 108.799171,
  },
  {
    Date: "01.02.2022",
    price: 109.589439,
  },
  {
    Date: "02.02.2022",
    price: 115.404503,
  },
  {
    Date: "03.02.2022",
    price: 108.534653,
  },
  {
    Date: "04.02.2022",
    price: 110.2911,
  },
  {
    Date: "05.02.2022",
    price: 120.923294,
  },
  {
    Date: "06.02.2022",
    price: 121.91935,
  },
  {
    Date: "07.02.2022",
    price: 127.444656,
  },
  {
    Date: "08.02.2022",
    price: 137.142395,
  },
  {
    Date: "09.02.2022",
    price: 133.962173,
  },
  {
    Date: "10.02.2022",
    price: 140.173904,
  },
  {
    Date: "11.02.2022",
    price: 135.09285,
  },
  {
    Date: "12.02.2022",
    price: 126.012909,
  },
  {
    Date: "13.02.2022",
    price: 126.670776,
  },
  {
    Date: "14.02.2022",
    price: 126.134789,
  },
  {
    Date: "15.02.2022",
    price: 124.792793,
  },
  {
    Date: "16.02.2022",
    price: 131.760529,
  },
  {
    Date: "17.02.2022",
    price: 128.10466,
  },
  {
    Date: "18.02.2022",
    price: 116.333084,
  },
  {
    Date: "19.02.2022",
    price: 115.193977,
  },
  {
    Date: "20.02.2022",
    price: 115.707825,
  },
  {
    Date: "21.02.2022",
    price: 110.835167,
  },
  {
    Date: "22.02.2022",
    price: 103.430824,
  },
  {
    Date: "23.02.2022",
    price: 107.518753,
  },
  {
    Date: "24.02.2022",
    price: 105.94165,
  },
  {
    Date: "25.02.2022",
    price: 104.234917,
  },
  {
    Date: "26.02.2022",
    price: 109.264053,
  },
  {
    Date: "27.02.2022",
    price: 108.237144,
  },
  {
    Date: "28.02.2022",
    price: 103.100426,
  },
  {
    Date: "01.03.2022",
    price: 113.476082,
  },
  {
    Date: "02.03.2022",
    price: 112.540298,
  },
  {
    Date: "03.03.2022",
    price: 110.356354,
  },
  {
    Date: "04.03.2022",
    price: 111.412834,
  },
  {
    Date: "05.03.2022",
    price: 101.408401,
  },
  {
    Date: "06.03.2022",
    price: 104.980621,
  },
  {
    Date: "07.03.2022",
    price: 101.595879,
  },
  {
    Date: "08.03.2022",
    price: 98.861572,
  },
  {
    Date: "09.03.2022",
    price: 100.621696,
  },
  {
    Date: "10.03.2022",
    price: 106.934196,
  },
  {
    Date: "11.03.2022",
    price: 102.649483,
  },
  {
    Date: "12.03.2022",
    price: 104.96476,
  },
  {
    Date: "13.03.2022",
    price: 105.812271,
  },
  {
    Date: "14.03.2022",
    price: 102.111214,
  },
  {
    Date: "15.03.2022",
    price: 105.665863,
  },
  {
    Date: "16.03.2022",
    price: 106.976494,
  },
  {
    Date: "17.03.2022",
    price: 111.35096,
  },
  {
    Date: "18.03.2022",
    price: 110.303444,
  },
  {
    Date: "19.03.2022",
    price: 112.087914,
  },
  {
    Date: "20.03.2022",
    price: 116.356514,
  },
  {
    Date: "21.03.2022",
    price: 114.834305,
  },
  {
    Date: "22.03.2022",
    price: 116.150185,
  },
  {
    Date: "23.03.2022",
    price: 122.922516,
  },
  {
    Date: "24.03.2022",
    price: 122.468063,
  },
  {
    Date: "25.03.2022",
    price: 126.58252,
  },
  {
    Date: "26.03.2022",
    price: 124.071068,
  },
  {
    Date: "27.03.2022",
    price: 124.889275,
  },
  {
    Date: "28.03.2022",
    price: 129.400635,
  },
  {
    Date: "29.03.2022",
    price: 127.911484,
  },
  {
    Date: "30.03.2022",
    price: 130.054733,
  },
  {
    Date: "31.03.2022",
    price: 131.166367,
  },
  {
    Date: "01.04.2022",
    price: 123.731697,
  },
  {
    Date: "02.04.2022",
    price: 124.882378,
  },
  {
    Date: "03.04.2022",
    price: 124.90181,
  },
  {
    Date: "04.04.2022",
    price: 128.907089,
  },
  {
    Date: "05.04.2022",
    price: 124.859909,
  },
  {
    Date: "06.04.2022",
    price: 123.200035,
  },
  {
    Date: "07.04.2022",
    price: 112.471046,
  },
  {
    Date: "08.04.2022",
    price: 114.238045,
  },
  {
    Date: "09.04.2022",
    price: 110.878258,
  },
  {
    Date: "10.04.2022",
    price: 112.778358,
  },
  {
    Date: "11.04.2022",
    price: 111.153145,
  },
  {
    Date: "12.04.2022",
    price: 102.64373,
  },
  {
    Date: "13.04.2022",
    price: 104.8246,
  },
  {
    Date: "14.04.2022",
    price: 110.4795,
  },
  {
    Date: "15.04.2022",
    price: 107.414223,
  },
  {
    Date: "16.04.2022",
    price: 111.105934,
  },
  {
    Date: "17.04.2022",
    price: 114.404831,
  },
  {
    Date: "18.04.2022",
    price: 109.035522,
  },
  {
    Date: "19.04.2022",
    price: 111.229103,
  },
  {
    Date: "20.04.2022",
    price: 113.815033,
  },
  {
    Date: "21.04.2022",
    price: 111.975327,
  },
  {
    Date: "22.04.2022",
    price: 106.996887,
  },
  {
    Date: "23.04.2022",
    price: 105.602066,
  },
  {
    Date: "24.04.2022",
    price: 105.492462,
  },
  {
    Date: "25.04.2022",
    price: 104.620399,
  },
  {
    Date: "26.04.2022",
    price: 104.671066,
  },
  {
    Date: "27.04.2022",
    price: 98.41317,
  },
  {
    Date: "28.04.2022",
    price: 100.542412,
  },
  {
    Date: "29.04.2022",
    price: 103.318619,
  },
  {
    Date: "30.04.2022",
    price: 100.378754,
  },
  {
    Date: "01.05.2022",
    price: 96.16787,
  },
  {
    Date: "02.05.2022",
    price: 99.433716,
  },
  {
    Date: "03.05.2022",
    price: 100.697548,
  },
  {
    Date: "04.05.2022",
    price: 99.33873,
  },
  {
    Date: "05.05.2022",
    price: 106.270821,
  },
  {
    Date: "06.05.2022",
    price: 96.909279,
  },
  {
    Date: "07.05.2022",
    price: 96.794212,
  },
  {
    Date: "08.05.2022",
    price: 94.571548,
  },
  {
    Date: "09.05.2022",
    price: 93.975418,
  },
  {
    Date: "10.05.2022",
    price: 77.831169,
  },
  {
    Date: "11.05.2022",
    price: 80.211548,
  },
  {
    Date: "12.05.2022",
    price: 66.407776,
  },
  {
    Date: "13.05.2022",
    price: 64.383049,
  },
  {
    Date: "14.05.2022",
    price: 67.94561,
  },
  {
    Date: "15.05.2022",
    price: 68.939713,
  },
  {
    Date: "16.05.2022",
    price: 71.519737,
  },
  {
    Date: "17.05.2022",
    price: 67.212189,
  },
  {
    Date: "18.05.2022",
    price: 72.967667,
  },
  {
    Date: "19.05.2022",
    price: 66.386711,
  },
  {
    Date: "20.05.2022",
    price: 72.077171,
  },
  {
    Date: "21.05.2022",
    price: 68.654564,
  },
  {
    Date: "22.05.2022",
    price: 69.707268,
  },
  {
    Date: "23.05.2022",
    price: 71.763947,
  },
  {
    Date: "24.05.2022",
    price: 69.013824,
  },
  {
    Date: "25.05.2022",
    price: 70.319221,
  },
  {
    Date: "26.05.2022",
    price: 68.315109,
  },
  {
    Date: "27.05.2022",
    price: 63.800579,
  },
  {
    Date: "28.05.2022",
    price: 61.55788,
  },
  {
    Date: "29.05.2022",
    price: 63.063595,
  },
  {
    Date: "30.05.2022",
    price: 63.7458,
  },
  {
    Date: "31.05.2022",
    price: 69.110268,
  },
  {
    Date: "01.06.2022",
    price: 68.417023,
  },
  {
    Date: "02.06.2022",
    price: 62.964443,
  },
  {
    Date: "03.06.2022",
    price: 64.455803,
  },
  {
    Date: "04.06.2022",
    price: 62.658833,
  },
  {
    Date: "05.06.2022",
    price: 63.749756,
  },
  {
    Date: "06.06.2022",
    price: 63.214748,
  },
  {
    Date: "07.06.2022",
    price: 64.418068,
  },
  {
    Date: "08.06.2022",
    price: 63.898411,
  },
  {
    Date: "09.06.2022",
    price: 61.543854,
  },
  {
    Date: "10.06.2022",
    price: 60.213543,
  },
  {
    Date: "11.06.2022",
    price: 56.665623,
  },
  {
    Date: "12.06.2022",
    price: 52.307575,
  },
  {
    Date: "13.06.2022",
    price: 48.213318,
  },
  {
    Date: "14.06.2022",
    price: 43.314003,
  },
  {
    Date: "15.06.2022",
    price: 46.13348,
  },
  {
    Date: "16.06.2022",
    price: 50.515755,
  },
  {
    Date: "17.06.2022",
    price: 44.706158,
  },
  {
    Date: "18.06.2022",
    price: 47.681702,
  },
  {
    Date: "19.06.2022",
    price: 47.237988,
  },
  {
    Date: "20.06.2022",
    price: 54.321991,
  },
  {
    Date: "21.06.2022",
    price: 52.898785,
  },
  {
    Date: "22.06.2022",
    price: 53.520718,
  },
  {
    Date: "23.06.2022",
    price: 52.109196,
  },
  {
    Date: "24.06.2022",
    price: 55.761623,
  },
  {
    Date: "25.06.2022",
    price: 55.990242,
  },
  {
    Date: "26.06.2022",
    price: 58.955406,
  },
  {
    Date: "27.06.2022",
    price: 56.862957,
  },
  {
    Date: "28.06.2022",
    price: 55.913891,
  },
  {
    Date: "29.06.2022",
    price: 52.807201,
  },
  {
    Date: "30.06.2022",
    price: 53.447411,
  },
  {
    Date: "01.07.2022",
    price: 53.699032,
  },
  {
    Date: "02.07.2022",
    price: 51.132595,
  },
  {
    Date: "03.07.2022",
    price: 50.484867,
  },
  {
    Date: "04.07.2022",
    price: 50.971882,
  },
  {
    Date: "05.07.2022",
    price: 52.235771,
  },
];
