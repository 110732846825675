export const BTCpriceHistory = [
  {
    Date: "17.09.2014",
    price: 465.864014,
  },
  {
    Date: "18.09.2014",
    price: 456.859985,
  },
  {
    Date: "19.09.2014",
    price: 424.102997,
  },
  {
    Date: "20.09.2014",
    price: 394.673004,
  },
  {
    Date: "21.09.2014",
    price: 408.084991,
  },
  {
    Date: "22.09.2014",
    price: 399.100006,
  },
  {
    Date: "23.09.2014",
    price: 402.09201,
  },
  {
    Date: "24.09.2014",
    price: 435.751007,
  },
  {
    Date: "25.09.2014",
    price: 423.156006,
  },
  {
    Date: "26.09.2014",
    price: 411.428986,
  },
  {
    Date: "27.09.2014",
    price: 403.556,
  },
  {
    Date: "28.09.2014",
    price: 399.471008,
  },
  {
    Date: "29.09.2014",
    price: 376.928009,
  },
  {
    Date: "30.09.2014",
    price: 376.088013,
  },
  {
    Date: "01.10.2014",
    price: 387.427002,
  },
  {
    Date: "02.10.2014",
    price: 383.988007,
  },
  {
    Date: "03.10.2014",
    price: 375.181,
  },
  {
    Date: "04.10.2014",
    price: 359.891998,
  },
  {
    Date: "05.10.2014",
    price: 328.915985,
  },
  {
    Date: "06.10.2014",
    price: 320.389008,
  },
  {
    Date: "07.10.2014",
    price: 330.584015,
  },
  {
    Date: "08.10.2014",
    price: 336.115997,
  },
  {
    Date: "09.10.2014",
    price: 352.747986,
  },
  {
    Date: "10.10.2014",
    price: 364.687012,
  },
  {
    Date: "11.10.2014",
    price: 361.362,
  },
  {
    Date: "12.10.2014",
    price: 362.605988,
  },
  {
    Date: "13.10.2014",
    price: 377.92099,
  },
  {
    Date: "14.10.2014",
    price: 391.691986,
  },
  {
    Date: "15.10.2014",
    price: 400.954987,
  },
  {
    Date: "16.10.2014",
    price: 394.518005,
  },
  {
    Date: "17.10.2014",
    price: 382.756012,
  },
  {
    Date: "18.10.2014",
    price: 383.976013,
  },
  {
    Date: "19.10.2014",
    price: 391.253998,
  },
  {
    Date: "20.10.2014",
    price: 389.230988,
  },
  {
    Date: "21.10.2014",
    price: 382.42099,
  },
  {
    Date: "22.10.2014",
    price: 386.118011,
  },
  {
    Date: "23.10.2014",
    price: 382.962006,
  },
  {
    Date: "24.10.2014",
    price: 358.591003,
  },
  {
    Date: "25.10.2014",
    price: 358.610992,
  },
  {
    Date: "26.10.2014",
    price: 347.487,
  },
  {
    Date: "27.10.2014",
    price: 354.777008,
  },
  {
    Date: "28.10.2014",
    price: 353.214996,
  },
  {
    Date: "29.10.2014",
    price: 357.088989,
  },
  {
    Date: "30.10.2014",
    price: 335.709015,
  },
  {
    Date: "31.10.2014",
    price: 345.009003,
  },
  {
    Date: "01.11.2014",
    price: 338.649994,
  },
  {
    Date: "02.11.2014",
    price: 326.075012,
  },
  {
    Date: "03.11.2014",
    price: 325.569,
  },
  {
    Date: "04.11.2014",
    price: 327.161011,
  },
  {
    Date: "05.11.2014",
    price: 330.683014,
  },
  {
    Date: "06.11.2014",
    price: 339.458008,
  },
  {
    Date: "07.11.2014",
    price: 349.817993,
  },
  {
    Date: "08.11.2014",
    price: 342.153992,
  },
  {
    Date: "09.11.2014",
    price: 345.376007,
  },
  {
    Date: "10.11.2014",
    price: 362.265015,
  },
  {
    Date: "11.11.2014",
    price: 365.856995,
  },
  {
    Date: "12.11.2014",
    price: 367.984985,
  },
  {
    Date: "13.11.2014",
    price: 427.27301,
  },
  {
    Date: "14.11.2014",
    price: 418.416992,
  },
  {
    Date: "15.11.2014",
    price: 399.649994,
  },
  {
    Date: "16.11.2014",
    price: 374.730011,
  },
  {
    Date: "17.11.2014",
    price: 388.348999,
  },
  {
    Date: "18.11.2014",
    price: 387.785004,
  },
  {
    Date: "19.11.2014",
    price: 373.895996,
  },
  {
    Date: "20.11.2014",
    price: 380.307007,
  },
  {
    Date: "21.11.2014",
    price: 357.878998,
  },
  {
    Date: "22.11.2014",
    price: 351.604004,
  },
  {
    Date: "23.11.2014",
    price: 353.174988,
  },
  {
    Date: "24.11.2014",
    price: 366.947998,
  },
  {
    Date: "25.11.2014",
    price: 376.885986,
  },
  {
    Date: "26.11.2014",
    price: 376.019012,
  },
  {
    Date: "27.11.2014",
    price: 370.502014,
  },
  {
    Date: "28.11.2014",
    price: 369.373993,
  },
  {
    Date: "29.11.2014",
    price: 376.152008,
  },
  {
    Date: "30.11.2014",
    price: 375.51001,
  },
  {
    Date: "01.12.2014",
    price: 378.248993,
  },
  {
    Date: "02.12.2014",
    price: 379.25,
  },
  {
    Date: "03.12.2014",
    price: 381.721985,
  },
  {
    Date: "04.12.2014",
    price: 375.717987,
  },
  {
    Date: "05.12.2014",
    price: 369.441986,
  },
  {
    Date: "06.12.2014",
    price: 376.756989,
  },
  {
    Date: "07.12.2014",
    price: 374.835999,
  },
  {
    Date: "08.12.2014",
    price: 374.964996,
  },
  {
    Date: "09.12.2014",
    price: 361.894989,
  },
  {
    Date: "10.12.2014",
    price: 352.204987,
  },
  {
    Date: "11.12.2014",
    price: 344.339996,
  },
  {
    Date: "12.12.2014",
    price: 350.833008,
  },
  {
    Date: "13.12.2014",
    price: 352.381012,
  },
  {
    Date: "14.12.2014",
    price: 346.72699,
  },
  {
    Date: "15.12.2014",
    price: 351.360992,
  },
  {
    Date: "16.12.2014",
    price: 345.673004,
  },
  {
    Date: "17.12.2014",
    price: 326.855011,
  },
  {
    Date: "18.12.2014",
    price: 319.785004,
  },
  {
    Date: "19.12.2014",
    price: 311.178986,
  },
  {
    Date: "20.12.2014",
    price: 317.618988,
  },
  {
    Date: "21.12.2014",
    price: 329.542999,
  },
  {
    Date: "22.12.2014",
    price: 321.067993,
  },
  {
    Date: "23.12.2014",
    price: 332.016998,
  },
  {
    Date: "24.12.2014",
    price: 334.38501,
  },
  {
    Date: "25.12.2014",
    price: 322.286011,
  },
  {
    Date: "26.12.2014",
    price: 319.152008,
  },
  {
    Date: "27.12.2014",
    price: 327.583008,
  },
  {
    Date: "28.12.2014",
    price: 316.160004,
  },
  {
    Date: "29.12.2014",
    price: 317.700989,
  },
  {
    Date: "30.12.2014",
    price: 312.718994,
  },
  {
    Date: "31.12.2014",
    price: 310.914001,
  },
  {
    Date: "01.01.2015",
    price: 320.434998,
  },
  {
    Date: "02.01.2015",
    price: 314.07901,
  },
  {
    Date: "03.01.2015",
    price: 314.846008,
  },
  {
    Date: "04.01.2015",
    price: 281.145996,
  },
  {
    Date: "05.01.2015",
    price: 265.084015,
  },
  {
    Date: "06.01.2015",
    price: 274.610992,
  },
  {
    Date: "07.01.2015",
    price: 286.076996,
  },
  {
    Date: "08.01.2015",
    price: 294.13501,
  },
  {
    Date: "09.01.2015",
    price: 282.382996,
  },
  {
    Date: "10.01.2015",
    price: 287.303009,
  },
  {
    Date: "11.01.2015",
    price: 274.608002,
  },
  {
    Date: "12.01.2015",
    price: 266.145996,
  },
  {
    Date: "13.01.2015",
    price: 267.394012,
  },
  {
    Date: "14.01.2015",
    price: 223.893997,
  },
  {
    Date: "15.01.2015",
    price: 176.897003,
  },
  {
    Date: "16.01.2015",
    price: 209.070007,
  },
  {
    Date: "17.01.2015",
    price: 207.834,
  },
  {
    Date: "18.01.2015",
    price: 200.050003,
  },
  {
    Date: "19.01.2015",
    price: 211.470993,
  },
  {
    Date: "20.01.2015",
    price: 212.906998,
  },
  {
    Date: "21.01.2015",
    price: 211.378006,
  },
  {
    Date: "22.01.2015",
    price: 227.322006,
  },
  {
    Date: "23.01.2015",
    price: 233.516998,
  },
  {
    Date: "24.01.2015",
    price: 232.699997,
  },
  {
    Date: "25.01.2015",
    price: 247.352005,
  },
  {
    Date: "26.01.2015",
    price: 254.078995,
  },
  {
    Date: "27.01.2015",
    price: 273.166992,
  },
  {
    Date: "28.01.2015",
    price: 263.351013,
  },
  {
    Date: "29.01.2015",
    price: 233.348007,
  },
  {
    Date: "30.01.2015",
    price: 232.772003,
  },
  {
    Date: "31.01.2015",
    price: 226.440994,
  },
  {
    Date: "01.02.2015",
    price: 216.867004,
  },
  {
    Date: "02.02.2015",
    price: 226.490997,
  },
  {
    Date: "03.02.2015",
    price: 237.453995,
  },
  {
    Date: "04.02.2015",
    price: 227.511002,
  },
  {
    Date: "05.02.2015",
    price: 227.664993,
  },
  {
    Date: "06.02.2015",
    price: 216.923004,
  },
  {
    Date: "07.02.2015",
    price: 222.632996,
  },
  {
    Date: "08.02.2015",
    price: 227.692993,
  },
  {
    Date: "09.02.2015",
    price: 223.389008,
  },
  {
    Date: "10.02.2015",
    price: 220.281998,
  },
  {
    Date: "11.02.2015",
    price: 219.731995,
  },
  {
    Date: "12.02.2015",
    price: 219.207993,
  },
  {
    Date: "13.02.2015",
    price: 221.968994,
  },
  {
    Date: "14.02.2015",
    price: 235.528,
  },
  {
    Date: "15.02.2015",
    price: 257.506989,
  },
  {
    Date: "16.02.2015",
    price: 234.824997,
  },
  {
    Date: "17.02.2015",
    price: 233.421997,
  },
  {
    Date: "18.02.2015",
    price: 243.779999,
  },
  {
    Date: "19.02.2015",
    price: 236.410004,
  },
  {
    Date: "20.02.2015",
    price: 240.251007,
  },
  {
    Date: "21.02.2015",
    price: 243.751999,
  },
  {
    Date: "22.02.2015",
    price: 244.544006,
  },
  {
    Date: "23.02.2015",
    price: 235.994995,
  },
  {
    Date: "24.02.2015",
    price: 238.998001,
  },
  {
    Date: "25.02.2015",
    price: 238.889999,
  },
  {
    Date: "26.02.2015",
    price: 237.337006,
  },
  {
    Date: "27.02.2015",
    price: 236.436005,
  },
  {
    Date: "28.02.2015",
    price: 253.520004,
  },
  {
    Date: "01.03.2015",
    price: 254.283005,
  },
  {
    Date: "02.03.2015",
    price: 260.356995,
  },
  {
    Date: "03.03.2015",
    price: 275.04599,
  },
  {
    Date: "04.03.2015",
    price: 281.98999,
  },
  {
    Date: "05.03.2015",
    price: 272.739014,
  },
  {
    Date: "06.03.2015",
    price: 275.600006,
  },
  {
    Date: "07.03.2015",
    price: 272.294006,
  },
  {
    Date: "08.03.2015",
    price: 276.433014,
  },
  {
    Date: "09.03.2015",
    price: 274.812012,
  },
  {
    Date: "10.03.2015",
    price: 289.862,
  },
  {
    Date: "11.03.2015",
    price: 291.524994,
  },
  {
    Date: "12.03.2015",
    price: 296.127014,
  },
  {
    Date: "13.03.2015",
    price: 294.118011,
  },
  {
    Date: "14.03.2015",
    price: 284.441986,
  },
  {
    Date: "15.03.2015",
    price: 281.424988,
  },
  {
    Date: "16.03.2015",
    price: 285.684998,
  },
  {
    Date: "17.03.2015",
    price: 290.595001,
  },
  {
    Date: "18.03.2015",
    price: 285.066986,
  },
  {
    Date: "19.03.2015",
    price: 255.880005,
  },
  {
    Date: "20.03.2015",
    price: 260.955994,
  },
  {
    Date: "21.03.2015",
    price: 261.644012,
  },
  {
    Date: "22.03.2015",
    price: 259.916992,
  },
  {
    Date: "23.03.2015",
    price: 267.894989,
  },
  {
    Date: "24.03.2015",
    price: 266.576996,
  },
  {
    Date: "25.03.2015",
    price: 247.472,
  },
  {
    Date: "26.03.2015",
    price: 246.276001,
  },
  {
    Date: "27.03.2015",
    price: 248.565994,
  },
  {
    Date: "28.03.2015",
    price: 246.975006,
  },
  {
    Date: "29.03.2015",
    price: 252.740005,
  },
  {
    Date: "30.03.2015",
    price: 242.878998,
  },
  {
    Date: "31.03.2015",
    price: 247.453995,
  },
  {
    Date: "01.04.2015",
    price: 244.223007,
  },
  {
    Date: "02.04.2015",
    price: 247.089005,
  },
  {
    Date: "03.04.2015",
    price: 253.074005,
  },
  {
    Date: "04.04.2015",
    price: 254.291,
  },
  {
    Date: "05.04.2015",
    price: 253.761002,
  },
  {
    Date: "06.04.2015",
    price: 260.721008,
  },
  {
    Date: "07.04.2015",
    price: 255.274002,
  },
  {
    Date: "08.04.2015",
    price: 253.063995,
  },
  {
    Date: "09.04.2015",
    price: 244.751007,
  },
  {
    Date: "10.04.2015",
    price: 243.694,
  },
  {
    Date: "11.04.2015",
    price: 236.016006,
  },
  {
    Date: "12.04.2015",
    price: 236.535004,
  },
  {
    Date: "13.04.2015",
    price: 235.949997,
  },
  {
    Date: "14.04.2015",
    price: 224.759003,
  },
  {
    Date: "15.04.2015",
    price: 219.072998,
  },
  {
    Date: "16.04.2015",
    price: 223.917007,
  },
  {
    Date: "17.04.2015",
    price: 228.574997,
  },
  {
    Date: "18.04.2015",
    price: 222.852997,
  },
  {
    Date: "19.04.2015",
    price: 223.455994,
  },
  {
    Date: "20.04.2015",
    price: 222.612,
  },
  {
    Date: "21.04.2015",
    price: 224.619995,
  },
  {
    Date: "22.04.2015",
    price: 235.602005,
  },
  {
    Date: "23.04.2015",
    price: 234.052994,
  },
  {
    Date: "24.04.2015",
    price: 235.970001,
  },
  {
    Date: "25.04.2015",
    price: 231.235001,
  },
  {
    Date: "26.04.2015",
    price: 226.410004,
  },
  {
    Date: "27.04.2015",
    price: 219.429001,
  },
  {
    Date: "28.04.2015",
    price: 228.968994,
  },
  {
    Date: "29.04.2015",
    price: 225.591003,
  },
  {
    Date: "30.04.2015",
    price: 225.692993,
  },
  {
    Date: "01.05.2015",
    price: 235.938995,
  },
  {
    Date: "02.05.2015",
    price: 232.341003,
  },
  {
    Date: "03.05.2015",
    price: 234.880005,
  },
  {
    Date: "04.05.2015",
    price: 240.356003,
  },
  {
    Date: "05.05.2015",
    price: 238.852005,
  },
  {
    Date: "06.05.2015",
    price: 236.248993,
  },
  {
    Date: "07.05.2015",
    price: 229.662003,
  },
  {
    Date: "08.05.2015",
    price: 237.203995,
  },
  {
    Date: "09.05.2015",
    price: 243.768997,
  },
  {
    Date: "10.05.2015",
    price: 241.729004,
  },
  {
    Date: "11.05.2015",
    price: 240.298996,
  },
  {
    Date: "12.05.2015",
    price: 242.145004,
  },
  {
    Date: "13.05.2015",
    price: 241.397995,
  },
  {
    Date: "14.05.2015",
    price: 236.214005,
  },
  {
    Date: "15.05.2015",
    price: 236.955002,
  },
  {
    Date: "16.05.2015",
    price: 237.643997,
  },
  {
    Date: "17.05.2015",
    price: 236.009995,
  },
  {
    Date: "18.05.2015",
    price: 236.886993,
  },
  {
    Date: "19.05.2015",
    price: 233.037003,
  },
  {
    Date: "20.05.2015",
    price: 231.889999,
  },
  {
    Date: "21.05.2015",
    price: 234.016006,
  },
  {
    Date: "22.05.2015",
    price: 235.320999,
  },
  {
    Date: "23.05.2015",
    price: 240.285995,
  },
  {
    Date: "24.05.2015",
    price: 238.975998,
  },
  {
    Date: "25.05.2015",
    price: 240.927002,
  },
  {
    Date: "26.05.2015",
    price: 237.104004,
  },
  {
    Date: "27.05.2015",
    price: 237.065002,
  },
  {
    Date: "28.05.2015",
    price: 237.257004,
  },
  {
    Date: "29.05.2015",
    price: 237.376999,
  },
  {
    Date: "30.05.2015",
    price: 237.091995,
  },
  {
    Date: "31.05.2015",
    price: 233.134995,
  },
  {
    Date: "01.06.2015",
    price: 230.233002,
  },
  {
    Date: "02.06.2015",
    price: 222.893997,
  },
  {
    Date: "03.06.2015",
    price: 225.735992,
  },
  {
    Date: "04.06.2015",
    price: 225.772003,
  },
  {
    Date: "05.06.2015",
    price: 224.154007,
  },
  {
    Date: "06.06.2015",
    price: 225.005005,
  },
  {
    Date: "07.06.2015",
    price: 225.595993,
  },
  {
    Date: "08.06.2015",
    price: 222.878998,
  },
  {
    Date: "09.06.2015",
    price: 228.537994,
  },
  {
    Date: "10.06.2015",
    price: 228.994995,
  },
  {
    Date: "11.06.2015",
    price: 228.854996,
  },
  {
    Date: "12.06.2015",
    price: 229.705002,
  },
  {
    Date: "13.06.2015",
    price: 229.919998,
  },
  {
    Date: "14.06.2015",
    price: 232.442001,
  },
  {
    Date: "15.06.2015",
    price: 233.421997,
  },
  {
    Date: "16.06.2015",
    price: 236.764999,
  },
  {
    Date: "17.06.2015",
    price: 250.822998,
  },
  {
    Date: "18.06.2015",
    price: 249.427994,
  },
  {
    Date: "19.06.2015",
    price: 249.042999,
  },
  {
    Date: "20.06.2015",
    price: 244.529999,
  },
  {
    Date: "21.06.2015",
    price: 245.100006,
  },
  {
    Date: "22.06.2015",
    price: 243.968994,
  },
  {
    Date: "23.06.2015",
    price: 246.927002,
  },
  {
    Date: "24.06.2015",
    price: 244.281998,
  },
  {
    Date: "25.06.2015",
    price: 240.365005,
  },
  {
    Date: "26.06.2015",
    price: 242.604004,
  },
  {
    Date: "27.06.2015",
    price: 243.548996,
  },
  {
    Date: "28.06.2015",
    price: 250.955002,
  },
  {
    Date: "29.06.2015",
    price: 248.720993,
  },
  {
    Date: "30.06.2015",
    price: 257.036011,
  },
  {
    Date: "01.07.2015",
    price: 263.345001,
  },
  {
    Date: "02.07.2015",
    price: 258.552002,
  },
  {
    Date: "03.07.2015",
    price: 255.459,
  },
  {
    Date: "04.07.2015",
    price: 256.490997,
  },
  {
    Date: "05.07.2015",
    price: 260.804993,
  },
  {
    Date: "06.07.2015",
    price: 271.108002,
  },
  {
    Date: "07.07.2015",
    price: 269.963013,
  },
  {
    Date: "08.07.2015",
    price: 265.981995,
  },
  {
    Date: "09.07.2015",
    price: 270.826996,
  },
  {
    Date: "10.07.2015",
    price: 269.156006,
  },
  {
    Date: "11.07.2015",
    price: 284.880005,
  },
  {
    Date: "12.07.2015",
    price: 293.140015,
  },
  {
    Date: "13.07.2015",
    price: 310.826996,
  },
  {
    Date: "14.07.2015",
    price: 292.033997,
  },
  {
    Date: "15.07.2015",
    price: 288.045013,
  },
  {
    Date: "16.07.2015",
    price: 286.041992,
  },
  {
    Date: "17.07.2015",
    price: 278.091003,
  },
  {
    Date: "18.07.2015",
    price: 279.330994,
  },
  {
    Date: "19.07.2015",
    price: 274.766998,
  },
  {
    Date: "20.07.2015",
    price: 273.498993,
  },
  {
    Date: "21.07.2015",
    price: 278.881989,
  },
  {
    Date: "22.07.2015",
    price: 275.657013,
  },
  {
    Date: "23.07.2015",
    price: 277.341003,
  },
  {
    Date: "24.07.2015",
    price: 276.005005,
  },
  {
    Date: "25.07.2015",
    price: 288.164001,
  },
  {
    Date: "26.07.2015",
    price: 288.640015,
  },
  {
    Date: "27.07.2015",
    price: 292.639008,
  },
  {
    Date: "28.07.2015",
    price: 293.632996,
  },
  {
    Date: "29.07.2015",
    price: 294.484009,
  },
  {
    Date: "30.07.2015",
    price: 289.102997,
  },
  {
    Date: "31.07.2015",
    price: 287.696014,
  },
  {
    Date: "01.08.2015",
    price: 284.686005,
  },
  {
    Date: "02.08.2015",
    price: 280.449005,
  },
  {
    Date: "03.08.2015",
    price: 282.806,
  },
  {
    Date: "04.08.2015",
    price: 281.225006,
  },
  {
    Date: "05.08.2015",
    price: 284.846985,
  },
  {
    Date: "06.08.2015",
    price: 281.906006,
  },
  {
    Date: "07.08.2015",
    price: 278.740997,
  },
  {
    Date: "08.08.2015",
    price: 279.742004,
  },
  {
    Date: "09.08.2015",
    price: 261.115997,
  },
  {
    Date: "10.08.2015",
    price: 265.477997,
  },
  {
    Date: "11.08.2015",
    price: 264.34201,
  },
  {
    Date: "12.08.2015",
    price: 270.597992,
  },
  {
    Date: "13.08.2015",
    price: 266.183014,
  },
  {
    Date: "14.08.2015",
    price: 264.131989,
  },
  {
    Date: "15.08.2015",
    price: 265.528992,
  },
  {
    Date: "16.08.2015",
    price: 261.865997,
  },
  {
    Date: "17.08.2015",
    price: 258.48999,
  },
  {
    Date: "18.08.2015",
    price: 257.925995,
  },
  {
    Date: "19.08.2015",
    price: 225.671005,
  },
  {
    Date: "20.08.2015",
    price: 226.899002,
  },
  {
    Date: "21.08.2015",
    price: 235.354996,
  },
  {
    Date: "22.08.2015",
    price: 232.662003,
  },
  {
    Date: "23.08.2015",
    price: 230.376007,
  },
  {
    Date: "24.08.2015",
    price: 228.112,
  },
  {
    Date: "25.08.2015",
    price: 210.067993,
  },
  {
    Date: "26.08.2015",
    price: 222.076004,
  },
  {
    Date: "27.08.2015",
    price: 226.050003,
  },
  {
    Date: "28.08.2015",
    price: 224.701004,
  },
  {
    Date: "29.08.2015",
    price: 231.548996,
  },
  {
    Date: "30.08.2015",
    price: 229.895004,
  },
  {
    Date: "31.08.2015",
    price: 229.113998,
  },
  {
    Date: "01.09.2015",
    price: 230.255997,
  },
  {
    Date: "02.09.2015",
    price: 228.026993,
  },
  {
    Date: "03.09.2015",
    price: 229.324005,
  },
  {
    Date: "04.09.2015",
    price: 227.214996,
  },
  {
    Date: "05.09.2015",
    price: 230.199005,
  },
  {
    Date: "06.09.2015",
    price: 234.869995,
  },
  {
    Date: "07.09.2015",
    price: 239.934006,
  },
  {
    Date: "08.09.2015",
    price: 239.845993,
  },
  {
    Date: "09.09.2015",
    price: 243.414993,
  },
  {
    Date: "10.09.2015",
    price: 238.335999,
  },
  {
    Date: "11.09.2015",
    price: 238.328995,
  },
  {
    Date: "12.09.2015",
    price: 239.854996,
  },
  {
    Date: "13.09.2015",
    price: 235.242004,
  },
  {
    Date: "14.09.2015",
    price: 230.608994,
  },
  {
    Date: "15.09.2015",
    price: 230.492004,
  },
  {
    Date: "16.09.2015",
    price: 230.25,
  },
  {
    Date: "17.09.2015",
    price: 229.076004,
  },
  {
    Date: "18.09.2015",
    price: 233.520996,
  },
  {
    Date: "19.09.2015",
    price: 232.858002,
  },
  {
    Date: "20.09.2015",
    price: 231.399002,
  },
  {
    Date: "21.09.2015",
    price: 231.216995,
  },
  {
    Date: "22.09.2015",
    price: 226.968994,
  },
  {
    Date: "23.09.2015",
    price: 230.936005,
  },
  {
    Date: "24.09.2015",
    price: 230.358002,
  },
  {
    Date: "25.09.2015",
    price: 234.362,
  },
  {
    Date: "26.09.2015",
    price: 235.076004,
  },
  {
    Date: "27.09.2015",
    price: 234.139008,
  },
  {
    Date: "28.09.2015",
    price: 232.835999,
  },
  {
    Date: "29.09.2015",
    price: 239.016006,
  },
  {
    Date: "30.09.2015",
    price: 236.639999,
  },
  {
    Date: "01.10.2015",
    price: 236.003998,
  },
  {
    Date: "02.10.2015",
    price: 237.264008,
  },
  {
    Date: "03.10.2015",
    price: 237.201996,
  },
  {
    Date: "04.10.2015",
    price: 238.531006,
  },
  {
    Date: "05.10.2015",
    price: 238.147003,
  },
  {
    Date: "06.10.2015",
    price: 240.363998,
  },
  {
    Date: "07.10.2015",
    price: 246.169998,
  },
  {
    Date: "08.10.2015",
    price: 243.074997,
  },
  {
    Date: "09.10.2015",
    price: 242.498001,
  },
  {
    Date: "10.10.2015",
    price: 243.740005,
  },
  {
    Date: "11.10.2015",
    price: 244.742004,
  },
  {
    Date: "12.10.2015",
    price: 246.875,
  },
  {
    Date: "13.10.2015",
    price: 245.199997,
  },
  {
    Date: "14.10.2015",
    price: 249.492996,
  },
  {
    Date: "15.10.2015",
    price: 252.106995,
  },
  {
    Date: "16.10.2015",
    price: 254.296005,
  },
  {
    Date: "17.10.2015",
    price: 262.747009,
  },
  {
    Date: "18.10.2015",
    price: 270.907013,
  },
  {
    Date: "19.10.2015",
    price: 261.860992,
  },
  {
    Date: "20.10.2015",
    price: 263.571991,
  },
  {
    Date: "21.10.2015",
    price: 269.306,
  },
  {
    Date: "22.10.2015",
    price: 266.496002,
  },
  {
    Date: "23.10.2015",
    price: 273.648987,
  },
  {
    Date: "24.10.2015",
    price: 276.503998,
  },
  {
    Date: "25.10.2015",
    price: 281.445007,
  },
  {
    Date: "26.10.2015",
    price: 283.627991,
  },
  {
    Date: "27.10.2015",
    price: 285.181,
  },
  {
    Date: "28.10.2015",
    price: 293.703003,
  },
  {
    Date: "29.10.2015",
    price: 304.324005,
  },
  {
    Date: "30.10.2015",
    price: 313.942993,
  },
  {
    Date: "31.10.2015",
    price: 328.511993,
  },
  {
    Date: "01.11.2015",
    price: 315.005005,
  },
  {
    Date: "02.11.2015",
    price: 325.941986,
  },
  {
    Date: "03.11.2015",
    price: 361.872986,
  },
  {
    Date: "04.11.2015",
    price: 403.664001,
  },
  {
    Date: "05.11.2015",
    price: 408.076996,
  },
  {
    Date: "06.11.2015",
    price: 388.046997,
  },
  {
    Date: "07.11.2015",
    price: 374.269012,
  },
  {
    Date: "08.11.2015",
    price: 384.278015,
  },
  {
    Date: "09.11.2015",
    price: 374.324005,
  },
  {
    Date: "10.11.2015",
    price: 379.984009,
  },
  {
    Date: "11.11.2015",
    price: 339.820007,
  },
  {
    Date: "12.11.2015",
    price: 314.07901,
  },
  {
    Date: "13.11.2015",
    price: 338.497986,
  },
  {
    Date: "14.11.2015",
    price: 336.623993,
  },
  {
    Date: "15.11.2015",
    price: 333.050995,
  },
  {
    Date: "16.11.2015",
    price: 319.734985,
  },
  {
    Date: "17.11.2015",
    price: 330.362,
  },
  {
    Date: "18.11.2015",
    price: 334.592987,
  },
  {
    Date: "19.11.2015",
    price: 334.678986,
  },
  {
    Date: "20.11.2015",
    price: 326.411011,
  },
  {
    Date: "21.11.2015",
    price: 322.09201,
  },
  {
    Date: "22.11.2015",
    price: 326.975006,
  },
  {
    Date: "23.11.2015",
    price: 324.350006,
  },
  {
    Date: "24.11.2015",
    price: 323.014008,
  },
  {
    Date: "25.11.2015",
    price: 320.045013,
  },
  {
    Date: "26.11.2015",
    price: 328.303009,
  },
  {
    Date: "27.11.2015",
    price: 351.860992,
  },
  {
    Date: "28.11.2015",
    price: 357.140991,
  },
  {
    Date: "29.11.2015",
    price: 357.471985,
  },
  {
    Date: "30.11.2015",
    price: 371.437012,
  },
  {
    Date: "01.12.2015",
    price: 377.414001,
  },
  {
    Date: "02.12.2015",
    price: 361.845001,
  },
  {
    Date: "03.12.2015",
    price: 359.330994,
  },
  {
    Date: "04.12.2015",
    price: 361.261993,
  },
  {
    Date: "05.12.2015",
    price: 363.721008,
  },
  {
    Date: "06.12.2015",
    price: 389.554993,
  },
  {
    Date: "07.12.2015",
    price: 389.977997,
  },
  {
    Date: "08.12.2015",
    price: 395.753998,
  },
  {
    Date: "09.12.2015",
    price: 414.44101,
  },
  {
    Date: "10.12.2015",
    price: 417.988007,
  },
  {
    Date: "11.12.2015",
    price: 415.281006,
  },
  {
    Date: "12.12.2015",
    price: 452.334991,
  },
  {
    Date: "13.12.2015",
    price: 431.660004,
  },
  {
    Date: "14.12.2015",
    price: 433.272003,
  },
  {
    Date: "15.12.2015",
    price: 443.877991,
  },
  {
    Date: "16.12.2015",
    price: 465.208008,
  },
  {
    Date: "17.12.2015",
    price: 454.777008,
  },
  {
    Date: "18.12.2015",
    price: 455.846985,
  },
  {
    Date: "19.12.2015",
    price: 463.552002,
  },
  {
    Date: "20.12.2015",
    price: 462.234009,
  },
  {
    Date: "21.12.2015",
    price: 442.838013,
  },
  {
    Date: "22.12.2015",
    price: 437.436005,
  },
  {
    Date: "23.12.2015",
    price: 436.720001,
  },
  {
    Date: "24.12.2015",
    price: 443.091003,
  },
  {
    Date: "25.12.2015",
    price: 454.855011,
  },
  {
    Date: "26.12.2015",
    price: 455.756012,
  },
  {
    Date: "27.12.2015",
    price: 416.514008,
  },
  {
    Date: "28.12.2015",
    price: 423.342987,
  },
  {
    Date: "29.12.2015",
    price: 422.097992,
  },
  {
    Date: "30.12.2015",
    price: 433.299988,
  },
  {
    Date: "31.12.2015",
    price: 425.875,
  },
  {
    Date: "01.01.2016",
    price: 430.721008,
  },
  {
    Date: "02.01.2016",
    price: 434.622009,
  },
  {
    Date: "03.01.2016",
    price: 433.578003,
  },
  {
    Date: "04.01.2016",
    price: 430.061005,
  },
  {
    Date: "05.01.2016",
    price: 433.069,
  },
  {
    Date: "06.01.2016",
    price: 431.855988,
  },
  {
    Date: "07.01.2016",
    price: 430.010986,
  },
  {
    Date: "08.01.2016",
    price: 457.537994,
  },
  {
    Date: "09.01.2016",
    price: 453.382996,
  },
  {
    Date: "10.01.2016",
    price: 448.238007,
  },
  {
    Date: "11.01.2016",
    price: 448.697998,
  },
  {
    Date: "12.01.2016",
    price: 448.182007,
  },
  {
    Date: "13.01.2016",
    price: 434.665009,
  },
  {
    Date: "14.01.2016",
    price: 432.287994,
  },
  {
    Date: "15.01.2016",
    price: 430.255005,
  },
  {
    Date: "16.01.2016",
    price: 365.072998,
  },
  {
    Date: "17.01.2016",
    price: 387.152008,
  },
  {
    Date: "18.01.2016",
    price: 381.733002,
  },
  {
    Date: "19.01.2016",
    price: 387.026001,
  },
  {
    Date: "20.01.2016",
    price: 379.73999,
  },
  {
    Date: "21.01.2016",
    price: 419.631989,
  },
  {
    Date: "22.01.2016",
    price: 409.751007,
  },
  {
    Date: "23.01.2016",
    price: 382.43399,
  },
  {
    Date: "24.01.2016",
    price: 388.10199,
  },
  {
    Date: "25.01.2016",
    price: 402.316986,
  },
  {
    Date: "26.01.2016",
    price: 392.002014,
  },
  {
    Date: "27.01.2016",
    price: 392.444,
  },
  {
    Date: "28.01.2016",
    price: 395.145996,
  },
  {
    Date: "29.01.2016",
    price: 380.108002,
  },
  {
    Date: "30.01.2016",
    price: 378.86499,
  },
  {
    Date: "31.01.2016",
    price: 378.292999,
  },
  {
    Date: "01.02.2016",
    price: 369.350006,
  },
  {
    Date: "02.02.2016",
    price: 372.920013,
  },
  {
    Date: "03.02.2016",
    price: 374.645996,
  },
  {
    Date: "04.02.2016",
    price: 370.174011,
  },
  {
    Date: "05.02.2016",
    price: 388.89801,
  },
  {
    Date: "06.02.2016",
    price: 386.588989,
  },
  {
    Date: "07.02.2016",
    price: 376.514008,
  },
  {
    Date: "08.02.2016",
    price: 376.756989,
  },
  {
    Date: "09.02.2016",
    price: 373.423004,
  },
  {
    Date: "10.02.2016",
    price: 376.145996,
  },
  {
    Date: "11.02.2016",
    price: 382.114014,
  },
  {
    Date: "12.02.2016",
    price: 379.686005,
  },
  {
    Date: "13.02.2016",
    price: 384.640991,
  },
  {
    Date: "14.02.2016",
    price: 392.932007,
  },
  {
    Date: "15.02.2016",
    price: 407.567993,
  },
  {
    Date: "16.02.2016",
    price: 401.432007,
  },
  {
    Date: "17.02.2016",
    price: 407.656006,
  },
  {
    Date: "18.02.2016",
    price: 416.571991,
  },
  {
    Date: "19.02.2016",
    price: 422.730011,
  },
  {
    Date: "20.02.2016",
    price: 421.601013,
  },
  {
    Date: "21.02.2016",
    price: 437.77301,
  },
  {
    Date: "22.02.2016",
    price: 438.989014,
  },
  {
    Date: "23.02.2016",
    price: 438.255005,
  },
  {
    Date: "24.02.2016",
    price: 420.955994,
  },
  {
    Date: "25.02.2016",
    price: 425.036987,
  },
  {
    Date: "26.02.2016",
    price: 424.628998,
  },
  {
    Date: "27.02.2016",
    price: 432.838989,
  },
  {
    Date: "28.02.2016",
    price: 432.571014,
  },
  {
    Date: "29.02.2016",
    price: 433.437988,
  },
  {
    Date: "01.03.2016",
    price: 437.916992,
  },
  {
    Date: "02.03.2016",
    price: 435.131012,
  },
  {
    Date: "03.03.2016",
    price: 423.911987,
  },
  {
    Date: "04.03.2016",
    price: 421.835999,
  },
  {
    Date: "05.03.2016",
    price: 410.781006,
  },
  {
    Date: "06.03.2016",
    price: 400.524994,
  },
  {
    Date: "07.03.2016",
    price: 407.756989,
  },
  {
    Date: "08.03.2016",
    price: 414.464996,
  },
  {
    Date: "09.03.2016",
    price: 413.894012,
  },
  {
    Date: "10.03.2016",
    price: 414.743988,
  },
  {
    Date: "11.03.2016",
    price: 417.238007,
  },
  {
    Date: "12.03.2016",
    price: 421.605011,
  },
  {
    Date: "13.03.2016",
    price: 411.64801,
  },
  {
    Date: "14.03.2016",
    price: 414.200989,
  },
  {
    Date: "15.03.2016",
    price: 416.388,
  },
  {
    Date: "16.03.2016",
    price: 416.888,
  },
  {
    Date: "17.03.2016",
    price: 417.889008,
  },
  {
    Date: "18.03.2016",
    price: 420.546997,
  },
  {
    Date: "19.03.2016",
    price: 409.265015,
  },
  {
    Date: "20.03.2016",
    price: 410.401001,
  },
  {
    Date: "21.03.2016",
    price: 413.417999,
  },
  {
    Date: "22.03.2016",
    price: 413.131989,
  },
  {
    Date: "23.03.2016",
    price: 418.161011,
  },
  {
    Date: "24.03.2016",
    price: 418.424011,
  },
  {
    Date: "25.03.2016",
    price: 416.507996,
  },
  {
    Date: "26.03.2016",
    price: 417.36499,
  },
  {
    Date: "27.03.2016",
    price: 418.140015,
  },
  {
    Date: "28.03.2016",
    price: 426.548004,
  },
  {
    Date: "29.03.2016",
    price: 424.303986,
  },
  {
    Date: "30.03.2016",
    price: 416.834015,
  },
  {
    Date: "31.03.2016",
    price: 415.256989,
  },
  {
    Date: "01.04.2016",
    price: 416.76001,
  },
  {
    Date: "02.04.2016",
    price: 418.421997,
  },
  {
    Date: "03.04.2016",
    price: 421.173004,
  },
  {
    Date: "04.04.2016",
    price: 421.299011,
  },
  {
    Date: "05.04.2016",
    price: 421.016998,
  },
  {
    Date: "06.04.2016",
    price: 424.283997,
  },
  {
    Date: "07.04.2016",
    price: 423.619995,
  },
  {
    Date: "08.04.2016",
    price: 422.907013,
  },
  {
    Date: "09.04.2016",
    price: 420.811005,
  },
  {
    Date: "10.04.2016",
    price: 419.59201,
  },
  {
    Date: "11.04.2016",
    price: 421.872009,
  },
  {
    Date: "12.04.2016",
    price: 422.842987,
  },
  {
    Date: "13.04.2016",
    price: 425.631989,
  },
  {
    Date: "14.04.2016",
    price: 423.934998,
  },
  {
    Date: "15.04.2016",
    price: 424.427002,
  },
  {
    Date: "16.04.2016",
    price: 429.575012,
  },
  {
    Date: "17.04.2016",
    price: 430.635986,
  },
  {
    Date: "18.04.2016",
    price: 427.610992,
  },
  {
    Date: "19.04.2016",
    price: 428.703003,
  },
  {
    Date: "20.04.2016",
    price: 435.324005,
  },
  {
    Date: "21.04.2016",
    price: 441.415985,
  },
  {
    Date: "22.04.2016",
    price: 449.687988,
  },
  {
    Date: "23.04.2016",
    price: 445.860992,
  },
  {
    Date: "24.04.2016",
    price: 450.559998,
  },
  {
    Date: "25.04.2016",
    price: 459.121002,
  },
  {
    Date: "26.04.2016",
    price: 461.64801,
  },
  {
    Date: "27.04.2016",
    price: 466.261993,
  },
  {
    Date: "28.04.2016",
    price: 445.037994,
  },
  {
    Date: "29.04.2016",
    price: 449.40799,
  },
  {
    Date: "30.04.2016",
    price: 455.178009,
  },
  {
    Date: "01.05.2016",
    price: 448.484009,
  },
  {
    Date: "02.05.2016",
    price: 451.933014,
  },
  {
    Date: "03.05.2016",
    price: 444.72699,
  },
  {
    Date: "04.05.2016",
    price: 450.183014,
  },
  {
    Date: "05.05.2016",
    price: 446.710999,
  },
  {
    Date: "06.05.2016",
    price: 447.941986,
  },
  {
    Date: "07.05.2016",
    price: 459.639008,
  },
  {
    Date: "08.05.2016",
    price: 458.428986,
  },
  {
    Date: "09.05.2016",
    price: 458.205994,
  },
  {
    Date: "10.05.2016",
    price: 460.518005,
  },
  {
    Date: "11.05.2016",
    price: 450.864014,
  },
  {
    Date: "12.05.2016",
    price: 452.446991,
  },
  {
    Date: "13.05.2016",
    price: 454.850006,
  },
  {
    Date: "14.05.2016",
    price: 455.822998,
  },
  {
    Date: "15.05.2016",
    price: 455.759003,
  },
  {
    Date: "16.05.2016",
    price: 457.585999,
  },
  {
    Date: "17.05.2016",
    price: 454.009003,
  },
  {
    Date: "18.05.2016",
    price: 453.69101,
  },
  {
    Date: "19.05.2016",
    price: 454.523987,
  },
  {
    Date: "20.05.2016",
    price: 437.792999,
  },
  {
    Date: "21.05.2016",
    price: 442.966003,
  },
  {
    Date: "22.05.2016",
    price: 443.217987,
  },
  {
    Date: "23.05.2016",
    price: 439.347992,
  },
  {
    Date: "24.05.2016",
    price: 444.290985,
  },
  {
    Date: "25.05.2016",
    price: 446.062012,
  },
  {
    Date: "26.05.2016",
    price: 449.671997,
  },
  {
    Date: "27.05.2016",
    price: 453.520996,
  },
  {
    Date: "28.05.2016",
    price: 473.028992,
  },
  {
    Date: "29.05.2016",
    price: 527.47699,
  },
  {
    Date: "30.05.2016",
    price: 528.471008,
  },
  {
    Date: "31.05.2016",
    price: 534.190979,
  },
  {
    Date: "01.06.2016",
    price: 531.106995,
  },
  {
    Date: "02.06.2016",
    price: 536.515015,
  },
  {
    Date: "03.06.2016",
    price: 537.682007,
  },
  {
    Date: "04.06.2016",
    price: 569.705017,
  },
  {
    Date: "05.06.2016",
    price: 573.307983,
  },
  {
    Date: "06.06.2016",
    price: 574.60199,
  },
  {
    Date: "07.06.2016",
    price: 585.445007,
  },
  {
    Date: "08.06.2016",
    price: 577.166992,
  },
  {
    Date: "09.06.2016",
    price: 582.203003,
  },
  {
    Date: "10.06.2016",
    price: 575.836975,
  },
  {
    Date: "11.06.2016",
    price: 578.674011,
  },
  {
    Date: "12.06.2016",
    price: 609.684021,
  },
  {
    Date: "13.06.2016",
    price: 671.653992,
  },
  {
    Date: "14.06.2016",
    price: 704.504028,
  },
  {
    Date: "15.06.2016",
    price: 685.684998,
  },
  {
    Date: "16.06.2016",
    price: 696.52301,
  },
  {
    Date: "17.06.2016",
    price: 768.487,
  },
  {
    Date: "18.06.2016",
    price: 748.755981,
  },
  {
    Date: "19.06.2016",
    price: 756.687988,
  },
  {
    Date: "20.06.2016",
    price: 763.927002,
  },
  {
    Date: "21.06.2016",
    price: 735.882996,
  },
  {
    Date: "22.06.2016",
    price: 665.914978,
  },
  {
    Date: "23.06.2016",
    price: 597.442993,
  },
  {
    Date: "24.06.2016",
    price: 625.575012,
  },
  {
    Date: "25.06.2016",
    price: 665.281006,
  },
  {
    Date: "26.06.2016",
    price: 665.93103,
  },
  {
    Date: "27.06.2016",
    price: 629.348999,
  },
  {
    Date: "28.06.2016",
    price: 658.10199,
  },
  {
    Date: "29.06.2016",
    price: 644.122009,
  },
  {
    Date: "30.06.2016",
    price: 640.591003,
  },
  {
    Date: "01.07.2016",
    price: 672.515015,
  },
  {
    Date: "02.07.2016",
    price: 676.734009,
  },
  {
    Date: "03.07.2016",
    price: 704.968018,
  },
  {
    Date: "04.07.2016",
    price: 658.804016,
  },
  {
    Date: "05.07.2016",
    price: 683.208984,
  },
  {
    Date: "06.07.2016",
    price: 670.41803,
  },
  {
    Date: "07.07.2016",
    price: 678.090027,
  },
  {
    Date: "08.07.2016",
    price: 640.687988,
  },
  {
    Date: "09.07.2016",
    price: 666.383972,
  },
  {
    Date: "10.07.2016",
    price: 650.598999,
  },
  {
    Date: "11.07.2016",
    price: 648.484009,
  },
  {
    Date: "12.07.2016",
    price: 648.28302,
  },
  {
    Date: "13.07.2016",
    price: 664.796997,
  },
  {
    Date: "14.07.2016",
    price: 652.922974,
  },
  {
    Date: "15.07.2016",
    price: 659.171021,
  },
  {
    Date: "16.07.2016",
    price: 663.781006,
  },
  {
    Date: "17.07.2016",
    price: 661.992981,
  },
  {
    Date: "18.07.2016",
    price: 679.809021,
  },
  {
    Date: "19.07.2016",
    price: 672.737976,
  },
  {
    Date: "20.07.2016",
    price: 672.80603,
  },
  {
    Date: "21.07.2016",
    price: 665.228027,
  },
  {
    Date: "22.07.2016",
    price: 664.921997,
  },
  {
    Date: "23.07.2016",
    price: 650.726013,
  },
  {
    Date: "24.07.2016",
    price: 655.409973,
  },
  {
    Date: "25.07.2016",
    price: 661.263,
  },
  {
    Date: "26.07.2016",
    price: 654.226013,
  },
  {
    Date: "27.07.2016",
    price: 651.627014,
  },
  {
    Date: "28.07.2016",
    price: 654.492004,
  },
  {
    Date: "29.07.2016",
    price: 655.111023,
  },
  {
    Date: "30.07.2016",
    price: 657.012024,
  },
  {
    Date: "31.07.2016",
    price: 655.099976,
  },
  {
    Date: "01.08.2016",
    price: 624.60199,
  },
  {
    Date: "02.08.2016",
    price: 606.396973,
  },
  {
    Date: "03.08.2016",
    price: 548.656006,
  },
  {
    Date: "04.08.2016",
    price: 566.328979,
  },
  {
    Date: "05.08.2016",
    price: 578.281006,
  },
  {
    Date: "06.08.2016",
    price: 575.030029,
  },
  {
    Date: "07.08.2016",
    price: 587.770996,
  },
  {
    Date: "08.08.2016",
    price: 592.736023,
  },
  {
    Date: "09.08.2016",
    price: 591.038025,
  },
  {
    Date: "10.08.2016",
    price: 587.64801,
  },
  {
    Date: "11.08.2016",
    price: 592.124023,
  },
  {
    Date: "12.08.2016",
    price: 588.797974,
  },
  {
    Date: "13.08.2016",
    price: 587.356995,
  },
  {
    Date: "14.08.2016",
    price: 585.588989,
  },
  {
    Date: "15.08.2016",
    price: 570.494019,
  },
  {
    Date: "16.08.2016",
    price: 567.242981,
  },
  {
    Date: "17.08.2016",
    price: 577.760986,
  },
  {
    Date: "18.08.2016",
    price: 573.70697,
  },
  {
    Date: "19.08.2016",
    price: 574.338989,
  },
  {
    Date: "20.08.2016",
    price: 576.083984,
  },
  {
    Date: "21.08.2016",
    price: 581.939026,
  },
  {
    Date: "22.08.2016",
    price: 581.310974,
  },
  {
    Date: "23.08.2016",
    price: 586.770996,
  },
  {
    Date: "24.08.2016",
    price: 583.411987,
  },
  {
    Date: "25.08.2016",
    price: 580.179993,
  },
  {
    Date: "26.08.2016",
    price: 577.752991,
  },
  {
    Date: "27.08.2016",
    price: 579.452026,
  },
  {
    Date: "28.08.2016",
    price: 569.830017,
  },
  {
    Date: "29.08.2016",
    price: 574.070984,
  },
  {
    Date: "30.08.2016",
    price: 574.114014,
  },
  {
    Date: "31.08.2016",
    price: 577.591003,
  },
  {
    Date: "01.09.2016",
    price: 575.546021,
  },
  {
    Date: "02.09.2016",
    price: 572.409973,
  },
  {
    Date: "03.09.2016",
    price: 575.554993,
  },
  {
    Date: "04.09.2016",
    price: 598.590027,
  },
  {
    Date: "05.09.2016",
    price: 608.98999,
  },
  {
    Date: "06.09.2016",
    price: 606.505981,
  },
  {
    Date: "07.09.2016",
    price: 610.572998,
  },
  {
    Date: "08.09.2016",
    price: 614.63501,
  },
  {
    Date: "09.09.2016",
    price: 626.35199,
  },
  {
    Date: "10.09.2016",
    price: 622.927002,
  },
  {
    Date: "11.09.2016",
    price: 623.424011,
  },
  {
    Date: "12.09.2016",
    price: 607.005005,
  },
  {
    Date: "13.09.2016",
    price: 608.025024,
  },
  {
    Date: "14.09.2016",
    price: 608.841003,
  },
  {
    Date: "15.09.2016",
    price: 610.588013,
  },
  {
    Date: "16.09.2016",
    price: 607.245972,
  },
  {
    Date: "17.09.2016",
    price: 607.218018,
  },
  {
    Date: "18.09.2016",
    price: 606.28302,
  },
  {
    Date: "19.09.2016",
    price: 609.870972,
  },
  {
    Date: "20.09.2016",
    price: 609.254028,
  },
  {
    Date: "21.09.2016",
    price: 603.588013,
  },
  {
    Date: "22.09.2016",
    price: 597.278992,
  },
  {
    Date: "23.09.2016",
    price: 596.198975,
  },
  {
    Date: "24.09.2016",
    price: 602.960999,
  },
  {
    Date: "25.09.2016",
    price: 602.749023,
  },
  {
    Date: "26.09.2016",
    price: 600.807007,
  },
  {
    Date: "27.09.2016",
    price: 608.021973,
  },
  {
    Date: "28.09.2016",
    price: 606.242981,
  },
  {
    Date: "29.09.2016",
    price: 605.018982,
  },
  {
    Date: "30.09.2016",
    price: 605.715027,
  },
  {
    Date: "01.10.2016",
    price: 609.929016,
  },
  {
    Date: "02.10.2016",
    price: 613.947998,
  },
  {
    Date: "03.10.2016",
    price: 610.968018,
  },
  {
    Date: "04.10.2016",
    price: 612.052002,
  },
  {
    Date: "05.10.2016",
    price: 610.218018,
  },
  {
    Date: "06.10.2016",
    price: 612.469971,
  },
  {
    Date: "07.10.2016",
    price: 612.607971,
  },
  {
    Date: "08.10.2016",
    price: 617.341003,
  },
  {
    Date: "09.10.2016",
    price: 619.171997,
  },
  {
    Date: "10.10.2016",
    price: 616.822021,
  },
  {
    Date: "11.10.2016",
    price: 619.237976,
  },
  {
    Date: "12.10.2016",
    price: 640.870972,
  },
  {
    Date: "13.10.2016",
    price: 636.030029,
  },
  {
    Date: "14.10.2016",
    price: 637.007996,
  },
  {
    Date: "15.10.2016",
    price: 640.310974,
  },
  {
    Date: "16.10.2016",
    price: 639.083008,
  },
  {
    Date: "17.10.2016",
    price: 641.817993,
  },
  {
    Date: "18.10.2016",
    price: 639.411011,
  },
  {
    Date: "19.10.2016",
    price: 638.133972,
  },
  {
    Date: "20.10.2016",
    price: 630.663025,
  },
  {
    Date: "21.10.2016",
    price: 630.825012,
  },
  {
    Date: "22.10.2016",
    price: 633.135986,
  },
  {
    Date: "23.10.2016",
    price: 657.620972,
  },
  {
    Date: "24.10.2016",
    price: 657.161011,
  },
  {
    Date: "25.10.2016",
    price: 654.002014,
  },
  {
    Date: "26.10.2016",
    price: 657.677979,
  },
  {
    Date: "27.10.2016",
    price: 678.213989,
  },
  {
    Date: "28.10.2016",
    price: 688,
  },
  {
    Date: "29.10.2016",
    price: 690.289001,
  },
  {
    Date: "30.10.2016",
    price: 714.117981,
  },
  {
    Date: "31.10.2016",
    price: 702.640015,
  },
  {
    Date: "01.11.2016",
    price: 701.336975,
  },
  {
    Date: "02.11.2016",
    price: 730.065979,
  },
  {
    Date: "03.11.2016",
    price: 742.346008,
  },
  {
    Date: "04.11.2016",
    price: 689.124023,
  },
  {
    Date: "05.11.2016",
    price: 703.525024,
  },
  {
    Date: "06.11.2016",
    price: 703.812012,
  },
  {
    Date: "07.11.2016",
    price: 710.736023,
  },
  {
    Date: "08.11.2016",
    price: 703.088989,
  },
  {
    Date: "09.11.2016",
    price: 709.825012,
  },
  {
    Date: "10.11.2016",
    price: 722.843994,
  },
  {
    Date: "11.11.2016",
    price: 715.554993,
  },
  {
    Date: "12.11.2016",
    price: 716.752014,
  },
  {
    Date: "13.11.2016",
    price: 705.195984,
  },
  {
    Date: "14.11.2016",
    price: 701.997009,
  },
  {
    Date: "15.11.2016",
    price: 705.794006,
  },
  {
    Date: "16.11.2016",
    price: 711.166992,
  },
  {
    Date: "17.11.2016",
    price: 744.875977,
  },
  {
    Date: "18.11.2016",
    price: 740.705017,
  },
  {
    Date: "19.11.2016",
    price: 751.833008,
  },
  {
    Date: "20.11.2016",
    price: 751.879028,
  },
  {
    Date: "21.11.2016",
    price: 731.265015,
  },
  {
    Date: "22.11.2016",
    price: 739.643005,
  },
  {
    Date: "23.11.2016",
    price: 751.741028,
  },
  {
    Date: "24.11.2016",
    price: 744.619995,
  },
  {
    Date: "25.11.2016",
    price: 740.442017,
  },
  {
    Date: "26.11.2016",
    price: 741.510986,
  },
  {
    Date: "27.11.2016",
    price: 735.437012,
  },
  {
    Date: "28.11.2016",
    price: 732.484009,
  },
  {
    Date: "29.11.2016",
    price: 736.328979,
  },
  {
    Date: "30.11.2016",
    price: 736.283997,
  },
  {
    Date: "01.12.2016",
    price: 746.046021,
  },
  {
    Date: "02.12.2016",
    price: 757.544983,
  },
  {
    Date: "03.12.2016",
    price: 778.247986,
  },
  {
    Date: "04.12.2016",
    price: 771.638,
  },
  {
    Date: "05.12.2016",
    price: 773.393982,
  },
  {
    Date: "06.12.2016",
    price: 758.719971,
  },
  {
    Date: "07.12.2016",
    price: 764.210999,
  },
  {
    Date: "08.12.2016",
    price: 768.075989,
  },
  {
    Date: "09.12.2016",
    price: 769.94397,
  },
  {
    Date: "10.12.2016",
    price: 773.02301,
  },
  {
    Date: "11.12.2016",
    price: 774.752014,
  },
  {
    Date: "12.12.2016",
    price: 770.039978,
  },
  {
    Date: "13.12.2016",
    price: 780.646973,
  },
  {
    Date: "14.12.2016",
    price: 780.005005,
  },
  {
    Date: "15.12.2016",
    price: 780.070007,
  },
  {
    Date: "16.12.2016",
    price: 778.963013,
  },
  {
    Date: "17.12.2016",
    price: 785.166016,
  },
  {
    Date: "18.12.2016",
    price: 791.007996,
  },
  {
    Date: "19.12.2016",
    price: 790.692017,
  },
  {
    Date: "20.12.2016",
    price: 792.247009,
  },
  {
    Date: "21.12.2016",
    price: 800.643982,
  },
  {
    Date: "22.12.2016",
    price: 834.179993,
  },
  {
    Date: "23.12.2016",
    price: 864.888,
  },
  {
    Date: "24.12.2016",
    price: 922.179993,
  },
  {
    Date: "25.12.2016",
    price: 899.651978,
  },
  {
    Date: "26.12.2016",
    price: 896.905029,
  },
  {
    Date: "27.12.2016",
    price: 908.354004,
  },
  {
    Date: "28.12.2016",
    price: 934.830994,
  },
  {
    Date: "29.12.2016",
    price: 975.125,
  },
  {
    Date: "30.12.2016",
    price: 972.534973,
  },
  {
    Date: "31.12.2016",
    price: 960.627014,
  },
  {
    Date: "01.01.2017",
    price: 963.65802,
  },
  {
    Date: "02.01.2017",
    price: 998.617004,
  },
  {
    Date: "03.01.2017",
    price: 1021.599976,
  },
  {
    Date: "04.01.2017",
    price: 1044.400024,
  },
  {
    Date: "05.01.2017",
    price: 1156.72998,
  },
  {
    Date: "06.01.2017",
    price: 1014.23999,
  },
  {
    Date: "07.01.2017",
    price: 903.487,
  },
  {
    Date: "08.01.2017",
    price: 908.174988,
  },
  {
    Date: "09.01.2017",
    price: 913.244019,
  },
  {
    Date: "10.01.2017",
    price: 902.440002,
  },
  {
    Date: "11.01.2017",
    price: 908.11499,
  },
  {
    Date: "12.01.2017",
    price: 775.177979,
  },
  {
    Date: "13.01.2017",
    price: 803.737,
  },
  {
    Date: "14.01.2017",
    price: 825.142029,
  },
  {
    Date: "15.01.2017",
    price: 818.142029,
  },
  {
    Date: "16.01.2017",
    price: 821.78302,
  },
  {
    Date: "17.01.2017",
    price: 830.945984,
  },
  {
    Date: "18.01.2017",
    price: 909.372986,
  },
  {
    Date: "19.01.2017",
    price: 888.335022,
  },
  {
    Date: "20.01.2017",
    price: 898.171997,
  },
  {
    Date: "21.01.2017",
    price: 895.549011,
  },
  {
    Date: "22.01.2017",
    price: 922.205017,
  },
  {
    Date: "23.01.2017",
    price: 925.499023,
  },
  {
    Date: "24.01.2017",
    price: 910.677002,
  },
  {
    Date: "25.01.2017",
    price: 891.924011,
  },
  {
    Date: "26.01.2017",
    price: 902.39502,
  },
  {
    Date: "27.01.2017",
    price: 918.359009,
  },
  {
    Date: "28.01.2017",
    price: 919.810974,
  },
  {
    Date: "29.01.2017",
    price: 922.067017,
  },
  {
    Date: "30.01.2017",
    price: 920.151001,
  },
  {
    Date: "31.01.2017",
    price: 920.958984,
  },
  {
    Date: "01.02.2017",
    price: 970.940979,
  },
  {
    Date: "02.02.2017",
    price: 990.000977,
  },
  {
    Date: "03.02.2017",
    price: 1011.460022,
  },
  {
    Date: "04.02.2017",
    price: 1031.329956,
  },
  {
    Date: "05.02.2017",
    price: 1043.52002,
  },
  {
    Date: "06.02.2017",
    price: 1028.400024,
  },
  {
    Date: "07.02.2017",
    price: 1040.140015,
  },
  {
    Date: "08.02.2017",
    price: 1062.319946,
  },
  {
    Date: "09.02.2017",
    price: 1064.699951,
  },
  {
    Date: "10.02.2017",
    price: 995.632019,
  },
  {
    Date: "11.02.2017",
    price: 988.89801,
  },
  {
    Date: "12.02.2017",
    price: 1003.52002,
  },
  {
    Date: "13.02.2017",
    price: 998.88501,
  },
  {
    Date: "14.02.2017",
    price: 991.734985,
  },
  {
    Date: "15.02.2017",
    price: 1006.210022,
  },
  {
    Date: "16.02.2017",
    price: 1007.650024,
  },
  {
    Date: "17.02.2017",
    price: 1026.119995,
  },
  {
    Date: "18.02.2017",
    price: 1049.209961,
  },
  {
    Date: "19.02.2017",
    price: 1054.76001,
  },
  {
    Date: "20.02.2017",
    price: 1048.689941,
  },
  {
    Date: "21.02.2017",
    price: 1079.280029,
  },
  {
    Date: "22.02.2017",
    price: 1114.800049,
  },
  {
    Date: "23.02.2017",
    price: 1117.27002,
  },
  {
    Date: "24.02.2017",
    price: 1172.709961,
  },
  {
    Date: "25.02.2017",
    price: 1170.410034,
  },
  {
    Date: "26.02.2017",
    price: 1144.27002,
  },
  {
    Date: "27.02.2017",
    price: 1163.780029,
  },
  {
    Date: "28.02.2017",
    price: 1180.719971,
  },
  {
    Date: "01.03.2017",
    price: 1180.040039,
  },
  {
    Date: "02.03.2017",
    price: 1224.680054,
  },
  {
    Date: "03.03.2017",
    price: 1250.709961,
  },
  {
    Date: "04.03.2017",
    price: 1277.430054,
  },
  {
    Date: "05.03.2017",
    price: 1254.290039,
  },
  {
    Date: "06.03.2017",
    price: 1267.469971,
  },
  {
    Date: "07.03.2017",
    price: 1273.209961,
  },
  {
    Date: "08.03.2017",
    price: 1223.22998,
  },
  {
    Date: "09.03.2017",
    price: 1150.349976,
  },
  {
    Date: "10.03.2017",
    price: 1189.359985,
  },
  {
    Date: "11.03.2017",
    price: 1116.319946,
  },
  {
    Date: "12.03.2017",
    price: 1176.619995,
  },
  {
    Date: "13.03.2017",
    price: 1221.780029,
  },
  {
    Date: "14.03.2017",
    price: 1232.160034,
  },
  {
    Date: "15.03.2017",
    price: 1240.160034,
  },
  {
    Date: "16.03.2017",
    price: 1251.329956,
  },
  {
    Date: "17.03.2017",
    price: 1180.160034,
  },
  {
    Date: "18.03.2017",
    price: 1099.689941,
  },
  {
    Date: "19.03.2017",
    price: 976.72998,
  },
  {
    Date: "20.03.2017",
    price: 1037.23999,
  },
  {
    Date: "21.03.2017",
    price: 1055.359985,
  },
  {
    Date: "22.03.2017",
    price: 1120.650024,
  },
  {
    Date: "23.03.2017",
    price: 1050.050049,
  },
  {
    Date: "24.03.2017",
    price: 1038.449951,
  },
  {
    Date: "25.03.2017",
    price: 936.539978,
  },
  {
    Date: "26.03.2017",
    price: 974.015015,
  },
  {
    Date: "27.03.2017",
    price: 972.054993,
  },
  {
    Date: "28.03.2017",
    price: 1044.579956,
  },
  {
    Date: "29.03.2017",
    price: 1046.079956,
  },
  {
    Date: "30.03.2017",
    price: 1042.209961,
  },
  {
    Date: "31.03.2017",
    price: 1026.640015,
  },
  {
    Date: "01.04.2017",
    price: 1071.709961,
  },
  {
    Date: "02.04.2017",
    price: 1080.609985,
  },
  {
    Date: "03.04.2017",
    price: 1102.949951,
  },
  {
    Date: "04.04.2017",
    price: 1145.52002,
  },
  {
    Date: "05.04.2017",
    price: 1134.140015,
  },
  {
    Date: "06.04.2017",
    price: 1125.810059,
  },
  {
    Date: "07.04.2017",
    price: 1178.939941,
  },
  {
    Date: "08.04.2017",
    price: 1172.650024,
  },
  {
    Date: "09.04.2017",
    price: 1176.569946,
  },
  {
    Date: "10.04.2017",
    price: 1187.300049,
  },
  {
    Date: "11.04.2017",
    price: 1187.459961,
  },
  {
    Date: "12.04.2017",
    price: 1204.810059,
  },
  {
    Date: "13.04.2017",
    price: 1201.02002,
  },
  {
    Date: "14.04.2017",
    price: 1170.329956,
  },
  {
    Date: "15.04.2017",
    price: 1167.300049,
  },
  {
    Date: "16.04.2017",
    price: 1172.609985,
  },
  {
    Date: "17.04.2017",
    price: 1183.25,
  },
  {
    Date: "18.04.2017",
    price: 1193.77002,
  },
  {
    Date: "19.04.2017",
    price: 1212.130005,
  },
  {
    Date: "20.04.2017",
    price: 1211.079956,
  },
  {
    Date: "21.04.2017",
    price: 1229.420044,
  },
  {
    Date: "22.04.2017",
    price: 1222.709961,
  },
  {
    Date: "23.04.2017",
    price: 1231.920044,
  },
  {
    Date: "24.04.2017",
    price: 1209.630005,
  },
  {
    Date: "25.04.2017",
    price: 1250.449951,
  },
  {
    Date: "26.04.2017",
    price: 1265.98999,
  },
  {
    Date: "27.04.2017",
    price: 1281.880005,
  },
  {
    Date: "28.04.2017",
    price: 1317.73999,
  },
  {
    Date: "29.04.2017",
    price: 1317.839966,
  },
  {
    Date: "30.04.2017",
    price: 1321.869995,
  },
  {
    Date: "01.05.2017",
    price: 1348.300049,
  },
  {
    Date: "02.05.2017",
    price: 1421.030029,
  },
  {
    Date: "03.05.2017",
    price: 1453.780029,
  },
  {
    Date: "04.05.2017",
    price: 1490.719971,
  },
  {
    Date: "05.05.2017",
    price: 1540.869995,
  },
  {
    Date: "06.05.2017",
    price: 1556.810059,
  },
  {
    Date: "07.05.2017",
    price: 1579.469971,
  },
  {
    Date: "08.05.2017",
    price: 1596.920044,
  },
  {
    Date: "09.05.2017",
    price: 1723.890015,
  },
  {
    Date: "10.05.2017",
    price: 1756.52002,
  },
  {
    Date: "11.05.2017",
    price: 1780.369995,
  },
  {
    Date: "12.05.2017",
    price: 1845.76001,
  },
  {
    Date: "13.05.2017",
    price: 1723.119995,
  },
  {
    Date: "14.05.2017",
    price: 1800.859985,
  },
  {
    Date: "15.05.2017",
    price: 1808.439941,
  },
  {
    Date: "16.05.2017",
    price: 1741.699951,
  },
  {
    Date: "17.05.2017",
    price: 1726.72998,
  },
  {
    Date: "18.05.2017",
    price: 1818.699951,
  },
  {
    Date: "19.05.2017",
    price: 1897.369995,
  },
  {
    Date: "20.05.2017",
    price: 1984.23999,
  },
  {
    Date: "21.05.2017",
    price: 2067.030029,
  },
  {
    Date: "22.05.2017",
    price: 2043.189941,
  },
  {
    Date: "23.05.2017",
    price: 2191.560059,
  },
  {
    Date: "24.05.2017",
    price: 2321.370117,
  },
  {
    Date: "25.05.2017",
    price: 2446.23999,
  },
  {
    Date: "26.05.2017",
    price: 2320.889893,
  },
  {
    Date: "27.05.2017",
    price: 2196.27002,
  },
  {
    Date: "28.05.2017",
    price: 2054.080078,
  },
  {
    Date: "29.05.2017",
    price: 2159.429932,
  },
  {
    Date: "30.05.2017",
    price: 2255.360107,
  },
  {
    Date: "31.05.2017",
    price: 2187.189941,
  },
  {
    Date: "01.06.2017",
    price: 2288.330078,
  },
  {
    Date: "02.06.2017",
    price: 2404.030029,
  },
  {
    Date: "03.06.2017",
    price: 2493.719971,
  },
  {
    Date: "04.06.2017",
    price: 2547.790039,
  },
  {
    Date: "05.06.2017",
    price: 2512.399902,
  },
  {
    Date: "06.06.2017",
    price: 2690.840088,
  },
  {
    Date: "07.06.2017",
    price: 2869.379883,
  },
  {
    Date: "08.06.2017",
    price: 2720.48999,
  },
  {
    Date: "09.06.2017",
    price: 2807.439941,
  },
  {
    Date: "10.06.2017",
    price: 2828.139893,
  },
  {
    Date: "11.06.2017",
    price: 2942.409912,
  },
  {
    Date: "12.06.2017",
    price: 2953.219971,
  },
  {
    Date: "13.06.2017",
    price: 2680.909912,
  },
  {
    Date: "14.06.2017",
    price: 2716.879883,
  },
  {
    Date: "15.06.2017",
    price: 2499.580078,
  },
  {
    Date: "16.06.2017",
    price: 2469.570068,
  },
  {
    Date: "17.06.2017",
    price: 2514.01001,
  },
  {
    Date: "18.06.2017",
    price: 2655.350098,
  },
  {
    Date: "19.06.2017",
    price: 2549.030029,
  },
  {
    Date: "20.06.2017",
    price: 2591.26001,
  },
  {
    Date: "21.06.2017",
    price: 2709.429932,
  },
  {
    Date: "22.06.2017",
    price: 2691.030029,
  },
  {
    Date: "23.06.2017",
    price: 2707.340088,
  },
  {
    Date: "24.06.2017",
    price: 2738.52002,
  },
  {
    Date: "25.06.2017",
    price: 2607.25,
  },
  {
    Date: "26.06.2017",
    price: 2590.570068,
  },
  {
    Date: "27.06.2017",
    price: 2478.449951,
  },
  {
    Date: "28.06.2017",
    price: 2553.030029,
  },
  {
    Date: "29.06.2017",
    price: 2567.560059,
  },
  {
    Date: "30.06.2017",
    price: 2539.23999,
  },
  {
    Date: "01.07.2017",
    price: 2492.600098,
  },
  {
    Date: "02.07.2017",
    price: 2436.399902,
  },
  {
    Date: "03.07.2017",
    price: 2498.560059,
  },
  {
    Date: "04.07.2017",
    price: 2561,
  },
  {
    Date: "05.07.2017",
    price: 2602.870117,
  },
  {
    Date: "06.07.2017",
    price: 2608.100098,
  },
  {
    Date: "07.07.2017",
    price: 2608.590088,
  },
  {
    Date: "08.07.2017",
    price: 2520.27002,
  },
  {
    Date: "09.07.2017",
    price: 2572.610107,
  },
  {
    Date: "10.07.2017",
    price: 2525.25,
  },
  {
    Date: "11.07.2017",
    price: 2385.889893,
  },
  {
    Date: "12.07.2017",
    price: 2332.77002,
  },
  {
    Date: "13.07.2017",
    price: 2402.699951,
  },
  {
    Date: "14.07.2017",
    price: 2360.590088,
  },
  {
    Date: "15.07.2017",
    price: 2230.120117,
  },
  {
    Date: "16.07.2017",
    price: 1991.97998,
  },
  {
    Date: "17.07.2017",
    price: 1932.619995,
  },
  {
    Date: "18.07.2017",
    price: 2233.52002,
  },
  {
    Date: "19.07.2017",
    price: 2323.080078,
  },
  {
    Date: "20.07.2017",
    price: 2269.889893,
  },
  {
    Date: "21.07.2017",
    price: 2838.409912,
  },
  {
    Date: "22.07.2017",
    price: 2668.629883,
  },
  {
    Date: "23.07.2017",
    price: 2808.100098,
  },
  {
    Date: "24.07.2017",
    price: 2732.699951,
  },
  {
    Date: "25.07.2017",
    price: 2757.5,
  },
  {
    Date: "26.07.2017",
    price: 2577.77002,
  },
  {
    Date: "27.07.2017",
    price: 2538.709961,
  },
  {
    Date: "28.07.2017",
    price: 2679.72998,
  },
  {
    Date: "29.07.2017",
    price: 2807.02002,
  },
  {
    Date: "30.07.2017",
    price: 2724.389893,
  },
  {
    Date: "31.07.2017",
    price: 2763.23999,
  },
  {
    Date: "01.08.2017",
    price: 2871.300049,
  },
  {
    Date: "02.08.2017",
    price: 2727.129883,
  },
  {
    Date: "03.08.2017",
    price: 2709.560059,
  },
  {
    Date: "04.08.2017",
    price: 2806.929932,
  },
  {
    Date: "05.08.2017",
    price: 2897.629883,
  },
  {
    Date: "06.08.2017",
    price: 3257.610107,
  },
  {
    Date: "07.08.2017",
    price: 3212.780029,
  },
  {
    Date: "08.08.2017",
    price: 3370.219971,
  },
  {
    Date: "09.08.2017",
    price: 3420.399902,
  },
  {
    Date: "10.08.2017",
    price: 3341.840088,
  },
  {
    Date: "11.08.2017",
    price: 3373.820068,
  },
  {
    Date: "12.08.2017",
    price: 3650.629883,
  },
  {
    Date: "13.08.2017",
    price: 3880.040039,
  },
  {
    Date: "14.08.2017",
    price: 4066.100098,
  },
  {
    Date: "15.08.2017",
    price: 4326.990234,
  },
  {
    Date: "16.08.2017",
    price: 4200.339844,
  },
  {
    Date: "17.08.2017",
    price: 4384.439941,
  },
  {
    Date: "18.08.2017",
    price: 4324.339844,
  },
  {
    Date: "19.08.2017",
    price: 4137.75,
  },
  {
    Date: "20.08.2017",
    price: 4189.310059,
  },
  {
    Date: "21.08.2017",
    price: 4090.47998,
  },
  {
    Date: "22.08.2017",
    price: 3998.350098,
  },
  {
    Date: "23.08.2017",
    price: 4089.01001,
  },
  {
    Date: "24.08.2017",
    price: 4137.600098,
  },
  {
    Date: "25.08.2017",
    price: 4332.819824,
  },
  {
    Date: "26.08.2017",
    price: 4372.060059,
  },
  {
    Date: "27.08.2017",
    price: 4345.100098,
  },
  {
    Date: "28.08.2017",
    price: 4384.450195,
  },
  {
    Date: "29.08.2017",
    price: 4389.209961,
  },
  {
    Date: "30.08.2017",
    price: 4570.359863,
  },
  {
    Date: "31.08.2017",
    price: 4555.589844,
  },
  {
    Date: "01.09.2017",
    price: 4701.759766,
  },
  {
    Date: "02.09.2017",
    price: 4901.419922,
  },
  {
    Date: "03.09.2017",
    price: 4585.27002,
  },
  {
    Date: "04.09.2017",
    price: 4591.629883,
  },
  {
    Date: "05.09.2017",
    price: 4228.290039,
  },
  {
    Date: "06.09.2017",
    price: 4376.589844,
  },
  {
    Date: "07.09.2017",
    price: 4589.140137,
  },
  {
    Date: "08.09.2017",
    price: 4605.160156,
  },
  {
    Date: "09.09.2017",
    price: 4229.810059,
  },
  {
    Date: "10.09.2017",
    price: 4229.339844,
  },
  {
    Date: "11.09.2017",
    price: 4122.470215,
  },
  {
    Date: "12.09.2017",
    price: 4168.879883,
  },
  {
    Date: "13.09.2017",
    price: 4131.97998,
  },
  {
    Date: "14.09.2017",
    price: 3875.370117,
  },
  {
    Date: "15.09.2017",
    price: 3166.300049,
  },
  {
    Date: "16.09.2017",
    price: 3637.75,
  },
  {
    Date: "17.09.2017",
    price: 3606.280029,
  },
  {
    Date: "18.09.2017",
    price: 3591.090088,
  },
  {
    Date: "19.09.2017",
    price: 4073.790039,
  },
  {
    Date: "20.09.2017",
    price: 3916.360107,
  },
  {
    Date: "21.09.2017",
    price: 3901.469971,
  },
  {
    Date: "22.09.2017",
    price: 3628.02002,
  },
  {
    Date: "23.09.2017",
    price: 3629.919922,
  },
  {
    Date: "24.09.2017",
    price: 3796.149902,
  },
  {
    Date: "25.09.2017",
    price: 3681.580078,
  },
  {
    Date: "26.09.2017",
    price: 3928.409912,
  },
  {
    Date: "27.09.2017",
    price: 3892.939941,
  },
  {
    Date: "28.09.2017",
    price: 4197.129883,
  },
  {
    Date: "29.09.2017",
    price: 4171.620117,
  },
  {
    Date: "30.09.2017",
    price: 4166.109863,
  },
  {
    Date: "01.10.2017",
    price: 4341.049805,
  },
  {
    Date: "02.10.2017",
    price: 4395.810059,
  },
  {
    Date: "03.10.2017",
    price: 4408.459961,
  },
  {
    Date: "04.10.2017",
    price: 4319.370117,
  },
  {
    Date: "05.10.2017",
    price: 4229.879883,
  },
  {
    Date: "06.10.2017",
    price: 4324.459961,
  },
  {
    Date: "07.10.2017",
    price: 4369.350098,
  },
  {
    Date: "08.10.2017",
    price: 4429.669922,
  },
  {
    Date: "09.10.2017",
    price: 4614.52002,
  },
  {
    Date: "10.10.2017",
    price: 4776.209961,
  },
  {
    Date: "11.10.2017",
    price: 4789.25,
  },
  {
    Date: "12.10.2017",
    price: 4829.580078,
  },
  {
    Date: "13.10.2017",
    price: 5464.160156,
  },
  {
    Date: "14.10.2017",
    price: 5643.529785,
  },
  {
    Date: "15.10.2017",
    price: 5835.959961,
  },
  {
    Date: "16.10.2017",
    price: 5687.569824,
  },
  {
    Date: "17.10.2017",
    price: 5741.580078,
  },
  {
    Date: "18.10.2017",
    price: 5603.819824,
  },
  {
    Date: "19.10.2017",
    price: 5583.740234,
  },
  {
    Date: "20.10.2017",
    price: 5708.109863,
  },
  {
    Date: "21.10.2017",
    price: 5996.790039,
  },
  {
    Date: "22.10.2017",
    price: 6036.660156,
  },
  {
    Date: "23.10.2017",
    price: 6006,
  },
  {
    Date: "24.10.2017",
    price: 5935.52002,
  },
  {
    Date: "25.10.2017",
    price: 5524.600098,
  },
  {
    Date: "26.10.2017",
    price: 5747.950195,
  },
  {
    Date: "27.10.2017",
    price: 5899.740234,
  },
  {
    Date: "28.10.2017",
    price: 5787.819824,
  },
  {
    Date: "29.10.2017",
    price: 5754.439941,
  },
  {
    Date: "30.10.2017",
    price: 6114.850098,
  },
  {
    Date: "31.10.2017",
    price: 6132.02002,
  },
  {
    Date: "01.11.2017",
    price: 6440.970215,
  },
  {
    Date: "02.11.2017",
    price: 6777.77002,
  },
  {
    Date: "03.11.2017",
    price: 7087.529785,
  },
  {
    Date: "04.11.2017",
    price: 7164.47998,
  },
  {
    Date: "05.11.2017",
    price: 7404.52002,
  },
  {
    Date: "06.11.2017",
    price: 7403.220215,
  },
  {
    Date: "07.11.2017",
    price: 7023.100098,
  },
  {
    Date: "08.11.2017",
    price: 7141.379883,
  },
  {
    Date: "09.11.2017",
    price: 7446.830078,
  },
  {
    Date: "10.11.2017",
    price: 7173.72998,
  },
  {
    Date: "11.11.2017",
    price: 6618.609863,
  },
  {
    Date: "12.11.2017",
    price: 6295.450195,
  },
  {
    Date: "13.11.2017",
    price: 5938.25,
  },
  {
    Date: "14.11.2017",
    price: 6561.47998,
  },
  {
    Date: "15.11.2017",
    price: 6634.759766,
  },
  {
    Date: "16.11.2017",
    price: 7323.240234,
  },
  {
    Date: "17.11.2017",
    price: 7853.569824,
  },
  {
    Date: "18.11.2017",
    price: 7697.209961,
  },
  {
    Date: "19.11.2017",
    price: 7766.029785,
  },
  {
    Date: "20.11.2017",
    price: 8039.069824,
  },
  {
    Date: "21.11.2017",
    price: 8205.740234,
  },
  {
    Date: "22.11.2017",
    price: 8077.950195,
  },
  {
    Date: "23.11.2017",
    price: 8232.379883,
  },
  {
    Date: "24.11.2017",
    price: 8074.02002,
  },
  {
    Date: "25.11.2017",
    price: 8241.709961,
  },
  {
    Date: "26.11.2017",
    price: 8789.040039,
  },
  {
    Date: "27.11.2017",
    price: 9352.719727,
  },
  {
    Date: "28.11.2017",
    price: 9823.429688,
  },
  {
    Date: "29.11.2017",
    price: 10077.400391,
  },
  {
    Date: "30.11.2017",
    price: 9906.790039,
  },
  {
    Date: "01.12.2017",
    price: 10198.599609,
  },
  {
    Date: "02.12.2017",
    price: 10978.299805,
  },
  {
    Date: "03.12.2017",
    price: 11082.700195,
  },
  {
    Date: "04.12.2017",
    price: 11315.400391,
  },
  {
    Date: "05.12.2017",
    price: 11685.700195,
  },
  {
    Date: "06.12.2017",
    price: 11923.400391,
  },
  {
    Date: "07.12.2017",
    price: 14266.099609,
  },
  {
    Date: "08.12.2017",
    price: 17802.900391,
  },
  {
    Date: "09.12.2017",
    price: 16523.300781,
  },
  {
    Date: "10.12.2017",
    price: 15168.400391,
  },
  {
    Date: "11.12.2017",
    price: 15427.400391,
  },
  {
    Date: "12.12.2017",
    price: 16919.800781,
  },
  {
    Date: "13.12.2017",
    price: 17500,
  },
  {
    Date: "14.12.2017",
    price: 16384.599609,
  },
  {
    Date: "15.12.2017",
    price: 16601.300781,
  },
  {
    Date: "16.12.2017",
    price: 17760.300781,
  },
  {
    Date: "17.12.2017",
    price: 19475.800781,
  },
  {
    Date: "18.12.2017",
    price: 19106.400391,
  },
  {
    Date: "19.12.2017",
    price: 19118.300781,
  },
  {
    Date: "20.12.2017",
    price: 17760.300781,
  },
  {
    Date: "21.12.2017",
    price: 16642.400391,
  },
  {
    Date: "22.12.2017",
    price: 15898,
  },
  {
    Date: "23.12.2017",
    price: 13948.700195,
  },
  {
    Date: "24.12.2017",
    price: 14608.200195,
  },
  {
    Date: "25.12.2017",
    price: 13995.900391,
  },
  {
    Date: "26.12.2017",
    price: 14036.599609,
  },
  {
    Date: "27.12.2017",
    price: 16163.5,
  },
  {
    Date: "28.12.2017",
    price: 15864.099609,
  },
  {
    Date: "29.12.2017",
    price: 14695.799805,
  },
  {
    Date: "30.12.2017",
    price: 14681.900391,
  },
  {
    Date: "31.12.2017",
    price: 12897.700195,
  },
  {
    Date: "01.01.2018",
    price: 14112.200195,
  },
  {
    Date: "02.01.2018",
    price: 13625,
  },
  {
    Date: "03.01.2018",
    price: 14978.200195,
  },
  {
    Date: "04.01.2018",
    price: 15270.700195,
  },
  {
    Date: "05.01.2018",
    price: 15477.200195,
  },
  {
    Date: "06.01.2018",
    price: 17462.099609,
  },
  {
    Date: "07.01.2018",
    price: 17527.300781,
  },
  {
    Date: "08.01.2018",
    price: 16476.199219,
  },
  {
    Date: "09.01.2018",
    price: 15123.700195,
  },
  {
    Date: "10.01.2018",
    price: 14588.5,
  },
  {
    Date: "11.01.2018",
    price: 14968.200195,
  },
  {
    Date: "12.01.2018",
    price: 13453.900391,
  },
  {
    Date: "13.01.2018",
    price: 13952.400391,
  },
  {
    Date: "14.01.2018",
    price: 14370.799805,
  },
  {
    Date: "15.01.2018",
    price: 13767.299805,
  },
  {
    Date: "16.01.2018",
    price: 13836.099609,
  },
  {
    Date: "17.01.2018",
    price: 11431.099609,
  },
  {
    Date: "18.01.2018",
    price: 11198.799805,
  },
  {
    Date: "19.01.2018",
    price: 11429.799805,
  },
  {
    Date: "20.01.2018",
    price: 11656.200195,
  },
  {
    Date: "21.01.2018",
    price: 12889.200195,
  },
  {
    Date: "22.01.2018",
    price: 11633.099609,
  },
  {
    Date: "23.01.2018",
    price: 10944.5,
  },
  {
    Date: "24.01.2018",
    price: 10903.400391,
  },
  {
    Date: "25.01.2018",
    price: 11421.700195,
  },
  {
    Date: "26.01.2018",
    price: 11256,
  },
  {
    Date: "27.01.2018",
    price: 11174.900391,
  },
  {
    Date: "28.01.2018",
    price: 11475.299805,
  },
  {
    Date: "29.01.2018",
    price: 11755.5,
  },
  {
    Date: "30.01.2018",
    price: 11306.799805,
  },
  {
    Date: "31.01.2018",
    price: 10108.200195,
  },
  {
    Date: "01.02.2018",
    price: 10237.299805,
  },
  {
    Date: "02.02.2018",
    price: 9142.280273,
  },
  {
    Date: "03.02.2018",
    price: 8852.120117,
  },
  {
    Date: "04.02.2018",
    price: 9175.700195,
  },
  {
    Date: "05.02.2018",
    price: 8270.540039,
  },
  {
    Date: "06.02.2018",
    price: 7051.75,
  },
  {
    Date: "07.02.2018",
    price: 7755.490234,
  },
  {
    Date: "08.02.2018",
    price: 7637.859863,
  },
  {
    Date: "09.02.2018",
    price: 8271.839844,
  },
  {
    Date: "10.02.2018",
    price: 8720.080078,
  },
  {
    Date: "11.02.2018",
    price: 8616.129883,
  },
  {
    Date: "12.02.2018",
    price: 8141.430176,
  },
  {
    Date: "13.02.2018",
    price: 8926.719727,
  },
  {
    Date: "14.02.2018",
    price: 8599.919922,
  },
  {
    Date: "15.02.2018",
    price: 9488.320313,
  },
  {
    Date: "16.02.2018",
    price: 10135.700195,
  },
  {
    Date: "17.02.2018",
    price: 10207.5,
  },
  {
    Date: "18.02.2018",
    price: 11123.400391,
  },
  {
    Date: "19.02.2018",
    price: 10552.599609,
  },
  {
    Date: "20.02.2018",
    price: 11231.799805,
  },
  {
    Date: "21.02.2018",
    price: 11372.200195,
  },
  {
    Date: "22.02.2018",
    price: 10660.400391,
  },
  {
    Date: "23.02.2018",
    price: 9937.070313,
  },
  {
    Date: "24.02.2018",
    price: 10287.700195,
  },
  {
    Date: "25.02.2018",
    price: 9796.419922,
  },
  {
    Date: "26.02.2018",
    price: 9669.429688,
  },
  {
    Date: "27.02.2018",
    price: 10393.900391,
  },
  {
    Date: "28.02.2018",
    price: 10687.200195,
  },
  {
    Date: "01.03.2018",
    price: 10385,
  },
  {
    Date: "02.03.2018",
    price: 10977.400391,
  },
  {
    Date: "03.03.2018",
    price: 11101.900391,
  },
  {
    Date: "04.03.2018",
    price: 11497.400391,
  },
  {
    Date: "05.03.2018",
    price: 11532.400391,
  },
  {
    Date: "06.03.2018",
    price: 11500.099609,
  },
  {
    Date: "07.03.2018",
    price: 10803.900391,
  },
  {
    Date: "08.03.2018",
    price: 9951.44043,
  },
  {
    Date: "09.03.2018",
    price: 9414.69043,
  },
  {
    Date: "10.03.2018",
    price: 9350.589844,
  },
  {
    Date: "11.03.2018",
    price: 8852.780273,
  },
  {
    Date: "12.03.2018",
    price: 9602.929688,
  },
  {
    Date: "13.03.2018",
    price: 9173.040039,
  },
  {
    Date: "14.03.2018",
    price: 9214.650391,
  },
  {
    Date: "15.03.2018",
    price: 8290.759766,
  },
  {
    Date: "16.03.2018",
    price: 8322.910156,
  },
  {
    Date: "17.03.2018",
    price: 8321.910156,
  },
  {
    Date: "18.03.2018",
    price: 7890.52002,
  },
  {
    Date: "19.03.2018",
    price: 8344.120117,
  },
  {
    Date: "20.03.2018",
    price: 8619.669922,
  },
  {
    Date: "21.03.2018",
    price: 8937.480469,
  },
  {
    Date: "22.03.2018",
    price: 8939.44043,
  },
  {
    Date: "23.03.2018",
    price: 8736.25,
  },
  {
    Date: "24.03.2018",
    price: 8901.950195,
  },
  {
    Date: "25.03.2018",
    price: 8612.80957,
  },
  {
    Date: "26.03.2018",
    price: 8498.469727,
  },
  {
    Date: "27.03.2018",
    price: 8200,
  },
  {
    Date: "28.03.2018",
    price: 7836.830078,
  },
  {
    Date: "29.03.2018",
    price: 7979.069824,
  },
  {
    Date: "30.03.2018",
    price: 7171.450195,
  },
  {
    Date: "31.03.2018",
    price: 6892.47998,
  },
  {
    Date: "01.04.2018",
    price: 7003.060059,
  },
  {
    Date: "02.04.2018",
    price: 6844.859863,
  },
  {
    Date: "03.04.2018",
    price: 7102.259766,
  },
  {
    Date: "04.04.2018",
    price: 7456.410156,
  },
  {
    Date: "05.04.2018",
    price: 6848.649902,
  },
  {
    Date: "06.04.2018",
    price: 6815.959961,
  },
  {
    Date: "07.04.2018",
    price: 6630.509766,
  },
  {
    Date: "08.04.2018",
    price: 6919.97998,
  },
  {
    Date: "09.04.2018",
    price: 7044.319824,
  },
  {
    Date: "10.04.2018",
    price: 6795.439941,
  },
  {
    Date: "11.04.2018",
    price: 6843.470215,
  },
  {
    Date: "12.04.2018",
    price: 6955.379883,
  },
  {
    Date: "13.04.2018",
    price: 7901.089844,
  },
  {
    Date: "14.04.2018",
    price: 7874.669922,
  },
  {
    Date: "15.04.2018",
    price: 7999.330078,
  },
  {
    Date: "16.04.2018",
    price: 8337.570313,
  },
  {
    Date: "17.04.2018",
    price: 8071.660156,
  },
  {
    Date: "18.04.2018",
    price: 7944.430176,
  },
  {
    Date: "19.04.2018",
    price: 8159.27002,
  },
  {
    Date: "20.04.2018",
    price: 8286.879883,
  },
  {
    Date: "21.04.2018",
    price: 8848.790039,
  },
  {
    Date: "22.04.2018",
    price: 8925.05957,
  },
  {
    Date: "23.04.2018",
    price: 8794.389648,
  },
  {
    Date: "24.04.2018",
    price: 8934.339844,
  },
  {
    Date: "25.04.2018",
    price: 9701.030273,
  },
  {
    Date: "26.04.2018",
    price: 8867.320313,
  },
  {
    Date: "27.04.2018",
    price: 9290.629883,
  },
  {
    Date: "28.04.2018",
    price: 8939.269531,
  },
  {
    Date: "29.04.2018",
    price: 9346.410156,
  },
  {
    Date: "30.04.2018",
    price: 9426.110352,
  },
  {
    Date: "01.05.2018",
    price: 9251.469727,
  },
  {
    Date: "02.05.2018",
    price: 9104.599609,
  },
  {
    Date: "03.05.2018",
    price: 9233.969727,
  },
  {
    Date: "04.05.2018",
    price: 9695.5,
  },
  {
    Date: "05.05.2018",
    price: 9700.280273,
  },
  {
    Date: "06.05.2018",
    price: 9845.30957,
  },
  {
    Date: "07.05.2018",
    price: 9645.669922,
  },
  {
    Date: "08.05.2018",
    price: 9380.870117,
  },
  {
    Date: "09.05.2018",
    price: 9223.730469,
  },
  {
    Date: "10.05.2018",
    price: 9325.959961,
  },
  {
    Date: "11.05.2018",
    price: 9052.959961,
  },
  {
    Date: "12.05.2018",
    price: 8441.44043,
  },
  {
    Date: "13.05.2018",
    price: 8515.490234,
  },
  {
    Date: "14.05.2018",
    price: 8713.099609,
  },
  {
    Date: "15.05.2018",
    price: 8705.19043,
  },
  {
    Date: "16.05.2018",
    price: 8504.410156,
  },
  {
    Date: "17.05.2018",
    price: 8370.049805,
  },
  {
    Date: "18.05.2018",
    price: 8091.830078,
  },
  {
    Date: "19.05.2018",
    price: 8255.730469,
  },
  {
    Date: "20.05.2018",
    price: 8246.990234,
  },
  {
    Date: "21.05.2018",
    price: 8522.330078,
  },
  {
    Date: "22.05.2018",
    price: 8419.870117,
  },
  {
    Date: "23.05.2018",
    price: 8037.080078,
  },
  {
    Date: "24.05.2018",
    price: 7561.120117,
  },
  {
    Date: "25.05.2018",
    price: 7592.299805,
  },
  {
    Date: "26.05.2018",
    price: 7486.47998,
  },
  {
    Date: "27.05.2018",
    price: 7362.080078,
  },
  {
    Date: "28.05.2018",
    price: 7371.310059,
  },
  {
    Date: "29.05.2018",
    price: 7129.459961,
  },
  {
    Date: "30.05.2018",
    price: 7469.72998,
  },
  {
    Date: "31.05.2018",
    price: 7406.149902,
  },
  {
    Date: "01.06.2018",
    price: 7500.700195,
  },
  {
    Date: "02.06.2018",
    price: 7536.720215,
  },
  {
    Date: "03.06.2018",
    price: 7632.089844,
  },
  {
    Date: "04.06.2018",
    price: 7722.529785,
  },
  {
    Date: "05.06.2018",
    price: 7500.899902,
  },
  {
    Date: "06.06.2018",
    price: 7625.970215,
  },
  {
    Date: "07.06.2018",
    price: 7650.819824,
  },
  {
    Date: "08.06.2018",
    price: 7685.140137,
  },
  {
    Date: "09.06.2018",
    price: 7632.52002,
  },
  {
    Date: "10.06.2018",
    price: 7499.549805,
  },
  {
    Date: "11.06.2018",
    price: 6799.290039,
  },
  {
    Date: "12.06.2018",
    price: 6905.819824,
  },
  {
    Date: "13.06.2018",
    price: 6596.879883,
  },
  {
    Date: "14.06.2018",
    price: 6342.75,
  },
  {
    Date: "15.06.2018",
    price: 6674.080078,
  },
  {
    Date: "16.06.2018",
    price: 6455.450195,
  },
  {
    Date: "17.06.2018",
    price: 6545.529785,
  },
  {
    Date: "18.06.2018",
    price: 6510.069824,
  },
  {
    Date: "19.06.2018",
    price: 6742.390137,
  },
  {
    Date: "20.06.2018",
    price: 6770.759766,
  },
  {
    Date: "21.06.2018",
    price: 6780.089844,
  },
  {
    Date: "22.06.2018",
    price: 6737.879883,
  },
  {
    Date: "23.06.2018",
    price: 6090.100098,
  },
  {
    Date: "24.06.2018",
    price: 6164.279785,
  },
  {
    Date: "25.06.2018",
    price: 6171.970215,
  },
  {
    Date: "26.06.2018",
    price: 6253.549805,
  },
  {
    Date: "27.06.2018",
    price: 6084.399902,
  },
  {
    Date: "28.06.2018",
    price: 6153.160156,
  },
  {
    Date: "29.06.2018",
    price: 5898.129883,
  },
  {
    Date: "30.06.2018",
    price: 6214.220215,
  },
  {
    Date: "01.07.2018",
    price: 6411.680176,
  },
  {
    Date: "02.07.2018",
    price: 6380.379883,
  },
  {
    Date: "03.07.2018",
    price: 6596.660156,
  },
  {
    Date: "04.07.2018",
    price: 6550.870117,
  },
  {
    Date: "05.07.2018",
    price: 6599.709961,
  },
  {
    Date: "06.07.2018",
    price: 6638.689941,
  },
  {
    Date: "07.07.2018",
    price: 6668.709961,
  },
  {
    Date: "08.07.2018",
    price: 6857.799805,
  },
  {
    Date: "09.07.2018",
    price: 6775.080078,
  },
  {
    Date: "10.07.2018",
    price: 6739.209961,
  },
  {
    Date: "11.07.2018",
    price: 6330.77002,
  },
  {
    Date: "12.07.2018",
    price: 6396.779785,
  },
  {
    Date: "13.07.2018",
    price: 6235.029785,
  },
  {
    Date: "14.07.2018",
    price: 6247.5,
  },
  {
    Date: "15.07.2018",
    price: 6272.700195,
  },
  {
    Date: "16.07.2018",
    price: 6357.009766,
  },
  {
    Date: "17.07.2018",
    price: 6739.649902,
  },
  {
    Date: "18.07.2018",
    price: 7315.319824,
  },
  {
    Date: "19.07.2018",
    price: 7378.200195,
  },
  {
    Date: "20.07.2018",
    price: 7467.399902,
  },
  {
    Date: "21.07.2018",
    price: 7352.720215,
  },
  {
    Date: "22.07.2018",
    price: 7417.799805,
  },
  {
    Date: "23.07.2018",
    price: 7414.709961,
  },
  {
    Date: "24.07.2018",
    price: 7716.509766,
  },
  {
    Date: "25.07.2018",
    price: 8379.660156,
  },
  {
    Date: "26.07.2018",
    price: 8176.850098,
  },
  {
    Date: "27.07.2018",
    price: 7950.399902,
  },
  {
    Date: "28.07.2018",
    price: 8169.060059,
  },
  {
    Date: "29.07.2018",
    price: 8205.820313,
  },
  {
    Date: "30.07.2018",
    price: 8221.580078,
  },
  {
    Date: "31.07.2018",
    price: 8181.200195,
  },
  {
    Date: "01.08.2018",
    price: 7769.040039,
  },
  {
    Date: "02.08.2018",
    price: 7634.189941,
  },
  {
    Date: "03.08.2018",
    price: 7562.140137,
  },
  {
    Date: "04.08.2018",
    price: 7438.669922,
  },
  {
    Date: "05.08.2018",
    price: 7031.080078,
  },
  {
    Date: "06.08.2018",
    price: 7062.939941,
  },
  {
    Date: "07.08.2018",
    price: 6958.319824,
  },
  {
    Date: "08.08.2018",
    price: 6746.850098,
  },
  {
    Date: "09.08.2018",
    price: 6305.560059,
  },
  {
    Date: "10.08.2018",
    price: 6571.419922,
  },
  {
    Date: "11.08.2018",
    price: 6185.790039,
  },
  {
    Date: "12.08.2018",
    price: 6283.649902,
  },
  {
    Date: "13.08.2018",
    price: 6341.359863,
  },
  {
    Date: "14.08.2018",
    price: 6287.660156,
  },
  {
    Date: "15.08.2018",
    price: 6221.419922,
  },
  {
    Date: "16.08.2018",
    price: 6294.22998,
  },
  {
    Date: "17.08.2018",
    price: 6340.910156,
  },
  {
    Date: "18.08.2018",
    price: 6583.430176,
  },
  {
    Date: "19.08.2018",
    price: 6422.569824,
  },
  {
    Date: "20.08.2018",
    price: 6500.509766,
  },
  {
    Date: "21.08.2018",
    price: 6301.069824,
  },
  {
    Date: "22.08.2018",
    price: 6486.25,
  },
  {
    Date: "23.08.2018",
    price: 6371.339844,
  },
  {
    Date: "24.08.2018",
    price: 6551.52002,
  },
  {
    Date: "25.08.2018",
    price: 6719.950195,
  },
  {
    Date: "26.08.2018",
    price: 6754.640137,
  },
  {
    Date: "27.08.2018",
    price: 6710.799805,
  },
  {
    Date: "28.08.2018",
    price: 6891.080078,
  },
  {
    Date: "29.08.2018",
    price: 7091.709961,
  },
  {
    Date: "30.08.2018",
    price: 7043.759766,
  },
  {
    Date: "31.08.2018",
    price: 6973.970215,
  },
  {
    Date: "01.09.2018",
    price: 7044.810059,
  },
  {
    Date: "02.09.2018",
    price: 7189.580078,
  },
  {
    Date: "03.09.2018",
    price: 7279.029785,
  },
  {
    Date: "04.09.2018",
    price: 7263,
  },
  {
    Date: "05.09.2018",
    price: 7361.459961,
  },
  {
    Date: "06.09.2018",
    price: 6755.140137,
  },
  {
    Date: "07.09.2018",
    price: 6528.919922,
  },
  {
    Date: "08.09.2018",
    price: 6460.169922,
  },
  {
    Date: "09.09.2018",
    price: 6223.379883,
  },
  {
    Date: "10.09.2018",
    price: 6301.569824,
  },
  {
    Date: "11.09.2018",
    price: 6331.879883,
  },
  {
    Date: "12.09.2018",
    price: 6317.009766,
  },
  {
    Date: "13.09.2018",
    price: 6354.240234,
  },
  {
    Date: "14.09.2018",
    price: 6515.410156,
  },
  {
    Date: "15.09.2018",
    price: 6509.399902,
  },
  {
    Date: "16.09.2018",
    price: 6536.680176,
  },
  {
    Date: "17.09.2018",
    price: 6514.060059,
  },
  {
    Date: "18.09.2018",
    price: 6280.910156,
  },
  {
    Date: "19.09.2018",
    price: 6371.850098,
  },
  {
    Date: "20.09.2018",
    price: 6398.850098,
  },
  {
    Date: "21.09.2018",
    price: 6513.870117,
  },
  {
    Date: "22.09.2018",
    price: 6735.049805,
  },
  {
    Date: "23.09.2018",
    price: 6715.319824,
  },
  {
    Date: "24.09.2018",
    price: 6704.77002,
  },
  {
    Date: "25.09.2018",
    price: 6603.640137,
  },
  {
    Date: "26.09.2018",
    price: 6452.790039,
  },
  {
    Date: "27.09.2018",
    price: 6495.290039,
  },
  {
    Date: "28.09.2018",
    price: 6678.75,
  },
  {
    Date: "29.09.2018",
    price: 6643.100098,
  },
  {
    Date: "30.09.2018",
    price: 6604.709961,
  },
  {
    Date: "01.10.2018",
    price: 6619.850098,
  },
  {
    Date: "02.10.2018",
    price: 6593.240234,
  },
  {
    Date: "03.10.2018",
    price: 6553.859863,
  },
  {
    Date: "04.10.2018",
    price: 6497.910156,
  },
  {
    Date: "05.10.2018",
    price: 6574.149902,
  },
  {
    Date: "06.10.2018",
    price: 6622.450195,
  },
  {
    Date: "07.10.2018",
    price: 6590.680176,
  },
  {
    Date: "08.10.2018",
    price: 6600.189941,
  },
  {
    Date: "09.10.2018",
    price: 6653.080078,
  },
  {
    Date: "10.10.2018",
    price: 6640.290039,
  },
  {
    Date: "11.10.2018",
    price: 6586.740234,
  },
  {
    Date: "12.10.2018",
    price: 6239.25,
  },
  {
    Date: "13.10.2018",
    price: 6278.080078,
  },
  {
    Date: "14.10.2018",
    price: 6288.490234,
  },
  {
    Date: "15.10.2018",
    price: 6292.640137,
  },
  {
    Date: "16.10.2018",
    price: 6601.410156,
  },
  {
    Date: "17.10.2018",
    price: 6590.52002,
  },
  {
    Date: "18.10.2018",
    price: 6542.330078,
  },
  {
    Date: "19.10.2018",
    price: 6478.069824,
  },
  {
    Date: "20.10.2018",
    price: 6460.919922,
  },
  {
    Date: "21.10.2018",
    price: 6490.089844,
  },
  {
    Date: "22.10.2018",
    price: 6486.049805,
  },
  {
    Date: "23.10.2018",
    price: 6472.359863,
  },
  {
    Date: "24.10.2018",
    price: 6478.890137,
  },
  {
    Date: "25.10.2018",
    price: 6484.649902,
  },
  {
    Date: "26.10.2018",
    price: 6468.439941,
  },
  {
    Date: "27.10.2018",
    price: 6480.839844,
  },
  {
    Date: "28.10.2018",
    price: 6482.660156,
  },
  {
    Date: "29.10.2018",
    price: 6492.350098,
  },
  {
    Date: "30.10.2018",
    price: 6337.040039,
  },
  {
    Date: "31.10.2018",
    price: 6336.990234,
  },
  {
    Date: "01.11.2018",
    price: 6318.140137,
  },
  {
    Date: "02.11.2018",
    price: 6378.919922,
  },
  {
    Date: "03.11.2018",
    price: 6387.240234,
  },
  {
    Date: "04.11.2018",
    price: 6365.470215,
  },
  {
    Date: "05.11.2018",
    price: 6363.620117,
  },
  {
    Date: "06.11.2018",
    price: 6433.379883,
  },
  {
    Date: "07.11.2018",
    price: 6468.5,
  },
  {
    Date: "08.11.2018",
    price: 6522.27002,
  },
  {
    Date: "09.11.2018",
    price: 6442.600098,
  },
  {
    Date: "10.11.2018",
    price: 6386.129883,
  },
  {
    Date: "11.11.2018",
    price: 6413.629883,
  },
  {
    Date: "12.11.2018",
    price: 6411.759766,
  },
  {
    Date: "13.11.2018",
    price: 6373.189941,
  },
  {
    Date: "14.11.2018",
    price: 6351.240234,
  },
  {
    Date: "15.11.2018",
    price: 5736.149902,
  },
  {
    Date: "16.11.2018",
    price: 5645.319824,
  },
  {
    Date: "17.11.2018",
    price: 5578.580078,
  },
  {
    Date: "18.11.2018",
    price: 5559.740234,
  },
  {
    Date: "19.11.2018",
    price: 5620.779785,
  },
  {
    Date: "20.11.2018",
    price: 4863.930176,
  },
  {
    Date: "21.11.2018",
    price: 4465.540039,
  },
  {
    Date: "22.11.2018",
    price: 4611.569824,
  },
  {
    Date: "23.11.2018",
    price: 4360.700195,
  },
  {
    Date: "24.11.2018",
    price: 4347.689941,
  },
  {
    Date: "25.11.2018",
    price: 3880.780029,
  },
  {
    Date: "26.11.2018",
    price: 4015.070068,
  },
  {
    Date: "27.11.2018",
    price: 3765.949951,
  },
  {
    Date: "28.11.2018",
    price: 3822.469971,
  },
  {
    Date: "29.11.2018",
    price: 4269.004395,
  },
  {
    Date: "30.11.2018",
    price: 4289.088867,
  },
  {
    Date: "01.12.2018",
    price: 4024.464355,
  },
  {
    Date: "02.12.2018",
    price: 4200.733398,
  },
  {
    Date: "03.12.2018",
    price: 4147.32373,
  },
  {
    Date: "04.12.2018",
    price: 3886.294922,
  },
  {
    Date: "05.12.2018",
    price: 3958.894775,
  },
  {
    Date: "06.12.2018",
    price: 3754.074463,
  },
  {
    Date: "07.12.2018",
    price: 3512.590332,
  },
  {
    Date: "08.12.2018",
    price: 3421.9104,
  },
  {
    Date: "09.12.2018",
    price: 3473.227539,
  },
  {
    Date: "10.12.2018",
    price: 3612.046387,
  },
  {
    Date: "11.12.2018",
    price: 3497.554688,
  },
  {
    Date: "12.12.2018",
    price: 3421.458252,
  },
  {
    Date: "13.12.2018",
    price: 3487.879395,
  },
  {
    Date: "14.12.2018",
    price: 3311.751953,
  },
  {
    Date: "15.12.2018",
    price: 3243.997559,
  },
  {
    Date: "16.12.2018",
    price: 3236.274658,
  },
  {
    Date: "17.12.2018",
    price: 3253.123047,
  },
  {
    Date: "18.12.2018",
    price: 3544.761475,
  },
  {
    Date: "19.12.2018",
    price: 3706.824951,
  },
  {
    Date: "20.12.2018",
    price: 3742.195068,
  },
  {
    Date: "21.12.2018",
    price: 4133.703613,
  },
  {
    Date: "22.12.2018",
    price: 3898.08374,
  },
  {
    Date: "23.12.2018",
    price: 4020.994629,
  },
  {
    Date: "24.12.2018",
    price: 4000.331787,
  },
  {
    Date: "25.12.2018",
    price: 4081.030518,
  },
  {
    Date: "26.12.2018",
    price: 3819.666748,
  },
  {
    Date: "27.12.2018",
    price: 3854.688477,
  },
  {
    Date: "28.12.2018",
    price: 3653.131836,
  },
  {
    Date: "29.12.2018",
    price: 3932.491699,
  },
  {
    Date: "30.12.2018",
    price: 3822.384766,
  },
  {
    Date: "31.12.2018",
    price: 3866.839111,
  },
  {
    Date: "01.01.2019",
    price: 3746.713379,
  },
  {
    Date: "02.01.2019",
    price: 3849.216309,
  },
  {
    Date: "03.01.2019",
    price: 3931.048584,
  },
  {
    Date: "04.01.2019",
    price: 3832.040039,
  },
  {
    Date: "05.01.2019",
    price: 3851.973877,
  },
  {
    Date: "06.01.2019",
    price: 3836.519043,
  },
  {
    Date: "07.01.2019",
    price: 4078.584961,
  },
  {
    Date: "08.01.2019",
    price: 4028.472168,
  },
  {
    Date: "09.01.2019",
    price: 4031.552002,
  },
  {
    Date: "10.01.2019",
    price: 4034.411377,
  },
  {
    Date: "11.01.2019",
    price: 3674.015381,
  },
  {
    Date: "12.01.2019",
    price: 3686.973145,
  },
  {
    Date: "13.01.2019",
    price: 3658.868164,
  },
  {
    Date: "14.01.2019",
    price: 3557.311035,
  },
  {
    Date: "15.01.2019",
    price: 3704.216309,
  },
  {
    Date: "16.01.2019",
    price: 3631.509766,
  },
  {
    Date: "17.01.2019",
    price: 3651.871094,
  },
  {
    Date: "18.01.2019",
    price: 3677.990479,
  },
  {
    Date: "19.01.2019",
    price: 3652.37793,
  },
  {
    Date: "20.01.2019",
    price: 3725.446045,
  },
  {
    Date: "21.01.2019",
    price: 3600.372803,
  },
  {
    Date: "22.01.2019",
    price: 3575.081299,
  },
  {
    Date: "23.01.2019",
    price: 3605.557129,
  },
  {
    Date: "24.01.2019",
    price: 3584.500244,
  },
  {
    Date: "25.01.2019",
    price: 3607.390381,
  },
  {
    Date: "26.01.2019",
    price: 3599.715332,
  },
  {
    Date: "27.01.2019",
    price: 3604.687256,
  },
  {
    Date: "28.01.2019",
    price: 3584.283203,
  },
  {
    Date: "29.01.2019",
    price: 3468.870117,
  },
  {
    Date: "30.01.2019",
    price: 3443.896973,
  },
  {
    Date: "31.01.2019",
    price: 3485.40918,
  },
  {
    Date: "01.02.2019",
    price: 3460.547119,
  },
  {
    Date: "02.02.2019",
    price: 3484.625977,
  },
  {
    Date: "03.02.2019",
    price: 3516.139648,
  },
  {
    Date: "04.02.2019",
    price: 3467.21167,
  },
  {
    Date: "05.02.2019",
    price: 3454.950928,
  },
  {
    Date: "06.02.2019",
    price: 3469.091797,
  },
  {
    Date: "07.02.2019",
    price: 3414.929443,
  },
  {
    Date: "08.02.2019",
    price: 3401.376465,
  },
  {
    Date: "09.02.2019",
    price: 3671.585938,
  },
  {
    Date: "10.02.2019",
    price: 3673.201416,
  },
  {
    Date: "11.02.2019",
    price: 3695.613037,
  },
  {
    Date: "12.02.2019",
    price: 3642.751953,
  },
  {
    Date: "13.02.2019",
    price: 3653.604004,
  },
  {
    Date: "14.02.2019",
    price: 3631.170166,
  },
  {
    Date: "15.02.2019",
    price: 3617.368408,
  },
  {
    Date: "16.02.2019",
    price: 3615.270264,
  },
  {
    Date: "17.02.2019",
    price: 3633.359375,
  },
  {
    Date: "18.02.2019",
    price: 3671.369873,
  },
  {
    Date: "19.02.2019",
    price: 3911.661621,
  },
  {
    Date: "20.02.2019",
    price: 3946.685059,
  },
  {
    Date: "21.02.2019",
    price: 4000.256836,
  },
  {
    Date: "22.02.2019",
    price: 3952.406494,
  },
  {
    Date: "23.02.2019",
    price: 3998.91626,
  },
  {
    Date: "24.02.2019",
    price: 4145.458008,
  },
  {
    Date: "25.02.2019",
    price: 3807.002441,
  },
  {
    Date: "26.02.2019",
    price: 3878.697266,
  },
  {
    Date: "27.02.2019",
    price: 3857.479736,
  },
  {
    Date: "28.02.2019",
    price: 3848.261963,
  },
  {
    Date: "01.03.2019",
    price: 3853.75708,
  },
  {
    Date: "02.03.2019",
    price: 3855.318115,
  },
  {
    Date: "03.03.2019",
    price: 3862.266113,
  },
  {
    Date: "04.03.2019",
    price: 3845.091553,
  },
  {
    Date: "05.03.2019",
    price: 3759.83252,
  },
  {
    Date: "06.03.2019",
    price: 3897.081055,
  },
  {
    Date: "07.03.2019",
    price: 3903.384766,
  },
  {
    Date: "08.03.2019",
    price: 3913.22583,
  },
  {
    Date: "09.03.2019",
    price: 3894.55249,
  },
  {
    Date: "10.03.2019",
    price: 3966.174316,
  },
  {
    Date: "11.03.2019",
    price: 3953.740234,
  },
  {
    Date: "12.03.2019",
    price: 3903.758301,
  },
  {
    Date: "13.03.2019",
    price: 3913.047363,
  },
  {
    Date: "14.03.2019",
    price: 3905.576904,
  },
  {
    Date: "15.03.2019",
    price: 3926.66333,
  },
  {
    Date: "16.03.2019",
    price: 3963.900146,
  },
  {
    Date: "17.03.2019",
    price: 4047.719482,
  },
  {
    Date: "18.03.2019",
    price: 4029.968506,
  },
  {
    Date: "19.03.2019",
    price: 4032.691895,
  },
  {
    Date: "20.03.2019",
    price: 4070.793945,
  },
  {
    Date: "21.03.2019",
    price: 4083.953857,
  },
  {
    Date: "22.03.2019",
    price: 4028.514648,
  },
  {
    Date: "23.03.2019",
    price: 4022.713379,
  },
  {
    Date: "24.03.2019",
    price: 4035.163574,
  },
  {
    Date: "25.03.2019",
    price: 4024.112793,
  },
  {
    Date: "26.03.2019",
    price: 3969.22876,
  },
  {
    Date: "27.03.2019",
    price: 3984.244873,
  },
  {
    Date: "28.03.2019",
    price: 4087.584473,
  },
  {
    Date: "29.03.2019",
    price: 4068.299805,
  },
  {
    Date: "30.03.2019",
    price: 4092.13623,
  },
  {
    Date: "31.03.2019",
    price: 4105.456055,
  },
  {
    Date: "01.04.2019",
    price: 4105.362305,
  },
  {
    Date: "02.04.2019",
    price: 4156.919434,
  },
  {
    Date: "03.04.2019",
    price: 4879.958008,
  },
  {
    Date: "04.04.2019",
    price: 4971.307617,
  },
  {
    Date: "05.04.2019",
    price: 4922.806152,
  },
  {
    Date: "06.04.2019",
    price: 5036.792969,
  },
  {
    Date: "07.04.2019",
    price: 5062.793945,
  },
  {
    Date: "08.04.2019",
    price: 5199.835449,
  },
  {
    Date: "09.04.2019",
    price: 5289.917969,
  },
  {
    Date: "10.04.2019",
    price: 5204.105469,
  },
  {
    Date: "11.04.2019",
    price: 5325.081543,
  },
  {
    Date: "12.04.2019",
    price: 5061.200684,
  },
  {
    Date: "13.04.2019",
    price: 5088.850098,
  },
  {
    Date: "14.04.2019",
    price: 5095.758789,
  },
  {
    Date: "15.04.2019",
    price: 5167.321777,
  },
  {
    Date: "16.04.2019",
    price: 5066.577637,
  },
  {
    Date: "17.04.2019",
    price: 5236.135254,
  },
  {
    Date: "18.04.2019",
    price: 5251.480469,
  },
  {
    Date: "19.04.2019",
    price: 5298.154297,
  },
  {
    Date: "20.04.2019",
    price: 5304.160645,
  },
  {
    Date: "21.04.2019",
    price: 5335.878906,
  },
  {
    Date: "22.04.2019",
    price: 5312.494629,
  },
  {
    Date: "23.04.2019",
    price: 5399.365723,
  },
  {
    Date: "24.04.2019",
    price: 5571.508301,
  },
  {
    Date: "25.04.2019",
    price: 5466.524414,
  },
  {
    Date: "26.04.2019",
    price: 5210.304688,
  },
  {
    Date: "27.04.2019",
    price: 5279.471191,
  },
  {
    Date: "28.04.2019",
    price: 5271.746582,
  },
  {
    Date: "29.04.2019",
    price: 5284.858398,
  },
  {
    Date: "30.04.2019",
    price: 5247.726074,
  },
  {
    Date: "01.05.2019",
    price: 5350.914551,
  },
  {
    Date: "02.05.2019",
    price: 5402.422852,
  },
  {
    Date: "03.05.2019",
    price: 5505.552246,
  },
  {
    Date: "04.05.2019",
    price: 5769.202637,
  },
  {
    Date: "05.05.2019",
    price: 5831.068359,
  },
  {
    Date: "06.05.2019",
    price: 5791.693359,
  },
  {
    Date: "07.05.2019",
    price: 5745.599121,
  },
  {
    Date: "08.05.2019",
    price: 5849.481445,
  },
  {
    Date: "09.05.2019",
    price: 5982.316406,
  },
  {
    Date: "10.05.2019",
    price: 6175.822754,
  },
  {
    Date: "11.05.2019",
    price: 6379.666992,
  },
  {
    Date: "12.05.2019",
    price: 7203.507324,
  },
  {
    Date: "13.05.2019",
    price: 6971.178223,
  },
  {
    Date: "14.05.2019",
    price: 7807.884277,
  },
  {
    Date: "15.05.2019",
    price: 7989.374512,
  },
  {
    Date: "16.05.2019",
    price: 8194.500977,
  },
  {
    Date: "17.05.2019",
    price: 7886.925781,
  },
  {
    Date: "18.05.2019",
    price: 7341.664551,
  },
  {
    Date: "19.05.2019",
    price: 7267.962891,
  },
  {
    Date: "20.05.2019",
    price: 8196.923828,
  },
  {
    Date: "21.05.2019",
    price: 7977.969238,
  },
  {
    Date: "22.05.2019",
    price: 7956.291992,
  },
  {
    Date: "23.05.2019",
    price: 7677.269043,
  },
  {
    Date: "24.05.2019",
    price: 7881.695313,
  },
  {
    Date: "25.05.2019",
    price: 7991.885254,
  },
  {
    Date: "26.05.2019",
    price: 8055.206055,
  },
  {
    Date: "27.05.2019",
    price: 8674.072266,
  },
  {
    Date: "28.05.2019",
    price: 8802.757813,
  },
  {
    Date: "29.05.2019",
    price: 8718.591797,
  },
  {
    Date: "30.05.2019",
    price: 8661.760742,
  },
  {
    Date: "31.05.2019",
    price: 8320.286133,
  },
  {
    Date: "01.06.2019",
    price: 8573.839844,
  },
  {
    Date: "02.06.2019",
    price: 8565.473633,
  },
  {
    Date: "03.06.2019",
    price: 8741.74707,
  },
  {
    Date: "04.06.2019",
    price: 8210.985352,
  },
  {
    Date: "05.06.2019",
    price: 7704.343262,
  },
  {
    Date: "06.06.2019",
    price: 7819.633301,
  },
  {
    Date: "07.06.2019",
    price: 7826.901367,
  },
  {
    Date: "08.06.2019",
    price: 8036.774902,
  },
  {
    Date: "09.06.2019",
    price: 7949.674805,
  },
  {
    Date: "10.06.2019",
    price: 7692.284668,
  },
  {
    Date: "11.06.2019",
    price: 8004.243652,
  },
  {
    Date: "12.06.2019",
    price: 7925.434082,
  },
  {
    Date: "13.06.2019",
    price: 8145.54541,
  },
  {
    Date: "14.06.2019",
    price: 8230.898438,
  },
  {
    Date: "15.06.2019",
    price: 8689.746094,
  },
  {
    Date: "16.06.2019",
    price: 8841.44043,
  },
  {
    Date: "17.06.2019",
    price: 8988.923828,
  },
  {
    Date: "18.06.2019",
    price: 9335.466797,
  },
  {
    Date: "19.06.2019",
    price: 9078.727539,
  },
  {
    Date: "20.06.2019",
    price: 9273.060547,
  },
  {
    Date: "21.06.2019",
    price: 9525.074219,
  },
  {
    Date: "22.06.2019",
    price: 10175.923828,
  },
  {
    Date: "23.06.2019",
    price: 10696.691406,
  },
  {
    Date: "24.06.2019",
    price: 10853.744141,
  },
  {
    Date: "25.06.2019",
    price: 11007.202148,
  },
  {
    Date: "26.06.2019",
    price: 11778.581055,
  },
  {
    Date: "27.06.2019",
    price: 13017.125,
  },
  {
    Date: "28.06.2019",
    price: 11162.167969,
  },
  {
    Date: "29.06.2019",
    price: 12400.763672,
  },
  {
    Date: "30.06.2019",
    price: 11931.991211,
  },
  {
    Date: "01.07.2019",
    price: 10796.930664,
  },
  {
    Date: "02.07.2019",
    price: 10588.683594,
  },
  {
    Date: "03.07.2019",
    price: 10818.15625,
  },
  {
    Date: "04.07.2019",
    price: 11972.71875,
  },
  {
    Date: "05.07.2019",
    price: 11203.102539,
  },
  {
    Date: "06.07.2019",
    price: 10982.543945,
  },
  {
    Date: "07.07.2019",
    price: 11217.616211,
  },
  {
    Date: "08.07.2019",
    price: 11446.59668,
  },
  {
    Date: "09.07.2019",
    price: 12284.326172,
  },
  {
    Date: "10.07.2019",
    price: 12571.537109,
  },
  {
    Date: "11.07.2019",
    price: 12139.713867,
  },
  {
    Date: "12.07.2019",
    price: 11354.299805,
  },
  {
    Date: "13.07.2019",
    price: 11813.126953,
  },
  {
    Date: "14.07.2019",
    price: 11381.020508,
  },
  {
    Date: "15.07.2019",
    price: 10257.838867,
  },
  {
    Date: "16.07.2019",
    price: 10896.65332,
  },
  {
    Date: "17.07.2019",
    price: 9471.213867,
  },
  {
    Date: "18.07.2019",
    price: 9698.50293,
  },
  {
    Date: "19.07.2019",
    price: 10653.956055,
  },
  {
    Date: "20.07.2019",
    price: 10525.819336,
  },
  {
    Date: "21.07.2019",
    price: 10777.529297,
  },
  {
    Date: "22.07.2019",
    price: 10596.948242,
  },
  {
    Date: "23.07.2019",
    price: 10346.748047,
  },
  {
    Date: "24.07.2019",
    price: 9887.730469,
  },
  {
    Date: "25.07.2019",
    price: 9809.09668,
  },
  {
    Date: "26.07.2019",
    price: 9913.126953,
  },
  {
    Date: "27.07.2019",
    price: 9871.165039,
  },
  {
    Date: "28.07.2019",
    price: 9491.626953,
  },
  {
    Date: "29.07.2019",
    price: 9548.178711,
  },
  {
    Date: "30.07.2019",
    price: 9522.329102,
  },
  {
    Date: "31.07.2019",
    price: 9604.050781,
  },
  {
    Date: "01.08.2019",
    price: 10077.442383,
  },
  {
    Date: "02.08.2019",
    price: 10402.042969,
  },
  {
    Date: "03.08.2019",
    price: 10519.27832,
  },
  {
    Date: "04.08.2019",
    price: 10821.632813,
  },
  {
    Date: "05.08.2019",
    price: 10960.735352,
  },
  {
    Date: "06.08.2019",
    price: 11811.544922,
  },
  {
    Date: "07.08.2019",
    price: 11476.193359,
  },
  {
    Date: "08.08.2019",
    price: 11954.040039,
  },
  {
    Date: "09.08.2019",
    price: 11953.469727,
  },
  {
    Date: "10.08.2019",
    price: 11861.556641,
  },
  {
    Date: "11.08.2019",
    price: 11349.740234,
  },
  {
    Date: "12.08.2019",
    price: 11528.189453,
  },
  {
    Date: "13.08.2019",
    price: 11385.052734,
  },
  {
    Date: "14.08.2019",
    price: 10889.487305,
  },
  {
    Date: "15.08.2019",
    price: 10038.421875,
  },
  {
    Date: "16.08.2019",
    price: 10319.419922,
  },
  {
    Date: "17.08.2019",
    price: 10358.722656,
  },
  {
    Date: "18.08.2019",
    price: 10233.005859,
  },
  {
    Date: "19.08.2019",
    price: 10350.283203,
  },
  {
    Date: "20.08.2019",
    price: 10916.34668,
  },
  {
    Date: "21.08.2019",
    price: 10764.572266,
  },
  {
    Date: "22.08.2019",
    price: 10142.521484,
  },
  {
    Date: "23.08.2019",
    price: 10136.30957,
  },
  {
    Date: "24.08.2019",
    price: 10407.644531,
  },
  {
    Date: "25.08.2019",
    price: 10160.737305,
  },
  {
    Date: "26.08.2019",
    price: 10126.299805,
  },
  {
    Date: "27.08.2019",
    price: 10372.826172,
  },
  {
    Date: "28.08.2019",
    price: 10203.426758,
  },
  {
    Date: "29.08.2019",
    price: 9756.786133,
  },
  {
    Date: "30.08.2019",
    price: 9514.844727,
  },
  {
    Date: "31.08.2019",
    price: 9597.539063,
  },
  {
    Date: "01.09.2019",
    price: 9630.592773,
  },
  {
    Date: "02.09.2019",
    price: 9757.473633,
  },
  {
    Date: "03.09.2019",
    price: 10345.725586,
  },
  {
    Date: "04.09.2019",
    price: 10621.180664,
  },
  {
    Date: "05.09.2019",
    price: 10588.183594,
  },
  {
    Date: "06.09.2019",
    price: 10578.198242,
  },
  {
    Date: "07.09.2019",
    price: 10353.931641,
  },
  {
    Date: "08.09.2019",
    price: 10518.114258,
  },
  {
    Date: "09.09.2019",
    price: 10443.228516,
  },
  {
    Date: "10.09.2019",
    price: 10336.408203,
  },
  {
    Date: "11.09.2019",
    price: 10123.035156,
  },
  {
    Date: "12.09.2019",
    price: 10176.819336,
  },
  {
    Date: "13.09.2019",
    price: 10415.362305,
  },
  {
    Date: "14.09.2019",
    price: 10345.40332,
  },
  {
    Date: "15.09.2019",
    price: 10356.46582,
  },
  {
    Date: "16.09.2019",
    price: 10347.222656,
  },
  {
    Date: "17.09.2019",
    price: 10281.513672,
  },
  {
    Date: "18.09.2019",
    price: 10247.795898,
  },
  {
    Date: "19.09.2019",
    price: 10200.496094,
  },
  {
    Date: "20.09.2019",
    price: 10266.318359,
  },
  {
    Date: "21.09.2019",
    price: 10183.648438,
  },
  {
    Date: "22.09.2019",
    price: 10024.115234,
  },
  {
    Date: "23.09.2019",
    price: 10067.962891,
  },
  {
    Date: "24.09.2019",
    price: 9729.321289,
  },
  {
    Date: "25.09.2019",
    price: 8603.428711,
  },
  {
    Date: "26.09.2019",
    price: 8487.669922,
  },
  {
    Date: "27.09.2019",
    price: 8113.101074,
  },
  {
    Date: "28.09.2019",
    price: 8251.273438,
  },
  {
    Date: "29.09.2019",
    price: 8246.037109,
  },
  {
    Date: "30.09.2019",
    price: 8104.226563,
  },
  {
    Date: "01.10.2019",
    price: 8299.720703,
  },
  {
    Date: "02.10.2019",
    price: 8344.212891,
  },
  {
    Date: "03.10.2019",
    price: 8390.774414,
  },
  {
    Date: "04.10.2019",
    price: 8259.494141,
  },
  {
    Date: "05.10.2019",
    price: 8210.149414,
  },
  {
    Date: "06.10.2019",
    price: 8149.876953,
  },
  {
    Date: "07.10.2019",
    price: 7989.120605,
  },
  {
    Date: "08.10.2019",
    price: 8246.849609,
  },
  {
    Date: "09.10.2019",
    price: 8229.84082,
  },
  {
    Date: "10.10.2019",
    price: 8585.280273,
  },
  {
    Date: "11.10.2019",
    price: 8585.262695,
  },
  {
    Date: "12.10.2019",
    price: 8315.665039,
  },
  {
    Date: "13.10.2019",
    price: 8336.902344,
  },
  {
    Date: "14.10.2019",
    price: 8320.832031,
  },
  {
    Date: "15.10.2019",
    price: 8373.458008,
  },
  {
    Date: "16.10.2019",
    price: 8204.674805,
  },
  {
    Date: "17.10.2019",
    price: 8047.8125,
  },
  {
    Date: "18.10.2019",
    price: 8100.933594,
  },
  {
    Date: "19.10.2019",
    price: 7973.803711,
  },
  {
    Date: "20.10.2019",
    price: 7997.807129,
  },
  {
    Date: "21.10.2019",
    price: 8225.115234,
  },
  {
    Date: "22.10.2019",
    price: 8243.402344,
  },
  {
    Date: "23.10.2019",
    price: 8076.228516,
  },
  {
    Date: "24.10.2019",
    price: 7509.728027,
  },
  {
    Date: "25.10.2019",
    price: 7490.703125,
  },
  {
    Date: "26.10.2019",
    price: 8667.577148,
  },
  {
    Date: "27.10.2019",
    price: 9241.707031,
  },
  {
    Date: "28.10.2019",
    price: 9565.101563,
  },
  {
    Date: "29.10.2019",
    price: 9248.44043,
  },
  {
    Date: "30.10.2019",
    price: 9422.462891,
  },
  {
    Date: "31.10.2019",
    price: 9202.458008,
  },
  {
    Date: "01.11.2019",
    price: 9193.992188,
  },
  {
    Date: "02.11.2019",
    price: 9259.783203,
  },
  {
    Date: "03.11.2019",
    price: 9324.787109,
  },
  {
    Date: "04.11.2019",
    price: 9235.607422,
  },
  {
    Date: "05.11.2019",
    price: 9413.004883,
  },
  {
    Date: "06.11.2019",
    price: 9340.864258,
  },
  {
    Date: "07.11.2019",
    price: 9352.393555,
  },
  {
    Date: "08.11.2019",
    price: 9265.368164,
  },
  {
    Date: "09.11.2019",
    price: 8809.46875,
  },
  {
    Date: "10.11.2019",
    price: 8812.489258,
  },
  {
    Date: "11.11.2019",
    price: 9056.917969,
  },
  {
    Date: "12.11.2019",
    price: 8759.751953,
  },
  {
    Date: "13.11.2019",
    price: 8812.033203,
  },
  {
    Date: "14.11.2019",
    price: 8811.936523,
  },
  {
    Date: "15.11.2019",
    price: 8705.708008,
  },
  {
    Date: "16.11.2019",
    price: 8491.166016,
  },
  {
    Date: "17.11.2019",
    price: 8549.470703,
  },
  {
    Date: "18.11.2019",
    price: 8573.980469,
  },
  {
    Date: "19.11.2019",
    price: 8305.134766,
  },
  {
    Date: "20.11.2019",
    price: 8203.613281,
  },
  {
    Date: "21.11.2019",
    price: 8023.644531,
  },
  {
    Date: "22.11.2019",
    price: 7643.569336,
  },
  {
    Date: "23.11.2019",
    price: 7296.164551,
  },
  {
    Date: "24.11.2019",
    price: 7398.633789,
  },
  {
    Date: "25.11.2019",
    price: 7039.977051,
  },
  {
    Date: "26.11.2019",
    price: 7145.15918,
  },
  {
    Date: "27.11.2019",
    price: 7220.880859,
  },
  {
    Date: "28.11.2019",
    price: 7536.820313,
  },
  {
    Date: "29.11.2019",
    price: 7466.727051,
  },
  {
    Date: "30.11.2019",
    price: 7764.057129,
  },
  {
    Date: "01.12.2019",
    price: 7571.616211,
  },
  {
    Date: "02.12.2019",
    price: 7424.036133,
  },
  {
    Date: "03.12.2019",
    price: 7323.975586,
  },
  {
    Date: "04.12.2019",
    price: 7320.125,
  },
  {
    Date: "05.12.2019",
    price: 7253.241699,
  },
  {
    Date: "06.12.2019",
    price: 7450.561523,
  },
  {
    Date: "07.12.2019",
    price: 7547.265625,
  },
  {
    Date: "08.12.2019",
    price: 7551.338867,
  },
  {
    Date: "09.12.2019",
    price: 7561.79541,
  },
  {
    Date: "10.12.2019",
    price: 7397.134277,
  },
  {
    Date: "11.12.2019",
    price: 7277.197754,
  },
  {
    Date: "12.12.2019",
    price: 7216.73877,
  },
  {
    Date: "13.12.2019",
    price: 7244.662109,
  },
  {
    Date: "14.12.2019",
    price: 7268.902832,
  },
  {
    Date: "15.12.2019",
    price: 7124.239746,
  },
  {
    Date: "16.12.2019",
    price: 7153.663086,
  },
  {
    Date: "17.12.2019",
    price: 6931.31543,
  },
  {
    Date: "18.12.2019",
    price: 6647.698242,
  },
  {
    Date: "19.12.2019",
    price: 7277.59082,
  },
  {
    Date: "20.12.2019",
    price: 7208.636719,
  },
  {
    Date: "21.12.2019",
    price: 7220.59375,
  },
  {
    Date: "22.12.2019",
    price: 7191.188477,
  },
  {
    Date: "23.12.2019",
    price: 7508.902344,
  },
  {
    Date: "24.12.2019",
    price: 7354.393066,
  },
  {
    Date: "25.12.2019",
    price: 7325.755859,
  },
  {
    Date: "26.12.2019",
    price: 7274.799316,
  },
  {
    Date: "27.12.2019",
    price: 7238.141113,
  },
  {
    Date: "28.12.2019",
    price: 7289.03125,
  },
  {
    Date: "29.12.2019",
    price: 7317.647461,
  },
  {
    Date: "30.12.2019",
    price: 7420.272949,
  },
  {
    Date: "31.12.2019",
    price: 7294.438965,
  },
  {
    Date: "01.01.2020",
    price: 7194.89209,
  },
  {
    Date: "02.01.2020",
    price: 7202.55127,
  },
  {
    Date: "03.01.2020",
    price: 6984.428711,
  },
  {
    Date: "04.01.2020",
    price: 7345.375488,
  },
  {
    Date: "05.01.2020",
    price: 7410.45166,
  },
  {
    Date: "06.01.2020",
    price: 7410.452148,
  },
  {
    Date: "07.01.2020",
    price: 7768.682129,
  },
  {
    Date: "08.01.2020",
    price: 8161.935547,
  },
  {
    Date: "09.01.2020",
    price: 8082.295898,
  },
  {
    Date: "10.01.2020",
    price: 7878.307617,
  },
  {
    Date: "11.01.2020",
    price: 8162.190918,
  },
  {
    Date: "12.01.2020",
    price: 8033.261719,
  },
  {
    Date: "13.01.2020",
    price: 8189.771973,
  },
  {
    Date: "14.01.2020",
    price: 8140.933105,
  },
  {
    Date: "15.01.2020",
    price: 8825.34375,
  },
  {
    Date: "16.01.2020",
    price: 8812.481445,
  },
  {
    Date: "17.01.2020",
    price: 8725.209961,
  },
  {
    Date: "18.01.2020",
    price: 8927.211914,
  },
  {
    Date: "19.01.2020",
    price: 8941.445313,
  },
  {
    Date: "20.01.2020",
    price: 8704.631836,
  },
  {
    Date: "21.01.2020",
    price: 8658.991211,
  },
  {
    Date: "22.01.2020",
    price: 8744.210938,
  },
  {
    Date: "23.01.2020",
    price: 8680.650391,
  },
  {
    Date: "24.01.2020",
    price: 8405.567383,
  },
  {
    Date: "25.01.2020",
    price: 8440.119141,
  },
  {
    Date: "26.01.2020",
    price: 8364.410156,
  },
  {
    Date: "27.01.2020",
    price: 8597.308594,
  },
  {
    Date: "28.01.2020",
    price: 8912.524414,
  },
  {
    Date: "29.01.2020",
    price: 9357.470703,
  },
  {
    Date: "30.01.2020",
    price: 9316.016602,
  },
  {
    Date: "31.01.2020",
    price: 9508.313477,
  },
  {
    Date: "01.02.2020",
    price: 9346.357422,
  },
  {
    Date: "02.02.2020",
    price: 9389.820313,
  },
  {
    Date: "03.02.2020",
    price: 9344.683594,
  },
  {
    Date: "04.02.2020",
    price: 9292.841797,
  },
  {
    Date: "05.02.2020",
    price: 9183.416016,
  },
  {
    Date: "06.02.2020",
    price: 9617.821289,
  },
  {
    Date: "07.02.2020",
    price: 9726.00293,
  },
  {
    Date: "08.02.2020",
    price: 9793.070313,
  },
  {
    Date: "09.02.2020",
    price: 9863.894531,
  },
  {
    Date: "10.02.2020",
    price: 10115.55957,
  },
  {
    Date: "11.02.2020",
    price: 9855.891602,
  },
  {
    Date: "12.02.2020",
    price: 10202.387695,
  },
  {
    Date: "13.02.2020",
    price: 10323.960938,
  },
  {
    Date: "14.02.2020",
    price: 10211.550781,
  },
  {
    Date: "15.02.2020",
    price: 10313.856445,
  },
  {
    Date: "16.02.2020",
    price: 9889.179688,
  },
  {
    Date: "17.02.2020",
    price: 9936.560547,
  },
  {
    Date: "18.02.2020",
    price: 9691.230469,
  },
  {
    Date: "19.02.2020",
    price: 10143.798828,
  },
  {
    Date: "20.02.2020",
    price: 9629.325195,
  },
  {
    Date: "21.02.2020",
    price: 9611.782227,
  },
  {
    Date: "22.02.2020",
    price: 9687.707031,
  },
  {
    Date: "23.02.2020",
    price: 9663.318359,
  },
  {
    Date: "24.02.2020",
    price: 9921.583008,
  },
  {
    Date: "25.02.2020",
    price: 9651.3125,
  },
  {
    Date: "26.02.2020",
    price: 9338.290039,
  },
  {
    Date: "27.02.2020",
    price: 8825.09375,
  },
  {
    Date: "28.02.2020",
    price: 8788.728516,
  },
  {
    Date: "29.02.2020",
    price: 8671.212891,
  },
  {
    Date: "01.03.2020",
    price: 8599.758789,
  },
  {
    Date: "02.03.2020",
    price: 8563.264648,
  },
  {
    Date: "03.03.2020",
    price: 8865.387695,
  },
  {
    Date: "04.03.2020",
    price: 8788.541992,
  },
  {
    Date: "05.03.2020",
    price: 8760.285156,
  },
  {
    Date: "06.03.2020",
    price: 9078.308594,
  },
  {
    Date: "07.03.2020",
    price: 9121.600586,
  },
  {
    Date: "08.03.2020",
    price: 8908.206055,
  },
  {
    Date: "09.03.2020",
    price: 8111.146484,
  },
  {
    Date: "10.03.2020",
    price: 7922.146973,
  },
  {
    Date: "11.03.2020",
    price: 7910.089844,
  },
  {
    Date: "12.03.2020",
    price: 7913.616211,
  },
  {
    Date: "13.03.2020",
    price: 5017.831055,
  },
  {
    Date: "14.03.2020",
    price: 5573.077637,
  },
  {
    Date: "15.03.2020",
    price: 5201.066895,
  },
  {
    Date: "16.03.2020",
    price: 5385.229492,
  },
  {
    Date: "17.03.2020",
    price: 5002.578125,
  },
  {
    Date: "18.03.2020",
    price: 5227.11377,
  },
  {
    Date: "19.03.2020",
    price: 5245.416504,
  },
  {
    Date: "20.03.2020",
    price: 6191.653809,
  },
  {
    Date: "21.03.2020",
    price: 6206.521484,
  },
  {
    Date: "22.03.2020",
    price: 6185.558105,
  },
  {
    Date: "23.03.2020",
    price: 5831.374512,
  },
  {
    Date: "24.03.2020",
    price: 6436.642578,
  },
  {
    Date: "25.03.2020",
    price: 6738.716797,
  },
  {
    Date: "26.03.2020",
    price: 6675.170898,
  },
  {
    Date: "27.03.2020",
    price: 6719.38916,
  },
  {
    Date: "28.03.2020",
    price: 6467.253906,
  },
  {
    Date: "29.03.2020",
    price: 6245.624512,
  },
  {
    Date: "30.03.2020",
    price: 5925.538574,
  },
  {
    Date: "31.03.2020",
    price: 6430.606445,
  },
  {
    Date: "01.04.2020",
    price: 6437.319336,
  },
  {
    Date: "02.04.2020",
    price: 6606.776367,
  },
  {
    Date: "03.04.2020",
    price: 6797.396484,
  },
  {
    Date: "04.04.2020",
    price: 6738.382813,
  },
  {
    Date: "05.04.2020",
    price: 6862.537598,
  },
  {
    Date: "06.04.2020",
    price: 6788.049805,
  },
  {
    Date: "07.04.2020",
    price: 7273.644043,
  },
  {
    Date: "08.04.2020",
    price: 7179.283203,
  },
  {
    Date: "09.04.2020",
    price: 7337.966309,
  },
  {
    Date: "10.04.2020",
    price: 7303.81543,
  },
  {
    Date: "11.04.2020",
    price: 6867.44043,
  },
  {
    Date: "12.04.2020",
    price: 6858.067871,
  },
  {
    Date: "13.04.2020",
    price: 6965.616699,
  },
  {
    Date: "14.04.2020",
    price: 6843.281738,
  },
  {
    Date: "15.04.2020",
    price: 6845.561523,
  },
  {
    Date: "16.04.2020",
    price: 6640.454102,
  },
  {
    Date: "17.04.2020",
    price: 7116.552734,
  },
  {
    Date: "18.04.2020",
    price: 7092.291504,
  },
  {
    Date: "19.04.2020",
    price: 7260.922363,
  },
  {
    Date: "20.04.2020",
    price: 7186.873535,
  },
  {
    Date: "21.04.2020",
    price: 6879.78418,
  },
  {
    Date: "22.04.2020",
    price: 6879.44043,
  },
  {
    Date: "23.04.2020",
    price: 7121.306152,
  },
  {
    Date: "24.04.2020",
    price: 7434.181641,
  },
  {
    Date: "25.04.2020",
    price: 7550.48291,
  },
  {
    Date: "26.04.2020",
    price: 7570.13916,
  },
  {
    Date: "27.04.2020",
    price: 7679.418945,
  },
  {
    Date: "28.04.2020",
    price: 7796.970215,
  },
  {
    Date: "29.04.2020",
    price: 7806.712402,
  },
  {
    Date: "30.04.2020",
    price: 8797.669922,
  },
  {
    Date: "01.05.2020",
    price: 8672.782227,
  },
  {
    Date: "02.05.2020",
    price: 8869.057617,
  },
  {
    Date: "03.05.2020",
    price: 8983.614258,
  },
  {
    Date: "04.05.2020",
    price: 8895.745117,
  },
  {
    Date: "05.05.2020",
    price: 8912.832031,
  },
  {
    Date: "06.05.2020",
    price: 9007.441406,
  },
  {
    Date: "07.05.2020",
    price: 9261.895508,
  },
  {
    Date: "08.05.2020",
    price: 9936.162109,
  },
  {
    Date: "09.05.2020",
    price: 9840.90625,
  },
  {
    Date: "10.05.2020",
    price: 9591.168945,
  },
  {
    Date: "11.05.2020",
    price: 8755.535156,
  },
  {
    Date: "12.05.2020",
    price: 8610.385742,
  },
  {
    Date: "13.05.2020",
    price: 8805.387695,
  },
  {
    Date: "14.05.2020",
    price: 9271.329102,
  },
  {
    Date: "15.05.2020",
    price: 9734.291016,
  },
  {
    Date: "16.05.2020",
    price: 9333.240234,
  },
  {
    Date: "17.05.2020",
    price: 9374.929688,
  },
  {
    Date: "18.05.2020",
    price: 9675.695313,
  },
  {
    Date: "19.05.2020",
    price: 9727.063477,
  },
  {
    Date: "20.05.2020",
    price: 9725.329102,
  },
  {
    Date: "21.05.2020",
    price: 9522.740234,
  },
  {
    Date: "22.05.2020",
    price: 9080.334961,
  },
  {
    Date: "23.05.2020",
    price: 9185.0625,
  },
  {
    Date: "24.05.2020",
    price: 9212.283203,
  },
  {
    Date: "25.05.2020",
    price: 8786.107422,
  },
  {
    Date: "26.05.2020",
    price: 8909.585938,
  },
  {
    Date: "27.05.2020",
    price: 8837.380859,
  },
  {
    Date: "28.05.2020",
    price: 9184.945313,
  },
  {
    Date: "29.05.2020",
    price: 9528.355469,
  },
  {
    Date: "30.05.2020",
    price: 9438.914063,
  },
  {
    Date: "31.05.2020",
    price: 9700.105469,
  },
  {
    Date: "01.06.2020",
    price: 9463.605469,
  },
  {
    Date: "02.06.2020",
    price: 10162.973633,
  },
  {
    Date: "03.06.2020",
    price: 9533.760742,
  },
  {
    Date: "04.06.2020",
    price: 9655.854492,
  },
  {
    Date: "05.06.2020",
    price: 9800.21582,
  },
  {
    Date: "06.06.2020",
    price: 9664.904297,
  },
  {
    Date: "07.06.2020",
    price: 9653.00293,
  },
  {
    Date: "08.06.2020",
    price: 9760.063477,
  },
  {
    Date: "09.06.2020",
    price: 9774.360352,
  },
  {
    Date: "10.06.2020",
    price: 9794.119141,
  },
  {
    Date: "11.06.2020",
    price: 9870.078125,
  },
  {
    Date: "12.06.2020",
    price: 9320.69043,
  },
  {
    Date: "13.06.2020",
    price: 9480.735352,
  },
  {
    Date: "14.06.2020",
    price: 9477.553711,
  },
  {
    Date: "15.06.2020",
    price: 9386.035156,
  },
  {
    Date: "16.06.2020",
    price: 9454.266602,
  },
  {
    Date: "17.06.2020",
    price: 9533.78418,
  },
  {
    Date: "18.06.2020",
    price: 9481.567383,
  },
  {
    Date: "19.06.2020",
    price: 9410.293945,
  },
  {
    Date: "20.06.2020",
    price: 9290.959961,
  },
  {
    Date: "21.06.2020",
    price: 9330.926758,
  },
  {
    Date: "22.06.2020",
    price: 9300.915039,
  },
  {
    Date: "23.06.2020",
    price: 9644.076172,
  },
  {
    Date: "24.06.2020",
    price: 9632.149414,
  },
  {
    Date: "25.06.2020",
    price: 9314.126953,
  },
  {
    Date: "26.06.2020",
    price: 9260.995117,
  },
  {
    Date: "27.06.2020",
    price: 9167.824219,
  },
  {
    Date: "28.06.2020",
    price: 9048.460938,
  },
  {
    Date: "29.06.2020",
    price: 9140.029297,
  },
  {
    Date: "30.06.2020",
    price: 9185.581055,
  },
  {
    Date: "01.07.2020",
    price: 9145.985352,
  },
  {
    Date: "02.07.2020",
    price: 9231.139648,
  },
  {
    Date: "03.07.2020",
    price: 9124.842773,
  },
  {
    Date: "04.07.2020",
    price: 9084.233398,
  },
  {
    Date: "05.07.2020",
    price: 9126.09082,
  },
  {
    Date: "06.07.2020",
    price: 9072.849609,
  },
  {
    Date: "07.07.2020",
    price: 9349.161133,
  },
  {
    Date: "08.07.2020",
    price: 9253.020508,
  },
  {
    Date: "09.07.2020",
    price: 9427.994141,
  },
  {
    Date: "10.07.2020",
    price: 9273.357422,
  },
  {
    Date: "11.07.2020",
    price: 9277.511719,
  },
  {
    Date: "12.07.2020",
    price: 9241.054688,
  },
  {
    Date: "13.07.2020",
    price: 9277.205078,
  },
  {
    Date: "14.07.2020",
    price: 9238.703125,
  },
  {
    Date: "15.07.2020",
    price: 9241.897461,
  },
  {
    Date: "16.07.2020",
    price: 9191.980469,
  },
  {
    Date: "17.07.2020",
    price: 9131.8125,
  },
  {
    Date: "18.07.2020",
    price: 9151.183594,
  },
  {
    Date: "19.07.2020",
    price: 9158.005859,
  },
  {
    Date: "20.07.2020",
    price: 9187.220703,
  },
  {
    Date: "21.07.2020",
    price: 9162.514648,
  },
  {
    Date: "22.07.2020",
    price: 9375.080078,
  },
  {
    Date: "23.07.2020",
    price: 9527.141602,
  },
  {
    Date: "24.07.2020",
    price: 9585.514648,
  },
  {
    Date: "25.07.2020",
    price: 9539.485352,
  },
  {
    Date: "26.07.2020",
    price: 9680.234375,
  },
  {
    Date: "27.07.2020",
    price: 9905.217773,
  },
  {
    Date: "28.07.2020",
    price: 11017.463867,
  },
  {
    Date: "29.07.2020",
    price: 10912.953125,
  },
  {
    Date: "30.07.2020",
    price: 11099.833008,
  },
  {
    Date: "31.07.2020",
    price: 11110.210938,
  },
  {
    Date: "01.08.2020",
    price: 11322.570313,
  },
  {
    Date: "02.08.2020",
    price: 11758.764648,
  },
  {
    Date: "03.08.2020",
    price: 11043.768555,
  },
  {
    Date: "04.08.2020",
    price: 11246.203125,
  },
  {
    Date: "05.08.2020",
    price: 11203.823242,
  },
  {
    Date: "06.08.2020",
    price: 11749.871094,
  },
  {
    Date: "07.08.2020",
    price: 11778.894531,
  },
  {
    Date: "08.08.2020",
    price: 11604.553711,
  },
  {
    Date: "09.08.2020",
    price: 11737.325195,
  },
  {
    Date: "10.08.2020",
    price: 11662.256836,
  },
  {
    Date: "11.08.2020",
    price: 11881.647461,
  },
  {
    Date: "12.08.2020",
    price: 11404.59668,
  },
  {
    Date: "13.08.2020",
    price: 11588.405273,
  },
  {
    Date: "14.08.2020",
    price: 11772.65918,
  },
  {
    Date: "15.08.2020",
    price: 11768.697266,
  },
  {
    Date: "16.08.2020",
    price: 11866.685547,
  },
  {
    Date: "17.08.2020",
    price: 11895.658203,
  },
  {
    Date: "18.08.2020",
    price: 12251.895508,
  },
  {
    Date: "19.08.2020",
    price: 11990.884766,
  },
  {
    Date: "20.08.2020",
    price: 11761.5,
  },
  {
    Date: "21.08.2020",
    price: 11878.026367,
  },
  {
    Date: "22.08.2020",
    price: 11585.477539,
  },
  {
    Date: "23.08.2020",
    price: 11679.696289,
  },
  {
    Date: "24.08.2020",
    price: 11663.689453,
  },
  {
    Date: "25.08.2020",
    price: 11773.588867,
  },
  {
    Date: "26.08.2020",
    price: 11366.894531,
  },
  {
    Date: "27.08.2020",
    price: 11485.608398,
  },
  {
    Date: "28.08.2020",
    price: 11325.295898,
  },
  {
    Date: "29.08.2020",
    price: 11541.054688,
  },
  {
    Date: "30.08.2020",
    price: 11508.713867,
  },
  {
    Date: "31.08.2020",
    price: 11713.306641,
  },
  {
    Date: "01.09.2020",
    price: 11679.316406,
  },
  {
    Date: "02.09.2020",
    price: 11964.823242,
  },
  {
    Date: "03.09.2020",
    price: 11407.191406,
  },
  {
    Date: "04.09.2020",
    price: 10230.365234,
  },
  {
    Date: "05.09.2020",
    price: 10512.530273,
  },
  {
    Date: "06.09.2020",
    price: 10167.216797,
  },
  {
    Date: "07.09.2020",
    price: 10280.998047,
  },
  {
    Date: "08.09.2020",
    price: 10369.306641,
  },
  {
    Date: "09.09.2020",
    price: 10134.151367,
  },
  {
    Date: "10.09.2020",
    price: 10242.330078,
  },
  {
    Date: "11.09.2020",
    price: 10369.02832,
  },
  {
    Date: "12.09.2020",
    price: 10409.861328,
  },
  {
    Date: "13.09.2020",
    price: 10452.399414,
  },
  {
    Date: "14.09.2020",
    price: 10328.734375,
  },
  {
    Date: "15.09.2020",
    price: 10677.754883,
  },
  {
    Date: "16.09.2020",
    price: 10797.761719,
  },
  {
    Date: "17.09.2020",
    price: 10973.251953,
  },
  {
    Date: "18.09.2020",
    price: 10951.820313,
  },
  {
    Date: "19.09.2020",
    price: 10933.75293,
  },
  {
    Date: "20.09.2020",
    price: 11095.870117,
  },
  {
    Date: "21.09.2020",
    price: 10934.925781,
  },
  {
    Date: "22.09.2020",
    price: 10459.624023,
  },
  {
    Date: "23.09.2020",
    price: 10535.492188,
  },
  {
    Date: "24.09.2020",
    price: 10248.786133,
  },
  {
    Date: "25.09.2020",
    price: 10761.109375,
  },
  {
    Date: "26.09.2020",
    price: 10695.575195,
  },
  {
    Date: "27.09.2020",
    price: 10746.892578,
  },
  {
    Date: "28.09.2020",
    price: 10776.613281,
  },
  {
    Date: "29.09.2020",
    price: 10709.650391,
  },
  {
    Date: "30.09.2020",
    price: 10843.871094,
  },
  {
    Date: "01.10.2020",
    price: 10795.254883,
  },
  {
    Date: "02.10.2020",
    price: 10619.821289,
  },
  {
    Date: "03.10.2020",
    price: 10575.100586,
  },
  {
    Date: "04.10.2020",
    price: 10550.44043,
  },
  {
    Date: "05.10.2020",
    price: 10676.529297,
  },
  {
    Date: "06.10.2020",
    price: 10796.306641,
  },
  {
    Date: "07.10.2020",
    price: 10603.355469,
  },
  {
    Date: "08.10.2020",
    price: 10669.371094,
  },
  {
    Date: "09.10.2020",
    price: 10927.913086,
  },
  {
    Date: "10.10.2020",
    price: 11059.142578,
  },
  {
    Date: "11.10.2020",
    price: 11296.082031,
  },
  {
    Date: "12.10.2020",
    price: 11392.635742,
  },
  {
    Date: "13.10.2020",
    price: 11548.719727,
  },
  {
    Date: "14.10.2020",
    price: 11429.047852,
  },
  {
    Date: "15.10.2020",
    price: 11426.602539,
  },
  {
    Date: "16.10.2020",
    price: 11502.828125,
  },
  {
    Date: "17.10.2020",
    price: 11322.123047,
  },
  {
    Date: "18.10.2020",
    price: 11355.982422,
  },
  {
    Date: "19.10.2020",
    price: 11495.038086,
  },
  {
    Date: "20.10.2020",
    price: 11745.974609,
  },
  {
    Date: "21.10.2020",
    price: 11913.077148,
  },
  {
    Date: "22.10.2020",
    price: 12801.635742,
  },
  {
    Date: "23.10.2020",
    price: 12971.548828,
  },
  {
    Date: "24.10.2020",
    price: 12931.574219,
  },
  {
    Date: "25.10.2020",
    price: 13108.063477,
  },
  {
    Date: "26.10.2020",
    price: 13031.201172,
  },
  {
    Date: "27.10.2020",
    price: 13075.242188,
  },
  {
    Date: "28.10.2020",
    price: 13654.214844,
  },
  {
    Date: "29.10.2020",
    price: 13271.298828,
  },
  {
    Date: "30.10.2020",
    price: 13437.874023,
  },
  {
    Date: "31.10.2020",
    price: 13546.532227,
  },
  {
    Date: "01.11.2020",
    price: 13780.995117,
  },
  {
    Date: "02.11.2020",
    price: 13737.032227,
  },
  {
    Date: "03.11.2020",
    price: 13550.451172,
  },
  {
    Date: "04.11.2020",
    price: 13950.488281,
  },
  {
    Date: "05.11.2020",
    price: 14133.733398,
  },
  {
    Date: "06.11.2020",
    price: 15579.729492,
  },
  {
    Date: "07.11.2020",
    price: 15565.880859,
  },
  {
    Date: "08.11.2020",
    price: 14833.753906,
  },
  {
    Date: "09.11.2020",
    price: 15479.595703,
  },
  {
    Date: "10.11.2020",
    price: 15332.350586,
  },
  {
    Date: "11.11.2020",
    price: 15290.90918,
  },
  {
    Date: "12.11.2020",
    price: 15701.298828,
  },
  {
    Date: "13.11.2020",
    price: 16276.44043,
  },
  {
    Date: "14.11.2020",
    price: 16317.808594,
  },
  {
    Date: "15.11.2020",
    price: 16068.139648,
  },
  {
    Date: "16.11.2020",
    price: 15955.577148,
  },
  {
    Date: "17.11.2020",
    price: 16685.691406,
  },
  {
    Date: "18.11.2020",
    price: 17645.191406,
  },
  {
    Date: "19.11.2020",
    price: 17803.861328,
  },
  {
    Date: "20.11.2020",
    price: 17817.083984,
  },
  {
    Date: "21.11.2020",
    price: 18621.316406,
  },
  {
    Date: "22.11.2020",
    price: 18642.232422,
  },
  {
    Date: "23.11.2020",
    price: 18370.017578,
  },
  {
    Date: "24.11.2020",
    price: 18365.015625,
  },
  {
    Date: "25.11.2020",
    price: 19104.410156,
  },
  {
    Date: "26.11.2020",
    price: 18729.839844,
  },
  {
    Date: "27.11.2020",
    price: 17153.914063,
  },
  {
    Date: "28.11.2020",
    price: 17112.933594,
  },
  {
    Date: "29.11.2020",
    price: 17719.634766,
  },
  {
    Date: "30.11.2020",
    price: 18178.322266,
  },
  {
    Date: "01.12.2020",
    price: 19633.769531,
  },
  {
    Date: "02.12.2020",
    price: 18801.744141,
  },
  {
    Date: "03.12.2020",
    price: 19205.925781,
  },
  {
    Date: "04.12.2020",
    price: 19446.966797,
  },
  {
    Date: "05.12.2020",
    price: 18698.384766,
  },
  {
    Date: "06.12.2020",
    price: 19154.179688,
  },
  {
    Date: "07.12.2020",
    price: 19343.128906,
  },
  {
    Date: "08.12.2020",
    price: 19191.529297,
  },
  {
    Date: "09.12.2020",
    price: 18320.884766,
  },
  {
    Date: "10.12.2020",
    price: 18553.298828,
  },
  {
    Date: "11.12.2020",
    price: 18263.929688,
  },
  {
    Date: "12.12.2020",
    price: 18051.320313,
  },
  {
    Date: "13.12.2020",
    price: 18806.765625,
  },
  {
    Date: "14.12.2020",
    price: 19144.492188,
  },
  {
    Date: "15.12.2020",
    price: 19246.919922,
  },
  {
    Date: "16.12.2020",
    price: 19418.818359,
  },
  {
    Date: "17.12.2020",
    price: 21308.351563,
  },
  {
    Date: "18.12.2020",
    price: 22806.796875,
  },
  {
    Date: "19.12.2020",
    price: 23132.865234,
  },
  {
    Date: "20.12.2020",
    price: 23861.765625,
  },
  {
    Date: "21.12.2020",
    price: 23474.455078,
  },
  {
    Date: "22.12.2020",
    price: 22794.039063,
  },
  {
    Date: "23.12.2020",
    price: 23781.974609,
  },
  {
    Date: "24.12.2020",
    price: 23240.203125,
  },
  {
    Date: "25.12.2020",
    price: 23733.570313,
  },
  {
    Date: "26.12.2020",
    price: 24677.015625,
  },
  {
    Date: "27.12.2020",
    price: 26439.373047,
  },
  {
    Date: "28.12.2020",
    price: 26280.822266,
  },
  {
    Date: "29.12.2020",
    price: 27081.810547,
  },
  {
    Date: "30.12.2020",
    price: 27360.089844,
  },
  {
    Date: "31.12.2020",
    price: 28841.574219,
  },
  {
    Date: "01.01.2021",
    price: 28994.009766,
  },
  {
    Date: "02.01.2021",
    price: 29376.455078,
  },
  {
    Date: "03.01.2021",
    price: 32129.408203,
  },
  {
    Date: "04.01.2021",
    price: 32810.949219,
  },
  {
    Date: "05.01.2021",
    price: 31977.041016,
  },
  {
    Date: "06.01.2021",
    price: 34013.613281,
  },
  {
    Date: "07.01.2021",
    price: 36833.875,
  },
  {
    Date: "08.01.2021",
    price: 39381.765625,
  },
  {
    Date: "09.01.2021",
    price: 40788.640625,
  },
  {
    Date: "10.01.2021",
    price: 40254.21875,
  },
  {
    Date: "11.01.2021",
    price: 38346.53125,
  },
  {
    Date: "12.01.2021",
    price: 35516.359375,
  },
  {
    Date: "13.01.2021",
    price: 33915.121094,
  },
  {
    Date: "14.01.2021",
    price: 37325.109375,
  },
  {
    Date: "15.01.2021",
    price: 39156.707031,
  },
  {
    Date: "16.01.2021",
    price: 36821.648438,
  },
  {
    Date: "17.01.2021",
    price: 36163.648438,
  },
  {
    Date: "18.01.2021",
    price: 35792.238281,
  },
  {
    Date: "19.01.2021",
    price: 36642.234375,
  },
  {
    Date: "20.01.2021",
    price: 36050.113281,
  },
  {
    Date: "21.01.2021",
    price: 35549.398438,
  },
  {
    Date: "22.01.2021",
    price: 30817.625,
  },
  {
    Date: "23.01.2021",
    price: 32985.757813,
  },
  {
    Date: "24.01.2021",
    price: 32064.376953,
  },
  {
    Date: "25.01.2021",
    price: 32285.798828,
  },
  {
    Date: "26.01.2021",
    price: 32358.613281,
  },
  {
    Date: "27.01.2021",
    price: 32564.029297,
  },
  {
    Date: "28.01.2021",
    price: 30441.041016,
  },
  {
    Date: "29.01.2021",
    price: 34318.671875,
  },
  {
    Date: "30.01.2021",
    price: 34295.933594,
  },
  {
    Date: "31.01.2021",
    price: 34270.878906,
  },
  {
    Date: "01.02.2021",
    price: 33114.578125,
  },
  {
    Date: "02.02.2021",
    price: 33533.199219,
  },
  {
    Date: "03.02.2021",
    price: 35510.820313,
  },
  {
    Date: "04.02.2021",
    price: 37475.105469,
  },
  {
    Date: "05.02.2021",
    price: 36931.546875,
  },
  {
    Date: "06.02.2021",
    price: 38138.386719,
  },
  {
    Date: "07.02.2021",
    price: 39250.191406,
  },
  {
    Date: "08.02.2021",
    price: 38886.828125,
  },
  {
    Date: "09.02.2021",
    price: 46184.992188,
  },
  {
    Date: "10.02.2021",
    price: 46469.761719,
  },
  {
    Date: "11.02.2021",
    price: 44898.710938,
  },
  {
    Date: "12.02.2021",
    price: 47877.035156,
  },
  {
    Date: "13.02.2021",
    price: 47491.203125,
  },
  {
    Date: "14.02.2021",
    price: 47114.507813,
  },
  {
    Date: "15.02.2021",
    price: 48696.535156,
  },
  {
    Date: "16.02.2021",
    price: 47944.457031,
  },
  {
    Date: "17.02.2021",
    price: 49207.277344,
  },
  {
    Date: "18.02.2021",
    price: 52140.972656,
  },
  {
    Date: "19.02.2021",
    price: 51675.980469,
  },
  {
    Date: "20.02.2021",
    price: 55887.335938,
  },
  {
    Date: "21.02.2021",
    price: 56068.566406,
  },
  {
    Date: "22.02.2021",
    price: 57532.738281,
  },
  {
    Date: "23.02.2021",
    price: 54204.929688,
  },
  {
    Date: "24.02.2021",
    price: 48835.085938,
  },
  {
    Date: "25.02.2021",
    price: 49709.082031,
  },
  {
    Date: "26.02.2021",
    price: 47180.464844,
  },
  {
    Date: "27.02.2021",
    price: 46344.773438,
  },
  {
    Date: "28.02.2021",
    price: 46194.015625,
  },
  {
    Date: "01.03.2021",
    price: 45159.503906,
  },
  {
    Date: "02.03.2021",
    price: 49612.105469,
  },
  {
    Date: "03.03.2021",
    price: 48415.816406,
  },
  {
    Date: "04.03.2021",
    price: 50522.304688,
  },
  {
    Date: "05.03.2021",
    price: 48527.03125,
  },
  {
    Date: "06.03.2021",
    price: 48899.230469,
  },
  {
    Date: "07.03.2021",
    price: 48918.679688,
  },
  {
    Date: "08.03.2021",
    price: 51174.117188,
  },
  {
    Date: "09.03.2021",
    price: 52272.96875,
  },
  {
    Date: "10.03.2021",
    price: 54824.011719,
  },
  {
    Date: "11.03.2021",
    price: 55963.179688,
  },
  {
    Date: "12.03.2021",
    price: 57821.21875,
  },
  {
    Date: "13.03.2021",
    price: 57343.371094,
  },
  {
    Date: "14.03.2021",
    price: 61221.132813,
  },
  {
    Date: "15.03.2021",
    price: 59267.429688,
  },
  {
    Date: "16.03.2021",
    price: 55840.785156,
  },
  {
    Date: "17.03.2021",
    price: 56825.828125,
  },
  {
    Date: "18.03.2021",
    price: 58893.078125,
  },
  {
    Date: "19.03.2021",
    price: 57850.441406,
  },
  {
    Date: "20.03.2021",
    price: 58332.261719,
  },
  {
    Date: "21.03.2021",
    price: 58309.914063,
  },
  {
    Date: "22.03.2021",
    price: 57517.890625,
  },
  {
    Date: "23.03.2021",
    price: 54511.660156,
  },
  {
    Date: "24.03.2021",
    price: 54710.488281,
  },
  {
    Date: "25.03.2021",
    price: 52726.746094,
  },
  {
    Date: "26.03.2021",
    price: 51683.011719,
  },
  {
    Date: "27.03.2021",
    price: 55137.566406,
  },
  {
    Date: "28.03.2021",
    price: 55974.941406,
  },
  {
    Date: "29.03.2021",
    price: 55947.898438,
  },
  {
    Date: "30.03.2021",
    price: 57750.132813,
  },
  {
    Date: "31.03.2021",
    price: 58930.277344,
  },
  {
    Date: "01.04.2021",
    price: 58926.5625,
  },
  {
    Date: "02.04.2021",
    price: 59098.878906,
  },
  {
    Date: "03.04.2021",
    price: 59397.410156,
  },
  {
    Date: "04.04.2021",
    price: 57604.839844,
  },
  {
    Date: "05.04.2021",
    price: 58760.875,
  },
  {
    Date: "06.04.2021",
    price: 59171.933594,
  },
  {
    Date: "07.04.2021",
    price: 58186.507813,
  },
  {
    Date: "08.04.2021",
    price: 56099.914063,
  },
  {
    Date: "09.04.2021",
    price: 58326.5625,
  },
  {
    Date: "10.04.2021",
    price: 58253.777344,
  },
  {
    Date: "11.04.2021",
    price: 59846.230469,
  },
  {
    Date: "12.04.2021",
    price: 60175.945313,
  },
  {
    Date: "13.04.2021",
    price: 59890.019531,
  },
  {
    Date: "14.04.2021",
    price: 63523.753906,
  },
  {
    Date: "15.04.2021",
    price: 63075.195313,
  },
  {
    Date: "16.04.2021",
    price: 63258.503906,
  },
  {
    Date: "17.04.2021",
    price: 61529.921875,
  },
  {
    Date: "18.04.2021",
    price: 60701.886719,
  },
  {
    Date: "19.04.2021",
    price: 56191.585938,
  },
  {
    Date: "20.04.2021",
    price: 55681.792969,
  },
  {
    Date: "21.04.2021",
    price: 56471.128906,
  },
  {
    Date: "22.04.2021",
    price: 53857.105469,
  },
  {
    Date: "23.04.2021",
    price: 51739.808594,
  },
  {
    Date: "24.04.2021",
    price: 51143.226563,
  },
  {
    Date: "25.04.2021",
    price: 50052.832031,
  },
  {
    Date: "26.04.2021",
    price: 49077.792969,
  },
  {
    Date: "27.04.2021",
    price: 54030.304688,
  },
  {
    Date: "28.04.2021",
    price: 55036.636719,
  },
  {
    Date: "29.04.2021",
    price: 54858.089844,
  },
  {
    Date: "30.04.2021",
    price: 53568.664063,
  },
  {
    Date: "01.05.2021",
    price: 57714.664063,
  },
  {
    Date: "02.05.2021",
    price: 57825.863281,
  },
  {
    Date: "03.05.2021",
    price: 56620.273438,
  },
  {
    Date: "04.05.2021",
    price: 57214.179688,
  },
  {
    Date: "05.05.2021",
    price: 53252.164063,
  },
  {
    Date: "06.05.2021",
    price: 57441.308594,
  },
  {
    Date: "07.05.2021",
    price: 56413.953125,
  },
  {
    Date: "08.05.2021",
    price: 57352.765625,
  },
  {
    Date: "09.05.2021",
    price: 58877.390625,
  },
  {
    Date: "10.05.2021",
    price: 58250.871094,
  },
  {
    Date: "11.05.2021",
    price: 55847.242188,
  },
  {
    Date: "12.05.2021",
    price: 56714.53125,
  },
  {
    Date: "13.05.2021",
    price: 49735.433594,
  },
  {
    Date: "14.05.2021",
    price: 49682.980469,
  },
  {
    Date: "15.05.2021",
    price: 49855.496094,
  },
  {
    Date: "16.05.2021",
    price: 46716.636719,
  },
  {
    Date: "17.05.2021",
    price: 46415.898438,
  },
  {
    Date: "18.05.2021",
    price: 43488.058594,
  },
  {
    Date: "19.05.2021",
    price: 42944.976563,
  },
  {
    Date: "20.05.2021",
    price: 36753.667969,
  },
  {
    Date: "21.05.2021",
    price: 40596.949219,
  },
  {
    Date: "22.05.2021",
    price: 37371.03125,
  },
  {
    Date: "23.05.2021",
    price: 37531.449219,
  },
  {
    Date: "24.05.2021",
    price: 34700.363281,
  },
  {
    Date: "25.05.2021",
    price: 38795.78125,
  },
  {
    Date: "26.05.2021",
    price: 38392.625,
  },
  {
    Date: "27.05.2021",
    price: 39316.890625,
  },
  {
    Date: "28.05.2021",
    price: 38507.082031,
  },
  {
    Date: "29.05.2021",
    price: 35684.15625,
  },
  {
    Date: "30.05.2021",
    price: 34607.40625,
  },
  {
    Date: "31.05.2021",
    price: 35658.59375,
  },
  {
    Date: "01.06.2021",
    price: 37293.792969,
  },
  {
    Date: "02.06.2021",
    price: 36699.921875,
  },
  {
    Date: "03.06.2021",
    price: 37599.410156,
  },
  {
    Date: "04.06.2021",
    price: 39242.484375,
  },
  {
    Date: "05.06.2021",
    price: 36880.15625,
  },
  {
    Date: "06.06.2021",
    price: 35538.609375,
  },
  {
    Date: "07.06.2021",
    price: 35835.265625,
  },
  {
    Date: "08.06.2021",
    price: 33589.519531,
  },
  {
    Date: "09.06.2021",
    price: 33416.976563,
  },
  {
    Date: "10.06.2021",
    price: 37389.515625,
  },
  {
    Date: "11.06.2021",
    price: 36697.03125,
  },
  {
    Date: "12.06.2021",
    price: 37340.144531,
  },
  {
    Date: "13.06.2021",
    price: 35555.789063,
  },
  {
    Date: "14.06.2021",
    price: 39016.96875,
  },
  {
    Date: "15.06.2021",
    price: 40427.167969,
  },
  {
    Date: "16.06.2021",
    price: 40168.691406,
  },
  {
    Date: "17.06.2021",
    price: 38341.421875,
  },
  {
    Date: "18.06.2021",
    price: 38099.476563,
  },
  {
    Date: "19.06.2021",
    price: 35854.527344,
  },
  {
    Date: "20.06.2021",
    price: 35563.140625,
  },
  {
    Date: "21.06.2021",
    price: 35641.144531,
  },
  {
    Date: "22.06.2021",
    price: 31622.376953,
  },
  {
    Date: "23.06.2021",
    price: 32515.714844,
  },
  {
    Date: "24.06.2021",
    price: 33682.800781,
  },
  {
    Date: "25.06.2021",
    price: 34659.105469,
  },
  {
    Date: "26.06.2021",
    price: 31594.664063,
  },
  {
    Date: "27.06.2021",
    price: 32287.523438,
  },
  {
    Date: "28.06.2021",
    price: 34679.121094,
  },
  {
    Date: "29.06.2021",
    price: 34475.558594,
  },
  {
    Date: "30.06.2021",
    price: 35908.386719,
  },
  {
    Date: "01.07.2021",
    price: 35035.984375,
  },
  {
    Date: "02.07.2021",
    price: 33549.601563,
  },
  {
    Date: "03.07.2021",
    price: 33854.421875,
  },
  {
    Date: "04.07.2021",
    price: 34665.566406,
  },
  {
    Date: "05.07.2021",
    price: 35284.34375,
  },
  {
    Date: "06.07.2021",
    price: 33723.507813,
  },
  {
    Date: "07.07.2021",
    price: 34225.679688,
  },
  {
    Date: "08.07.2021",
    price: 33889.605469,
  },
  {
    Date: "09.07.2021",
    price: 32861.671875,
  },
  {
    Date: "10.07.2021",
    price: 33811.242188,
  },
  {
    Date: "11.07.2021",
    price: 33509.078125,
  },
  {
    Date: "12.07.2021",
    price: 34254.015625,
  },
  {
    Date: "13.07.2021",
    price: 33125.46875,
  },
  {
    Date: "14.07.2021",
    price: 32723.845703,
  },
  {
    Date: "15.07.2021",
    price: 32827.875,
  },
  {
    Date: "16.07.2021",
    price: 31841.550781,
  },
  {
    Date: "17.07.2021",
    price: 31397.308594,
  },
  {
    Date: "18.07.2021",
    price: 31533.884766,
  },
  {
    Date: "19.07.2021",
    price: 31800.011719,
  },
  {
    Date: "20.07.2021",
    price: 30838.285156,
  },
  {
    Date: "21.07.2021",
    price: 29796.285156,
  },
  {
    Date: "22.07.2021",
    price: 32138.873047,
  },
  {
    Date: "23.07.2021",
    price: 32305.958984,
  },
  {
    Date: "24.07.2021",
    price: 33593.730469,
  },
  {
    Date: "25.07.2021",
    price: 34290.292969,
  },
  {
    Date: "26.07.2021",
    price: 35384.03125,
  },
  {
    Date: "27.07.2021",
    price: 37276.035156,
  },
  {
    Date: "28.07.2021",
    price: 39503.1875,
  },
  {
    Date: "29.07.2021",
    price: 39995.453125,
  },
  {
    Date: "30.07.2021",
    price: 40027.484375,
  },
  {
    Date: "31.07.2021",
    price: 42196.304688,
  },
  {
    Date: "01.08.2021",
    price: 41460.84375,
  },
  {
    Date: "02.08.2021",
    price: 39907.261719,
  },
  {
    Date: "03.08.2021",
    price: 39178.402344,
  },
  {
    Date: "04.08.2021",
    price: 38213.332031,
  },
  {
    Date: "05.08.2021",
    price: 39744.515625,
  },
  {
    Date: "06.08.2021",
    price: 40865.867188,
  },
  {
    Date: "07.08.2021",
    price: 42832.796875,
  },
  {
    Date: "08.08.2021",
    price: 44574.4375,
  },
  {
    Date: "09.08.2021",
    price: 43791.925781,
  },
  {
    Date: "10.08.2021",
    price: 46280.847656,
  },
  {
    Date: "11.08.2021",
    price: 45599.703125,
  },
  {
    Date: "12.08.2021",
    price: 45576.878906,
  },
  {
    Date: "13.08.2021",
    price: 44439.691406,
  },
  {
    Date: "14.08.2021",
    price: 47810.6875,
  },
  {
    Date: "15.08.2021",
    price: 47096.667969,
  },
  {
    Date: "16.08.2021",
    price: 47019.960938,
  },
  {
    Date: "17.08.2021",
    price: 45936.457031,
  },
  {
    Date: "18.08.2021",
    price: 44686.75,
  },
  {
    Date: "19.08.2021",
    price: 44741.882813,
  },
  {
    Date: "20.08.2021",
    price: 46723.121094,
  },
  {
    Date: "21.08.2021",
    price: 49327.074219,
  },
  {
    Date: "22.08.2021",
    price: 48869.105469,
  },
  {
    Date: "23.08.2021",
    price: 49291.675781,
  },
  {
    Date: "24.08.2021",
    price: 49562.347656,
  },
  {
    Date: "25.08.2021",
    price: 47727.257813,
  },
  {
    Date: "26.08.2021",
    price: 49002.640625,
  },
  {
    Date: "27.08.2021",
    price: 46894.554688,
  },
  {
    Date: "28.08.2021",
    price: 49072.585938,
  },
  {
    Date: "29.08.2021",
    price: 48911.25,
  },
  {
    Date: "30.08.2021",
    price: 48834.851563,
  },
  {
    Date: "31.08.2021",
    price: 47024.339844,
  },
  {
    Date: "01.09.2021",
    price: 47099.773438,
  },
  {
    Date: "02.09.2021",
    price: 48807.847656,
  },
  {
    Date: "03.09.2021",
    price: 49288.25,
  },
  {
    Date: "04.09.2021",
    price: 50009.324219,
  },
  {
    Date: "05.09.2021",
    price: 49937.859375,
  },
  {
    Date: "06.09.2021",
    price: 51769.003906,
  },
  {
    Date: "07.09.2021",
    price: 52660.480469,
  },
  {
    Date: "08.09.2021",
    price: 46827.761719,
  },
  {
    Date: "09.09.2021",
    price: 45774.742188,
  },
  {
    Date: "10.09.2021",
    price: 46396.664063,
  },
  {
    Date: "11.09.2021",
    price: 44869.839844,
  },
  {
    Date: "12.09.2021",
    price: 45206.628906,
  },
  {
    Date: "13.09.2021",
    price: 46057.214844,
  },
  {
    Date: "14.09.2021",
    price: 44960.050781,
  },
  {
    Date: "15.09.2021",
    price: 47098,
  },
  {
    Date: "16.09.2021",
    price: 48158.90625,
  },
  {
    Date: "17.09.2021",
    price: 47771.003906,
  },
  {
    Date: "18.09.2021",
    price: 47273.527344,
  },
  {
    Date: "19.09.2021",
    price: 48268.855469,
  },
  {
    Date: "20.09.2021",
    price: 47261.40625,
  },
  {
    Date: "21.09.2021",
    price: 43012.234375,
  },
  {
    Date: "22.09.2021",
    price: 40677.953125,
  },
  {
    Date: "23.09.2021",
    price: 43560.296875,
  },
  {
    Date: "24.09.2021",
    price: 44894.300781,
  },
  {
    Date: "25.09.2021",
    price: 42840.890625,
  },
  {
    Date: "26.09.2021",
    price: 42721.628906,
  },
  {
    Date: "27.09.2021",
    price: 43234.183594,
  },
  {
    Date: "28.09.2021",
    price: 42200.898438,
  },
  {
    Date: "29.09.2021",
    price: 41064.984375,
  },
  {
    Date: "30.09.2021",
    price: 41551.269531,
  },
  {
    Date: "01.10.2021",
    price: 43816.742188,
  },
  {
    Date: "02.10.2021",
    price: 48137.46875,
  },
  {
    Date: "03.10.2021",
    price: 47680.027344,
  },
  {
    Date: "04.10.2021",
    price: 48208.90625,
  },
  {
    Date: "05.10.2021",
    price: 49174.960938,
  },
  {
    Date: "06.10.2021",
    price: 51486.664063,
  },
  {
    Date: "07.10.2021",
    price: 55338.625,
  },
  {
    Date: "08.10.2021",
    price: 53802.144531,
  },
  {
    Date: "09.10.2021",
    price: 53929.78125,
  },
  {
    Date: "10.10.2021",
    price: 54952.820313,
  },
  {
    Date: "11.10.2021",
    price: 54734.125,
  },
  {
    Date: "12.10.2021",
    price: 57526.832031,
  },
  {
    Date: "13.10.2021",
    price: 56038.257813,
  },
  {
    Date: "14.10.2021",
    price: 57372.832031,
  },
  {
    Date: "15.10.2021",
    price: 57345.902344,
  },
  {
    Date: "16.10.2021",
    price: 61609.527344,
  },
  {
    Date: "17.10.2021",
    price: 60887.652344,
  },
  {
    Date: "18.10.2021",
    price: 61548.804688,
  },
  {
    Date: "19.10.2021",
    price: 62043.164063,
  },
  {
    Date: "20.10.2021",
    price: 64284.585938,
  },
  {
    Date: "21.10.2021",
    price: 66002.234375,
  },
  {
    Date: "22.10.2021",
    price: 62237.890625,
  },
  {
    Date: "23.10.2021",
    price: 60694.628906,
  },
  {
    Date: "24.10.2021",
    price: 61368.34375,
  },
  {
    Date: "25.10.2021",
    price: 60893.925781,
  },
  {
    Date: "26.10.2021",
    price: 63032.761719,
  },
  {
    Date: "27.10.2021",
    price: 60352,
  },
  {
    Date: "28.10.2021",
    price: 58470.730469,
  },
  {
    Date: "29.10.2021",
    price: 60624.871094,
  },
  {
    Date: "30.10.2021",
    price: 62239.363281,
  },
  {
    Date: "31.10.2021",
    price: 61850.488281,
  },
  {
    Date: "01.11.2021",
    price: 61320.449219,
  },
  {
    Date: "02.11.2021",
    price: 60963.253906,
  },
  {
    Date: "03.11.2021",
    price: 63254.335938,
  },
  {
    Date: "04.11.2021",
    price: 62941.804688,
  },
  {
    Date: "05.11.2021",
    price: 61460.078125,
  },
  {
    Date: "06.11.2021",
    price: 61068.875,
  },
  {
    Date: "07.11.2021",
    price: 61554.921875,
  },
  {
    Date: "08.11.2021",
    price: 63344.066406,
  },
  {
    Date: "09.11.2021",
    price: 67549.734375,
  },
  {
    Date: "10.11.2021",
    price: 66953.335938,
  },
  {
    Date: "11.11.2021",
    price: 64978.890625,
  },
  {
    Date: "12.11.2021",
    price: 64863.980469,
  },
  {
    Date: "13.11.2021",
    price: 64158.121094,
  },
  {
    Date: "14.11.2021",
    price: 64455.371094,
  },
  {
    Date: "15.11.2021",
    price: 65521.289063,
  },
  {
    Date: "16.11.2021",
    price: 63721.195313,
  },
  {
    Date: "17.11.2021",
    price: 60139.621094,
  },
  {
    Date: "18.11.2021",
    price: 60360.136719,
  },
  {
    Date: "19.11.2021",
    price: 56896.128906,
  },
  {
    Date: "20.11.2021",
    price: 58115.082031,
  },
  {
    Date: "21.11.2021",
    price: 59730.507813,
  },
  {
    Date: "22.11.2021",
    price: 58706.847656,
  },
  {
    Date: "23.11.2021",
    price: 56304.554688,
  },
  {
    Date: "24.11.2021",
    price: 57565.851563,
  },
  {
    Date: "25.11.2021",
    price: 57165.417969,
  },
  {
    Date: "26.11.2021",
    price: 58960.285156,
  },
  {
    Date: "27.11.2021",
    price: 53736.429688,
  },
  {
    Date: "28.11.2021",
    price: 54813.023438,
  },
  {
    Date: "29.11.2021",
    price: 57291.90625,
  },
  {
    Date: "30.11.2021",
    price: 57830.113281,
  },
  {
    Date: "01.12.2021",
    price: 56907.964844,
  },
  {
    Date: "02.12.2021",
    price: 57217.371094,
  },
  {
    Date: "03.12.2021",
    price: 56509.164063,
  },
  {
    Date: "04.12.2021",
    price: 53727.878906,
  },
  {
    Date: "05.12.2021",
    price: 49201.519531,
  },
  {
    Date: "06.12.2021",
    price: 49413.480469,
  },
  {
    Date: "07.12.2021",
    price: 50581.828125,
  },
  {
    Date: "08.12.2021",
    price: 50667.648438,
  },
  {
    Date: "09.12.2021",
    price: 50450.082031,
  },
  {
    Date: "10.12.2021",
    price: 47642.144531,
  },
  {
    Date: "11.12.2021",
    price: 47264.632813,
  },
  {
    Date: "12.12.2021",
    price: 49354.855469,
  },
  {
    Date: "13.12.2021",
    price: 50114.742188,
  },
  {
    Date: "14.12.2021",
    price: 46709.824219,
  },
  {
    Date: "15.12.2021",
    price: 48379.753906,
  },
  {
    Date: "16.12.2021",
    price: 48900.464844,
  },
  {
    Date: "17.12.2021",
    price: 47653.730469,
  },
  {
    Date: "18.12.2021",
    price: 46219.253906,
  },
  {
    Date: "19.12.2021",
    price: 46853.867188,
  },
  {
    Date: "20.12.2021",
    price: 46707.0625,
  },
  {
    Date: "21.12.2021",
    price: 46886.078125,
  },
  {
    Date: "22.12.2021",
    price: 48937.097656,
  },
  {
    Date: "23.12.2021",
    price: 48626.34375,
  },
  {
    Date: "24.12.2021",
    price: 50806.050781,
  },
  {
    Date: "25.12.2021",
    price: 50854.917969,
  },
  {
    Date: "26.12.2021",
    price: 50428.691406,
  },
  {
    Date: "27.12.2021",
    price: 50802.609375,
  },
  {
    Date: "28.12.2021",
    price: 50679.859375,
  },
  {
    Date: "29.12.2021",
    price: 47623.871094,
  },
  {
    Date: "30.12.2021",
    price: 46490.605469,
  },
  {
    Date: "31.12.2021",
    price: 47169.371094,
  },
  {
    Date: "01.01.2022",
    price: 46311.746094,
  },
  {
    Date: "02.01.2022",
    price: 47680.925781,
  },
  {
    Date: "03.01.2022",
    price: 47343.542969,
  },
  {
    Date: "04.01.2022",
    price: 46458.851563,
  },
  {
    Date: "05.01.2022",
    price: 45899.359375,
  },
  {
    Date: "06.01.2022",
    price: 43565.511719,
  },
  {
    Date: "07.01.2022",
    price: 43153.570313,
  },
  {
    Date: "08.01.2022",
    price: 41561.464844,
  },
  {
    Date: "09.01.2022",
    price: 41734.726563,
  },
  {
    Date: "10.01.2022",
    price: 41910.230469,
  },
  {
    Date: "11.01.2022",
    price: 41819.507813,
  },
  {
    Date: "12.01.2022",
    price: 42742.179688,
  },
  {
    Date: "13.01.2022",
    price: 43946.742188,
  },
  {
    Date: "14.01.2022",
    price: 42598.871094,
  },
  {
    Date: "15.01.2022",
    price: 43101.898438,
  },
  {
    Date: "16.01.2022",
    price: 43172.039063,
  },
  {
    Date: "17.01.2022",
    price: 43118.121094,
  },
  {
    Date: "18.01.2022",
    price: 42250.074219,
  },
  {
    Date: "19.01.2022",
    price: 42374.039063,
  },
  {
    Date: "20.01.2022",
    price: 41744.027344,
  },
  {
    Date: "21.01.2022",
    price: 40699.605469,
  },
  {
    Date: "22.01.2022",
    price: 36471.589844,
  },
  {
    Date: "23.01.2022",
    price: 35047.359375,
  },
  {
    Date: "24.01.2022",
    price: 36275.734375,
  },
  {
    Date: "25.01.2022",
    price: 36654.804688,
  },
  {
    Date: "26.01.2022",
    price: 36950.515625,
  },
  {
    Date: "27.01.2022",
    price: 36841.878906,
  },
  {
    Date: "28.01.2022",
    price: 37128.445313,
  },
  {
    Date: "29.01.2022",
    price: 37780.714844,
  },
  {
    Date: "30.01.2022",
    price: 38151.917969,
  },
  {
    Date: "31.01.2022",
    price: 37920.28125,
  },
  {
    Date: "01.02.2022",
    price: 38481.765625,
  },
  {
    Date: "02.02.2022",
    price: 38743.714844,
  },
  {
    Date: "03.02.2022",
    price: 36944.804688,
  },
  {
    Date: "04.02.2022",
    price: 37149.265625,
  },
  {
    Date: "05.02.2022",
    price: 41501.480469,
  },
  {
    Date: "06.02.2022",
    price: 41441.121094,
  },
  {
    Date: "07.02.2022",
    price: 42406.78125,
  },
  {
    Date: "08.02.2022",
    price: 43854.652344,
  },
  {
    Date: "09.02.2022",
    price: 44096.703125,
  },
  {
    Date: "10.02.2022",
    price: 44347.800781,
  },
  {
    Date: "11.02.2022",
    price: 43571.128906,
  },
  {
    Date: "12.02.2022",
    price: 42412.300781,
  },
  {
    Date: "13.02.2022",
    price: 42236.566406,
  },
  {
    Date: "14.02.2022",
    price: 42157.398438,
  },
  {
    Date: "15.02.2022",
    price: 42586.464844,
  },
  {
    Date: "16.02.2022",
    price: 44578.277344,
  },
  {
    Date: "17.02.2022",
    price: 43937.070313,
  },
  {
    Date: "18.02.2022",
    price: 40552.132813,
  },
  {
    Date: "19.02.2022",
    price: 40026.023438,
  },
  {
    Date: "20.02.2022",
    price: 40118.101563,
  },
  {
    Date: "21.02.2022",
    price: 38423.210938,
  },
  {
    Date: "22.02.2022",
    price: 37068.769531,
  },
  {
    Date: "23.02.2022",
    price: 38285.28125,
  },
  {
    Date: "24.02.2022",
    price: 37278.566406,
  },
  {
    Date: "25.02.2022",
    price: 38333.746094,
  },
  {
    Date: "26.02.2022",
    price: 39213.082031,
  },
  {
    Date: "27.02.2022",
    price: 39098.699219,
  },
  {
    Date: "28.02.2022",
    price: 37706,
  },
  {
    Date: "01.03.2022",
    price: 43194.503906,
  },
  {
    Date: "02.03.2022",
    price: 44357.617188,
  },
  {
    Date: "03.03.2022",
    price: 43925.195313,
  },
  {
    Date: "04.03.2022",
    price: 42458.140625,
  },
  {
    Date: "05.03.2022",
    price: 39148.449219,
  },
  {
    Date: "06.03.2022",
    price: 39404.199219,
  },
  {
    Date: "07.03.2022",
    price: 38429.304688,
  },
  {
    Date: "08.03.2022",
    price: 38059.902344,
  },
  {
    Date: "09.03.2022",
    price: 38742.816406,
  },
  {
    Date: "10.03.2022",
    price: 41974.070313,
  },
  {
    Date: "11.03.2022",
    price: 39439.96875,
  },
  {
    Date: "12.03.2022",
    price: 38794.464844,
  },
  {
    Date: "13.03.2022",
    price: 38884.726563,
  },
  {
    Date: "14.03.2022",
    price: 37846.316406,
  },
  {
    Date: "15.03.2022",
    price: 39664.25,
  },
  {
    Date: "16.03.2022",
    price: 39335.570313,
  },
  {
    Date: "17.03.2022",
    price: 41140.84375,
  },
  {
    Date: "18.03.2022",
    price: 40944.839844,
  },
  {
    Date: "19.03.2022",
    price: 41794.648438,
  },
  {
    Date: "20.03.2022",
    price: 42191.40625,
  },
  {
    Date: "21.03.2022",
    price: 41246.132813,
  },
  {
    Date: "22.03.2022",
    price: 41074.105469,
  },
  {
    Date: "23.03.2022",
    price: 42364.378906,
  },
  {
    Date: "24.03.2022",
    price: 42886.652344,
  },
  {
    Date: "25.03.2022",
    price: 43964.546875,
  },
  {
    Date: "26.03.2022",
    price: 44349.859375,
  },
  {
    Date: "27.03.2022",
    price: 44505.355469,
  },
  {
    Date: "28.03.2022",
    price: 46821.851563,
  },
  {
    Date: "29.03.2022",
    price: 47100.4375,
  },
  {
    Date: "30.03.2022",
    price: 47456.898438,
  },
  {
    Date: "31.03.2022",
    price: 47062.148438,
  },
  {
    Date: "01.04.2022",
    price: 45554.164063,
  },
  {
    Date: "02.04.2022",
    price: 46285.5,
  },
  {
    Date: "03.04.2022",
    price: 45859.128906,
  },
  {
    Date: "04.04.2022",
    price: 46445.273438,
  },
  {
    Date: "05.04.2022",
    price: 46624.507813,
  },
  {
    Date: "06.04.2022",
    price: 45544.355469,
  },
  {
    Date: "07.04.2022",
    price: 43207.5,
  },
  {
    Date: "08.04.2022",
    price: 43505.136719,
  },
  {
    Date: "09.04.2022",
    price: 42282.078125,
  },
  {
    Date: "10.04.2022",
    price: 42781.089844,
  },
  {
    Date: "11.04.2022",
    price: 42201.039063,
  },
  {
    Date: "12.04.2022",
    price: 39533.714844,
  },
  {
    Date: "13.04.2022",
    price: 40123.570313,
  },
  {
    Date: "14.04.2022",
    price: 41160.21875,
  },
  {
    Date: "15.04.2022",
    price: 39939.402344,
  },
  {
    Date: "16.04.2022",
    price: 40552.316406,
  },
  {
    Date: "17.04.2022",
    price: 40417.777344,
  },
  {
    Date: "18.04.2022",
    price: 39721.203125,
  },
  {
    Date: "19.04.2022",
    price: 40828.175781,
  },
  {
    Date: "20.04.2022",
    price: 41501.746094,
  },
  {
    Date: "21.04.2022",
    price: 41371.515625,
  },
  {
    Date: "22.04.2022",
    price: 40525.863281,
  },
  {
    Date: "23.04.2022",
    price: 39738.722656,
  },
  {
    Date: "24.04.2022",
    price: 39478.375,
  },
  {
    Date: "25.04.2022",
    price: 39472.605469,
  },
  {
    Date: "26.04.2022",
    price: 40448.421875,
  },
  {
    Date: "27.04.2022",
    price: 38120.300781,
  },
  {
    Date: "28.04.2022",
    price: 39241.429688,
  },
  {
    Date: "29.04.2022",
    price: 39768.617188,
  },
  {
    Date: "30.04.2022",
    price: 38605.859375,
  },
  {
    Date: "01.05.2022",
    price: 37713.265625,
  },
  {
    Date: "02.05.2022",
    price: 38472.1875,
  },
  {
    Date: "03.05.2022",
    price: 38528.109375,
  },
  {
    Date: "04.05.2022",
    price: 37748.011719,
  },
  {
    Date: "05.05.2022",
    price: 39695.746094,
  },
  {
    Date: "06.05.2022",
    price: 36573.183594,
  },
  {
    Date: "07.05.2022",
    price: 36042.503906,
  },
  {
    Date: "08.05.2022",
    price: 35502.941406,
  },
  {
    Date: "09.05.2022",
    price: 34060.015625,
  },
  {
    Date: "10.05.2022",
    price: 30273.654297,
  },
  {
    Date: "11.05.2022",
    price: 31016.183594,
  },
  {
    Date: "12.05.2022",
    price: 28936.734375,
  },
  {
    Date: "13.05.2022",
    price: 29030.910156,
  },
  {
    Date: "14.05.2022",
    price: 29285.642578,
  },
  {
    Date: "15.05.2022",
    price: 30098.585938,
  },
  {
    Date: "16.05.2022",
    price: 31304.375,
  },
  {
    Date: "17.05.2022",
    price: 29862.408203,
  },
  {
    Date: "18.05.2022",
    price: 30424.478516,
  },
  {
    Date: "19.05.2022",
    price: 28720.359375,
  },
  {
    Date: "20.05.2022",
    price: 30311.119141,
  },
  {
    Date: "21.05.2022",
    price: 29199.859375,
  },
  {
    Date: "22.05.2022",
    price: 29432.472656,
  },
  {
    Date: "23.05.2022",
    price: 30309.396484,
  },
  {
    Date: "24.05.2022",
    price: 29101.125,
  },
  {
    Date: "25.05.2022",
    price: 29653.134766,
  },
  {
    Date: "26.05.2022",
    price: 29564.777344,
  },
  {
    Date: "27.05.2022",
    price: 29251.140625,
  },
  {
    Date: "28.05.2022",
    price: 28622.625,
  },
  {
    Date: "29.05.2022",
    price: 29019.867188,
  },
  {
    Date: "30.05.2022",
    price: 29443.365234,
  },
  {
    Date: "31.05.2022",
    price: 31723.865234,
  },
  {
    Date: "01.06.2022",
    price: 31792.554688,
  },
  {
    Date: "02.06.2022",
    price: 29794.890625,
  },
  {
    Date: "03.06.2022",
    price: 30467.806641,
  },
  {
    Date: "04.06.2022",
    price: 29706.138672,
  },
  {
    Date: "05.06.2022",
    price: 29835.117188,
  },
  {
    Date: "06.06.2022",
    price: 29910.283203,
  },
  {
    Date: "07.06.2022",
    price: 31371.742188,
  },
  {
    Date: "08.06.2022",
    price: 31151.480469,
  },
  {
    Date: "09.06.2022",
    price: 30215.279297,
  },
  {
    Date: "10.06.2022",
    price: 30110.330078,
  },
  {
    Date: "11.06.2022",
    price: 29084.666016,
  },
  {
    Date: "12.06.2022",
    price: 28373.513672,
  },
  {
    Date: "13.06.2022",
    price: 26737.578125,
  },
  {
    Date: "14.06.2022",
    price: 22487.986328,
  },
  {
    Date: "15.06.2022",
    price: 22196.730469,
  },
  {
    Date: "16.06.2022",
    price: 22576.304688,
  },
  {
    Date: "17.06.2022",
    price: 20385.71875,
  },
  {
    Date: "18.06.2022",
    price: 20473.425781,
  },
  {
    Date: "19.06.2022",
    price: 19010.902344,
  },
  {
    Date: "20.06.2022",
    price: 20553.371094,
  },
  {
    Date: "21.06.2022",
    price: 20594.294922,
  },
  {
    Date: "22.06.2022",
    price: 20719.414063,
  },
  {
    Date: "23.06.2022",
    price: 19986.607422,
  },
  {
    Date: "24.06.2022",
    price: 21084.648438,
  },
  {
    Date: "25.06.2022",
    price: 21233.609375,
  },
  {
    Date: "26.06.2022",
    price: 21496.494141,
  },
  {
    Date: "27.06.2022",
    price: 21028.238281,
  },
  {
    Date: "28.06.2022",
    price: 20731.544922,
  },
  {
    Date: "29.06.2022",
    price: 20281.169922,
  },
  {
    Date: "30.06.2022",
    price: 20108.3125,
  },
  {
    Date: "01.07.2022",
    price: 19820.470703,
  },
  {
    Date: "02.07.2022",
    price: 19274.835938,
  },
  {
    Date: "03.07.2022",
    price: 19242.095703,
  },
  {
    Date: "04.07.2022",
    price: 19297.314453,
  },
  {
    Date: "05.07.2022",
    price: 20200.667969,
  },
];
