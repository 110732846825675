import styled from "styled-components";

export const GameTitleContainer = styled.div`
  //background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: black;

  /* @media (max-width: 1024px) {
    font-size: 17px;
    width: 98%;
    margin-top: 0;
    height: 10%;
  } */
`;
