export const MATICpriceHistory = [
  {
    Date: "17.09.2014",
    price: 0,
  },
  {
    Date: "18.09.2014",
    price: 0,
  },
  {
    Date: "19.09.2014",
    price: 0,
  },
  {
    Date: "20.09.2014",
    price: 0,
  },
  {
    Date: "21.09.2014",
    price: 0,
  },
  {
    Date: "22.09.2014",
    price: 0,
  },
  {
    Date: "23.09.2014",
    price: 0,
  },
  {
    Date: "24.09.2014",
    price: 0,
  },
  {
    Date: "25.09.2014",
    price: 0,
  },
  {
    Date: "26.09.2014",
    price: 0,
  },
  {
    Date: "27.09.2014",
    price: 0,
  },
  {
    Date: "28.09.2014",
    price: 0,
  },
  {
    Date: "29.09.2014",
    price: 0,
  },
  {
    Date: "30.09.2014",
    price: 0,
  },
  {
    Date: "01.10.2014",
    price: 0,
  },
  {
    Date: "02.10.2014",
    price: 0,
  },
  {
    Date: "03.10.2014",
    price: 0,
  },
  {
    Date: "04.10.2014",
    price: 0,
  },
  {
    Date: "05.10.2014",
    price: 0,
  },
  {
    Date: "06.10.2014",
    price: 0,
  },
  {
    Date: "07.10.2014",
    price: 0,
  },
  {
    Date: "08.10.2014",
    price: 0,
  },
  {
    Date: "09.10.2014",
    price: 0,
  },
  {
    Date: "10.10.2014",
    price: 0,
  },
  {
    Date: "11.10.2014",
    price: 0,
  },
  {
    Date: "12.10.2014",
    price: 0,
  },
  {
    Date: "13.10.2014",
    price: 0,
  },
  {
    Date: "14.10.2014",
    price: 0,
  },
  {
    Date: "15.10.2014",
    price: 0,
  },
  {
    Date: "16.10.2014",
    price: 0,
  },
  {
    Date: "17.10.2014",
    price: 0,
  },
  {
    Date: "18.10.2014",
    price: 0,
  },
  {
    Date: "19.10.2014",
    price: 0,
  },
  {
    Date: "20.10.2014",
    price: 0,
  },
  {
    Date: "21.10.2014",
    price: 0,
  },
  {
    Date: "22.10.2014",
    price: 0,
  },
  {
    Date: "23.10.2014",
    price: 0,
  },
  {
    Date: "24.10.2014",
    price: 0,
  },
  {
    Date: "25.10.2014",
    price: 0,
  },
  {
    Date: "26.10.2014",
    price: 0,
  },
  {
    Date: "27.10.2014",
    price: 0,
  },
  {
    Date: "28.10.2014",
    price: 0,
  },
  {
    Date: "29.10.2014",
    price: 0,
  },
  {
    Date: "30.10.2014",
    price: 0,
  },
  {
    Date: "31.10.2014",
    price: 0,
  },
  {
    Date: "01.11.2014",
    price: 0,
  },
  {
    Date: "02.11.2014",
    price: 0,
  },
  {
    Date: "03.11.2014",
    price: 0,
  },
  {
    Date: "04.11.2014",
    price: 0,
  },
  {
    Date: "05.11.2014",
    price: 0,
  },
  {
    Date: "06.11.2014",
    price: 0,
  },
  {
    Date: "07.11.2014",
    price: 0,
  },
  {
    Date: "08.11.2014",
    price: 0,
  },
  {
    Date: "09.11.2014",
    price: 0,
  },
  {
    Date: "10.11.2014",
    price: 0,
  },
  {
    Date: "11.11.2014",
    price: 0,
  },
  {
    Date: "12.11.2014",
    price: 0,
  },
  {
    Date: "13.11.2014",
    price: 0,
  },
  {
    Date: "14.11.2014",
    price: 0,
  },
  {
    Date: "15.11.2014",
    price: 0,
  },
  {
    Date: "16.11.2014",
    price: 0,
  },
  {
    Date: "17.11.2014",
    price: 0,
  },
  {
    Date: "18.11.2014",
    price: 0,
  },
  {
    Date: "19.11.2014",
    price: 0,
  },
  {
    Date: "20.11.2014",
    price: 0,
  },
  {
    Date: "21.11.2014",
    price: 0,
  },
  {
    Date: "22.11.2014",
    price: 0,
  },
  {
    Date: "23.11.2014",
    price: 0,
  },
  {
    Date: "24.11.2014",
    price: 0,
  },
  {
    Date: "25.11.2014",
    price: 0,
  },
  {
    Date: "26.11.2014",
    price: 0,
  },
  {
    Date: "27.11.2014",
    price: 0,
  },
  {
    Date: "28.11.2014",
    price: 0,
  },
  {
    Date: "29.11.2014",
    price: 0,
  },
  {
    Date: "30.11.2014",
    price: 0,
  },
  {
    Date: "01.12.2014",
    price: 0,
  },
  {
    Date: "02.12.2014",
    price: 0,
  },
  {
    Date: "03.12.2014",
    price: 0,
  },
  {
    Date: "04.12.2014",
    price: 0,
  },
  {
    Date: "05.12.2014",
    price: 0,
  },
  {
    Date: "06.12.2014",
    price: 0,
  },
  {
    Date: "07.12.2014",
    price: 0,
  },
  {
    Date: "08.12.2014",
    price: 0,
  },
  {
    Date: "09.12.2014",
    price: 0,
  },
  {
    Date: "10.12.2014",
    price: 0,
  },
  {
    Date: "11.12.2014",
    price: 0,
  },
  {
    Date: "12.12.2014",
    price: 0,
  },
  {
    Date: "13.12.2014",
    price: 0,
  },
  {
    Date: "14.12.2014",
    price: 0,
  },
  {
    Date: "15.12.2014",
    price: 0,
  },
  {
    Date: "16.12.2014",
    price: 0,
  },
  {
    Date: "17.12.2014",
    price: 0,
  },
  {
    Date: "18.12.2014",
    price: 0,
  },
  {
    Date: "19.12.2014",
    price: 0,
  },
  {
    Date: "20.12.2014",
    price: 0,
  },
  {
    Date: "21.12.2014",
    price: 0,
  },
  {
    Date: "22.12.2014",
    price: 0,
  },
  {
    Date: "23.12.2014",
    price: 0,
  },
  {
    Date: "24.12.2014",
    price: 0,
  },
  {
    Date: "25.12.2014",
    price: 0,
  },
  {
    Date: "26.12.2014",
    price: 0,
  },
  {
    Date: "27.12.2014",
    price: 0,
  },
  {
    Date: "28.12.2014",
    price: 0,
  },
  {
    Date: "29.12.2014",
    price: 0,
  },
  {
    Date: "30.12.2014",
    price: 0,
  },
  {
    Date: "31.12.2014",
    price: 0,
  },
  {
    Date: "01.01.2015",
    price: 0,
  },
  {
    Date: "02.01.2015",
    price: 0,
  },
  {
    Date: "03.01.2015",
    price: 0,
  },
  {
    Date: "04.01.2015",
    price: 0,
  },
  {
    Date: "05.01.2015",
    price: 0,
  },
  {
    Date: "06.01.2015",
    price: 0,
  },
  {
    Date: "07.01.2015",
    price: 0,
  },
  {
    Date: "08.01.2015",
    price: 0,
  },
  {
    Date: "09.01.2015",
    price: 0,
  },
  {
    Date: "10.01.2015",
    price: 0,
  },
  {
    Date: "11.01.2015",
    price: 0,
  },
  {
    Date: "12.01.2015",
    price: 0,
  },
  {
    Date: "13.01.2015",
    price: 0,
  },
  {
    Date: "14.01.2015",
    price: 0,
  },
  {
    Date: "15.01.2015",
    price: 0,
  },
  {
    Date: "16.01.2015",
    price: 0,
  },
  {
    Date: "17.01.2015",
    price: 0,
  },
  {
    Date: "18.01.2015",
    price: 0,
  },
  {
    Date: "19.01.2015",
    price: 0,
  },
  {
    Date: "20.01.2015",
    price: 0,
  },
  {
    Date: "21.01.2015",
    price: 0,
  },
  {
    Date: "22.01.2015",
    price: 0,
  },
  {
    Date: "23.01.2015",
    price: 0,
  },
  {
    Date: "24.01.2015",
    price: 0,
  },
  {
    Date: "25.01.2015",
    price: 0,
  },
  {
    Date: "26.01.2015",
    price: 0,
  },
  {
    Date: "27.01.2015",
    price: 0,
  },
  {
    Date: "28.01.2015",
    price: 0,
  },
  {
    Date: "29.01.2015",
    price: 0,
  },
  {
    Date: "30.01.2015",
    price: 0,
  },
  {
    Date: "31.01.2015",
    price: 0,
  },
  {
    Date: "01.02.2015",
    price: 0,
  },
  {
    Date: "02.02.2015",
    price: 0,
  },
  {
    Date: "03.02.2015",
    price: 0,
  },
  {
    Date: "04.02.2015",
    price: 0,
  },
  {
    Date: "05.02.2015",
    price: 0,
  },
  {
    Date: "06.02.2015",
    price: 0,
  },
  {
    Date: "07.02.2015",
    price: 0,
  },
  {
    Date: "08.02.2015",
    price: 0,
  },
  {
    Date: "09.02.2015",
    price: 0,
  },
  {
    Date: "10.02.2015",
    price: 0,
  },
  {
    Date: "11.02.2015",
    price: 0,
  },
  {
    Date: "12.02.2015",
    price: 0,
  },
  {
    Date: "13.02.2015",
    price: 0,
  },
  {
    Date: "14.02.2015",
    price: 0,
  },
  {
    Date: "15.02.2015",
    price: 0,
  },
  {
    Date: "16.02.2015",
    price: 0,
  },
  {
    Date: "17.02.2015",
    price: 0,
  },
  {
    Date: "18.02.2015",
    price: 0,
  },
  {
    Date: "19.02.2015",
    price: 0,
  },
  {
    Date: "20.02.2015",
    price: 0,
  },
  {
    Date: "21.02.2015",
    price: 0,
  },
  {
    Date: "22.02.2015",
    price: 0,
  },
  {
    Date: "23.02.2015",
    price: 0,
  },
  {
    Date: "24.02.2015",
    price: 0,
  },
  {
    Date: "25.02.2015",
    price: 0,
  },
  {
    Date: "26.02.2015",
    price: 0,
  },
  {
    Date: "27.02.2015",
    price: 0,
  },
  {
    Date: "28.02.2015",
    price: 0,
  },
  {
    Date: "01.03.2015",
    price: 0,
  },
  {
    Date: "02.03.2015",
    price: 0,
  },
  {
    Date: "03.03.2015",
    price: 0,
  },
  {
    Date: "04.03.2015",
    price: 0,
  },
  {
    Date: "05.03.2015",
    price: 0,
  },
  {
    Date: "06.03.2015",
    price: 0,
  },
  {
    Date: "07.03.2015",
    price: 0,
  },
  {
    Date: "08.03.2015",
    price: 0,
  },
  {
    Date: "09.03.2015",
    price: 0,
  },
  {
    Date: "10.03.2015",
    price: 0,
  },
  {
    Date: "11.03.2015",
    price: 0,
  },
  {
    Date: "12.03.2015",
    price: 0,
  },
  {
    Date: "13.03.2015",
    price: 0,
  },
  {
    Date: "14.03.2015",
    price: 0,
  },
  {
    Date: "15.03.2015",
    price: 0,
  },
  {
    Date: "16.03.2015",
    price: 0,
  },
  {
    Date: "17.03.2015",
    price: 0,
  },
  {
    Date: "18.03.2015",
    price: 0,
  },
  {
    Date: "19.03.2015",
    price: 0,
  },
  {
    Date: "20.03.2015",
    price: 0,
  },
  {
    Date: "21.03.2015",
    price: 0,
  },
  {
    Date: "22.03.2015",
    price: 0,
  },
  {
    Date: "23.03.2015",
    price: 0,
  },
  {
    Date: "24.03.2015",
    price: 0,
  },
  {
    Date: "25.03.2015",
    price: 0,
  },
  {
    Date: "26.03.2015",
    price: 0,
  },
  {
    Date: "27.03.2015",
    price: 0,
  },
  {
    Date: "28.03.2015",
    price: 0,
  },
  {
    Date: "29.03.2015",
    price: 0,
  },
  {
    Date: "30.03.2015",
    price: 0,
  },
  {
    Date: "31.03.2015",
    price: 0,
  },
  {
    Date: "01.04.2015",
    price: 0,
  },
  {
    Date: "02.04.2015",
    price: 0,
  },
  {
    Date: "03.04.2015",
    price: 0,
  },
  {
    Date: "04.04.2015",
    price: 0,
  },
  {
    Date: "05.04.2015",
    price: 0,
  },
  {
    Date: "06.04.2015",
    price: 0,
  },
  {
    Date: "07.04.2015",
    price: 0,
  },
  {
    Date: "08.04.2015",
    price: 0,
  },
  {
    Date: "09.04.2015",
    price: 0,
  },
  {
    Date: "10.04.2015",
    price: 0,
  },
  {
    Date: "11.04.2015",
    price: 0,
  },
  {
    Date: "12.04.2015",
    price: 0,
  },
  {
    Date: "13.04.2015",
    price: 0,
  },
  {
    Date: "14.04.2015",
    price: 0,
  },
  {
    Date: "15.04.2015",
    price: 0,
  },
  {
    Date: "16.04.2015",
    price: 0,
  },
  {
    Date: "17.04.2015",
    price: 0,
  },
  {
    Date: "18.04.2015",
    price: 0,
  },
  {
    Date: "19.04.2015",
    price: 0,
  },
  {
    Date: "20.04.2015",
    price: 0,
  },
  {
    Date: "21.04.2015",
    price: 0,
  },
  {
    Date: "22.04.2015",
    price: 0,
  },
  {
    Date: "23.04.2015",
    price: 0,
  },
  {
    Date: "24.04.2015",
    price: 0,
  },
  {
    Date: "25.04.2015",
    price: 0,
  },
  {
    Date: "26.04.2015",
    price: 0,
  },
  {
    Date: "27.04.2015",
    price: 0,
  },
  {
    Date: "28.04.2015",
    price: 0,
  },
  {
    Date: "29.04.2015",
    price: 0,
  },
  {
    Date: "30.04.2015",
    price: 0,
  },
  {
    Date: "01.05.2015",
    price: 0,
  },
  {
    Date: "02.05.2015",
    price: 0,
  },
  {
    Date: "03.05.2015",
    price: 0,
  },
  {
    Date: "04.05.2015",
    price: 0,
  },
  {
    Date: "05.05.2015",
    price: 0,
  },
  {
    Date: "06.05.2015",
    price: 0,
  },
  {
    Date: "07.05.2015",
    price: 0,
  },
  {
    Date: "08.05.2015",
    price: 0,
  },
  {
    Date: "09.05.2015",
    price: 0,
  },
  {
    Date: "10.05.2015",
    price: 0,
  },
  {
    Date: "11.05.2015",
    price: 0,
  },
  {
    Date: "12.05.2015",
    price: 0,
  },
  {
    Date: "13.05.2015",
    price: 0,
  },
  {
    Date: "14.05.2015",
    price: 0,
  },
  {
    Date: "15.05.2015",
    price: 0,
  },
  {
    Date: "16.05.2015",
    price: 0,
  },
  {
    Date: "17.05.2015",
    price: 0,
  },
  {
    Date: "18.05.2015",
    price: 0,
  },
  {
    Date: "19.05.2015",
    price: 0,
  },
  {
    Date: "20.05.2015",
    price: 0,
  },
  {
    Date: "21.05.2015",
    price: 0,
  },
  {
    Date: "22.05.2015",
    price: 0,
  },
  {
    Date: "23.05.2015",
    price: 0,
  },
  {
    Date: "24.05.2015",
    price: 0,
  },
  {
    Date: "25.05.2015",
    price: 0,
  },
  {
    Date: "26.05.2015",
    price: 0,
  },
  {
    Date: "27.05.2015",
    price: 0,
  },
  {
    Date: "28.05.2015",
    price: 0,
  },
  {
    Date: "29.05.2015",
    price: 0,
  },
  {
    Date: "30.05.2015",
    price: 0,
  },
  {
    Date: "31.05.2015",
    price: 0,
  },
  {
    Date: "01.06.2015",
    price: 0,
  },
  {
    Date: "02.06.2015",
    price: 0,
  },
  {
    Date: "03.06.2015",
    price: 0,
  },
  {
    Date: "04.06.2015",
    price: 0,
  },
  {
    Date: "05.06.2015",
    price: 0,
  },
  {
    Date: "06.06.2015",
    price: 0,
  },
  {
    Date: "07.06.2015",
    price: 0,
  },
  {
    Date: "08.06.2015",
    price: 0,
  },
  {
    Date: "09.06.2015",
    price: 0,
  },
  {
    Date: "10.06.2015",
    price: 0,
  },
  {
    Date: "11.06.2015",
    price: 0,
  },
  {
    Date: "12.06.2015",
    price: 0,
  },
  {
    Date: "13.06.2015",
    price: 0,
  },
  {
    Date: "14.06.2015",
    price: 0,
  },
  {
    Date: "15.06.2015",
    price: 0,
  },
  {
    Date: "16.06.2015",
    price: 0,
  },
  {
    Date: "17.06.2015",
    price: 0,
  },
  {
    Date: "18.06.2015",
    price: 0,
  },
  {
    Date: "19.06.2015",
    price: 0,
  },
  {
    Date: "20.06.2015",
    price: 0,
  },
  {
    Date: "21.06.2015",
    price: 0,
  },
  {
    Date: "22.06.2015",
    price: 0,
  },
  {
    Date: "23.06.2015",
    price: 0,
  },
  {
    Date: "24.06.2015",
    price: 0,
  },
  {
    Date: "25.06.2015",
    price: 0,
  },
  {
    Date: "26.06.2015",
    price: 0,
  },
  {
    Date: "27.06.2015",
    price: 0,
  },
  {
    Date: "28.06.2015",
    price: 0,
  },
  {
    Date: "29.06.2015",
    price: 0,
  },
  {
    Date: "30.06.2015",
    price: 0,
  },
  {
    Date: "01.07.2015",
    price: 0,
  },
  {
    Date: "02.07.2015",
    price: 0,
  },
  {
    Date: "03.07.2015",
    price: 0,
  },
  {
    Date: "04.07.2015",
    price: 0,
  },
  {
    Date: "05.07.2015",
    price: 0,
  },
  {
    Date: "06.07.2015",
    price: 0,
  },
  {
    Date: "07.07.2015",
    price: 0,
  },
  {
    Date: "08.07.2015",
    price: 0,
  },
  {
    Date: "09.07.2015",
    price: 0,
  },
  {
    Date: "10.07.2015",
    price: 0,
  },
  {
    Date: "11.07.2015",
    price: 0,
  },
  {
    Date: "12.07.2015",
    price: 0,
  },
  {
    Date: "13.07.2015",
    price: 0,
  },
  {
    Date: "14.07.2015",
    price: 0,
  },
  {
    Date: "15.07.2015",
    price: 0,
  },
  {
    Date: "16.07.2015",
    price: 0,
  },
  {
    Date: "17.07.2015",
    price: 0,
  },
  {
    Date: "18.07.2015",
    price: 0,
  },
  {
    Date: "19.07.2015",
    price: 0,
  },
  {
    Date: "20.07.2015",
    price: 0,
  },
  {
    Date: "21.07.2015",
    price: 0,
  },
  {
    Date: "22.07.2015",
    price: 0,
  },
  {
    Date: "23.07.2015",
    price: 0,
  },
  {
    Date: "24.07.2015",
    price: 0,
  },
  {
    Date: "25.07.2015",
    price: 0,
  },
  {
    Date: "26.07.2015",
    price: 0,
  },
  {
    Date: "27.07.2015",
    price: 0,
  },
  {
    Date: "28.07.2015",
    price: 0,
  },
  {
    Date: "29.07.2015",
    price: 0,
  },
  {
    Date: "30.07.2015",
    price: 0,
  },
  {
    Date: "31.07.2015",
    price: 0,
  },
  {
    Date: "01.08.2015",
    price: 0,
  },
  {
    Date: "02.08.2015",
    price: 0,
  },
  {
    Date: "03.08.2015",
    price: 0,
  },
  {
    Date: "04.08.2015",
    price: 0,
  },
  {
    Date: "05.08.2015",
    price: 0,
  },
  {
    Date: "06.08.2015",
    price: 0,
  },
  {
    Date: "07.08.2015",
    price: 0,
  },
  {
    Date: "08.08.2015",
    price: 0,
  },
  {
    Date: "09.08.2015",
    price: 0,
  },
  {
    Date: "10.08.2015",
    price: 0,
  },
  {
    Date: "11.08.2015",
    price: 0,
  },
  {
    Date: "12.08.2015",
    price: 0,
  },
  {
    Date: "13.08.2015",
    price: 0,
  },
  {
    Date: "14.08.2015",
    price: 0,
  },
  {
    Date: "15.08.2015",
    price: 0,
  },
  {
    Date: "16.08.2015",
    price: 0,
  },
  {
    Date: "17.08.2015",
    price: 0,
  },
  {
    Date: "18.08.2015",
    price: 0,
  },
  {
    Date: "19.08.2015",
    price: 0,
  },
  {
    Date: "20.08.2015",
    price: 0,
  },
  {
    Date: "21.08.2015",
    price: 0,
  },
  {
    Date: "22.08.2015",
    price: 0,
  },
  {
    Date: "23.08.2015",
    price: 0,
  },
  {
    Date: "24.08.2015",
    price: 0,
  },
  {
    Date: "25.08.2015",
    price: 0,
  },
  {
    Date: "26.08.2015",
    price: 0,
  },
  {
    Date: "27.08.2015",
    price: 0,
  },
  {
    Date: "28.08.2015",
    price: 0,
  },
  {
    Date: "29.08.2015",
    price: 0,
  },
  {
    Date: "30.08.2015",
    price: 0,
  },
  {
    Date: "31.08.2015",
    price: 0,
  },
  {
    Date: "01.09.2015",
    price: 0,
  },
  {
    Date: "02.09.2015",
    price: 0,
  },
  {
    Date: "03.09.2015",
    price: 0,
  },
  {
    Date: "04.09.2015",
    price: 0,
  },
  {
    Date: "05.09.2015",
    price: 0,
  },
  {
    Date: "06.09.2015",
    price: 0,
  },
  {
    Date: "07.09.2015",
    price: 0,
  },
  {
    Date: "08.09.2015",
    price: 0,
  },
  {
    Date: "09.09.2015",
    price: 0,
  },
  {
    Date: "10.09.2015",
    price: 0,
  },
  {
    Date: "11.09.2015",
    price: 0,
  },
  {
    Date: "12.09.2015",
    price: 0,
  },
  {
    Date: "13.09.2015",
    price: 0,
  },
  {
    Date: "14.09.2015",
    price: 0,
  },
  {
    Date: "15.09.2015",
    price: 0,
  },
  {
    Date: "16.09.2015",
    price: 0,
  },
  {
    Date: "17.09.2015",
    price: 0,
  },
  {
    Date: "18.09.2015",
    price: 0,
  },
  {
    Date: "19.09.2015",
    price: 0,
  },
  {
    Date: "20.09.2015",
    price: 0,
  },
  {
    Date: "21.09.2015",
    price: 0,
  },
  {
    Date: "22.09.2015",
    price: 0,
  },
  {
    Date: "23.09.2015",
    price: 0,
  },
  {
    Date: "24.09.2015",
    price: 0,
  },
  {
    Date: "25.09.2015",
    price: 0,
  },
  {
    Date: "26.09.2015",
    price: 0,
  },
  {
    Date: "27.09.2015",
    price: 0,
  },
  {
    Date: "28.09.2015",
    price: 0,
  },
  {
    Date: "29.09.2015",
    price: 0,
  },
  {
    Date: "30.09.2015",
    price: 0,
  },
  {
    Date: "01.10.2015",
    price: 0,
  },
  {
    Date: "02.10.2015",
    price: 0,
  },
  {
    Date: "03.10.2015",
    price: 0,
  },
  {
    Date: "04.10.2015",
    price: 0,
  },
  {
    Date: "05.10.2015",
    price: 0,
  },
  {
    Date: "06.10.2015",
    price: 0,
  },
  {
    Date: "07.10.2015",
    price: 0,
  },
  {
    Date: "08.10.2015",
    price: 0,
  },
  {
    Date: "09.10.2015",
    price: 0,
  },
  {
    Date: "10.10.2015",
    price: 0,
  },
  {
    Date: "11.10.2015",
    price: 0,
  },
  {
    Date: "12.10.2015",
    price: 0,
  },
  {
    Date: "13.10.2015",
    price: 0,
  },
  {
    Date: "14.10.2015",
    price: 0,
  },
  {
    Date: "15.10.2015",
    price: 0,
  },
  {
    Date: "16.10.2015",
    price: 0,
  },
  {
    Date: "17.10.2015",
    price: 0,
  },
  {
    Date: "18.10.2015",
    price: 0,
  },
  {
    Date: "19.10.2015",
    price: 0,
  },
  {
    Date: "20.10.2015",
    price: 0,
  },
  {
    Date: "21.10.2015",
    price: 0,
  },
  {
    Date: "22.10.2015",
    price: 0,
  },
  {
    Date: "23.10.2015",
    price: 0,
  },
  {
    Date: "24.10.2015",
    price: 0,
  },
  {
    Date: "25.10.2015",
    price: 0,
  },
  {
    Date: "26.10.2015",
    price: 0,
  },
  {
    Date: "27.10.2015",
    price: 0,
  },
  {
    Date: "28.10.2015",
    price: 0,
  },
  {
    Date: "29.10.2015",
    price: 0,
  },
  {
    Date: "30.10.2015",
    price: 0,
  },
  {
    Date: "31.10.2015",
    price: 0,
  },
  {
    Date: "01.11.2015",
    price: 0,
  },
  {
    Date: "02.11.2015",
    price: 0,
  },
  {
    Date: "03.11.2015",
    price: 0,
  },
  {
    Date: "04.11.2015",
    price: 0,
  },
  {
    Date: "05.11.2015",
    price: 0,
  },
  {
    Date: "06.11.2015",
    price: 0,
  },
  {
    Date: "07.11.2015",
    price: 0,
  },
  {
    Date: "08.11.2015",
    price: 0,
  },
  {
    Date: "09.11.2015",
    price: 0,
  },
  {
    Date: "10.11.2015",
    price: 0,
  },
  {
    Date: "11.11.2015",
    price: 0,
  },
  {
    Date: "12.11.2015",
    price: 0,
  },
  {
    Date: "13.11.2015",
    price: 0,
  },
  {
    Date: "14.11.2015",
    price: 0,
  },
  {
    Date: "15.11.2015",
    price: 0,
  },
  {
    Date: "16.11.2015",
    price: 0,
  },
  {
    Date: "17.11.2015",
    price: 0,
  },
  {
    Date: "18.11.2015",
    price: 0,
  },
  {
    Date: "19.11.2015",
    price: 0,
  },
  {
    Date: "20.11.2015",
    price: 0,
  },
  {
    Date: "21.11.2015",
    price: 0,
  },
  {
    Date: "22.11.2015",
    price: 0,
  },
  {
    Date: "23.11.2015",
    price: 0,
  },
  {
    Date: "24.11.2015",
    price: 0,
  },
  {
    Date: "25.11.2015",
    price: 0,
  },
  {
    Date: "26.11.2015",
    price: 0,
  },
  {
    Date: "27.11.2015",
    price: 0,
  },
  {
    Date: "28.11.2015",
    price: 0,
  },
  {
    Date: "29.11.2015",
    price: 0,
  },
  {
    Date: "30.11.2015",
    price: 0,
  },
  {
    Date: "01.12.2015",
    price: 0,
  },
  {
    Date: "02.12.2015",
    price: 0,
  },
  {
    Date: "03.12.2015",
    price: 0,
  },
  {
    Date: "04.12.2015",
    price: 0,
  },
  {
    Date: "05.12.2015",
    price: 0,
  },
  {
    Date: "06.12.2015",
    price: 0,
  },
  {
    Date: "07.12.2015",
    price: 0,
  },
  {
    Date: "08.12.2015",
    price: 0,
  },
  {
    Date: "09.12.2015",
    price: 0,
  },
  {
    Date: "10.12.2015",
    price: 0,
  },
  {
    Date: "11.12.2015",
    price: 0,
  },
  {
    Date: "12.12.2015",
    price: 0,
  },
  {
    Date: "13.12.2015",
    price: 0,
  },
  {
    Date: "14.12.2015",
    price: 0,
  },
  {
    Date: "15.12.2015",
    price: 0,
  },
  {
    Date: "16.12.2015",
    price: 0,
  },
  {
    Date: "17.12.2015",
    price: 0,
  },
  {
    Date: "18.12.2015",
    price: 0,
  },
  {
    Date: "19.12.2015",
    price: 0,
  },
  {
    Date: "20.12.2015",
    price: 0,
  },
  {
    Date: "21.12.2015",
    price: 0,
  },
  {
    Date: "22.12.2015",
    price: 0,
  },
  {
    Date: "23.12.2015",
    price: 0,
  },
  {
    Date: "24.12.2015",
    price: 0,
  },
  {
    Date: "25.12.2015",
    price: 0,
  },
  {
    Date: "26.12.2015",
    price: 0,
  },
  {
    Date: "27.12.2015",
    price: 0,
  },
  {
    Date: "28.12.2015",
    price: 0,
  },
  {
    Date: "29.12.2015",
    price: 0,
  },
  {
    Date: "30.12.2015",
    price: 0,
  },
  {
    Date: "31.12.2015",
    price: 0,
  },
  {
    Date: "01.01.2016",
    price: 0,
  },
  {
    Date: "02.01.2016",
    price: 0,
  },
  {
    Date: "03.01.2016",
    price: 0,
  },
  {
    Date: "04.01.2016",
    price: 0,
  },
  {
    Date: "05.01.2016",
    price: 0,
  },
  {
    Date: "06.01.2016",
    price: 0,
  },
  {
    Date: "07.01.2016",
    price: 0,
  },
  {
    Date: "08.01.2016",
    price: 0,
  },
  {
    Date: "09.01.2016",
    price: 0,
  },
  {
    Date: "10.01.2016",
    price: 0,
  },
  {
    Date: "11.01.2016",
    price: 0,
  },
  {
    Date: "12.01.2016",
    price: 0,
  },
  {
    Date: "13.01.2016",
    price: 0,
  },
  {
    Date: "14.01.2016",
    price: 0,
  },
  {
    Date: "15.01.2016",
    price: 0,
  },
  {
    Date: "16.01.2016",
    price: 0,
  },
  {
    Date: "17.01.2016",
    price: 0,
  },
  {
    Date: "18.01.2016",
    price: 0,
  },
  {
    Date: "19.01.2016",
    price: 0,
  },
  {
    Date: "20.01.2016",
    price: 0,
  },
  {
    Date: "21.01.2016",
    price: 0,
  },
  {
    Date: "22.01.2016",
    price: 0,
  },
  {
    Date: "23.01.2016",
    price: 0,
  },
  {
    Date: "24.01.2016",
    price: 0,
  },
  {
    Date: "25.01.2016",
    price: 0,
  },
  {
    Date: "26.01.2016",
    price: 0,
  },
  {
    Date: "27.01.2016",
    price: 0,
  },
  {
    Date: "28.01.2016",
    price: 0,
  },
  {
    Date: "29.01.2016",
    price: 0,
  },
  {
    Date: "30.01.2016",
    price: 0,
  },
  {
    Date: "31.01.2016",
    price: 0,
  },
  {
    Date: "01.02.2016",
    price: 0,
  },
  {
    Date: "02.02.2016",
    price: 0,
  },
  {
    Date: "03.02.2016",
    price: 0,
  },
  {
    Date: "04.02.2016",
    price: 0,
  },
  {
    Date: "05.02.2016",
    price: 0,
  },
  {
    Date: "06.02.2016",
    price: 0,
  },
  {
    Date: "07.02.2016",
    price: 0,
  },
  {
    Date: "08.02.2016",
    price: 0,
  },
  {
    Date: "09.02.2016",
    price: 0,
  },
  {
    Date: "10.02.2016",
    price: 0,
  },
  {
    Date: "11.02.2016",
    price: 0,
  },
  {
    Date: "12.02.2016",
    price: 0,
  },
  {
    Date: "13.02.2016",
    price: 0,
  },
  {
    Date: "14.02.2016",
    price: 0,
  },
  {
    Date: "15.02.2016",
    price: 0,
  },
  {
    Date: "16.02.2016",
    price: 0,
  },
  {
    Date: "17.02.2016",
    price: 0,
  },
  {
    Date: "18.02.2016",
    price: 0,
  },
  {
    Date: "19.02.2016",
    price: 0,
  },
  {
    Date: "20.02.2016",
    price: 0,
  },
  {
    Date: "21.02.2016",
    price: 0,
  },
  {
    Date: "22.02.2016",
    price: 0,
  },
  {
    Date: "23.02.2016",
    price: 0,
  },
  {
    Date: "24.02.2016",
    price: 0,
  },
  {
    Date: "25.02.2016",
    price: 0,
  },
  {
    Date: "26.02.2016",
    price: 0,
  },
  {
    Date: "27.02.2016",
    price: 0,
  },
  {
    Date: "28.02.2016",
    price: 0,
  },
  {
    Date: "29.02.2016",
    price: 0,
  },
  {
    Date: "01.03.2016",
    price: 0,
  },
  {
    Date: "02.03.2016",
    price: 0,
  },
  {
    Date: "03.03.2016",
    price: 0,
  },
  {
    Date: "04.03.2016",
    price: 0,
  },
  {
    Date: "05.03.2016",
    price: 0,
  },
  {
    Date: "06.03.2016",
    price: 0,
  },
  {
    Date: "07.03.2016",
    price: 0,
  },
  {
    Date: "08.03.2016",
    price: 0,
  },
  {
    Date: "09.03.2016",
    price: 0,
  },
  {
    Date: "10.03.2016",
    price: 0,
  },
  {
    Date: "11.03.2016",
    price: 0,
  },
  {
    Date: "12.03.2016",
    price: 0,
  },
  {
    Date: "13.03.2016",
    price: 0,
  },
  {
    Date: "14.03.2016",
    price: 0,
  },
  {
    Date: "15.03.2016",
    price: 0,
  },
  {
    Date: "16.03.2016",
    price: 0,
  },
  {
    Date: "17.03.2016",
    price: 0,
  },
  {
    Date: "18.03.2016",
    price: 0,
  },
  {
    Date: "19.03.2016",
    price: 0,
  },
  {
    Date: "20.03.2016",
    price: 0,
  },
  {
    Date: "21.03.2016",
    price: 0,
  },
  {
    Date: "22.03.2016",
    price: 0,
  },
  {
    Date: "23.03.2016",
    price: 0,
  },
  {
    Date: "24.03.2016",
    price: 0,
  },
  {
    Date: "25.03.2016",
    price: 0,
  },
  {
    Date: "26.03.2016",
    price: 0,
  },
  {
    Date: "27.03.2016",
    price: 0,
  },
  {
    Date: "28.03.2016",
    price: 0,
  },
  {
    Date: "29.03.2016",
    price: 0,
  },
  {
    Date: "30.03.2016",
    price: 0,
  },
  {
    Date: "31.03.2016",
    price: 0,
  },
  {
    Date: "01.04.2016",
    price: 0,
  },
  {
    Date: "02.04.2016",
    price: 0,
  },
  {
    Date: "03.04.2016",
    price: 0,
  },
  {
    Date: "04.04.2016",
    price: 0,
  },
  {
    Date: "05.04.2016",
    price: 0,
  },
  {
    Date: "06.04.2016",
    price: 0,
  },
  {
    Date: "07.04.2016",
    price: 0,
  },
  {
    Date: "08.04.2016",
    price: 0,
  },
  {
    Date: "09.04.2016",
    price: 0,
  },
  {
    Date: "10.04.2016",
    price: 0,
  },
  {
    Date: "11.04.2016",
    price: 0,
  },
  {
    Date: "12.04.2016",
    price: 0,
  },
  {
    Date: "13.04.2016",
    price: 0,
  },
  {
    Date: "14.04.2016",
    price: 0,
  },
  {
    Date: "15.04.2016",
    price: 0,
  },
  {
    Date: "16.04.2016",
    price: 0,
  },
  {
    Date: "17.04.2016",
    price: 0,
  },
  {
    Date: "18.04.2016",
    price: 0,
  },
  {
    Date: "19.04.2016",
    price: 0,
  },
  {
    Date: "20.04.2016",
    price: 0,
  },
  {
    Date: "21.04.2016",
    price: 0,
  },
  {
    Date: "22.04.2016",
    price: 0,
  },
  {
    Date: "23.04.2016",
    price: 0,
  },
  {
    Date: "24.04.2016",
    price: 0,
  },
  {
    Date: "25.04.2016",
    price: 0,
  },
  {
    Date: "26.04.2016",
    price: 0,
  },
  {
    Date: "27.04.2016",
    price: 0,
  },
  {
    Date: "28.04.2016",
    price: 0,
  },
  {
    Date: "29.04.2016",
    price: 0,
  },
  {
    Date: "30.04.2016",
    price: 0,
  },
  {
    Date: "01.05.2016",
    price: 0,
  },
  {
    Date: "02.05.2016",
    price: 0,
  },
  {
    Date: "03.05.2016",
    price: 0,
  },
  {
    Date: "04.05.2016",
    price: 0,
  },
  {
    Date: "05.05.2016",
    price: 0,
  },
  {
    Date: "06.05.2016",
    price: 0,
  },
  {
    Date: "07.05.2016",
    price: 0,
  },
  {
    Date: "08.05.2016",
    price: 0,
  },
  {
    Date: "09.05.2016",
    price: 0,
  },
  {
    Date: "10.05.2016",
    price: 0,
  },
  {
    Date: "11.05.2016",
    price: 0,
  },
  {
    Date: "12.05.2016",
    price: 0,
  },
  {
    Date: "13.05.2016",
    price: 0,
  },
  {
    Date: "14.05.2016",
    price: 0,
  },
  {
    Date: "15.05.2016",
    price: 0,
  },
  {
    Date: "16.05.2016",
    price: 0,
  },
  {
    Date: "17.05.2016",
    price: 0,
  },
  {
    Date: "18.05.2016",
    price: 0,
  },
  {
    Date: "19.05.2016",
    price: 0,
  },
  {
    Date: "20.05.2016",
    price: 0,
  },
  {
    Date: "21.05.2016",
    price: 0,
  },
  {
    Date: "22.05.2016",
    price: 0,
  },
  {
    Date: "23.05.2016",
    price: 0,
  },
  {
    Date: "24.05.2016",
    price: 0,
  },
  {
    Date: "25.05.2016",
    price: 0,
  },
  {
    Date: "26.05.2016",
    price: 0,
  },
  {
    Date: "27.05.2016",
    price: 0,
  },
  {
    Date: "28.05.2016",
    price: 0,
  },
  {
    Date: "29.05.2016",
    price: 0,
  },
  {
    Date: "30.05.2016",
    price: 0,
  },
  {
    Date: "31.05.2016",
    price: 0,
  },
  {
    Date: "01.06.2016",
    price: 0,
  },
  {
    Date: "02.06.2016",
    price: 0,
  },
  {
    Date: "03.06.2016",
    price: 0,
  },
  {
    Date: "04.06.2016",
    price: 0,
  },
  {
    Date: "05.06.2016",
    price: 0,
  },
  {
    Date: "06.06.2016",
    price: 0,
  },
  {
    Date: "07.06.2016",
    price: 0,
  },
  {
    Date: "08.06.2016",
    price: 0,
  },
  {
    Date: "09.06.2016",
    price: 0,
  },
  {
    Date: "10.06.2016",
    price: 0,
  },
  {
    Date: "11.06.2016",
    price: 0,
  },
  {
    Date: "12.06.2016",
    price: 0,
  },
  {
    Date: "13.06.2016",
    price: 0,
  },
  {
    Date: "14.06.2016",
    price: 0,
  },
  {
    Date: "15.06.2016",
    price: 0,
  },
  {
    Date: "16.06.2016",
    price: 0,
  },
  {
    Date: "17.06.2016",
    price: 0,
  },
  {
    Date: "18.06.2016",
    price: 0,
  },
  {
    Date: "19.06.2016",
    price: 0,
  },
  {
    Date: "20.06.2016",
    price: 0,
  },
  {
    Date: "21.06.2016",
    price: 0,
  },
  {
    Date: "22.06.2016",
    price: 0,
  },
  {
    Date: "23.06.2016",
    price: 0,
  },
  {
    Date: "24.06.2016",
    price: 0,
  },
  {
    Date: "25.06.2016",
    price: 0,
  },
  {
    Date: "26.06.2016",
    price: 0,
  },
  {
    Date: "27.06.2016",
    price: 0,
  },
  {
    Date: "28.06.2016",
    price: 0,
  },
  {
    Date: "29.06.2016",
    price: 0,
  },
  {
    Date: "30.06.2016",
    price: 0,
  },
  {
    Date: "01.07.2016",
    price: 0,
  },
  {
    Date: "02.07.2016",
    price: 0,
  },
  {
    Date: "03.07.2016",
    price: 0,
  },
  {
    Date: "04.07.2016",
    price: 0,
  },
  {
    Date: "05.07.2016",
    price: 0,
  },
  {
    Date: "06.07.2016",
    price: 0,
  },
  {
    Date: "07.07.2016",
    price: 0,
  },
  {
    Date: "08.07.2016",
    price: 0,
  },
  {
    Date: "09.07.2016",
    price: 0,
  },
  {
    Date: "10.07.2016",
    price: 0,
  },
  {
    Date: "11.07.2016",
    price: 0,
  },
  {
    Date: "12.07.2016",
    price: 0,
  },
  {
    Date: "13.07.2016",
    price: 0,
  },
  {
    Date: "14.07.2016",
    price: 0,
  },
  {
    Date: "15.07.2016",
    price: 0,
  },
  {
    Date: "16.07.2016",
    price: 0,
  },
  {
    Date: "17.07.2016",
    price: 0,
  },
  {
    Date: "18.07.2016",
    price: 0,
  },
  {
    Date: "19.07.2016",
    price: 0,
  },
  {
    Date: "20.07.2016",
    price: 0,
  },
  {
    Date: "21.07.2016",
    price: 0,
  },
  {
    Date: "22.07.2016",
    price: 0,
  },
  {
    Date: "23.07.2016",
    price: 0,
  },
  {
    Date: "24.07.2016",
    price: 0,
  },
  {
    Date: "25.07.2016",
    price: 0,
  },
  {
    Date: "26.07.2016",
    price: 0,
  },
  {
    Date: "27.07.2016",
    price: 0,
  },
  {
    Date: "28.07.2016",
    price: 0,
  },
  {
    Date: "29.07.2016",
    price: 0,
  },
  {
    Date: "30.07.2016",
    price: 0,
  },
  {
    Date: "31.07.2016",
    price: 0,
  },
  {
    Date: "01.08.2016",
    price: 0,
  },
  {
    Date: "02.08.2016",
    price: 0,
  },
  {
    Date: "03.08.2016",
    price: 0,
  },
  {
    Date: "04.08.2016",
    price: 0,
  },
  {
    Date: "05.08.2016",
    price: 0,
  },
  {
    Date: "06.08.2016",
    price: 0,
  },
  {
    Date: "07.08.2016",
    price: 0,
  },
  {
    Date: "08.08.2016",
    price: 0,
  },
  {
    Date: "09.08.2016",
    price: 0,
  },
  {
    Date: "10.08.2016",
    price: 0,
  },
  {
    Date: "11.08.2016",
    price: 0,
  },
  {
    Date: "12.08.2016",
    price: 0,
  },
  {
    Date: "13.08.2016",
    price: 0,
  },
  {
    Date: "14.08.2016",
    price: 0,
  },
  {
    Date: "15.08.2016",
    price: 0,
  },
  {
    Date: "16.08.2016",
    price: 0,
  },
  {
    Date: "17.08.2016",
    price: 0,
  },
  {
    Date: "18.08.2016",
    price: 0,
  },
  {
    Date: "19.08.2016",
    price: 0,
  },
  {
    Date: "20.08.2016",
    price: 0,
  },
  {
    Date: "21.08.2016",
    price: 0,
  },
  {
    Date: "22.08.2016",
    price: 0,
  },
  {
    Date: "23.08.2016",
    price: 0,
  },
  {
    Date: "24.08.2016",
    price: 0,
  },
  {
    Date: "25.08.2016",
    price: 0,
  },
  {
    Date: "26.08.2016",
    price: 0,
  },
  {
    Date: "27.08.2016",
    price: 0,
  },
  {
    Date: "28.08.2016",
    price: 0,
  },
  {
    Date: "29.08.2016",
    price: 0,
  },
  {
    Date: "30.08.2016",
    price: 0,
  },
  {
    Date: "31.08.2016",
    price: 0,
  },
  {
    Date: "01.09.2016",
    price: 0,
  },
  {
    Date: "02.09.2016",
    price: 0,
  },
  {
    Date: "03.09.2016",
    price: 0,
  },
  {
    Date: "04.09.2016",
    price: 0,
  },
  {
    Date: "05.09.2016",
    price: 0,
  },
  {
    Date: "06.09.2016",
    price: 0,
  },
  {
    Date: "07.09.2016",
    price: 0,
  },
  {
    Date: "08.09.2016",
    price: 0,
  },
  {
    Date: "09.09.2016",
    price: 0,
  },
  {
    Date: "10.09.2016",
    price: 0,
  },
  {
    Date: "11.09.2016",
    price: 0,
  },
  {
    Date: "12.09.2016",
    price: 0,
  },
  {
    Date: "13.09.2016",
    price: 0,
  },
  {
    Date: "14.09.2016",
    price: 0,
  },
  {
    Date: "15.09.2016",
    price: 0,
  },
  {
    Date: "16.09.2016",
    price: 0,
  },
  {
    Date: "17.09.2016",
    price: 0,
  },
  {
    Date: "18.09.2016",
    price: 0,
  },
  {
    Date: "19.09.2016",
    price: 0,
  },
  {
    Date: "20.09.2016",
    price: 0,
  },
  {
    Date: "21.09.2016",
    price: 0,
  },
  {
    Date: "22.09.2016",
    price: 0,
  },
  {
    Date: "23.09.2016",
    price: 0,
  },
  {
    Date: "24.09.2016",
    price: 0,
  },
  {
    Date: "25.09.2016",
    price: 0,
  },
  {
    Date: "26.09.2016",
    price: 0,
  },
  {
    Date: "27.09.2016",
    price: 0,
  },
  {
    Date: "28.09.2016",
    price: 0,
  },
  {
    Date: "29.09.2016",
    price: 0,
  },
  {
    Date: "30.09.2016",
    price: 0,
  },
  {
    Date: "01.10.2016",
    price: 0,
  },
  {
    Date: "02.10.2016",
    price: 0,
  },
  {
    Date: "03.10.2016",
    price: 0,
  },
  {
    Date: "04.10.2016",
    price: 0,
  },
  {
    Date: "05.10.2016",
    price: 0,
  },
  {
    Date: "06.10.2016",
    price: 0,
  },
  {
    Date: "07.10.2016",
    price: 0,
  },
  {
    Date: "08.10.2016",
    price: 0,
  },
  {
    Date: "09.10.2016",
    price: 0,
  },
  {
    Date: "10.10.2016",
    price: 0,
  },
  {
    Date: "11.10.2016",
    price: 0,
  },
  {
    Date: "12.10.2016",
    price: 0,
  },
  {
    Date: "13.10.2016",
    price: 0,
  },
  {
    Date: "14.10.2016",
    price: 0,
  },
  {
    Date: "15.10.2016",
    price: 0,
  },
  {
    Date: "16.10.2016",
    price: 0,
  },
  {
    Date: "17.10.2016",
    price: 0,
  },
  {
    Date: "18.10.2016",
    price: 0,
  },
  {
    Date: "19.10.2016",
    price: 0,
  },
  {
    Date: "20.10.2016",
    price: 0,
  },
  {
    Date: "21.10.2016",
    price: 0,
  },
  {
    Date: "22.10.2016",
    price: 0,
  },
  {
    Date: "23.10.2016",
    price: 0,
  },
  {
    Date: "24.10.2016",
    price: 0,
  },
  {
    Date: "25.10.2016",
    price: 0,
  },
  {
    Date: "26.10.2016",
    price: 0,
  },
  {
    Date: "27.10.2016",
    price: 0,
  },
  {
    Date: "28.10.2016",
    price: 0,
  },
  {
    Date: "29.10.2016",
    price: 0,
  },
  {
    Date: "30.10.2016",
    price: 0,
  },
  {
    Date: "31.10.2016",
    price: 0,
  },
  {
    Date: "01.11.2016",
    price: 0,
  },
  {
    Date: "02.11.2016",
    price: 0,
  },
  {
    Date: "03.11.2016",
    price: 0,
  },
  {
    Date: "04.11.2016",
    price: 0,
  },
  {
    Date: "05.11.2016",
    price: 0,
  },
  {
    Date: "06.11.2016",
    price: 0,
  },
  {
    Date: "07.11.2016",
    price: 0,
  },
  {
    Date: "08.11.2016",
    price: 0,
  },
  {
    Date: "09.11.2016",
    price: 0,
  },
  {
    Date: "10.11.2016",
    price: 0,
  },
  {
    Date: "11.11.2016",
    price: 0,
  },
  {
    Date: "12.11.2016",
    price: 0,
  },
  {
    Date: "13.11.2016",
    price: 0,
  },
  {
    Date: "14.11.2016",
    price: 0,
  },
  {
    Date: "15.11.2016",
    price: 0,
  },
  {
    Date: "16.11.2016",
    price: 0,
  },
  {
    Date: "17.11.2016",
    price: 0,
  },
  {
    Date: "18.11.2016",
    price: 0,
  },
  {
    Date: "19.11.2016",
    price: 0,
  },
  {
    Date: "20.11.2016",
    price: 0,
  },
  {
    Date: "21.11.2016",
    price: 0,
  },
  {
    Date: "22.11.2016",
    price: 0,
  },
  {
    Date: "23.11.2016",
    price: 0,
  },
  {
    Date: "24.11.2016",
    price: 0,
  },
  {
    Date: "25.11.2016",
    price: 0,
  },
  {
    Date: "26.11.2016",
    price: 0,
  },
  {
    Date: "27.11.2016",
    price: 0,
  },
  {
    Date: "28.11.2016",
    price: 0,
  },
  {
    Date: "29.11.2016",
    price: 0,
  },
  {
    Date: "30.11.2016",
    price: 0,
  },
  {
    Date: "01.12.2016",
    price: 0,
  },
  {
    Date: "02.12.2016",
    price: 0,
  },
  {
    Date: "03.12.2016",
    price: 0,
  },
  {
    Date: "04.12.2016",
    price: 0,
  },
  {
    Date: "05.12.2016",
    price: 0,
  },
  {
    Date: "06.12.2016",
    price: 0,
  },
  {
    Date: "07.12.2016",
    price: 0,
  },
  {
    Date: "08.12.2016",
    price: 0,
  },
  {
    Date: "09.12.2016",
    price: 0,
  },
  {
    Date: "10.12.2016",
    price: 0,
  },
  {
    Date: "11.12.2016",
    price: 0,
  },
  {
    Date: "12.12.2016",
    price: 0,
  },
  {
    Date: "13.12.2016",
    price: 0,
  },
  {
    Date: "14.12.2016",
    price: 0,
  },
  {
    Date: "15.12.2016",
    price: 0,
  },
  {
    Date: "16.12.2016",
    price: 0,
  },
  {
    Date: "17.12.2016",
    price: 0,
  },
  {
    Date: "18.12.2016",
    price: 0,
  },
  {
    Date: "19.12.2016",
    price: 0,
  },
  {
    Date: "20.12.2016",
    price: 0,
  },
  {
    Date: "21.12.2016",
    price: 0,
  },
  {
    Date: "22.12.2016",
    price: 0,
  },
  {
    Date: "23.12.2016",
    price: 0,
  },
  {
    Date: "24.12.2016",
    price: 0,
  },
  {
    Date: "25.12.2016",
    price: 0,
  },
  {
    Date: "26.12.2016",
    price: 0,
  },
  {
    Date: "27.12.2016",
    price: 0,
  },
  {
    Date: "28.12.2016",
    price: 0,
  },
  {
    Date: "29.12.2016",
    price: 0,
  },
  {
    Date: "30.12.2016",
    price: 0,
  },
  {
    Date: "31.12.2016",
    price: 0,
  },
  {
    Date: "01.01.2017",
    price: 0,
  },
  {
    Date: "02.01.2017",
    price: 0,
  },
  {
    Date: "03.01.2017",
    price: 0,
  },
  {
    Date: "04.01.2017",
    price: 0,
  },
  {
    Date: "05.01.2017",
    price: 0,
  },
  {
    Date: "06.01.2017",
    price: 0,
  },
  {
    Date: "07.01.2017",
    price: 0,
  },
  {
    Date: "08.01.2017",
    price: 0,
  },
  {
    Date: "09.01.2017",
    price: 0,
  },
  {
    Date: "10.01.2017",
    price: 0,
  },
  {
    Date: "11.01.2017",
    price: 0,
  },
  {
    Date: "12.01.2017",
    price: 0,
  },
  {
    Date: "13.01.2017",
    price: 0,
  },
  {
    Date: "14.01.2017",
    price: 0,
  },
  {
    Date: "15.01.2017",
    price: 0,
  },
  {
    Date: "16.01.2017",
    price: 0,
  },
  {
    Date: "17.01.2017",
    price: 0,
  },
  {
    Date: "18.01.2017",
    price: 0,
  },
  {
    Date: "19.01.2017",
    price: 0,
  },
  {
    Date: "20.01.2017",
    price: 0,
  },
  {
    Date: "21.01.2017",
    price: 0,
  },
  {
    Date: "22.01.2017",
    price: 0,
  },
  {
    Date: "23.01.2017",
    price: 0,
  },
  {
    Date: "24.01.2017",
    price: 0,
  },
  {
    Date: "25.01.2017",
    price: 0,
  },
  {
    Date: "26.01.2017",
    price: 0,
  },
  {
    Date: "27.01.2017",
    price: 0,
  },
  {
    Date: "28.01.2017",
    price: 0,
  },
  {
    Date: "29.01.2017",
    price: 0,
  },
  {
    Date: "30.01.2017",
    price: 0,
  },
  {
    Date: "31.01.2017",
    price: 0,
  },
  {
    Date: "01.02.2017",
    price: 0,
  },
  {
    Date: "02.02.2017",
    price: 0,
  },
  {
    Date: "03.02.2017",
    price: 0,
  },
  {
    Date: "04.02.2017",
    price: 0,
  },
  {
    Date: "05.02.2017",
    price: 0,
  },
  {
    Date: "06.02.2017",
    price: 0,
  },
  {
    Date: "07.02.2017",
    price: 0,
  },
  {
    Date: "08.02.2017",
    price: 0,
  },
  {
    Date: "09.02.2017",
    price: 0,
  },
  {
    Date: "10.02.2017",
    price: 0,
  },
  {
    Date: "11.02.2017",
    price: 0,
  },
  {
    Date: "12.02.2017",
    price: 0,
  },
  {
    Date: "13.02.2017",
    price: 0,
  },
  {
    Date: "14.02.2017",
    price: 0,
  },
  {
    Date: "15.02.2017",
    price: 0,
  },
  {
    Date: "16.02.2017",
    price: 0,
  },
  {
    Date: "17.02.2017",
    price: 0,
  },
  {
    Date: "18.02.2017",
    price: 0,
  },
  {
    Date: "19.02.2017",
    price: 0,
  },
  {
    Date: "20.02.2017",
    price: 0,
  },
  {
    Date: "21.02.2017",
    price: 0,
  },
  {
    Date: "22.02.2017",
    price: 0,
  },
  {
    Date: "23.02.2017",
    price: 0,
  },
  {
    Date: "24.02.2017",
    price: 0,
  },
  {
    Date: "25.02.2017",
    price: 0,
  },
  {
    Date: "26.02.2017",
    price: 0,
  },
  {
    Date: "27.02.2017",
    price: 0,
  },
  {
    Date: "28.02.2017",
    price: 0,
  },
  {
    Date: "01.03.2017",
    price: 0,
  },
  {
    Date: "02.03.2017",
    price: 0,
  },
  {
    Date: "03.03.2017",
    price: 0,
  },
  {
    Date: "04.03.2017",
    price: 0,
  },
  {
    Date: "05.03.2017",
    price: 0,
  },
  {
    Date: "06.03.2017",
    price: 0,
  },
  {
    Date: "07.03.2017",
    price: 0,
  },
  {
    Date: "08.03.2017",
    price: 0,
  },
  {
    Date: "09.03.2017",
    price: 0,
  },
  {
    Date: "10.03.2017",
    price: 0,
  },
  {
    Date: "11.03.2017",
    price: 0,
  },
  {
    Date: "12.03.2017",
    price: 0,
  },
  {
    Date: "13.03.2017",
    price: 0,
  },
  {
    Date: "14.03.2017",
    price: 0,
  },
  {
    Date: "15.03.2017",
    price: 0,
  },
  {
    Date: "16.03.2017",
    price: 0,
  },
  {
    Date: "17.03.2017",
    price: 0,
  },
  {
    Date: "18.03.2017",
    price: 0,
  },
  {
    Date: "19.03.2017",
    price: 0,
  },
  {
    Date: "20.03.2017",
    price: 0,
  },
  {
    Date: "21.03.2017",
    price: 0,
  },
  {
    Date: "22.03.2017",
    price: 0,
  },
  {
    Date: "23.03.2017",
    price: 0,
  },
  {
    Date: "24.03.2017",
    price: 0,
  },
  {
    Date: "25.03.2017",
    price: 0,
  },
  {
    Date: "26.03.2017",
    price: 0,
  },
  {
    Date: "27.03.2017",
    price: 0,
  },
  {
    Date: "28.03.2017",
    price: 0,
  },
  {
    Date: "29.03.2017",
    price: 0,
  },
  {
    Date: "30.03.2017",
    price: 0,
  },
  {
    Date: "31.03.2017",
    price: 0,
  },
  {
    Date: "01.04.2017",
    price: 0,
  },
  {
    Date: "02.04.2017",
    price: 0,
  },
  {
    Date: "03.04.2017",
    price: 0,
  },
  {
    Date: "04.04.2017",
    price: 0,
  },
  {
    Date: "05.04.2017",
    price: 0,
  },
  {
    Date: "06.04.2017",
    price: 0,
  },
  {
    Date: "07.04.2017",
    price: 0,
  },
  {
    Date: "08.04.2017",
    price: 0,
  },
  {
    Date: "09.04.2017",
    price: 0,
  },
  {
    Date: "10.04.2017",
    price: 0,
  },
  {
    Date: "11.04.2017",
    price: 0,
  },
  {
    Date: "12.04.2017",
    price: 0,
  },
  {
    Date: "13.04.2017",
    price: 0,
  },
  {
    Date: "14.04.2017",
    price: 0,
  },
  {
    Date: "15.04.2017",
    price: 0,
  },
  {
    Date: "16.04.2017",
    price: 0,
  },
  {
    Date: "17.04.2017",
    price: 0,
  },
  {
    Date: "18.04.2017",
    price: 0,
  },
  {
    Date: "19.04.2017",
    price: 0,
  },
  {
    Date: "20.04.2017",
    price: 0,
  },
  {
    Date: "21.04.2017",
    price: 0,
  },
  {
    Date: "22.04.2017",
    price: 0,
  },
  {
    Date: "23.04.2017",
    price: 0,
  },
  {
    Date: "24.04.2017",
    price: 0,
  },
  {
    Date: "25.04.2017",
    price: 0,
  },
  {
    Date: "26.04.2017",
    price: 0,
  },
  {
    Date: "27.04.2017",
    price: 0,
  },
  {
    Date: "28.04.2017",
    price: 0,
  },
  {
    Date: "29.04.2017",
    price: 0,
  },
  {
    Date: "30.04.2017",
    price: 0,
  },
  {
    Date: "01.05.2017",
    price: 0,
  },
  {
    Date: "02.05.2017",
    price: 0,
  },
  {
    Date: "03.05.2017",
    price: 0,
  },
  {
    Date: "04.05.2017",
    price: 0,
  },
  {
    Date: "05.05.2017",
    price: 0,
  },
  {
    Date: "06.05.2017",
    price: 0,
  },
  {
    Date: "07.05.2017",
    price: 0,
  },
  {
    Date: "08.05.2017",
    price: 0,
  },
  {
    Date: "09.05.2017",
    price: 0,
  },
  {
    Date: "10.05.2017",
    price: 0,
  },
  {
    Date: "11.05.2017",
    price: 0,
  },
  {
    Date: "12.05.2017",
    price: 0,
  },
  {
    Date: "13.05.2017",
    price: 0,
  },
  {
    Date: "14.05.2017",
    price: 0,
  },
  {
    Date: "15.05.2017",
    price: 0,
  },
  {
    Date: "16.05.2017",
    price: 0,
  },
  {
    Date: "17.05.2017",
    price: 0,
  },
  {
    Date: "18.05.2017",
    price: 0,
  },
  {
    Date: "19.05.2017",
    price: 0,
  },
  {
    Date: "20.05.2017",
    price: 0,
  },
  {
    Date: "21.05.2017",
    price: 0,
  },
  {
    Date: "22.05.2017",
    price: 0,
  },
  {
    Date: "23.05.2017",
    price: 0,
  },
  {
    Date: "24.05.2017",
    price: 0,
  },
  {
    Date: "25.05.2017",
    price: 0,
  },
  {
    Date: "26.05.2017",
    price: 0,
  },
  {
    Date: "27.05.2017",
    price: 0,
  },
  {
    Date: "28.05.2017",
    price: 0,
  },
  {
    Date: "29.05.2017",
    price: 0,
  },
  {
    Date: "30.05.2017",
    price: 0,
  },
  {
    Date: "31.05.2017",
    price: 0,
  },
  {
    Date: "01.06.2017",
    price: 0,
  },
  {
    Date: "02.06.2017",
    price: 0,
  },
  {
    Date: "03.06.2017",
    price: 0,
  },
  {
    Date: "04.06.2017",
    price: 0,
  },
  {
    Date: "05.06.2017",
    price: 0,
  },
  {
    Date: "06.06.2017",
    price: 0,
  },
  {
    Date: "07.06.2017",
    price: 0,
  },
  {
    Date: "08.06.2017",
    price: 0,
  },
  {
    Date: "09.06.2017",
    price: 0,
  },
  {
    Date: "10.06.2017",
    price: 0,
  },
  {
    Date: "11.06.2017",
    price: 0,
  },
  {
    Date: "12.06.2017",
    price: 0,
  },
  {
    Date: "13.06.2017",
    price: 0,
  },
  {
    Date: "14.06.2017",
    price: 0,
  },
  {
    Date: "15.06.2017",
    price: 0,
  },
  {
    Date: "16.06.2017",
    price: 0,
  },
  {
    Date: "17.06.2017",
    price: 0,
  },
  {
    Date: "18.06.2017",
    price: 0,
  },
  {
    Date: "19.06.2017",
    price: 0,
  },
  {
    Date: "20.06.2017",
    price: 0,
  },
  {
    Date: "21.06.2017",
    price: 0,
  },
  {
    Date: "22.06.2017",
    price: 0,
  },
  {
    Date: "23.06.2017",
    price: 0,
  },
  {
    Date: "24.06.2017",
    price: 0,
  },
  {
    Date: "25.06.2017",
    price: 0,
  },
  {
    Date: "26.06.2017",
    price: 0,
  },
  {
    Date: "27.06.2017",
    price: 0,
  },
  {
    Date: "28.06.2017",
    price: 0,
  },
  {
    Date: "29.06.2017",
    price: 0,
  },
  {
    Date: "30.06.2017",
    price: 0,
  },
  {
    Date: "01.07.2017",
    price: 0,
  },
  {
    Date: "02.07.2017",
    price: 0,
  },
  {
    Date: "03.07.2017",
    price: 0,
  },
  {
    Date: "04.07.2017",
    price: 0,
  },
  {
    Date: "05.07.2017",
    price: 0,
  },
  {
    Date: "06.07.2017",
    price: 0,
  },
  {
    Date: "07.07.2017",
    price: 0,
  },
  {
    Date: "08.07.2017",
    price: 0,
  },
  {
    Date: "09.07.2017",
    price: 0,
  },
  {
    Date: "10.07.2017",
    price: 0,
  },
  {
    Date: "11.07.2017",
    price: 0,
  },
  {
    Date: "12.07.2017",
    price: 0,
  },
  {
    Date: "13.07.2017",
    price: 0,
  },
  {
    Date: "14.07.2017",
    price: 0,
  },
  {
    Date: "15.07.2017",
    price: 0,
  },
  {
    Date: "16.07.2017",
    price: 0,
  },
  {
    Date: "17.07.2017",
    price: 0,
  },
  {
    Date: "18.07.2017",
    price: 0,
  },
  {
    Date: "19.07.2017",
    price: 0,
  },
  {
    Date: "20.07.2017",
    price: 0,
  },
  {
    Date: "21.07.2017",
    price: 0,
  },
  {
    Date: "22.07.2017",
    price: 0,
  },
  {
    Date: "23.07.2017",
    price: 0,
  },
  {
    Date: "24.07.2017",
    price: 0,
  },
  {
    Date: "25.07.2017",
    price: 0,
  },
  {
    Date: "26.07.2017",
    price: 0,
  },
  {
    Date: "27.07.2017",
    price: 0,
  },
  {
    Date: "28.07.2017",
    price: 0,
  },
  {
    Date: "29.07.2017",
    price: 0,
  },
  {
    Date: "30.07.2017",
    price: 0,
  },
  {
    Date: "31.07.2017",
    price: 0,
  },
  {
    Date: "01.08.2017",
    price: 0,
  },
  {
    Date: "02.08.2017",
    price: 0,
  },
  {
    Date: "03.08.2017",
    price: 0,
  },
  {
    Date: "04.08.2017",
    price: 0,
  },
  {
    Date: "05.08.2017",
    price: 0,
  },
  {
    Date: "06.08.2017",
    price: 0,
  },
  {
    Date: "07.08.2017",
    price: 0,
  },
  {
    Date: "08.08.2017",
    price: 0,
  },
  {
    Date: "09.08.2017",
    price: 0,
  },
  {
    Date: "10.08.2017",
    price: 0,
  },
  {
    Date: "11.08.2017",
    price: 0,
  },
  {
    Date: "12.08.2017",
    price: 0,
  },
  {
    Date: "13.08.2017",
    price: 0,
  },
  {
    Date: "14.08.2017",
    price: 0,
  },
  {
    Date: "15.08.2017",
    price: 0,
  },
  {
    Date: "16.08.2017",
    price: 0,
  },
  {
    Date: "17.08.2017",
    price: 0,
  },
  {
    Date: "18.08.2017",
    price: 0,
  },
  {
    Date: "19.08.2017",
    price: 0,
  },
  {
    Date: "20.08.2017",
    price: 0,
  },
  {
    Date: "21.08.2017",
    price: 0,
  },
  {
    Date: "22.08.2017",
    price: 0,
  },
  {
    Date: "23.08.2017",
    price: 0,
  },
  {
    Date: "24.08.2017",
    price: 0,
  },
  {
    Date: "25.08.2017",
    price: 0,
  },
  {
    Date: "26.08.2017",
    price: 0,
  },
  {
    Date: "27.08.2017",
    price: 0,
  },
  {
    Date: "28.08.2017",
    price: 0,
  },
  {
    Date: "29.08.2017",
    price: 0,
  },
  {
    Date: "30.08.2017",
    price: 0,
  },
  {
    Date: "31.08.2017",
    price: 0,
  },
  {
    Date: "01.09.2017",
    price: 0,
  },
  {
    Date: "02.09.2017",
    price: 0,
  },
  {
    Date: "03.09.2017",
    price: 0,
  },
  {
    Date: "04.09.2017",
    price: 0,
  },
  {
    Date: "05.09.2017",
    price: 0,
  },
  {
    Date: "06.09.2017",
    price: 0,
  },
  {
    Date: "07.09.2017",
    price: 0,
  },
  {
    Date: "08.09.2017",
    price: 0,
  },
  {
    Date: "09.09.2017",
    price: 0,
  },
  {
    Date: "10.09.2017",
    price: 0,
  },
  {
    Date: "11.09.2017",
    price: 0,
  },
  {
    Date: "12.09.2017",
    price: 0,
  },
  {
    Date: "13.09.2017",
    price: 0,
  },
  {
    Date: "14.09.2017",
    price: 0,
  },
  {
    Date: "15.09.2017",
    price: 0,
  },
  {
    Date: "16.09.2017",
    price: 0,
  },
  {
    Date: "17.09.2017",
    price: 0,
  },
  {
    Date: "18.09.2017",
    price: 0,
  },
  {
    Date: "19.09.2017",
    price: 0,
  },
  {
    Date: "20.09.2017",
    price: 0,
  },
  {
    Date: "21.09.2017",
    price: 0,
  },
  {
    Date: "22.09.2017",
    price: 0,
  },
  {
    Date: "23.09.2017",
    price: 0,
  },
  {
    Date: "24.09.2017",
    price: 0,
  },
  {
    Date: "25.09.2017",
    price: 0,
  },
  {
    Date: "26.09.2017",
    price: 0,
  },
  {
    Date: "27.09.2017",
    price: 0,
  },
  {
    Date: "28.09.2017",
    price: 0,
  },
  {
    Date: "29.09.2017",
    price: 0,
  },
  {
    Date: "30.09.2017",
    price: 0,
  },
  {
    Date: "01.10.2017",
    price: 0,
  },
  {
    Date: "02.10.2017",
    price: 0,
  },
  {
    Date: "03.10.2017",
    price: 0,
  },
  {
    Date: "04.10.2017",
    price: 0,
  },
  {
    Date: "05.10.2017",
    price: 0,
  },
  {
    Date: "06.10.2017",
    price: 0,
  },
  {
    Date: "07.10.2017",
    price: 0,
  },
  {
    Date: "08.10.2017",
    price: 0,
  },
  {
    Date: "09.10.2017",
    price: 0,
  },
  {
    Date: "10.10.2017",
    price: 0,
  },
  {
    Date: "11.10.2017",
    price: 0,
  },
  {
    Date: "12.10.2017",
    price: 0,
  },
  {
    Date: "13.10.2017",
    price: 0,
  },
  {
    Date: "14.10.2017",
    price: 0,
  },
  {
    Date: "15.10.2017",
    price: 0,
  },
  {
    Date: "16.10.2017",
    price: 0,
  },
  {
    Date: "17.10.2017",
    price: 0,
  },
  {
    Date: "18.10.2017",
    price: 0,
  },
  {
    Date: "19.10.2017",
    price: 0,
  },
  {
    Date: "20.10.2017",
    price: 0,
  },
  {
    Date: "21.10.2017",
    price: 0,
  },
  {
    Date: "22.10.2017",
    price: 0,
  },
  {
    Date: "23.10.2017",
    price: 0,
  },
  {
    Date: "24.10.2017",
    price: 0,
  },
  {
    Date: "25.10.2017",
    price: 0,
  },
  {
    Date: "26.10.2017",
    price: 0,
  },
  {
    Date: "27.10.2017",
    price: 0,
  },
  {
    Date: "28.10.2017",
    price: 0,
  },
  {
    Date: "29.10.2017",
    price: 0,
  },
  {
    Date: "30.10.2017",
    price: 0,
  },
  {
    Date: "31.10.2017",
    price: 0,
  },
  {
    Date: "01.11.2017",
    price: 0,
  },
  {
    Date: "02.11.2017",
    price: 0,
  },
  {
    Date: "03.11.2017",
    price: 0,
  },
  {
    Date: "04.11.2017",
    price: 0,
  },
  {
    Date: "05.11.2017",
    price: 0,
  },
  {
    Date: "06.11.2017",
    price: 0,
  },
  {
    Date: "07.11.2017",
    price: 0,
  },
  {
    Date: "08.11.2017",
    price: 0,
  },
  {
    Date: "09.11.2017",
    price: 0,
  },
  {
    Date: "10.11.2017",
    price: 0,
  },
  {
    Date: "11.11.2017",
    price: 0,
  },
  {
    Date: "12.11.2017",
    price: 0,
  },
  {
    Date: "13.11.2017",
    price: 0,
  },
  {
    Date: "14.11.2017",
    price: 0,
  },
  {
    Date: "15.11.2017",
    price: 0,
  },
  {
    Date: "16.11.2017",
    price: 0,
  },
  {
    Date: "17.11.2017",
    price: 0,
  },
  {
    Date: "18.11.2017",
    price: 0,
  },
  {
    Date: "19.11.2017",
    price: 0,
  },
  {
    Date: "20.11.2017",
    price: 0,
  },
  {
    Date: "21.11.2017",
    price: 0,
  },
  {
    Date: "22.11.2017",
    price: 0,
  },
  {
    Date: "23.11.2017",
    price: 0,
  },
  {
    Date: "24.11.2017",
    price: 0,
  },
  {
    Date: "25.11.2017",
    price: 0,
  },
  {
    Date: "26.11.2017",
    price: 0,
  },
  {
    Date: "27.11.2017",
    price: 0,
  },
  {
    Date: "28.11.2017",
    price: 0,
  },
  {
    Date: "29.11.2017",
    price: 0,
  },
  {
    Date: "30.11.2017",
    price: 0,
  },
  {
    Date: "01.12.2017",
    price: 0,
  },
  {
    Date: "02.12.2017",
    price: 0,
  },
  {
    Date: "03.12.2017",
    price: 0,
  },
  {
    Date: "04.12.2017",
    price: 0,
  },
  {
    Date: "05.12.2017",
    price: 0,
  },
  {
    Date: "06.12.2017",
    price: 0,
  },
  {
    Date: "07.12.2017",
    price: 0,
  },
  {
    Date: "08.12.2017",
    price: 0,
  },
  {
    Date: "09.12.2017",
    price: 0,
  },
  {
    Date: "10.12.2017",
    price: 0,
  },
  {
    Date: "11.12.2017",
    price: 0,
  },
  {
    Date: "12.12.2017",
    price: 0,
  },
  {
    Date: "13.12.2017",
    price: 0,
  },
  {
    Date: "14.12.2017",
    price: 0,
  },
  {
    Date: "15.12.2017",
    price: 0,
  },
  {
    Date: "16.12.2017",
    price: 0,
  },
  {
    Date: "17.12.2017",
    price: 0,
  },
  {
    Date: "18.12.2017",
    price: 0,
  },
  {
    Date: "19.12.2017",
    price: 0,
  },
  {
    Date: "20.12.2017",
    price: 0,
  },
  {
    Date: "21.12.2017",
    price: 0,
  },
  {
    Date: "22.12.2017",
    price: 0,
  },
  {
    Date: "23.12.2017",
    price: 0,
  },
  {
    Date: "24.12.2017",
    price: 0,
  },
  {
    Date: "25.12.2017",
    price: 0,
  },
  {
    Date: "26.12.2017",
    price: 0,
  },
  {
    Date: "27.12.2017",
    price: 0,
  },
  {
    Date: "28.12.2017",
    price: 0,
  },
  {
    Date: "29.12.2017",
    price: 0,
  },
  {
    Date: "30.12.2017",
    price: 0,
  },
  {
    Date: "31.12.2017",
    price: 0,
  },
  {
    Date: "01.01.2018",
    price: 0,
  },
  {
    Date: "02.01.2018",
    price: 0,
  },
  {
    Date: "03.01.2018",
    price: 0,
  },
  {
    Date: "04.01.2018",
    price: 0,
  },
  {
    Date: "05.01.2018",
    price: 0,
  },
  {
    Date: "06.01.2018",
    price: 0,
  },
  {
    Date: "07.01.2018",
    price: 0,
  },
  {
    Date: "08.01.2018",
    price: 0,
  },
  {
    Date: "09.01.2018",
    price: 0,
  },
  {
    Date: "10.01.2018",
    price: 0,
  },
  {
    Date: "11.01.2018",
    price: 0,
  },
  {
    Date: "12.01.2018",
    price: 0,
  },
  {
    Date: "13.01.2018",
    price: 0,
  },
  {
    Date: "14.01.2018",
    price: 0,
  },
  {
    Date: "15.01.2018",
    price: 0,
  },
  {
    Date: "16.01.2018",
    price: 0,
  },
  {
    Date: "17.01.2018",
    price: 0,
  },
  {
    Date: "18.01.2018",
    price: 0,
  },
  {
    Date: "19.01.2018",
    price: 0,
  },
  {
    Date: "20.01.2018",
    price: 0,
  },
  {
    Date: "21.01.2018",
    price: 0,
  },
  {
    Date: "22.01.2018",
    price: 0,
  },
  {
    Date: "23.01.2018",
    price: 0,
  },
  {
    Date: "24.01.2018",
    price: 0,
  },
  {
    Date: "25.01.2018",
    price: 0,
  },
  {
    Date: "26.01.2018",
    price: 0,
  },
  {
    Date: "27.01.2018",
    price: 0,
  },
  {
    Date: "28.01.2018",
    price: 0,
  },
  {
    Date: "29.01.2018",
    price: 0,
  },
  {
    Date: "30.01.2018",
    price: 0,
  },
  {
    Date: "31.01.2018",
    price: 0,
  },
  {
    Date: "01.02.2018",
    price: 0,
  },
  {
    Date: "02.02.2018",
    price: 0,
  },
  {
    Date: "03.02.2018",
    price: 0,
  },
  {
    Date: "04.02.2018",
    price: 0,
  },
  {
    Date: "05.02.2018",
    price: 0,
  },
  {
    Date: "06.02.2018",
    price: 0,
  },
  {
    Date: "07.02.2018",
    price: 0,
  },
  {
    Date: "08.02.2018",
    price: 0,
  },
  {
    Date: "09.02.2018",
    price: 0,
  },
  {
    Date: "10.02.2018",
    price: 0,
  },
  {
    Date: "11.02.2018",
    price: 0,
  },
  {
    Date: "12.02.2018",
    price: 0,
  },
  {
    Date: "13.02.2018",
    price: 0,
  },
  {
    Date: "14.02.2018",
    price: 0,
  },
  {
    Date: "15.02.2018",
    price: 0,
  },
  {
    Date: "16.02.2018",
    price: 0,
  },
  {
    Date: "17.02.2018",
    price: 0,
  },
  {
    Date: "18.02.2018",
    price: 0,
  },
  {
    Date: "19.02.2018",
    price: 0,
  },
  {
    Date: "20.02.2018",
    price: 0,
  },
  {
    Date: "21.02.2018",
    price: 0,
  },
  {
    Date: "22.02.2018",
    price: 0,
  },
  {
    Date: "23.02.2018",
    price: 0,
  },
  {
    Date: "24.02.2018",
    price: 0,
  },
  {
    Date: "25.02.2018",
    price: 0,
  },
  {
    Date: "26.02.2018",
    price: 0,
  },
  {
    Date: "27.02.2018",
    price: 0,
  },
  {
    Date: "28.02.2018",
    price: 0,
  },
  {
    Date: "01.03.2018",
    price: 0,
  },
  {
    Date: "02.03.2018",
    price: 0,
  },
  {
    Date: "03.03.2018",
    price: 0,
  },
  {
    Date: "04.03.2018",
    price: 0,
  },
  {
    Date: "05.03.2018",
    price: 0,
  },
  {
    Date: "06.03.2018",
    price: 0,
  },
  {
    Date: "07.03.2018",
    price: 0,
  },
  {
    Date: "08.03.2018",
    price: 0,
  },
  {
    Date: "09.03.2018",
    price: 0,
  },
  {
    Date: "10.03.2018",
    price: 0,
  },
  {
    Date: "11.03.2018",
    price: 0,
  },
  {
    Date: "12.03.2018",
    price: 0,
  },
  {
    Date: "13.03.2018",
    price: 0,
  },
  {
    Date: "14.03.2018",
    price: 0,
  },
  {
    Date: "15.03.2018",
    price: 0,
  },
  {
    Date: "16.03.2018",
    price: 0,
  },
  {
    Date: "17.03.2018",
    price: 0,
  },
  {
    Date: "18.03.2018",
    price: 0,
  },
  {
    Date: "19.03.2018",
    price: 0,
  },
  {
    Date: "20.03.2018",
    price: 0,
  },
  {
    Date: "21.03.2018",
    price: 0,
  },
  {
    Date: "22.03.2018",
    price: 0,
  },
  {
    Date: "23.03.2018",
    price: 0,
  },
  {
    Date: "24.03.2018",
    price: 0,
  },
  {
    Date: "25.03.2018",
    price: 0,
  },
  {
    Date: "26.03.2018",
    price: 0,
  },
  {
    Date: "27.03.2018",
    price: 0,
  },
  {
    Date: "28.03.2018",
    price: 0,
  },
  {
    Date: "29.03.2018",
    price: 0,
  },
  {
    Date: "30.03.2018",
    price: 0,
  },
  {
    Date: "31.03.2018",
    price: 0,
  },
  {
    Date: "01.04.2018",
    price: 0,
  },
  {
    Date: "02.04.2018",
    price: 0,
  },
  {
    Date: "03.04.2018",
    price: 0,
  },
  {
    Date: "04.04.2018",
    price: 0,
  },
  {
    Date: "05.04.2018",
    price: 0,
  },
  {
    Date: "06.04.2018",
    price: 0,
  },
  {
    Date: "07.04.2018",
    price: 0,
  },
  {
    Date: "08.04.2018",
    price: 0,
  },
  {
    Date: "09.04.2018",
    price: 0,
  },
  {
    Date: "10.04.2018",
    price: 0,
  },
  {
    Date: "11.04.2018",
    price: 0,
  },
  {
    Date: "12.04.2018",
    price: 0,
  },
  {
    Date: "13.04.2018",
    price: 0,
  },
  {
    Date: "14.04.2018",
    price: 0,
  },
  {
    Date: "15.04.2018",
    price: 0,
  },
  {
    Date: "16.04.2018",
    price: 0,
  },
  {
    Date: "17.04.2018",
    price: 0,
  },
  {
    Date: "18.04.2018",
    price: 0,
  },
  {
    Date: "19.04.2018",
    price: 0,
  },
  {
    Date: "20.04.2018",
    price: 0,
  },
  {
    Date: "21.04.2018",
    price: 0,
  },
  {
    Date: "22.04.2018",
    price: 0,
  },
  {
    Date: "23.04.2018",
    price: 0,
  },
  {
    Date: "24.04.2018",
    price: 0,
  },
  {
    Date: "25.04.2018",
    price: 0,
  },
  {
    Date: "26.04.2018",
    price: 0,
  },
  {
    Date: "27.04.2018",
    price: 0,
  },
  {
    Date: "28.04.2018",
    price: 0,
  },
  {
    Date: "29.04.2018",
    price: 0,
  },
  {
    Date: "30.04.2018",
    price: 0,
  },
  {
    Date: "01.05.2018",
    price: 0,
  },
  {
    Date: "02.05.2018",
    price: 0,
  },
  {
    Date: "03.05.2018",
    price: 0,
  },
  {
    Date: "04.05.2018",
    price: 0,
  },
  {
    Date: "05.05.2018",
    price: 0,
  },
  {
    Date: "06.05.2018",
    price: 0,
  },
  {
    Date: "07.05.2018",
    price: 0,
  },
  {
    Date: "08.05.2018",
    price: 0,
  },
  {
    Date: "09.05.2018",
    price: 0,
  },
  {
    Date: "10.05.2018",
    price: 0,
  },
  {
    Date: "11.05.2018",
    price: 0,
  },
  {
    Date: "12.05.2018",
    price: 0,
  },
  {
    Date: "13.05.2018",
    price: 0,
  },
  {
    Date: "14.05.2018",
    price: 0,
  },
  {
    Date: "15.05.2018",
    price: 0,
  },
  {
    Date: "16.05.2018",
    price: 0,
  },
  {
    Date: "17.05.2018",
    price: 0,
  },
  {
    Date: "18.05.2018",
    price: 0,
  },
  {
    Date: "19.05.2018",
    price: 0,
  },
  {
    Date: "20.05.2018",
    price: 0,
  },
  {
    Date: "21.05.2018",
    price: 0,
  },
  {
    Date: "22.05.2018",
    price: 0,
  },
  {
    Date: "23.05.2018",
    price: 0,
  },
  {
    Date: "24.05.2018",
    price: 0,
  },
  {
    Date: "25.05.2018",
    price: 0,
  },
  {
    Date: "26.05.2018",
    price: 0,
  },
  {
    Date: "27.05.2018",
    price: 0,
  },
  {
    Date: "28.05.2018",
    price: 0,
  },
  {
    Date: "29.05.2018",
    price: 0,
  },
  {
    Date: "30.05.2018",
    price: 0,
  },
  {
    Date: "31.05.2018",
    price: 0,
  },
  {
    Date: "01.06.2018",
    price: 0,
  },
  {
    Date: "02.06.2018",
    price: 0,
  },
  {
    Date: "03.06.2018",
    price: 0,
  },
  {
    Date: "04.06.2018",
    price: 0,
  },
  {
    Date: "05.06.2018",
    price: 0,
  },
  {
    Date: "06.06.2018",
    price: 0,
  },
  {
    Date: "07.06.2018",
    price: 0,
  },
  {
    Date: "08.06.2018",
    price: 0,
  },
  {
    Date: "09.06.2018",
    price: 0,
  },
  {
    Date: "10.06.2018",
    price: 0,
  },
  {
    Date: "11.06.2018",
    price: 0,
  },
  {
    Date: "12.06.2018",
    price: 0,
  },
  {
    Date: "13.06.2018",
    price: 0,
  },
  {
    Date: "14.06.2018",
    price: 0,
  },
  {
    Date: "15.06.2018",
    price: 0,
  },
  {
    Date: "16.06.2018",
    price: 0,
  },
  {
    Date: "17.06.2018",
    price: 0,
  },
  {
    Date: "18.06.2018",
    price: 0,
  },
  {
    Date: "19.06.2018",
    price: 0,
  },
  {
    Date: "20.06.2018",
    price: 0,
  },
  {
    Date: "21.06.2018",
    price: 0,
  },
  {
    Date: "22.06.2018",
    price: 0,
  },
  {
    Date: "23.06.2018",
    price: 0,
  },
  {
    Date: "24.06.2018",
    price: 0,
  },
  {
    Date: "25.06.2018",
    price: 0,
  },
  {
    Date: "26.06.2018",
    price: 0,
  },
  {
    Date: "27.06.2018",
    price: 0,
  },
  {
    Date: "28.06.2018",
    price: 0,
  },
  {
    Date: "29.06.2018",
    price: 0,
  },
  {
    Date: "30.06.2018",
    price: 0,
  },
  {
    Date: "01.07.2018",
    price: 0,
  },
  {
    Date: "02.07.2018",
    price: 0,
  },
  {
    Date: "03.07.2018",
    price: 0,
  },
  {
    Date: "04.07.2018",
    price: 0,
  },
  {
    Date: "05.07.2018",
    price: 0,
  },
  {
    Date: "06.07.2018",
    price: 0,
  },
  {
    Date: "07.07.2018",
    price: 0,
  },
  {
    Date: "08.07.2018",
    price: 0,
  },
  {
    Date: "09.07.2018",
    price: 0,
  },
  {
    Date: "10.07.2018",
    price: 0,
  },
  {
    Date: "11.07.2018",
    price: 0,
  },
  {
    Date: "12.07.2018",
    price: 0,
  },
  {
    Date: "13.07.2018",
    price: 0,
  },
  {
    Date: "14.07.2018",
    price: 0,
  },
  {
    Date: "15.07.2018",
    price: 0,
  },
  {
    Date: "16.07.2018",
    price: 0,
  },
  {
    Date: "17.07.2018",
    price: 0,
  },
  {
    Date: "18.07.2018",
    price: 0,
  },
  {
    Date: "19.07.2018",
    price: 0,
  },
  {
    Date: "20.07.2018",
    price: 0,
  },
  {
    Date: "21.07.2018",
    price: 0,
  },
  {
    Date: "22.07.2018",
    price: 0,
  },
  {
    Date: "23.07.2018",
    price: 0,
  },
  {
    Date: "24.07.2018",
    price: 0,
  },
  {
    Date: "25.07.2018",
    price: 0,
  },
  {
    Date: "26.07.2018",
    price: 0,
  },
  {
    Date: "27.07.2018",
    price: 0,
  },
  {
    Date: "28.07.2018",
    price: 0,
  },
  {
    Date: "29.07.2018",
    price: 0,
  },
  {
    Date: "30.07.2018",
    price: 0,
  },
  {
    Date: "31.07.2018",
    price: 0,
  },
  {
    Date: "01.08.2018",
    price: 0,
  },
  {
    Date: "02.08.2018",
    price: 0,
  },
  {
    Date: "03.08.2018",
    price: 0,
  },
  {
    Date: "04.08.2018",
    price: 0,
  },
  {
    Date: "05.08.2018",
    price: 0,
  },
  {
    Date: "06.08.2018",
    price: 0,
  },
  {
    Date: "07.08.2018",
    price: 0,
  },
  {
    Date: "08.08.2018",
    price: 0,
  },
  {
    Date: "09.08.2018",
    price: 0,
  },
  {
    Date: "10.08.2018",
    price: 0,
  },
  {
    Date: "11.08.2018",
    price: 0,
  },
  {
    Date: "12.08.2018",
    price: 0,
  },
  {
    Date: "13.08.2018",
    price: 0,
  },
  {
    Date: "14.08.2018",
    price: 0,
  },
  {
    Date: "15.08.2018",
    price: 0,
  },
  {
    Date: "16.08.2018",
    price: 0,
  },
  {
    Date: "17.08.2018",
    price: 0,
  },
  {
    Date: "18.08.2018",
    price: 0,
  },
  {
    Date: "19.08.2018",
    price: 0,
  },
  {
    Date: "20.08.2018",
    price: 0,
  },
  {
    Date: "21.08.2018",
    price: 0,
  },
  {
    Date: "22.08.2018",
    price: 0,
  },
  {
    Date: "23.08.2018",
    price: 0,
  },
  {
    Date: "24.08.2018",
    price: 0,
  },
  {
    Date: "25.08.2018",
    price: 0,
  },
  {
    Date: "26.08.2018",
    price: 0,
  },
  {
    Date: "27.08.2018",
    price: 0,
  },
  {
    Date: "28.08.2018",
    price: 0,
  },
  {
    Date: "29.08.2018",
    price: 0,
  },
  {
    Date: "30.08.2018",
    price: 0,
  },
  {
    Date: "31.08.2018",
    price: 0,
  },
  {
    Date: "01.09.2018",
    price: 0,
  },
  {
    Date: "02.09.2018",
    price: 0,
  },
  {
    Date: "03.09.2018",
    price: 0,
  },
  {
    Date: "04.09.2018",
    price: 0,
  },
  {
    Date: "05.09.2018",
    price: 0,
  },
  {
    Date: "06.09.2018",
    price: 0,
  },
  {
    Date: "07.09.2018",
    price: 0,
  },
  {
    Date: "08.09.2018",
    price: 0,
  },
  {
    Date: "09.09.2018",
    price: 0,
  },
  {
    Date: "10.09.2018",
    price: 0,
  },
  {
    Date: "11.09.2018",
    price: 0,
  },
  {
    Date: "12.09.2018",
    price: 0,
  },
  {
    Date: "13.09.2018",
    price: 0,
  },
  {
    Date: "14.09.2018",
    price: 0,
  },
  {
    Date: "15.09.2018",
    price: 0,
  },
  {
    Date: "16.09.2018",
    price: 0,
  },
  {
    Date: "17.09.2018",
    price: 0,
  },
  {
    Date: "18.09.2018",
    price: 0,
  },
  {
    Date: "19.09.2018",
    price: 0,
  },
  {
    Date: "20.09.2018",
    price: 0,
  },
  {
    Date: "21.09.2018",
    price: 0,
  },
  {
    Date: "22.09.2018",
    price: 0,
  },
  {
    Date: "23.09.2018",
    price: 0,
  },
  {
    Date: "24.09.2018",
    price: 0,
  },
  {
    Date: "25.09.2018",
    price: 0,
  },
  {
    Date: "26.09.2018",
    price: 0,
  },
  {
    Date: "27.09.2018",
    price: 0,
  },
  {
    Date: "28.09.2018",
    price: 0,
  },
  {
    Date: "29.09.2018",
    price: 0,
  },
  {
    Date: "30.09.2018",
    price: 0,
  },
  {
    Date: "01.10.2018",
    price: 0,
  },
  {
    Date: "02.10.2018",
    price: 0,
  },
  {
    Date: "03.10.2018",
    price: 0,
  },
  {
    Date: "04.10.2018",
    price: 0,
  },
  {
    Date: "05.10.2018",
    price: 0,
  },
  {
    Date: "06.10.2018",
    price: 0,
  },
  {
    Date: "07.10.2018",
    price: 0,
  },
  {
    Date: "08.10.2018",
    price: 0,
  },
  {
    Date: "09.10.2018",
    price: 0,
  },
  {
    Date: "10.10.2018",
    price: 0,
  },
  {
    Date: "11.10.2018",
    price: 0,
  },
  {
    Date: "12.10.2018",
    price: 0,
  },
  {
    Date: "13.10.2018",
    price: 0,
  },
  {
    Date: "14.10.2018",
    price: 0,
  },
  {
    Date: "15.10.2018",
    price: 0,
  },
  {
    Date: "16.10.2018",
    price: 0,
  },
  {
    Date: "17.10.2018",
    price: 0,
  },
  {
    Date: "18.10.2018",
    price: 0,
  },
  {
    Date: "19.10.2018",
    price: 0,
  },
  {
    Date: "20.10.2018",
    price: 0,
  },
  {
    Date: "21.10.2018",
    price: 0,
  },
  {
    Date: "22.10.2018",
    price: 0,
  },
  {
    Date: "23.10.2018",
    price: 0,
  },
  {
    Date: "24.10.2018",
    price: 0,
  },
  {
    Date: "25.10.2018",
    price: 0,
  },
  {
    Date: "26.10.2018",
    price: 0,
  },
  {
    Date: "27.10.2018",
    price: 0,
  },
  {
    Date: "28.10.2018",
    price: 0,
  },
  {
    Date: "29.10.2018",
    price: 0,
  },
  {
    Date: "30.10.2018",
    price: 0,
  },
  {
    Date: "31.10.2018",
    price: 0,
  },
  {
    Date: "01.11.2018",
    price: 0,
  },
  {
    Date: "02.11.2018",
    price: 0,
  },
  {
    Date: "03.11.2018",
    price: 0,
  },
  {
    Date: "04.11.2018",
    price: 0,
  },
  {
    Date: "05.11.2018",
    price: 0,
  },
  {
    Date: "06.11.2018",
    price: 0,
  },
  {
    Date: "07.11.2018",
    price: 0,
  },
  {
    Date: "08.11.2018",
    price: 0,
  },
  {
    Date: "09.11.2018",
    price: 0,
  },
  {
    Date: "10.11.2018",
    price: 0,
  },
  {
    Date: "11.11.2018",
    price: 0,
  },
  {
    Date: "12.11.2018",
    price: 0,
  },
  {
    Date: "13.11.2018",
    price: 0,
  },
  {
    Date: "14.11.2018",
    price: 0,
  },
  {
    Date: "15.11.2018",
    price: 0,
  },
  {
    Date: "16.11.2018",
    price: 0,
  },
  {
    Date: "17.11.2018",
    price: 0,
  },
  {
    Date: "18.11.2018",
    price: 0,
  },
  {
    Date: "19.11.2018",
    price: 0,
  },
  {
    Date: "20.11.2018",
    price: 0,
  },
  {
    Date: "21.11.2018",
    price: 0,
  },
  {
    Date: "22.11.2018",
    price: 0,
  },
  {
    Date: "23.11.2018",
    price: 0,
  },
  {
    Date: "24.11.2018",
    price: 0,
  },
  {
    Date: "25.11.2018",
    price: 0,
  },
  {
    Date: "26.11.2018",
    price: 0,
  },
  {
    Date: "27.11.2018",
    price: 0,
  },
  {
    Date: "28.11.2018",
    price: 0,
  },
  {
    Date: "29.11.2018",
    price: 0,
  },
  {
    Date: "30.11.2018",
    price: 0,
  },
  {
    Date: "01.12.2018",
    price: 0,
  },
  {
    Date: "02.12.2018",
    price: 0,
  },
  {
    Date: "03.12.2018",
    price: 0,
  },
  {
    Date: "04.12.2018",
    price: 0,
  },
  {
    Date: "05.12.2018",
    price: 0,
  },
  {
    Date: "06.12.2018",
    price: 0,
  },
  {
    Date: "07.12.2018",
    price: 0,
  },
  {
    Date: "08.12.2018",
    price: 0,
  },
  {
    Date: "09.12.2018",
    price: 0,
  },
  {
    Date: "10.12.2018",
    price: 0,
  },
  {
    Date: "11.12.2018",
    price: 0,
  },
  {
    Date: "12.12.2018",
    price: 0,
  },
  {
    Date: "13.12.2018",
    price: 0,
  },
  {
    Date: "14.12.2018",
    price: 0,
  },
  {
    Date: "15.12.2018",
    price: 0,
  },
  {
    Date: "16.12.2018",
    price: 0,
  },
  {
    Date: "17.12.2018",
    price: 0,
  },
  {
    Date: "18.12.2018",
    price: 0,
  },
  {
    Date: "19.12.2018",
    price: 0,
  },
  {
    Date: "20.12.2018",
    price: 0,
  },
  {
    Date: "21.12.2018",
    price: 0,
  },
  {
    Date: "22.12.2018",
    price: 0,
  },
  {
    Date: "23.12.2018",
    price: 0,
  },
  {
    Date: "24.12.2018",
    price: 0,
  },
  {
    Date: "25.12.2018",
    price: 0,
  },
  {
    Date: "26.12.2018",
    price: 0,
  },
  {
    Date: "27.12.2018",
    price: 0,
  },
  {
    Date: "28.12.2018",
    price: 0,
  },
  {
    Date: "29.12.2018",
    price: 0,
  },
  {
    Date: "30.12.2018",
    price: 0,
  },
  {
    Date: "31.12.2018",
    price: 0,
  },
  {
    Date: "01.01.2019",
    price: 0,
  },
  {
    Date: "02.01.2019",
    price: 0,
  },
  {
    Date: "03.01.2019",
    price: 0,
  },
  {
    Date: "04.01.2019",
    price: 0,
  },
  {
    Date: "05.01.2019",
    price: 0,
  },
  {
    Date: "06.01.2019",
    price: 0,
  },
  {
    Date: "07.01.2019",
    price: 0,
  },
  {
    Date: "08.01.2019",
    price: 0,
  },
  {
    Date: "09.01.2019",
    price: 0,
  },
  {
    Date: "10.01.2019",
    price: 0,
  },
  {
    Date: "11.01.2019",
    price: 0,
  },
  {
    Date: "12.01.2019",
    price: 0,
  },
  {
    Date: "13.01.2019",
    price: 0,
  },
  {
    Date: "14.01.2019",
    price: 0,
  },
  {
    Date: "15.01.2019",
    price: 0,
  },
  {
    Date: "16.01.2019",
    price: 0,
  },
  {
    Date: "17.01.2019",
    price: 0,
  },
  {
    Date: "18.01.2019",
    price: 0,
  },
  {
    Date: "19.01.2019",
    price: 0,
  },
  {
    Date: "20.01.2019",
    price: 0,
  },
  {
    Date: "21.01.2019",
    price: 0,
  },
  {
    Date: "22.01.2019",
    price: 0,
  },
  {
    Date: "23.01.2019",
    price: 0,
  },
  {
    Date: "24.01.2019",
    price: 0,
  },
  {
    Date: "25.01.2019",
    price: 0,
  },
  {
    Date: "26.01.2019",
    price: 0,
  },
  {
    Date: "27.01.2019",
    price: 0,
  },
  {
    Date: "28.01.2019",
    price: 0,
  },
  {
    Date: "29.01.2019",
    price: 0,
  },
  {
    Date: "30.01.2019",
    price: 0,
  },
  {
    Date: "31.01.2019",
    price: 0,
  },
  {
    Date: "01.02.2019",
    price: 0,
  },
  {
    Date: "02.02.2019",
    price: 0,
  },
  {
    Date: "03.02.2019",
    price: 0,
  },
  {
    Date: "04.02.2019",
    price: 0,
  },
  {
    Date: "05.02.2019",
    price: 0,
  },
  {
    Date: "06.02.2019",
    price: 0,
  },
  {
    Date: "07.02.2019",
    price: 0,
  },
  {
    Date: "08.02.2019",
    price: 0,
  },
  {
    Date: "09.02.2019",
    price: 0,
  },
  {
    Date: "10.02.2019",
    price: 0,
  },
  {
    Date: "11.02.2019",
    price: 0,
  },
  {
    Date: "12.02.2019",
    price: 0,
  },
  {
    Date: "13.02.2019",
    price: 0,
  },
  {
    Date: "14.02.2019",
    price: 0,
  },
  {
    Date: "15.02.2019",
    price: 0,
  },
  {
    Date: "16.02.2019",
    price: 0,
  },
  {
    Date: "17.02.2019",
    price: 0,
  },
  {
    Date: "18.02.2019",
    price: 0,
  },
  {
    Date: "19.02.2019",
    price: 0,
  },
  {
    Date: "20.02.2019",
    price: 0,
  },
  {
    Date: "21.02.2019",
    price: 0,
  },
  {
    Date: "22.02.2019",
    price: 0,
  },
  {
    Date: "23.02.2019",
    price: 0,
  },
  {
    Date: "24.02.2019",
    price: 0,
  },
  {
    Date: "25.02.2019",
    price: 0,
  },
  {
    Date: "26.02.2019",
    price: 0,
  },
  {
    Date: "27.02.2019",
    price: 0,
  },
  {
    Date: "28.02.2019",
    price: 0,
  },
  {
    Date: "01.03.2019",
    price: 0,
  },
  {
    Date: "02.03.2019",
    price: 0,
  },
  {
    Date: "03.03.2019",
    price: 0,
  },
  {
    Date: "04.03.2019",
    price: 0,
  },
  {
    Date: "05.03.2019",
    price: 0,
  },
  {
    Date: "06.03.2019",
    price: 0,
  },
  {
    Date: "07.03.2019",
    price: 0,
  },
  {
    Date: "08.03.2019",
    price: 0,
  },
  {
    Date: "09.03.2019",
    price: 0,
  },
  {
    Date: "10.03.2019",
    price: 0,
  },
  {
    Date: "11.03.2019",
    price: 0,
  },
  {
    Date: "12.03.2019",
    price: 0,
  },
  {
    Date: "13.03.2019",
    price: 0,
  },
  {
    Date: "14.03.2019",
    price: 0,
  },
  {
    Date: "15.03.2019",
    price: 0,
  },
  {
    Date: "16.03.2019",
    price: 0,
  },
  {
    Date: "17.03.2019",
    price: 0,
  },
  {
    Date: "18.03.2019",
    price: 0,
  },
  {
    Date: "19.03.2019",
    price: 0,
  },
  {
    Date: "20.03.2019",
    price: 0,
  },
  {
    Date: "21.03.2019",
    price: 0,
  },
  {
    Date: "22.03.2019",
    price: 0,
  },
  {
    Date: "23.03.2019",
    price: 0,
  },
  {
    Date: "24.03.2019",
    price: 0,
  },
  {
    Date: "25.03.2019",
    price: 0,
  },
  {
    Date: "26.03.2019",
    price: 0,
  },
  {
    Date: "27.03.2019",
    price: 0,
  },
  {
    Date: "28.03.2019",
    price: 0,
  },
  {
    Date: "29.03.2019",
    price: 0,
  },
  {
    Date: "30.03.2019",
    price: 0,
  },
  {
    Date: "31.03.2019",
    price: 0,
  },
  {
    Date: "01.04.2019",
    price: 0,
  },
  {
    Date: "02.04.2019",
    price: 0,
  },
  {
    Date: "03.04.2019",
    price: 0,
  },
  {
    Date: "04.04.2019",
    price: 0,
  },
  {
    Date: "05.04.2019",
    price: 0,
  },
  {
    Date: "06.04.2019",
    price: 0,
  },
  {
    Date: "07.04.2019",
    price: 0,
  },
  {
    Date: "08.04.2019",
    price: 0,
  },
  {
    Date: "09.04.2019",
    price: 0,
  },
  {
    Date: "10.04.2019",
    price: 0,
  },
  {
    Date: "11.04.2019",
    price: 0,
  },
  {
    Date: "12.04.2019",
    price: 0,
  },
  {
    Date: "13.04.2019",
    price: 0,
  },
  {
    Date: "14.04.2019",
    price: 0,
  },
  {
    Date: "15.04.2019",
    price: 0,
  },
  {
    Date: "16.04.2019",
    price: 0,
  },
  {
    Date: "17.04.2019",
    price: 0,
  },
  {
    Date: "18.04.2019",
    price: 0,
  },
  {
    Date: "19.04.2019",
    price: 0,
  },
  {
    Date: "20.04.2019",
    price: 0,
  },
  {
    Date: "21.04.2019",
    price: 0,
  },
  {
    Date: "22.04.2019",
    price: 0,
  },
  {
    Date: "23.04.2019",
    price: 0,
  },
  {
    Date: "24.04.2019",
    price: 0,
  },
  {
    Date: "25.04.2019",
    price: 0,
  },
  {
    Date: "26.04.2019",
    price: 0,
  },
  {
    Date: "27.04.2019",
    price: 0,
  },
  {
    Date: "28.04.2019",
    price: 0.004521,
  },
  {
    Date: "29.04.2019",
    price: 0.004418,
  },
  {
    Date: "30.04.2019",
    price: 0.003462,
  },
  {
    Date: "01.05.2019",
    price: 0.00444,
  },
  {
    Date: "02.05.2019",
    price: 0.004366,
  },
  {
    Date: "03.05.2019",
    price: 0.004192,
  },
  {
    Date: "04.05.2019",
    price: 0.004256,
  },
  {
    Date: "05.05.2019",
    price: 0.003969,
  },
  {
    Date: "06.05.2019",
    price: 0.003746,
  },
  {
    Date: "07.05.2019",
    price: 0.003768,
  },
  {
    Date: "08.05.2019",
    price: 0.003343,
  },
  {
    Date: "09.05.2019",
    price: 0.003351,
  },
  {
    Date: "10.05.2019",
    price: 0.003143,
  },
  {
    Date: "11.05.2019",
    price: 0.003775,
  },
  {
    Date: "12.05.2019",
    price: 0.004619,
  },
  {
    Date: "13.05.2019",
    price: 0.005273,
  },
  {
    Date: "14.05.2019",
    price: 0.008548,
  },
  {
    Date: "15.05.2019",
    price: 0.007731,
  },
  {
    Date: "16.05.2019",
    price: 0.009549,
  },
  {
    Date: "17.05.2019",
    price: 0.010863,
  },
  {
    Date: "18.05.2019",
    price: 0.014353,
  },
  {
    Date: "19.05.2019",
    price: 0.01232,
  },
  {
    Date: "20.05.2019",
    price: 0.020231,
  },
  {
    Date: "21.05.2019",
    price: 0.029349,
  },
  {
    Date: "22.05.2019",
    price: 0.029454,
  },
  {
    Date: "23.05.2019",
    price: 0.022186,
  },
  {
    Date: "24.05.2019",
    price: 0.027429,
  },
  {
    Date: "25.05.2019",
    price: 0.025363,
  },
  {
    Date: "26.05.2019",
    price: 0.024888,
  },
  {
    Date: "27.05.2019",
    price: 0.024386,
  },
  {
    Date: "28.05.2019",
    price: 0.022252,
  },
  {
    Date: "29.05.2019",
    price: 0.023642,
  },
  {
    Date: "30.05.2019",
    price: 0.022108,
  },
  {
    Date: "31.05.2019",
    price: 0.019793,
  },
  {
    Date: "01.06.2019",
    price: 0.0222,
  },
  {
    Date: "02.06.2019",
    price: 0.021059,
  },
  {
    Date: "03.06.2019",
    price: 0.020114,
  },
  {
    Date: "04.06.2019",
    price: 0.019168,
  },
  {
    Date: "05.06.2019",
    price: 0.021307,
  },
  {
    Date: "06.06.2019",
    price: 0.022839,
  },
  {
    Date: "07.06.2019",
    price: 0.023677,
  },
  {
    Date: "08.06.2019",
    price: 0.024413,
  },
  {
    Date: "09.06.2019",
    price: 0.025876,
  },
  {
    Date: "10.06.2019",
    price: 0.025467,
  },
  {
    Date: "11.06.2019",
    price: 0.026987,
  },
  {
    Date: "12.06.2019",
    price: 0.023812,
  },
  {
    Date: "13.06.2019",
    price: 0.022943,
  },
  {
    Date: "14.06.2019",
    price: 0.023182,
  },
  {
    Date: "15.06.2019",
    price: 0.020686,
  },
  {
    Date: "16.06.2019",
    price: 0.021899,
  },
  {
    Date: "17.06.2019",
    price: 0.022647,
  },
  {
    Date: "18.06.2019",
    price: 0.021767,
  },
  {
    Date: "19.06.2019",
    price: 0.022462,
  },
  {
    Date: "20.06.2019",
    price: 0.023054,
  },
  {
    Date: "21.06.2019",
    price: 0.022445,
  },
  {
    Date: "22.06.2019",
    price: 0.02117,
  },
  {
    Date: "23.06.2019",
    price: 0.021839,
  },
  {
    Date: "24.06.2019",
    price: 0.020988,
  },
  {
    Date: "25.06.2019",
    price: 0.020664,
  },
  {
    Date: "26.06.2019",
    price: 0.019054,
  },
  {
    Date: "27.06.2019",
    price: 0.017804,
  },
  {
    Date: "28.06.2019",
    price: 0.01928,
  },
  {
    Date: "29.06.2019",
    price: 0.019159,
  },
  {
    Date: "30.06.2019",
    price: 0.023872,
  },
  {
    Date: "01.07.2019",
    price: 0.021971,
  },
  {
    Date: "02.07.2019",
    price: 0.022229,
  },
  {
    Date: "03.07.2019",
    price: 0.020004,
  },
  {
    Date: "04.07.2019",
    price: 0.019331,
  },
  {
    Date: "05.07.2019",
    price: 0.018092,
  },
  {
    Date: "06.07.2019",
    price: 0.018117,
  },
  {
    Date: "07.07.2019",
    price: 0.017606,
  },
  {
    Date: "08.07.2019",
    price: 0.017642,
  },
  {
    Date: "09.07.2019",
    price: 0.016721,
  },
  {
    Date: "10.07.2019",
    price: 0.016222,
  },
  {
    Date: "11.07.2019",
    price: 0.016144,
  },
  {
    Date: "12.07.2019",
    price: 0.014439,
  },
  {
    Date: "13.07.2019",
    price: 0.015838,
  },
  {
    Date: "14.07.2019",
    price: 0.016611,
  },
  {
    Date: "15.07.2019",
    price: 0.014308,
  },
  {
    Date: "16.07.2019",
    price: 0.013866,
  },
  {
    Date: "17.07.2019",
    price: 0.011983,
  },
  {
    Date: "18.07.2019",
    price: 0.013239,
  },
  {
    Date: "19.07.2019",
    price: 0.013706,
  },
  {
    Date: "20.07.2019",
    price: 0.013456,
  },
  {
    Date: "21.07.2019",
    price: 0.013972,
  },
  {
    Date: "22.07.2019",
    price: 0.013448,
  },
  {
    Date: "23.07.2019",
    price: 0.012749,
  },
  {
    Date: "24.07.2019",
    price: 0.0115,
  },
  {
    Date: "25.07.2019",
    price: 0.012039,
  },
  {
    Date: "26.07.2019",
    price: 0.012219,
  },
  {
    Date: "27.07.2019",
    price: 0.012782,
  },
  {
    Date: "28.07.2019",
    price: 0.011952,
  },
  {
    Date: "29.07.2019",
    price: 0.01179,
  },
  {
    Date: "30.07.2019",
    price: 0.011418,
  },
  {
    Date: "31.07.2019",
    price: 0.011541,
  },
  {
    Date: "01.08.2019",
    price: 0.011393,
  },
  {
    Date: "02.08.2019",
    price: 0.010714,
  },
  {
    Date: "03.08.2019",
    price: 0.011095,
  },
  {
    Date: "04.08.2019",
    price: 0.010667,
  },
  {
    Date: "05.08.2019",
    price: 0.010264,
  },
  {
    Date: "06.08.2019",
    price: 0.011797,
  },
  {
    Date: "07.08.2019",
    price: 0.015281,
  },
  {
    Date: "08.08.2019",
    price: 0.016646,
  },
  {
    Date: "09.08.2019",
    price: 0.01438,
  },
  {
    Date: "10.08.2019",
    price: 0.012197,
  },
  {
    Date: "11.08.2019",
    price: 0.015012,
  },
  {
    Date: "12.08.2019",
    price: 0.016083,
  },
  {
    Date: "13.08.2019",
    price: 0.016911,
  },
  {
    Date: "14.08.2019",
    price: 0.016564,
  },
  {
    Date: "15.08.2019",
    price: 0.014685,
  },
  {
    Date: "16.08.2019",
    price: 0.014627,
  },
  {
    Date: "17.08.2019",
    price: 0.013365,
  },
  {
    Date: "18.08.2019",
    price: 0.01416,
  },
  {
    Date: "19.08.2019",
    price: 0.013919,
  },
  {
    Date: "20.08.2019",
    price: 0.015466,
  },
  {
    Date: "21.08.2019",
    price: 0.015758,
  },
  {
    Date: "22.08.2019",
    price: 0.015414,
  },
  {
    Date: "23.08.2019",
    price: 0.016794,
  },
  {
    Date: "24.08.2019",
    price: 0.015382,
  },
  {
    Date: "25.08.2019",
    price: 0.014857,
  },
  {
    Date: "26.08.2019",
    price: 0.013755,
  },
  {
    Date: "27.08.2019",
    price: 0.013938,
  },
  {
    Date: "28.08.2019",
    price: 0.01423,
  },
  {
    Date: "29.08.2019",
    price: 0.012942,
  },
  {
    Date: "30.08.2019",
    price: 0.011709,
  },
  {
    Date: "31.08.2019",
    price: 0.012271,
  },
  {
    Date: "01.09.2019",
    price: 0.011847,
  },
  {
    Date: "02.09.2019",
    price: 0.01223,
  },
  {
    Date: "03.09.2019",
    price: 0.013719,
  },
  {
    Date: "04.09.2019",
    price: 0.014349,
  },
  {
    Date: "05.09.2019",
    price: 0.014151,
  },
  {
    Date: "06.09.2019",
    price: 0.014845,
  },
  {
    Date: "07.09.2019",
    price: 0.013658,
  },
  {
    Date: "08.09.2019",
    price: 0.013903,
  },
  {
    Date: "09.09.2019",
    price: 0.013927,
  },
  {
    Date: "10.09.2019",
    price: 0.014158,
  },
  {
    Date: "11.09.2019",
    price: 0.01354,
  },
  {
    Date: "12.09.2019",
    price: 0.013117,
  },
  {
    Date: "13.09.2019",
    price: 0.012975,
  },
  {
    Date: "14.09.2019",
    price: 0.012935,
  },
  {
    Date: "15.09.2019",
    price: 0.013108,
  },
  {
    Date: "16.09.2019",
    price: 0.012921,
  },
  {
    Date: "17.09.2019",
    price: 0.012907,
  },
  {
    Date: "18.09.2019",
    price: 0.013534,
  },
  {
    Date: "19.09.2019",
    price: 0.014356,
  },
  {
    Date: "20.09.2019",
    price: 0.013745,
  },
  {
    Date: "21.09.2019",
    price: 0.01368,
  },
  {
    Date: "22.09.2019",
    price: 0.013394,
  },
  {
    Date: "23.09.2019",
    price: 0.012591,
  },
  {
    Date: "24.09.2019",
    price: 0.012249,
  },
  {
    Date: "25.09.2019",
    price: 0.011061,
  },
  {
    Date: "26.09.2019",
    price: 0.011038,
  },
  {
    Date: "27.09.2019",
    price: 0.01064,
  },
  {
    Date: "28.09.2019",
    price: 0.010953,
  },
  {
    Date: "29.09.2019",
    price: 0.011257,
  },
  {
    Date: "30.09.2019",
    price: 0.010939,
  },
  {
    Date: "01.10.2019",
    price: 0.010851,
  },
  {
    Date: "02.10.2019",
    price: 0.010731,
  },
  {
    Date: "03.10.2019",
    price: 0.010845,
  },
  {
    Date: "04.10.2019",
    price: 0.010666,
  },
  {
    Date: "05.10.2019",
    price: 0.010716,
  },
  {
    Date: "06.10.2019",
    price: 0.010587,
  },
  {
    Date: "07.10.2019",
    price: 0.010383,
  },
  {
    Date: "08.10.2019",
    price: 0.011002,
  },
  {
    Date: "09.10.2019",
    price: 0.012439,
  },
  {
    Date: "10.10.2019",
    price: 0.014322,
  },
  {
    Date: "11.10.2019",
    price: 0.016193,
  },
  {
    Date: "12.10.2019",
    price: 0.015958,
  },
  {
    Date: "13.10.2019",
    price: 0.01559,
  },
  {
    Date: "14.10.2019",
    price: 0.015021,
  },
  {
    Date: "15.10.2019",
    price: 0.015306,
  },
  {
    Date: "16.10.2019",
    price: 0.014415,
  },
  {
    Date: "17.10.2019",
    price: 0.013903,
  },
  {
    Date: "18.10.2019",
    price: 0.014343,
  },
  {
    Date: "19.10.2019",
    price: 0.013369,
  },
  {
    Date: "20.10.2019",
    price: 0.013813,
  },
  {
    Date: "21.10.2019",
    price: 0.013873,
  },
  {
    Date: "22.10.2019",
    price: 0.014943,
  },
  {
    Date: "23.10.2019",
    price: 0.014538,
  },
  {
    Date: "24.10.2019",
    price: 0.013298,
  },
  {
    Date: "25.10.2019",
    price: 0.013594,
  },
  {
    Date: "26.10.2019",
    price: 0.014391,
  },
  {
    Date: "27.10.2019",
    price: 0.013547,
  },
  {
    Date: "28.10.2019",
    price: 0.014092,
  },
  {
    Date: "29.10.2019",
    price: 0.014137,
  },
  {
    Date: "30.10.2019",
    price: 0.014098,
  },
  {
    Date: "31.10.2019",
    price: 0.013476,
  },
  {
    Date: "01.11.2019",
    price: 0.013661,
  },
  {
    Date: "02.11.2019",
    price: 0.013783,
  },
  {
    Date: "03.11.2019",
    price: 0.013595,
  },
  {
    Date: "04.11.2019",
    price: 0.013573,
  },
  {
    Date: "05.11.2019",
    price: 0.014397,
  },
  {
    Date: "06.11.2019",
    price: 0.014708,
  },
  {
    Date: "07.11.2019",
    price: 0.014498,
  },
  {
    Date: "08.11.2019",
    price: 0.015141,
  },
  {
    Date: "09.11.2019",
    price: 0.01438,
  },
  {
    Date: "10.11.2019",
    price: 0.014876,
  },
  {
    Date: "11.11.2019",
    price: 0.015735,
  },
  {
    Date: "12.11.2019",
    price: 0.015402,
  },
  {
    Date: "13.11.2019",
    price: 0.014885,
  },
  {
    Date: "14.11.2019",
    price: 0.014977,
  },
  {
    Date: "15.11.2019",
    price: 0.014537,
  },
  {
    Date: "16.11.2019",
    price: 0.013843,
  },
  {
    Date: "17.11.2019",
    price: 0.014462,
  },
  {
    Date: "18.11.2019",
    price: 0.014414,
  },
  {
    Date: "19.11.2019",
    price: 0.014179,
  },
  {
    Date: "20.11.2019",
    price: 0.014963,
  },
  {
    Date: "21.11.2019",
    price: 0.014405,
  },
  {
    Date: "22.11.2019",
    price: 0.014248,
  },
  {
    Date: "23.11.2019",
    price: 0.014069,
  },
  {
    Date: "24.11.2019",
    price: 0.016904,
  },
  {
    Date: "25.11.2019",
    price: 0.015352,
  },
  {
    Date: "26.11.2019",
    price: 0.020231,
  },
  {
    Date: "27.11.2019",
    price: 0.021838,
  },
  {
    Date: "28.11.2019",
    price: 0.024564,
  },
  {
    Date: "29.11.2019",
    price: 0.022765,
  },
  {
    Date: "30.11.2019",
    price: 0.024637,
  },
  {
    Date: "01.12.2019",
    price: 0.028999,
  },
  {
    Date: "02.12.2019",
    price: 0.026546,
  },
  {
    Date: "03.12.2019",
    price: 0.026396,
  },
  {
    Date: "04.12.2019",
    price: 0.029316,
  },
  {
    Date: "05.12.2019",
    price: 0.032146,
  },
  {
    Date: "06.12.2019",
    price: 0.03334,
  },
  {
    Date: "07.12.2019",
    price: 0.04151,
  },
  {
    Date: "08.12.2019",
    price: 0.039122,
  },
  {
    Date: "09.12.2019",
    price: 0.041676,
  },
  {
    Date: "10.12.2019",
    price: 0.035612,
  },
  {
    Date: "11.12.2019",
    price: 0.017396,
  },
  {
    Date: "12.12.2019",
    price: 0.017914,
  },
  {
    Date: "13.12.2019",
    price: 0.015836,
  },
  {
    Date: "14.12.2019",
    price: 0.016047,
  },
  {
    Date: "15.12.2019",
    price: 0.013702,
  },
  {
    Date: "16.12.2019",
    price: 0.014292,
  },
  {
    Date: "17.12.2019",
    price: 0.013313,
  },
  {
    Date: "18.12.2019",
    price: 0.012068,
  },
  {
    Date: "19.12.2019",
    price: 0.01272,
  },
  {
    Date: "20.12.2019",
    price: 0.013232,
  },
  {
    Date: "21.12.2019",
    price: 0.015655,
  },
  {
    Date: "22.12.2019",
    price: 0.018177,
  },
  {
    Date: "23.12.2019",
    price: 0.016176,
  },
  {
    Date: "24.12.2019",
    price: 0.015145,
  },
  {
    Date: "25.12.2019",
    price: 0.016254,
  },
  {
    Date: "26.12.2019",
    price: 0.015793,
  },
  {
    Date: "27.12.2019",
    price: 0.015728,
  },
  {
    Date: "28.12.2019",
    price: 0.015287,
  },
  {
    Date: "29.12.2019",
    price: 0.014957,
  },
  {
    Date: "30.12.2019",
    price: 0.0148,
  },
  {
    Date: "31.12.2019",
    price: 0.014417,
  },
  {
    Date: "01.01.2020",
    price: 0.013807,
  },
  {
    Date: "02.01.2020",
    price: 0.014942,
  },
  {
    Date: "03.01.2020",
    price: 0.014694,
  },
  {
    Date: "04.01.2020",
    price: 0.01517,
  },
  {
    Date: "05.01.2020",
    price: 0.014954,
  },
  {
    Date: "06.01.2020",
    price: 0.014908,
  },
  {
    Date: "07.01.2020",
    price: 0.015587,
  },
  {
    Date: "08.01.2020",
    price: 0.0151,
  },
  {
    Date: "09.01.2020",
    price: 0.015025,
  },
  {
    Date: "10.01.2020",
    price: 0.014691,
  },
  {
    Date: "11.01.2020",
    price: 0.014863,
  },
  {
    Date: "12.01.2020",
    price: 0.014798,
  },
  {
    Date: "13.01.2020",
    price: 0.014989,
  },
  {
    Date: "14.01.2020",
    price: 0.015505,
  },
  {
    Date: "15.01.2020",
    price: 0.015699,
  },
  {
    Date: "16.01.2020",
    price: 0.016381,
  },
  {
    Date: "17.01.2020",
    price: 0.016064,
  },
  {
    Date: "18.01.2020",
    price: 0.018816,
  },
  {
    Date: "19.01.2020",
    price: 0.017519,
  },
  {
    Date: "20.01.2020",
    price: 0.016397,
  },
  {
    Date: "21.01.2020",
    price: 0.016866,
  },
  {
    Date: "22.01.2020",
    price: 0.017156,
  },
  {
    Date: "23.01.2020",
    price: 0.017095,
  },
  {
    Date: "24.01.2020",
    price: 0.015949,
  },
  {
    Date: "25.01.2020",
    price: 0.016278,
  },
  {
    Date: "26.01.2020",
    price: 0.016063,
  },
  {
    Date: "27.01.2020",
    price: 0.016474,
  },
  {
    Date: "28.01.2020",
    price: 0.016913,
  },
  {
    Date: "29.01.2020",
    price: 0.017233,
  },
  {
    Date: "30.01.2020",
    price: 0.017106,
  },
  {
    Date: "31.01.2020",
    price: 0.018634,
  },
  {
    Date: "01.02.2020",
    price: 0.017901,
  },
  {
    Date: "02.02.2020",
    price: 0.018301,
  },
  {
    Date: "03.02.2020",
    price: 0.019115,
  },
  {
    Date: "04.02.2020",
    price: 0.021892,
  },
  {
    Date: "05.02.2020",
    price: 0.021118,
  },
  {
    Date: "06.02.2020",
    price: 0.02153,
  },
  {
    Date: "07.02.2020",
    price: 0.024471,
  },
  {
    Date: "08.02.2020",
    price: 0.022967,
  },
  {
    Date: "09.02.2020",
    price: 0.021159,
  },
  {
    Date: "10.02.2020",
    price: 0.021929,
  },
  {
    Date: "11.02.2020",
    price: 0.021605,
  },
  {
    Date: "12.02.2020",
    price: 0.02281,
  },
  {
    Date: "13.02.2020",
    price: 0.022898,
  },
  {
    Date: "14.02.2020",
    price: 0.02225,
  },
  {
    Date: "15.02.2020",
    price: 0.023249,
  },
  {
    Date: "16.02.2020",
    price: 0.020871,
  },
  {
    Date: "17.02.2020",
    price: 0.020132,
  },
  {
    Date: "18.02.2020",
    price: 0.020043,
  },
  {
    Date: "19.02.2020",
    price: 0.02152,
  },
  {
    Date: "20.02.2020",
    price: 0.020704,
  },
  {
    Date: "21.02.2020",
    price: 0.02071,
  },
  {
    Date: "22.02.2020",
    price: 0.020749,
  },
  {
    Date: "23.02.2020",
    price: 0.020825,
  },
  {
    Date: "24.02.2020",
    price: 0.022278,
  },
  {
    Date: "25.02.2020",
    price: 0.020737,
  },
  {
    Date: "26.02.2020",
    price: 0.019131,
  },
  {
    Date: "27.02.2020",
    price: 0.018303,
  },
  {
    Date: "28.02.2020",
    price: 0.019592,
  },
  {
    Date: "29.02.2020",
    price: 0.019992,
  },
  {
    Date: "01.03.2020",
    price: 0.020335,
  },
  {
    Date: "02.03.2020",
    price: 0.019451,
  },
  {
    Date: "03.03.2020",
    price: 0.021232,
  },
  {
    Date: "04.03.2020",
    price: 0.023463,
  },
  {
    Date: "05.03.2020",
    price: 0.025139,
  },
  {
    Date: "06.03.2020",
    price: 0.026482,
  },
  {
    Date: "07.03.2020",
    price: 0.024872,
  },
  {
    Date: "08.03.2020",
    price: 0.026827,
  },
  {
    Date: "09.03.2020",
    price: 0.021387,
  },
  {
    Date: "10.03.2020",
    price: 0.020533,
  },
  {
    Date: "11.03.2020",
    price: 0.020612,
  },
  {
    Date: "12.03.2020",
    price: 0.018952,
  },
  {
    Date: "13.03.2020",
    price: 0.009332,
  },
  {
    Date: "14.03.2020",
    price: 0.010787,
  },
  {
    Date: "15.03.2020",
    price: 0.009872,
  },
  {
    Date: "16.03.2020",
    price: 0.009902,
  },
  {
    Date: "17.03.2020",
    price: 0.008109,
  },
  {
    Date: "18.03.2020",
    price: 0.00906,
  },
  {
    Date: "19.03.2020",
    price: 0.008864,
  },
  {
    Date: "20.03.2020",
    price: 0.01124,
  },
  {
    Date: "21.03.2020",
    price: 0.010832,
  },
  {
    Date: "22.03.2020",
    price: 0.011204,
  },
  {
    Date: "23.03.2020",
    price: 0.010057,
  },
  {
    Date: "24.03.2020",
    price: 0.011089,
  },
  {
    Date: "25.03.2020",
    price: 0.011276,
  },
  {
    Date: "26.03.2020",
    price: 0.011445,
  },
  {
    Date: "27.03.2020",
    price: 0.011856,
  },
  {
    Date: "28.03.2020",
    price: 0.011004,
  },
  {
    Date: "29.03.2020",
    price: 0.01065,
  },
  {
    Date: "30.03.2020",
    price: 0.010085,
  },
  {
    Date: "31.03.2020",
    price: 0.010762,
  },
  {
    Date: "01.04.2020",
    price: 0.011043,
  },
  {
    Date: "02.04.2020",
    price: 0.011272,
  },
  {
    Date: "03.04.2020",
    price: 0.011558,
  },
  {
    Date: "04.04.2020",
    price: 0.011572,
  },
  {
    Date: "05.04.2020",
    price: 0.011757,
  },
  {
    Date: "06.04.2020",
    price: 0.011455,
  },
  {
    Date: "07.04.2020",
    price: 0.013413,
  },
  {
    Date: "08.04.2020",
    price: 0.01292,
  },
  {
    Date: "09.04.2020",
    price: 0.013717,
  },
  {
    Date: "10.04.2020",
    price: 0.01355,
  },
  {
    Date: "11.04.2020",
    price: 0.012033,
  },
  {
    Date: "12.04.2020",
    price: 0.012172,
  },
  {
    Date: "13.04.2020",
    price: 0.012316,
  },
  {
    Date: "14.04.2020",
    price: 0.012013,
  },
  {
    Date: "15.04.2020",
    price: 0.012156,
  },
  {
    Date: "16.04.2020",
    price: 0.011643,
  },
  {
    Date: "17.04.2020",
    price: 0.012643,
  },
  {
    Date: "18.04.2020",
    price: 0.012827,
  },
  {
    Date: "19.04.2020",
    price: 0.013383,
  },
  {
    Date: "20.04.2020",
    price: 0.012986,
  },
  {
    Date: "21.04.2020",
    price: 0.012107,
  },
  {
    Date: "22.04.2020",
    price: 0.012082,
  },
  {
    Date: "23.04.2020",
    price: 0.012776,
  },
  {
    Date: "24.04.2020",
    price: 0.014212,
  },
  {
    Date: "25.04.2020",
    price: 0.014764,
  },
  {
    Date: "26.04.2020",
    price: 0.015126,
  },
  {
    Date: "27.04.2020",
    price: 0.015626,
  },
  {
    Date: "28.04.2020",
    price: 0.015446,
  },
  {
    Date: "29.04.2020",
    price: 0.015794,
  },
  {
    Date: "30.04.2020",
    price: 0.016527,
  },
  {
    Date: "01.05.2020",
    price: 0.015502,
  },
  {
    Date: "02.05.2020",
    price: 0.01602,
  },
  {
    Date: "03.05.2020",
    price: 0.016947,
  },
  {
    Date: "04.05.2020",
    price: 0.017125,
  },
  {
    Date: "05.05.2020",
    price: 0.017066,
  },
  {
    Date: "06.05.2020",
    price: 0.016948,
  },
  {
    Date: "07.05.2020",
    price: 0.017288,
  },
  {
    Date: "08.05.2020",
    price: 0.017397,
  },
  {
    Date: "09.05.2020",
    price: 0.017944,
  },
  {
    Date: "10.05.2020",
    price: 0.01732,
  },
  {
    Date: "11.05.2020",
    price: 0.015356,
  },
  {
    Date: "12.05.2020",
    price: 0.015068,
  },
  {
    Date: "13.05.2020",
    price: 0.016918,
  },
  {
    Date: "14.05.2020",
    price: 0.019216,
  },
  {
    Date: "15.05.2020",
    price: 0.019938,
  },
  {
    Date: "16.05.2020",
    price: 0.019272,
  },
  {
    Date: "17.05.2020",
    price: 0.020012,
  },
  {
    Date: "18.05.2020",
    price: 0.020272,
  },
  {
    Date: "19.05.2020",
    price: 0.02063,
  },
  {
    Date: "20.05.2020",
    price: 0.022124,
  },
  {
    Date: "21.05.2020",
    price: 0.020881,
  },
  {
    Date: "22.05.2020",
    price: 0.019276,
  },
  {
    Date: "23.05.2020",
    price: 0.020432,
  },
  {
    Date: "24.05.2020",
    price: 0.020197,
  },
  {
    Date: "25.05.2020",
    price: 0.019434,
  },
  {
    Date: "26.05.2020",
    price: 0.021243,
  },
  {
    Date: "27.05.2020",
    price: 0.025818,
  },
  {
    Date: "28.05.2020",
    price: 0.024388,
  },
  {
    Date: "29.05.2020",
    price: 0.02514,
  },
  {
    Date: "30.05.2020",
    price: 0.025933,
  },
  {
    Date: "31.05.2020",
    price: 0.02408,
  },
  {
    Date: "01.06.2020",
    price: 0.020361,
  },
  {
    Date: "02.06.2020",
    price: 0.021709,
  },
  {
    Date: "03.06.2020",
    price: 0.020781,
  },
  {
    Date: "04.06.2020",
    price: 0.021638,
  },
  {
    Date: "05.06.2020",
    price: 0.021734,
  },
  {
    Date: "06.06.2020",
    price: 0.020698,
  },
  {
    Date: "07.06.2020",
    price: 0.02063,
  },
  {
    Date: "08.06.2020",
    price: 0.02036,
  },
  {
    Date: "09.06.2020",
    price: 0.020331,
  },
  {
    Date: "10.06.2020",
    price: 0.020219,
  },
  {
    Date: "11.06.2020",
    price: 0.021282,
  },
  {
    Date: "12.06.2020",
    price: 0.019721,
  },
  {
    Date: "13.06.2020",
    price: 0.020497,
  },
  {
    Date: "14.06.2020",
    price: 0.020702,
  },
  {
    Date: "15.06.2020",
    price: 0.020672,
  },
  {
    Date: "16.06.2020",
    price: 0.020445,
  },
  {
    Date: "17.06.2020",
    price: 0.020602,
  },
  {
    Date: "18.06.2020",
    price: 0.020738,
  },
  {
    Date: "19.06.2020",
    price: 0.020475,
  },
  {
    Date: "20.06.2020",
    price: 0.020135,
  },
  {
    Date: "21.06.2020",
    price: 0.022574,
  },
  {
    Date: "22.06.2020",
    price: 0.021398,
  },
  {
    Date: "23.06.2020",
    price: 0.022058,
  },
  {
    Date: "24.06.2020",
    price: 0.022695,
  },
  {
    Date: "25.06.2020",
    price: 0.022392,
  },
  {
    Date: "26.06.2020",
    price: 0.021614,
  },
  {
    Date: "27.06.2020",
    price: 0.020832,
  },
  {
    Date: "28.06.2020",
    price: 0.019436,
  },
  {
    Date: "29.06.2020",
    price: 0.020103,
  },
  {
    Date: "30.06.2020",
    price: 0.019663,
  },
  {
    Date: "01.07.2020",
    price: 0.01901,
  },
  {
    Date: "02.07.2020",
    price: 0.018717,
  },
  {
    Date: "03.07.2020",
    price: 0.019099,
  },
  {
    Date: "04.07.2020",
    price: 0.019243,
  },
  {
    Date: "05.07.2020",
    price: 0.019511,
  },
  {
    Date: "06.07.2020",
    price: 0.019067,
  },
  {
    Date: "07.07.2020",
    price: 0.019927,
  },
  {
    Date: "08.07.2020",
    price: 0.020104,
  },
  {
    Date: "09.07.2020",
    price: 0.021634,
  },
  {
    Date: "10.07.2020",
    price: 0.020698,
  },
  {
    Date: "11.07.2020",
    price: 0.021107,
  },
  {
    Date: "12.07.2020",
    price: 0.021298,
  },
  {
    Date: "13.07.2020",
    price: 0.021159,
  },
  {
    Date: "14.07.2020",
    price: 0.022424,
  },
  {
    Date: "15.07.2020",
    price: 0.022353,
  },
  {
    Date: "16.07.2020",
    price: 0.022258,
  },
  {
    Date: "17.07.2020",
    price: 0.020896,
  },
  {
    Date: "18.07.2020",
    price: 0.021182,
  },
  {
    Date: "19.07.2020",
    price: 0.021495,
  },
  {
    Date: "20.07.2020",
    price: 0.021244,
  },
  {
    Date: "21.07.2020",
    price: 0.021605,
  },
  {
    Date: "22.07.2020",
    price: 0.021265,
  },
  {
    Date: "23.07.2020",
    price: 0.021213,
  },
  {
    Date: "24.07.2020",
    price: 0.021052,
  },
  {
    Date: "25.07.2020",
    price: 0.020643,
  },
  {
    Date: "26.07.2020",
    price: 0.020858,
  },
  {
    Date: "27.07.2020",
    price: 0.020694,
  },
  {
    Date: "28.07.2020",
    price: 0.019913,
  },
  {
    Date: "29.07.2020",
    price: 0.020488,
  },
  {
    Date: "30.07.2020",
    price: 0.020381,
  },
  {
    Date: "31.07.2020",
    price: 0.020419,
  },
  {
    Date: "01.08.2020",
    price: 0.020258,
  },
  {
    Date: "02.08.2020",
    price: 0.021008,
  },
  {
    Date: "03.08.2020",
    price: 0.020001,
  },
  {
    Date: "04.08.2020",
    price: 0.020458,
  },
  {
    Date: "05.08.2020",
    price: 0.02065,
  },
  {
    Date: "06.08.2020",
    price: 0.02135,
  },
  {
    Date: "07.08.2020",
    price: 0.021767,
  },
  {
    Date: "08.08.2020",
    price: 0.021376,
  },
  {
    Date: "09.08.2020",
    price: 0.022871,
  },
  {
    Date: "10.08.2020",
    price: 0.022779,
  },
  {
    Date: "11.08.2020",
    price: 0.024379,
  },
  {
    Date: "12.08.2020",
    price: 0.023116,
  },
  {
    Date: "13.08.2020",
    price: 0.025547,
  },
  {
    Date: "14.08.2020",
    price: 0.027433,
  },
  {
    Date: "15.08.2020",
    price: 0.028223,
  },
  {
    Date: "16.08.2020",
    price: 0.030129,
  },
  {
    Date: "17.08.2020",
    price: 0.030161,
  },
  {
    Date: "18.08.2020",
    price: 0.02871,
  },
  {
    Date: "19.08.2020",
    price: 0.026933,
  },
  {
    Date: "20.08.2020",
    price: 0.024896,
  },
  {
    Date: "21.08.2020",
    price: 0.02668,
  },
  {
    Date: "22.08.2020",
    price: 0.024981,
  },
  {
    Date: "23.08.2020",
    price: 0.026155,
  },
  {
    Date: "24.08.2020",
    price: 0.025349,
  },
  {
    Date: "25.08.2020",
    price: 0.027941,
  },
  {
    Date: "26.08.2020",
    price: 0.025798,
  },
  {
    Date: "27.08.2020",
    price: 0.026919,
  },
  {
    Date: "28.08.2020",
    price: 0.025766,
  },
  {
    Date: "29.08.2020",
    price: 0.026862,
  },
  {
    Date: "30.08.2020",
    price: 0.027642,
  },
  {
    Date: "31.08.2020",
    price: 0.027158,
  },
  {
    Date: "01.09.2020",
    price: 0.026567,
  },
  {
    Date: "02.09.2020",
    price: 0.025626,
  },
  {
    Date: "03.09.2020",
    price: 0.023507,
  },
  {
    Date: "04.09.2020",
    price: 0.019451,
  },
  {
    Date: "05.09.2020",
    price: 0.019425,
  },
  {
    Date: "06.09.2020",
    price: 0.018039,
  },
  {
    Date: "07.09.2020",
    price: 0.018805,
  },
  {
    Date: "08.09.2020",
    price: 0.018817,
  },
  {
    Date: "09.09.2020",
    price: 0.018267,
  },
  {
    Date: "10.09.2020",
    price: 0.020161,
  },
  {
    Date: "11.09.2020",
    price: 0.020467,
  },
  {
    Date: "12.09.2020",
    price: 0.021196,
  },
  {
    Date: "13.09.2020",
    price: 0.020981,
  },
  {
    Date: "14.09.2020",
    price: 0.019802,
  },
  {
    Date: "15.09.2020",
    price: 0.020139,
  },
  {
    Date: "16.09.2020",
    price: 0.020491,
  },
  {
    Date: "17.09.2020",
    price: 0.019857,
  },
  {
    Date: "18.09.2020",
    price: 0.020431,
  },
  {
    Date: "19.09.2020",
    price: 0.021036,
  },
  {
    Date: "20.09.2020",
    price: 0.020898,
  },
  {
    Date: "21.09.2020",
    price: 0.021955,
  },
  {
    Date: "22.09.2020",
    price: 0.018915,
  },
  {
    Date: "23.09.2020",
    price: 0.019633,
  },
  {
    Date: "24.09.2020",
    price: 0.017742,
  },
  {
    Date: "25.09.2020",
    price: 0.018993,
  },
  {
    Date: "26.09.2020",
    price: 0.019179,
  },
  {
    Date: "27.09.2020",
    price: 0.018884,
  },
  {
    Date: "28.09.2020",
    price: 0.018734,
  },
  {
    Date: "29.09.2020",
    price: 0.018902,
  },
  {
    Date: "30.09.2020",
    price: 0.019647,
  },
  {
    Date: "01.10.2020",
    price: 0.020169,
  },
  {
    Date: "02.10.2020",
    price: 0.018988,
  },
  {
    Date: "03.10.2020",
    price: 0.018278,
  },
  {
    Date: "04.10.2020",
    price: 0.018529,
  },
  {
    Date: "05.10.2020",
    price: 0.018602,
  },
  {
    Date: "06.10.2020",
    price: 0.01883,
  },
  {
    Date: "07.10.2020",
    price: 0.017171,
  },
  {
    Date: "08.10.2020",
    price: 0.017,
  },
  {
    Date: "09.10.2020",
    price: 0.016657,
  },
  {
    Date: "10.10.2020",
    price: 0.017096,
  },
  {
    Date: "11.10.2020",
    price: 0.017226,
  },
  {
    Date: "12.10.2020",
    price: 0.017819,
  },
  {
    Date: "13.10.2020",
    price: 0.017885,
  },
  {
    Date: "14.10.2020",
    price: 0.017689,
  },
  {
    Date: "15.10.2020",
    price: 0.01719,
  },
  {
    Date: "16.10.2020",
    price: 0.017181,
  },
  {
    Date: "17.10.2020",
    price: 0.016278,
  },
  {
    Date: "18.10.2020",
    price: 0.016739,
  },
  {
    Date: "19.10.2020",
    price: 0.016874,
  },
  {
    Date: "20.10.2020",
    price: 0.016243,
  },
  {
    Date: "21.10.2020",
    price: 0.015649,
  },
  {
    Date: "22.10.2020",
    price: 0.016386,
  },
  {
    Date: "23.10.2020",
    price: 0.017408,
  },
  {
    Date: "24.10.2020",
    price: 0.01704,
  },
  {
    Date: "25.10.2020",
    price: 0.017683,
  },
  {
    Date: "26.10.2020",
    price: 0.017165,
  },
  {
    Date: "27.10.2020",
    price: 0.016562,
  },
  {
    Date: "28.10.2020",
    price: 0.0158,
  },
  {
    Date: "29.10.2020",
    price: 0.015086,
  },
  {
    Date: "30.10.2020",
    price: 0.013877,
  },
  {
    Date: "31.10.2020",
    price: 0.01391,
  },
  {
    Date: "01.11.2020",
    price: 0.013721,
  },
  {
    Date: "02.11.2020",
    price: 0.014107,
  },
  {
    Date: "03.11.2020",
    price: 0.013688,
  },
  {
    Date: "04.11.2020",
    price: 0.012717,
  },
  {
    Date: "05.11.2020",
    price: 0.012253,
  },
  {
    Date: "06.11.2020",
    price: 0.013476,
  },
  {
    Date: "07.11.2020",
    price: 0.015509,
  },
  {
    Date: "08.11.2020",
    price: 0.014393,
  },
  {
    Date: "09.11.2020",
    price: 0.015735,
  },
  {
    Date: "10.11.2020",
    price: 0.015634,
  },
  {
    Date: "11.11.2020",
    price: 0.017314,
  },
  {
    Date: "12.11.2020",
    price: 0.016603,
  },
  {
    Date: "13.11.2020",
    price: 0.01586,
  },
  {
    Date: "14.11.2020",
    price: 0.016917,
  },
  {
    Date: "15.11.2020",
    price: 0.016751,
  },
  {
    Date: "16.11.2020",
    price: 0.016461,
  },
  {
    Date: "17.11.2020",
    price: 0.016764,
  },
  {
    Date: "18.11.2020",
    price: 0.017692,
  },
  {
    Date: "19.11.2020",
    price: 0.017094,
  },
  {
    Date: "20.11.2020",
    price: 0.017272,
  },
  {
    Date: "21.11.2020",
    price: 0.01787,
  },
  {
    Date: "22.11.2020",
    price: 0.019758,
  },
  {
    Date: "23.11.2020",
    price: 0.019036,
  },
  {
    Date: "24.11.2020",
    price: 0.021163,
  },
  {
    Date: "25.11.2020",
    price: 0.021637,
  },
  {
    Date: "26.11.2020",
    price: 0.021124,
  },
  {
    Date: "27.11.2020",
    price: 0.017799,
  },
  {
    Date: "28.11.2020",
    price: 0.017726,
  },
  {
    Date: "29.11.2020",
    price: 0.018754,
  },
  {
    Date: "30.11.2020",
    price: 0.018856,
  },
  {
    Date: "01.12.2020",
    price: 0.019568,
  },
  {
    Date: "02.12.2020",
    price: 0.01871,
  },
  {
    Date: "03.12.2020",
    price: 0.019334,
  },
  {
    Date: "04.12.2020",
    price: 0.020808,
  },
  {
    Date: "05.12.2020",
    price: 0.018835,
  },
  {
    Date: "06.12.2020",
    price: 0.01973,
  },
  {
    Date: "07.12.2020",
    price: 0.019572,
  },
  {
    Date: "08.12.2020",
    price: 0.019234,
  },
  {
    Date: "09.12.2020",
    price: 0.017604,
  },
  {
    Date: "10.12.2020",
    price: 0.018104,
  },
  {
    Date: "11.12.2020",
    price: 0.017772,
  },
  {
    Date: "12.12.2020",
    price: 0.017481,
  },
  {
    Date: "13.12.2020",
    price: 0.017869,
  },
  {
    Date: "14.12.2020",
    price: 0.018099,
  },
  {
    Date: "15.12.2020",
    price: 0.018387,
  },
  {
    Date: "16.12.2020",
    price: 0.019488,
  },
  {
    Date: "17.12.2020",
    price: 0.019447,
  },
  {
    Date: "18.12.2020",
    price: 0.018958,
  },
  {
    Date: "19.12.2020",
    price: 0.020593,
  },
  {
    Date: "20.12.2020",
    price: 0.019982,
  },
  {
    Date: "21.12.2020",
    price: 0.019219,
  },
  {
    Date: "22.12.2020",
    price: 0.01813,
  },
  {
    Date: "23.12.2020",
    price: 0.018636,
  },
  {
    Date: "24.12.2020",
    price: 0.015246,
  },
  {
    Date: "25.12.2020",
    price: 0.016449,
  },
  {
    Date: "26.12.2020",
    price: 0.01663,
  },
  {
    Date: "27.12.2020",
    price: 0.01928,
  },
  {
    Date: "28.12.2020",
    price: 0.018322,
  },
  {
    Date: "29.12.2020",
    price: 0.018873,
  },
  {
    Date: "30.12.2020",
    price: 0.018442,
  },
  {
    Date: "31.12.2020",
    price: 0.018136,
  },
  {
    Date: "01.01.2021",
    price: 0.017633,
  },
  {
    Date: "02.01.2021",
    price: 0.017812,
  },
  {
    Date: "03.01.2021",
    price: 0.019053,
  },
  {
    Date: "04.01.2021",
    price: 0.02,
  },
  {
    Date: "05.01.2021",
    price: 0.024675,
  },
  {
    Date: "06.01.2021",
    price: 0.024468,
  },
  {
    Date: "07.01.2021",
    price: 0.035312,
  },
  {
    Date: "08.01.2021",
    price: 0.033009,
  },
  {
    Date: "09.01.2021",
    price: 0.031697,
  },
  {
    Date: "10.01.2021",
    price: 0.033582,
  },
  {
    Date: "11.01.2021",
    price: 0.030388,
  },
  {
    Date: "12.01.2021",
    price: 0.026068,
  },
  {
    Date: "13.01.2021",
    price: 0.026084,
  },
  {
    Date: "14.01.2021",
    price: 0.030069,
  },
  {
    Date: "15.01.2021",
    price: 0.03278,
  },
  {
    Date: "16.01.2021",
    price: 0.034062,
  },
  {
    Date: "17.01.2021",
    price: 0.032795,
  },
  {
    Date: "18.01.2021",
    price: 0.034036,
  },
  {
    Date: "19.01.2021",
    price: 0.036054,
  },
  {
    Date: "20.01.2021",
    price: 0.03245,
  },
  {
    Date: "21.01.2021",
    price: 0.034547,
  },
  {
    Date: "22.01.2021",
    price: 0.028596,
  },
  {
    Date: "23.01.2021",
    price: 0.03126,
  },
  {
    Date: "24.01.2021",
    price: 0.033999,
  },
  {
    Date: "25.01.2021",
    price: 0.033176,
  },
  {
    Date: "26.01.2021",
    price: 0.032908,
  },
  {
    Date: "27.01.2021",
    price: 0.043351,
  },
  {
    Date: "28.01.2021",
    price: 0.042064,
  },
  {
    Date: "29.01.2021",
    price: 0.038492,
  },
  {
    Date: "30.01.2021",
    price: 0.04029,
  },
  {
    Date: "31.01.2021",
    price: 0.039954,
  },
  {
    Date: "01.02.2021",
    price: 0.038278,
  },
  {
    Date: "02.02.2021",
    price: 0.040826,
  },
  {
    Date: "03.02.2021",
    price: 0.041076,
  },
  {
    Date: "04.02.2021",
    price: 0.043811,
  },
  {
    Date: "05.02.2021",
    price: 0.04737,
  },
  {
    Date: "06.02.2021",
    price: 0.054739,
  },
  {
    Date: "07.02.2021",
    price: 0.052492,
  },
  {
    Date: "08.02.2021",
    price: 0.067275,
  },
  {
    Date: "09.02.2021",
    price: 0.071313,
  },
  {
    Date: "10.02.2021",
    price: 0.098897,
  },
  {
    Date: "11.02.2021",
    price: 0.116711,
  },
  {
    Date: "12.02.2021",
    price: 0.123384,
  },
  {
    Date: "13.02.2021",
    price: 0.121137,
  },
  {
    Date: "14.02.2021",
    price: 0.11166,
  },
  {
    Date: "15.02.2021",
    price: 0.100833,
  },
  {
    Date: "16.02.2021",
    price: 0.107351,
  },
  {
    Date: "17.02.2021",
    price: 0.108891,
  },
  {
    Date: "18.02.2021",
    price: 0.11229,
  },
  {
    Date: "19.02.2021",
    price: 0.122753,
  },
  {
    Date: "20.02.2021",
    price: 0.154576,
  },
  {
    Date: "21.02.2021",
    price: 0.14544,
  },
  {
    Date: "22.02.2021",
    price: 0.162348,
  },
  {
    Date: "23.02.2021",
    price: 0.152149,
  },
  {
    Date: "24.02.2021",
    price: 0.136798,
  },
  {
    Date: "25.02.2021",
    price: 0.160051,
  },
  {
    Date: "26.02.2021",
    price: 0.176919,
  },
  {
    Date: "27.02.2021",
    price: 0.190532,
  },
  {
    Date: "28.02.2021",
    price: 0.197906,
  },
  {
    Date: "01.03.2021",
    price: 0.230546,
  },
  {
    Date: "02.03.2021",
    price: 0.250081,
  },
  {
    Date: "03.03.2021",
    price: 0.231971,
  },
  {
    Date: "04.03.2021",
    price: 0.223779,
  },
  {
    Date: "05.03.2021",
    price: 0.199785,
  },
  {
    Date: "06.03.2021",
    price: 0.213962,
  },
  {
    Date: "07.03.2021",
    price: 0.212928,
  },
  {
    Date: "08.03.2021",
    price: 0.21229,
  },
  {
    Date: "09.03.2021",
    price: 0.216834,
  },
  {
    Date: "10.03.2021",
    price: 0.298759,
  },
  {
    Date: "11.03.2021",
    price: 0.287132,
  },
  {
    Date: "12.03.2021",
    price: 0.410418,
  },
  {
    Date: "13.03.2021",
    price: 0.414562,
  },
  {
    Date: "14.03.2021",
    price: 0.425346,
  },
  {
    Date: "15.03.2021",
    price: 0.381473,
  },
  {
    Date: "16.03.2021",
    price: 0.39229,
  },
  {
    Date: "17.03.2021",
    price: 0.373021,
  },
  {
    Date: "18.03.2021",
    price: 0.401438,
  },
  {
    Date: "19.03.2021",
    price: 0.390534,
  },
  {
    Date: "20.03.2021",
    price: 0.410084,
  },
  {
    Date: "21.03.2021",
    price: 0.383504,
  },
  {
    Date: "22.03.2021",
    price: 0.381039,
  },
  {
    Date: "23.03.2021",
    price: 0.347699,
  },
  {
    Date: "24.03.2021",
    price: 0.331411,
  },
  {
    Date: "25.03.2021",
    price: 0.323941,
  },
  {
    Date: "26.03.2021",
    price: 0.3018,
  },
  {
    Date: "27.03.2021",
    price: 0.336951,
  },
  {
    Date: "28.03.2021",
    price: 0.333223,
  },
  {
    Date: "29.03.2021",
    price: 0.346505,
  },
  {
    Date: "30.03.2021",
    price: 0.384417,
  },
  {
    Date: "31.03.2021",
    price: 0.37144,
  },
  {
    Date: "01.04.2021",
    price: 0.360324,
  },
  {
    Date: "02.04.2021",
    price: 0.364159,
  },
  {
    Date: "03.04.2021",
    price: 0.383484,
  },
  {
    Date: "04.04.2021",
    price: 0.35385,
  },
  {
    Date: "05.04.2021",
    price: 0.36266,
  },
  {
    Date: "06.04.2021",
    price: 0.370452,
  },
  {
    Date: "07.04.2021",
    price: 0.374547,
  },
  {
    Date: "08.04.2021",
    price: 0.337845,
  },
  {
    Date: "09.04.2021",
    price: 0.356203,
  },
  {
    Date: "10.04.2021",
    price: 0.367531,
  },
  {
    Date: "11.04.2021",
    price: 0.358065,
  },
  {
    Date: "12.04.2021",
    price: 0.370304,
  },
  {
    Date: "13.04.2021",
    price: 0.35759,
  },
  {
    Date: "14.04.2021",
    price: 0.422413,
  },
  {
    Date: "15.04.2021",
    price: 0.414665,
  },
  {
    Date: "16.04.2021",
    price: 0.44099,
  },
  {
    Date: "17.04.2021",
    price: 0.436716,
  },
  {
    Date: "18.04.2021",
    price: 0.40668,
  },
  {
    Date: "19.04.2021",
    price: 0.363456,
  },
  {
    Date: "20.04.2021",
    price: 0.325507,
  },
  {
    Date: "21.04.2021",
    price: 0.342312,
  },
  {
    Date: "22.04.2021",
    price: 0.356474,
  },
  {
    Date: "23.04.2021",
    price: 0.35012,
  },
  {
    Date: "24.04.2021",
    price: 0.381905,
  },
  {
    Date: "25.04.2021",
    price: 0.354823,
  },
  {
    Date: "26.04.2021",
    price: 0.384003,
  },
  {
    Date: "27.04.2021",
    price: 0.536222,
  },
  {
    Date: "28.04.2021",
    price: 0.828999,
  },
  {
    Date: "29.04.2021",
    price: 0.776181,
  },
  {
    Date: "30.04.2021",
    price: 0.888491,
  },
  {
    Date: "01.05.2021",
    price: 0.820682,
  },
  {
    Date: "02.05.2021",
    price: 0.785502,
  },
  {
    Date: "03.05.2021",
    price: 0.822579,
  },
  {
    Date: "04.05.2021",
    price: 0.795066,
  },
  {
    Date: "05.05.2021",
    price: 0.703297,
  },
  {
    Date: "06.05.2021",
    price: 0.809184,
  },
  {
    Date: "07.05.2021",
    price: 0.762863,
  },
  {
    Date: "08.05.2021",
    price: 0.751463,
  },
  {
    Date: "09.05.2021",
    price: 0.848966,
  },
  {
    Date: "10.05.2021",
    price: 0.969873,
  },
  {
    Date: "11.05.2021",
    price: 0.876136,
  },
  {
    Date: "12.05.2021",
    price: 1.066634,
  },
  {
    Date: "13.05.2021",
    price: 1.041993,
  },
  {
    Date: "14.05.2021",
    price: 1.052197,
  },
  {
    Date: "15.05.2021",
    price: 1.471554,
  },
  {
    Date: "16.05.2021",
    price: 1.596607,
  },
  {
    Date: "17.05.2021",
    price: 1.719248,
  },
  {
    Date: "18.05.2021",
    price: 1.724034,
  },
  {
    Date: "19.05.2021",
    price: 2.448309,
  },
  {
    Date: "20.05.2021",
    price: 1.625707,
  },
  {
    Date: "21.05.2021",
    price: 1.830957,
  },
  {
    Date: "22.05.2021",
    price: 1.486962,
  },
  {
    Date: "23.05.2021",
    price: 1.195548,
  },
  {
    Date: "24.05.2021",
    price: 1.077332,
  },
  {
    Date: "25.05.2021",
    price: 1.742494,
  },
  {
    Date: "26.05.2021",
    price: 1.93822,
  },
  {
    Date: "27.05.2021",
    price: 2.213053,
  },
  {
    Date: "28.05.2021",
    price: 2.039898,
  },
  {
    Date: "29.05.2021",
    price: 1.818158,
  },
  {
    Date: "30.05.2021",
    price: 1.691446,
  },
  {
    Date: "31.05.2021",
    price: 1.864914,
  },
  {
    Date: "01.06.2021",
    price: 1.872787,
  },
  {
    Date: "02.06.2021",
    price: 1.82416,
  },
  {
    Date: "03.06.2021",
    price: 1.80024,
  },
  {
    Date: "04.06.2021",
    price: 1.824849,
  },
  {
    Date: "05.06.2021",
    price: 1.632547,
  },
  {
    Date: "06.06.2021",
    price: 1.52757,
  },
  {
    Date: "07.06.2021",
    price: 1.581552,
  },
  {
    Date: "08.06.2021",
    price: 1.486308,
  },
  {
    Date: "09.06.2021",
    price: 1.48007,
  },
  {
    Date: "10.06.2021",
    price: 1.490975,
  },
  {
    Date: "11.06.2021",
    price: 1.363053,
  },
  {
    Date: "12.06.2021",
    price: 1.250332,
  },
  {
    Date: "13.06.2021",
    price: 1.340909,
  },
  {
    Date: "14.06.2021",
    price: 1.465227,
  },
  {
    Date: "15.06.2021",
    price: 1.59438,
  },
  {
    Date: "16.06.2021",
    price: 1.628095,
  },
  {
    Date: "17.06.2021",
    price: 1.478923,
  },
  {
    Date: "18.06.2021",
    price: 1.503763,
  },
  {
    Date: "19.06.2021",
    price: 1.380531,
  },
  {
    Date: "20.06.2021",
    price: 1.360871,
  },
  {
    Date: "21.06.2021",
    price: 1.399662,
  },
  {
    Date: "22.06.2021",
    price: 1.097809,
  },
  {
    Date: "23.06.2021",
    price: 1.075182,
  },
  {
    Date: "24.06.2021",
    price: 1.178167,
  },
  {
    Date: "25.06.2021",
    price: 1.202085,
  },
  {
    Date: "26.06.2021",
    price: 1.040057,
  },
  {
    Date: "27.06.2021",
    price: 1.077131,
  },
  {
    Date: "28.06.2021",
    price: 1.114083,
  },
  {
    Date: "29.06.2021",
    price: 1.120081,
  },
  {
    Date: "30.06.2021",
    price: 1.171831,
  },
  {
    Date: "01.07.2021",
    price: 1.167458,
  },
  {
    Date: "02.07.2021",
    price: 1.0742,
  },
  {
    Date: "03.07.2021",
    price: 1.102771,
  },
  {
    Date: "04.07.2021",
    price: 1.117961,
  },
  {
    Date: "05.07.2021",
    price: 1.146954,
  },
  {
    Date: "06.07.2021",
    price: 1.101168,
  },
  {
    Date: "07.07.2021",
    price: 1.131174,
  },
  {
    Date: "08.07.2021",
    price: 1.128707,
  },
  {
    Date: "09.07.2021",
    price: 1.040867,
  },
  {
    Date: "10.07.2021",
    price: 1.051684,
  },
  {
    Date: "11.07.2021",
    price: 1.029663,
  },
  {
    Date: "12.07.2021",
    price: 1.042564,
  },
  {
    Date: "13.07.2021",
    price: 0.995747,
  },
  {
    Date: "14.07.2021",
    price: 0.945853,
  },
  {
    Date: "15.07.2021",
    price: 0.929696,
  },
  {
    Date: "16.07.2021",
    price: 0.878216,
  },
  {
    Date: "17.07.2021",
    price: 0.811375,
  },
  {
    Date: "18.07.2021",
    price: 0.801653,
  },
  {
    Date: "19.07.2021",
    price: 0.794281,
  },
  {
    Date: "20.07.2021",
    price: 0.733269,
  },
  {
    Date: "21.07.2021",
    price: 0.688997,
  },
  {
    Date: "22.07.2021",
    price: 0.888893,
  },
  {
    Date: "23.07.2021",
    price: 0.881175,
  },
  {
    Date: "24.07.2021",
    price: 0.948503,
  },
  {
    Date: "25.07.2021",
    price: 0.948596,
  },
  {
    Date: "26.07.2021",
    price: 0.93968,
  },
  {
    Date: "27.07.2021",
    price: 1.015553,
  },
  {
    Date: "28.07.2021",
    price: 1.042601,
  },
  {
    Date: "29.07.2021",
    price: 1.016751,
  },
  {
    Date: "30.07.2021",
    price: 1.027766,
  },
  {
    Date: "31.07.2021",
    price: 1.067661,
  },
  {
    Date: "01.08.2021",
    price: 1.082968,
  },
  {
    Date: "02.08.2021",
    price: 1.049876,
  },
  {
    Date: "03.08.2021",
    price: 1.046281,
  },
  {
    Date: "04.08.2021",
    price: 1.023031,
  },
  {
    Date: "05.08.2021",
    price: 1.053901,
  },
  {
    Date: "06.08.2021",
    price: 1.065567,
  },
  {
    Date: "07.08.2021",
    price: 1.080801,
  },
  {
    Date: "08.08.2021",
    price: 1.166928,
  },
  {
    Date: "09.08.2021",
    price: 1.081684,
  },
  {
    Date: "10.08.2021",
    price: 1.126806,
  },
  {
    Date: "11.08.2021",
    price: 1.157485,
  },
  {
    Date: "12.08.2021",
    price: 1.372889,
  },
  {
    Date: "13.08.2021",
    price: 1.323649,
  },
  {
    Date: "14.08.2021",
    price: 1.510772,
  },
  {
    Date: "15.08.2021",
    price: 1.442296,
  },
  {
    Date: "16.08.2021",
    price: 1.467584,
  },
  {
    Date: "17.08.2021",
    price: 1.420477,
  },
  {
    Date: "18.08.2021",
    price: 1.349103,
  },
  {
    Date: "19.08.2021",
    price: 1.336254,
  },
  {
    Date: "20.08.2021",
    price: 1.468939,
  },
  {
    Date: "21.08.2021",
    price: 1.550503,
  },
  {
    Date: "22.08.2021",
    price: 1.584749,
  },
  {
    Date: "23.08.2021",
    price: 1.624619,
  },
  {
    Date: "24.08.2021",
    price: 1.626455,
  },
  {
    Date: "25.08.2021",
    price: 1.475563,
  },
  {
    Date: "26.08.2021",
    price: 1.528527,
  },
  {
    Date: "27.08.2021",
    price: 1.37388,
  },
  {
    Date: "28.08.2021",
    price: 1.519393,
  },
  {
    Date: "29.08.2021",
    price: 1.457982,
  },
  {
    Date: "30.08.2021",
    price: 1.42082,
  },
  {
    Date: "31.08.2021",
    price: 1.316764,
  },
  {
    Date: "01.09.2021",
    price: 1.337826,
  },
  {
    Date: "02.09.2021",
    price: 1.469452,
  },
  {
    Date: "03.09.2021",
    price: 1.446509,
  },
  {
    Date: "04.09.2021",
    price: 1.4573,
  },
  {
    Date: "05.09.2021",
    price: 1.740503,
  },
  {
    Date: "06.09.2021",
    price: 1.680402,
  },
  {
    Date: "07.09.2021",
    price: 1.643872,
  },
  {
    Date: "08.09.2021",
    price: 1.357791,
  },
  {
    Date: "09.09.2021",
    price: 1.291792,
  },
  {
    Date: "10.09.2021",
    price: 1.367249,
  },
  {
    Date: "11.09.2021",
    price: 1.303582,
  },
  {
    Date: "12.09.2021",
    price: 1.317212,
  },
  {
    Date: "13.09.2021",
    price: 1.329806,
  },
  {
    Date: "14.09.2021",
    price: 1.247486,
  },
  {
    Date: "15.09.2021",
    price: 1.317646,
  },
  {
    Date: "16.09.2021",
    price: 1.416557,
  },
  {
    Date: "17.09.2021",
    price: 1.384657,
  },
  {
    Date: "18.09.2021",
    price: 1.420563,
  },
  {
    Date: "19.09.2021",
    price: 1.364317,
  },
  {
    Date: "20.09.2021",
    price: 1.314702,
  },
  {
    Date: "21.09.2021",
    price: 1.134853,
  },
  {
    Date: "22.09.2021",
    price: 1.045979,
  },
  {
    Date: "23.09.2021",
    price: 1.230167,
  },
  {
    Date: "24.09.2021",
    price: 1.225561,
  },
  {
    Date: "25.09.2021",
    price: 1.126984,
  },
  {
    Date: "26.09.2021",
    price: 1.123193,
  },
  {
    Date: "27.09.2021",
    price: 1.124869,
  },
  {
    Date: "28.09.2021",
    price: 1.084828,
  },
  {
    Date: "29.09.2021",
    price: 1.051223,
  },
  {
    Date: "30.09.2021",
    price: 1.10029,
  },
  {
    Date: "01.10.2021",
    price: 1.127102,
  },
  {
    Date: "02.10.2021",
    price: 1.265286,
  },
  {
    Date: "03.10.2021",
    price: 1.294534,
  },
  {
    Date: "04.10.2021",
    price: 1.326274,
  },
  {
    Date: "05.10.2021",
    price: 1.274767,
  },
  {
    Date: "06.10.2021",
    price: 1.315488,
  },
  {
    Date: "07.10.2021",
    price: 1.257506,
  },
  {
    Date: "08.10.2021",
    price: 1.274651,
  },
  {
    Date: "09.10.2021",
    price: 1.341249,
  },
  {
    Date: "10.10.2021",
    price: 1.350992,
  },
  {
    Date: "11.10.2021",
    price: 1.246429,
  },
  {
    Date: "12.10.2021",
    price: 1.242912,
  },
  {
    Date: "13.10.2021",
    price: 1.223045,
  },
  {
    Date: "14.10.2021",
    price: 1.241095,
  },
  {
    Date: "15.10.2021",
    price: 1.264026,
  },
  {
    Date: "16.10.2021",
    price: 1.589172,
  },
  {
    Date: "17.10.2021",
    price: 1.505144,
  },
  {
    Date: "18.10.2021",
    price: 1.456528,
  },
  {
    Date: "19.10.2021",
    price: 1.480057,
  },
  {
    Date: "20.10.2021",
    price: 1.471905,
  },
  {
    Date: "21.10.2021",
    price: 1.533992,
  },
  {
    Date: "22.10.2021",
    price: 1.50633,
  },
  {
    Date: "23.10.2021",
    price: 1.516041,
  },
  {
    Date: "24.10.2021",
    price: 1.642783,
  },
  {
    Date: "25.10.2021",
    price: 1.571753,
  },
  {
    Date: "26.10.2021",
    price: 1.672453,
  },
  {
    Date: "27.10.2021",
    price: 1.734081,
  },
  {
    Date: "28.10.2021",
    price: 1.756913,
  },
  {
    Date: "29.10.2021",
    price: 2.135261,
  },
  {
    Date: "30.10.2021",
    price: 2.018873,
  },
  {
    Date: "31.10.2021",
    price: 1.868267,
  },
  {
    Date: "01.11.2021",
    price: 1.94058,
  },
  {
    Date: "02.11.2021",
    price: 1.905609,
  },
  {
    Date: "03.11.2021",
    price: 1.93856,
  },
  {
    Date: "04.11.2021",
    price: 2.0356,
  },
  {
    Date: "05.11.2021",
    price: 1.918781,
  },
  {
    Date: "06.11.2021",
    price: 1.899545,
  },
  {
    Date: "07.11.2021",
    price: 1.886103,
  },
  {
    Date: "08.11.2021",
    price: 1.887885,
  },
  {
    Date: "09.11.2021",
    price: 1.893082,
  },
  {
    Date: "10.11.2021",
    price: 1.774682,
  },
  {
    Date: "11.11.2021",
    price: 1.704521,
  },
  {
    Date: "12.11.2021",
    price: 1.756441,
  },
  {
    Date: "13.11.2021",
    price: 1.715861,
  },
  {
    Date: "14.11.2021",
    price: 1.752943,
  },
  {
    Date: "15.11.2021",
    price: 1.730004,
  },
  {
    Date: "16.11.2021",
    price: 1.69599,
  },
  {
    Date: "17.11.2021",
    price: 1.562739,
  },
  {
    Date: "18.11.2021",
    price: 1.638233,
  },
  {
    Date: "19.11.2021",
    price: 1.494473,
  },
  {
    Date: "20.11.2021",
    price: 1.617811,
  },
  {
    Date: "21.11.2021",
    price: 1.665696,
  },
  {
    Date: "22.11.2021",
    price: 1.601035,
  },
  {
    Date: "23.11.2021",
    price: 1.560132,
  },
  {
    Date: "24.11.2021",
    price: 1.751205,
  },
  {
    Date: "25.11.2021",
    price: 1.687243,
  },
  {
    Date: "26.11.2021",
    price: 1.927023,
  },
  {
    Date: "27.11.2021",
    price: 1.638972,
  },
  {
    Date: "28.11.2021",
    price: 1.616072,
  },
  {
    Date: "29.11.2021",
    price: 1.653487,
  },
  {
    Date: "30.11.2021",
    price: 1.818236,
  },
  {
    Date: "01.12.2021",
    price: 1.783193,
  },
  {
    Date: "02.12.2021",
    price: 2.006373,
  },
  {
    Date: "03.12.2021",
    price: 2.12721,
  },
  {
    Date: "04.12.2021",
    price: 2.27925,
  },
  {
    Date: "05.12.2021",
    price: 1.977345,
  },
  {
    Date: "06.12.2021",
    price: 2.053477,
  },
  {
    Date: "07.12.2021",
    price: 2.284448,
  },
  {
    Date: "08.12.2021",
    price: 2.351063,
  },
  {
    Date: "09.12.2021",
    price: 2.417144,
  },
  {
    Date: "10.12.2021",
    price: 2.089155,
  },
  {
    Date: "11.12.2021",
    price: 2.100382,
  },
  {
    Date: "12.12.2021",
    price: 2.136991,
  },
  {
    Date: "13.12.2021",
    price: 2.087085,
  },
  {
    Date: "14.12.2021",
    price: 1.818421,
  },
  {
    Date: "15.12.2021",
    price: 1.942835,
  },
  {
    Date: "16.12.2021",
    price: 2.130628,
  },
  {
    Date: "17.12.2021",
    price: 2.07114,
  },
  {
    Date: "18.12.2021",
    price: 2.133015,
  },
  {
    Date: "19.12.2021",
    price: 2.231206,
  },
  {
    Date: "20.12.2021",
    price: 2.222542,
  },
  {
    Date: "21.12.2021",
    price: 2.126939,
  },
  {
    Date: "22.12.2021",
    price: 2.374386,
  },
  {
    Date: "23.12.2021",
    price: 2.501931,
  },
  {
    Date: "24.12.2021",
    price: 2.682404,
  },
  {
    Date: "25.12.2021",
    price: 2.470144,
  },
  {
    Date: "26.12.2021",
    price: 2.639664,
  },
  {
    Date: "27.12.2021",
    price: 2.876459,
  },
  {
    Date: "28.12.2021",
    price: 2.727522,
  },
  {
    Date: "29.12.2021",
    price: 2.493963,
  },
  {
    Date: "30.12.2021",
    price: 2.476642,
  },
  {
    Date: "31.12.2021",
    price: 2.557003,
  },
  {
    Date: "01.01.2022",
    price: 2.527818,
  },
  {
    Date: "02.01.2022",
    price: 2.574261,
  },
  {
    Date: "03.01.2022",
    price: 2.549156,
  },
  {
    Date: "04.01.2022",
    price: 2.434234,
  },
  {
    Date: "05.01.2022",
    price: 2.367199,
  },
  {
    Date: "06.01.2022",
    price: 2.194927,
  },
  {
    Date: "07.01.2022",
    price: 2.250183,
  },
  {
    Date: "08.01.2022",
    price: 2.053821,
  },
  {
    Date: "09.01.2022",
    price: 1.995515,
  },
  {
    Date: "10.01.2022",
    price: 2.115431,
  },
  {
    Date: "11.01.2022",
    price: 2.071873,
  },
  {
    Date: "12.01.2022",
    price: 2.357821,
  },
  {
    Date: "13.01.2022",
    price: 2.392827,
  },
  {
    Date: "14.01.2022",
    price: 2.263815,
  },
  {
    Date: "15.01.2022",
    price: 2.337607,
  },
  {
    Date: "16.01.2022",
    price: 2.352909,
  },
  {
    Date: "17.01.2022",
    price: 2.33025,
  },
  {
    Date: "18.01.2022",
    price: 2.221839,
  },
  {
    Date: "19.01.2022",
    price: 2.127974,
  },
  {
    Date: "20.01.2022",
    price: 2.035699,
  },
  {
    Date: "21.01.2022",
    price: 1.961334,
  },
  {
    Date: "22.01.2022",
    price: 1.699591,
  },
  {
    Date: "23.01.2022",
    price: 1.531852,
  },
  {
    Date: "24.01.2022",
    price: 1.616332,
  },
  {
    Date: "25.01.2022",
    price: 1.507711,
  },
  {
    Date: "26.01.2022",
    price: 1.558341,
  },
  {
    Date: "27.01.2022",
    price: 1.590669,
  },
  {
    Date: "28.01.2022",
    price: 1.590581,
  },
  {
    Date: "29.01.2022",
    price: 1.678734,
  },
  {
    Date: "30.01.2022",
    price: 1.695255,
  },
  {
    Date: "31.01.2022",
    price: 1.605615,
  },
  {
    Date: "01.02.2022",
    price: 1.643913,
  },
  {
    Date: "02.02.2022",
    price: 1.647756,
  },
  {
    Date: "03.02.2022",
    price: 1.541537,
  },
  {
    Date: "04.02.2022",
    price: 1.549637,
  },
  {
    Date: "05.02.2022",
    price: 1.691145,
  },
  {
    Date: "06.02.2022",
    price: 1.714442,
  },
  {
    Date: "07.02.2022",
    price: 1.739765,
  },
  {
    Date: "08.02.2022",
    price: 2.0202,
  },
  {
    Date: "09.02.2022",
    price: 1.947171,
  },
  {
    Date: "10.02.2022",
    price: 2.042493,
  },
  {
    Date: "11.02.2022",
    price: 1.8963,
  },
  {
    Date: "12.02.2022",
    price: 1.714691,
  },
  {
    Date: "13.02.2022",
    price: 1.699193,
  },
  {
    Date: "14.02.2022",
    price: 1.668161,
  },
  {
    Date: "15.02.2022",
    price: 1.658845,
  },
  {
    Date: "16.02.2022",
    price: 1.863287,
  },
  {
    Date: "17.02.2022",
    price: 1.837905,
  },
  {
    Date: "18.02.2022",
    price: 1.658498,
  },
  {
    Date: "19.02.2022",
    price: 1.595383,
  },
  {
    Date: "20.02.2022",
    price: 1.631846,
  },
  {
    Date: "21.02.2022",
    price: 1.515127,
  },
  {
    Date: "22.02.2022",
    price: 1.408425,
  },
  {
    Date: "23.02.2022",
    price: 1.464253,
  },
  {
    Date: "24.02.2022",
    price: 1.441229,
  },
  {
    Date: "25.02.2022",
    price: 1.43984,
  },
  {
    Date: "26.02.2022",
    price: 1.550656,
  },
  {
    Date: "27.02.2022",
    price: 1.515085,
  },
  {
    Date: "28.02.2022",
    price: 1.45254,
  },
  {
    Date: "01.03.2022",
    price: 1.611454,
  },
  {
    Date: "02.03.2022",
    price: 1.621319,
  },
  {
    Date: "03.03.2022",
    price: 1.635497,
  },
  {
    Date: "04.03.2022",
    price: 1.584856,
  },
  {
    Date: "05.03.2022",
    price: 1.47545,
  },
  {
    Date: "06.03.2022",
    price: 1.508317,
  },
  {
    Date: "07.03.2022",
    price: 1.436818,
  },
  {
    Date: "08.03.2022",
    price: 1.416264,
  },
  {
    Date: "09.03.2022",
    price: 1.445649,
  },
  {
    Date: "10.03.2022",
    price: 1.506553,
  },
  {
    Date: "11.03.2022",
    price: 1.439097,
  },
  {
    Date: "12.03.2022",
    price: 1.406509,
  },
  {
    Date: "13.03.2022",
    price: 1.399065,
  },
  {
    Date: "14.03.2022",
    price: 1.362736,
  },
  {
    Date: "15.03.2022",
    price: 1.377846,
  },
  {
    Date: "16.03.2022",
    price: 1.380056,
  },
  {
    Date: "17.03.2022",
    price: 1.468233,
  },
  {
    Date: "18.03.2022",
    price: 1.448697,
  },
  {
    Date: "19.03.2022",
    price: 1.499313,
  },
  {
    Date: "20.03.2022",
    price: 1.528184,
  },
  {
    Date: "21.03.2022",
    price: 1.463852,
  },
  {
    Date: "22.03.2022",
    price: 1.466254,
  },
  {
    Date: "23.03.2022",
    price: 1.505498,
  },
  {
    Date: "24.03.2022",
    price: 1.556207,
  },
  {
    Date: "25.03.2022",
    price: 1.628767,
  },
  {
    Date: "26.03.2022",
    price: 1.579601,
  },
  {
    Date: "27.03.2022",
    price: 1.610832,
  },
  {
    Date: "28.03.2022",
    price: 1.69356,
  },
  {
    Date: "29.03.2022",
    price: 1.659675,
  },
  {
    Date: "30.03.2022",
    price: 1.67476,
  },
  {
    Date: "31.03.2022",
    price: 1.684221,
  },
  {
    Date: "01.04.2022",
    price: 1.617752,
  },
  {
    Date: "02.04.2022",
    price: 1.69402,
  },
  {
    Date: "03.04.2022",
    price: 1.653808,
  },
  {
    Date: "04.04.2022",
    price: 1.688619,
  },
  {
    Date: "05.04.2022",
    price: 1.649984,
  },
  {
    Date: "06.04.2022",
    price: 1.5929,
  },
  {
    Date: "07.04.2022",
    price: 1.458903,
  },
  {
    Date: "08.04.2022",
    price: 1.513931,
  },
  {
    Date: "09.04.2022",
    price: 1.435769,
  },
  {
    Date: "10.04.2022",
    price: 1.464259,
  },
  {
    Date: "11.04.2022",
    price: 1.432646,
  },
  {
    Date: "12.04.2022",
    price: 1.333526,
  },
  {
    Date: "13.04.2022",
    price: 1.392733,
  },
  {
    Date: "14.04.2022",
    price: 1.419758,
  },
  {
    Date: "15.04.2022",
    price: 1.372716,
  },
  {
    Date: "16.04.2022",
    price: 1.39628,
  },
  {
    Date: "17.04.2022",
    price: 1.390765,
  },
  {
    Date: "18.04.2022",
    price: 1.340103,
  },
  {
    Date: "19.04.2022",
    price: 1.421118,
  },
  {
    Date: "20.04.2022",
    price: 1.428233,
  },
  {
    Date: "21.04.2022",
    price: 1.417712,
  },
  {
    Date: "22.04.2022",
    price: 1.379697,
  },
  {
    Date: "23.04.2022",
    price: 1.399106,
  },
  {
    Date: "24.04.2022",
    price: 1.365753,
  },
  {
    Date: "25.04.2022",
    price: 1.344233,
  },
  {
    Date: "26.04.2022",
    price: 1.352522,
  },
  {
    Date: "27.04.2022",
    price: 1.243275,
  },
  {
    Date: "28.04.2022",
    price: 1.257674,
  },
  {
    Date: "29.04.2022",
    price: 1.238752,
  },
  {
    Date: "30.04.2022",
    price: 1.140665,
  },
  {
    Date: "01.05.2022",
    price: 1.035657,
  },
  {
    Date: "02.05.2022",
    price: 1.101111,
  },
  {
    Date: "03.05.2022",
    price: 1.077642,
  },
  {
    Date: "04.05.2022",
    price: 1.075928,
  },
  {
    Date: "05.05.2022",
    price: 1.170986,
  },
  {
    Date: "06.05.2022",
    price: 1.064479,
  },
  {
    Date: "07.05.2022",
    price: 1.044149,
  },
  {
    Date: "08.05.2022",
    price: 1.021219,
  },
  {
    Date: "09.05.2022",
    price: 0.974406,
  },
  {
    Date: "10.05.2022",
    price: 0.820769,
  },
  {
    Date: "11.05.2022",
    price: 0.890196,
  },
  {
    Date: "12.05.2022",
    price: 0.666876,
  },
  {
    Date: "13.05.2022",
    price: 0.600941,
  },
  {
    Date: "14.05.2022",
    price: 0.667636,
  },
  {
    Date: "15.05.2022",
    price: 0.686084,
  },
  {
    Date: "16.05.2022",
    price: 0.737511,
  },
  {
    Date: "17.05.2022",
    price: 0.677313,
  },
  {
    Date: "18.05.2022",
    price: 0.726947,
  },
  {
    Date: "19.05.2022",
    price: 0.62728,
  },
  {
    Date: "20.05.2022",
    price: 0.658694,
  },
  {
    Date: "21.05.2022",
    price: 0.636431,
  },
  {
    Date: "22.05.2022",
    price: 0.649499,
  },
  {
    Date: "23.05.2022",
    price: 0.677974,
  },
  {
    Date: "24.05.2022",
    price: 0.642034,
  },
  {
    Date: "25.05.2022",
    price: 0.661673,
  },
  {
    Date: "26.05.2022",
    price: 0.646042,
  },
  {
    Date: "27.05.2022",
    price: 0.600756,
  },
  {
    Date: "28.05.2022",
    price: 0.583936,
  },
  {
    Date: "29.05.2022",
    price: 0.587893,
  },
  {
    Date: "30.05.2022",
    price: 0.598522,
  },
  {
    Date: "31.05.2022",
    price: 0.657141,
  },
  {
    Date: "01.06.2022",
    price: 0.662113,
  },
  {
    Date: "02.06.2022",
    price: 0.601487,
  },
  {
    Date: "03.06.2022",
    price: 0.619434,
  },
  {
    Date: "04.06.2022",
    price: 0.589992,
  },
  {
    Date: "05.06.2022",
    price: 0.595671,
  },
  {
    Date: "06.06.2022",
    price: 0.598014,
  },
  {
    Date: "07.06.2022",
    price: 0.632534,
  },
  {
    Date: "08.06.2022",
    price: 0.616556,
  },
  {
    Date: "09.06.2022",
    price: 0.607448,
  },
  {
    Date: "10.06.2022",
    price: 0.633637,
  },
  {
    Date: "11.06.2022",
    price: 0.594986,
  },
  {
    Date: "12.06.2022",
    price: 0.538779,
  },
  {
    Date: "13.06.2022",
    price: 0.501487,
  },
  {
    Date: "14.06.2022",
    price: 0.428144,
  },
  {
    Date: "15.06.2022",
    price: 0.415688,
  },
  {
    Date: "16.06.2022",
    price: 0.437785,
  },
  {
    Date: "17.06.2022",
    price: 0.382045,
  },
  {
    Date: "18.06.2022",
    price: 0.399798,
  },
  {
    Date: "19.06.2022",
    price: 0.346595,
  },
  {
    Date: "20.06.2022",
    price: 0.383117,
  },
  {
    Date: "21.06.2022",
    price: 0.400766,
  },
  {
    Date: "22.06.2022",
    price: 0.41907,
  },
  {
    Date: "23.06.2022",
    price: 0.456587,
  },
  {
    Date: "24.06.2022",
    price: 0.561384,
  },
  {
    Date: "25.06.2022",
    price: 0.612233,
  },
  {
    Date: "26.06.2022",
    price: 0.600394,
  },
  {
    Date: "27.06.2022",
    price: 0.560376,
  },
  {
    Date: "28.06.2022",
    price: 0.532485,
  },
  {
    Date: "29.06.2022",
    price: 0.511,
  },
  {
    Date: "30.06.2022",
    price: 0.495514,
  },
  {
    Date: "01.07.2022",
    price: 0.478104,
  },
  {
    Date: "02.07.2022",
    price: 0.461396,
  },
  {
    Date: "03.07.2022",
    price: 0.492778,
  },
  {
    Date: "04.07.2022",
    price: 0.460897,
  },
  {
    Date: "05.07.2022",
    price: 0.490713,
  },
];
