export const ETHpriceHistory = [
  {
    Date: "17.09.2014",
    price: 0,
  },
  {
    Date: "18.09.2014",
    price: 0,
  },
  {
    Date: "19.09.2014",
    price: 0,
  },
  {
    Date: "20.09.2014",
    price: 0,
  },
  {
    Date: "21.09.2014",
    price: 0,
  },
  {
    Date: "22.09.2014",
    price: 0,
  },
  {
    Date: "23.09.2014",
    price: 0,
  },
  {
    Date: "24.09.2014",
    price: 0,
  },
  {
    Date: "25.09.2014",
    price: 0,
  },
  {
    Date: "26.09.2014",
    price: 0,
  },
  {
    Date: "27.09.2014",
    price: 0,
  },
  {
    Date: "28.09.2014",
    price: 0,
  },
  {
    Date: "29.09.2014",
    price: 0,
  },
  {
    Date: "30.09.2014",
    price: 0,
  },
  {
    Date: "01.10.2014",
    price: 0,
  },
  {
    Date: "02.10.2014",
    price: 0,
  },
  {
    Date: "03.10.2014",
    price: 0,
  },
  {
    Date: "04.10.2014",
    price: 0,
  },
  {
    Date: "05.10.2014",
    price: 0,
  },
  {
    Date: "06.10.2014",
    price: 0,
  },
  {
    Date: "07.10.2014",
    price: 0,
  },
  {
    Date: "08.10.2014",
    price: 0,
  },
  {
    Date: "09.10.2014",
    price: 0,
  },
  {
    Date: "10.10.2014",
    price: 0,
  },
  {
    Date: "11.10.2014",
    price: 0,
  },
  {
    Date: "12.10.2014",
    price: 0,
  },
  {
    Date: "13.10.2014",
    price: 0,
  },
  {
    Date: "14.10.2014",
    price: 0,
  },
  {
    Date: "15.10.2014",
    price: 0,
  },
  {
    Date: "16.10.2014",
    price: 0,
  },
  {
    Date: "17.10.2014",
    price: 0,
  },
  {
    Date: "18.10.2014",
    price: 0,
  },
  {
    Date: "19.10.2014",
    price: 0,
  },
  {
    Date: "20.10.2014",
    price: 0,
  },
  {
    Date: "21.10.2014",
    price: 0,
  },
  {
    Date: "22.10.2014",
    price: 0,
  },
  {
    Date: "23.10.2014",
    price: 0,
  },
  {
    Date: "24.10.2014",
    price: 0,
  },
  {
    Date: "25.10.2014",
    price: 0,
  },
  {
    Date: "26.10.2014",
    price: 0,
  },
  {
    Date: "27.10.2014",
    price: 0,
  },
  {
    Date: "28.10.2014",
    price: 0,
  },
  {
    Date: "29.10.2014",
    price: 0,
  },
  {
    Date: "30.10.2014",
    price: 0,
  },
  {
    Date: "31.10.2014",
    price: 0,
  },
  {
    Date: "01.11.2014",
    price: 0,
  },
  {
    Date: "02.11.2014",
    price: 0,
  },
  {
    Date: "03.11.2014",
    price: 0,
  },
  {
    Date: "04.11.2014",
    price: 0,
  },
  {
    Date: "05.11.2014",
    price: 0,
  },
  {
    Date: "06.11.2014",
    price: 0,
  },
  {
    Date: "07.11.2014",
    price: 0,
  },
  {
    Date: "08.11.2014",
    price: 0,
  },
  {
    Date: "09.11.2014",
    price: 0,
  },
  {
    Date: "10.11.2014",
    price: 0,
  },
  {
    Date: "11.11.2014",
    price: 0,
  },
  {
    Date: "12.11.2014",
    price: 0,
  },
  {
    Date: "13.11.2014",
    price: 0,
  },
  {
    Date: "14.11.2014",
    price: 0,
  },
  {
    Date: "15.11.2014",
    price: 0,
  },
  {
    Date: "16.11.2014",
    price: 0,
  },
  {
    Date: "17.11.2014",
    price: 0,
  },
  {
    Date: "18.11.2014",
    price: 0,
  },
  {
    Date: "19.11.2014",
    price: 0,
  },
  {
    Date: "20.11.2014",
    price: 0,
  },
  {
    Date: "21.11.2014",
    price: 0,
  },
  {
    Date: "22.11.2014",
    price: 0,
  },
  {
    Date: "23.11.2014",
    price: 0,
  },
  {
    Date: "24.11.2014",
    price: 0,
  },
  {
    Date: "25.11.2014",
    price: 0,
  },
  {
    Date: "26.11.2014",
    price: 0,
  },
  {
    Date: "27.11.2014",
    price: 0,
  },
  {
    Date: "28.11.2014",
    price: 0,
  },
  {
    Date: "29.11.2014",
    price: 0,
  },
  {
    Date: "30.11.2014",
    price: 0,
  },
  {
    Date: "01.12.2014",
    price: 0,
  },
  {
    Date: "02.12.2014",
    price: 0,
  },
  {
    Date: "03.12.2014",
    price: 0,
  },
  {
    Date: "04.12.2014",
    price: 0,
  },
  {
    Date: "05.12.2014",
    price: 0,
  },
  {
    Date: "06.12.2014",
    price: 0,
  },
  {
    Date: "07.12.2014",
    price: 0,
  },
  {
    Date: "08.12.2014",
    price: 0,
  },
  {
    Date: "09.12.2014",
    price: 0,
  },
  {
    Date: "10.12.2014",
    price: 0,
  },
  {
    Date: "11.12.2014",
    price: 0,
  },
  {
    Date: "12.12.2014",
    price: 0,
  },
  {
    Date: "13.12.2014",
    price: 0,
  },
  {
    Date: "14.12.2014",
    price: 0,
  },
  {
    Date: "15.12.2014",
    price: 0,
  },
  {
    Date: "16.12.2014",
    price: 0,
  },
  {
    Date: "17.12.2014",
    price: 0,
  },
  {
    Date: "18.12.2014",
    price: 0,
  },
  {
    Date: "19.12.2014",
    price: 0,
  },
  {
    Date: "20.12.2014",
    price: 0,
  },
  {
    Date: "21.12.2014",
    price: 0,
  },
  {
    Date: "22.12.2014",
    price: 0,
  },
  {
    Date: "23.12.2014",
    price: 0,
  },
  {
    Date: "24.12.2014",
    price: 0,
  },
  {
    Date: "25.12.2014",
    price: 0,
  },
  {
    Date: "26.12.2014",
    price: 0,
  },
  {
    Date: "27.12.2014",
    price: 0,
  },
  {
    Date: "28.12.2014",
    price: 0,
  },
  {
    Date: "29.12.2014",
    price: 0,
  },
  {
    Date: "30.12.2014",
    price: 0,
  },
  {
    Date: "31.12.2014",
    price: 0,
  },
  {
    Date: "01.01.2015",
    price: 0,
  },
  {
    Date: "02.01.2015",
    price: 0,
  },
  {
    Date: "03.01.2015",
    price: 0,
  },
  {
    Date: "04.01.2015",
    price: 0,
  },
  {
    Date: "05.01.2015",
    price: 0,
  },
  {
    Date: "06.01.2015",
    price: 0,
  },
  {
    Date: "07.01.2015",
    price: 0,
  },
  {
    Date: "08.01.2015",
    price: 0,
  },
  {
    Date: "09.01.2015",
    price: 0,
  },
  {
    Date: "10.01.2015",
    price: 0,
  },
  {
    Date: "11.01.2015",
    price: 0,
  },
  {
    Date: "12.01.2015",
    price: 0,
  },
  {
    Date: "13.01.2015",
    price: 0,
  },
  {
    Date: "14.01.2015",
    price: 0,
  },
  {
    Date: "15.01.2015",
    price: 0,
  },
  {
    Date: "16.01.2015",
    price: 0,
  },
  {
    Date: "17.01.2015",
    price: 0,
  },
  {
    Date: "18.01.2015",
    price: 0,
  },
  {
    Date: "19.01.2015",
    price: 0,
  },
  {
    Date: "20.01.2015",
    price: 0,
  },
  {
    Date: "21.01.2015",
    price: 0,
  },
  {
    Date: "22.01.2015",
    price: 0,
  },
  {
    Date: "23.01.2015",
    price: 0,
  },
  {
    Date: "24.01.2015",
    price: 0,
  },
  {
    Date: "25.01.2015",
    price: 0,
  },
  {
    Date: "26.01.2015",
    price: 0,
  },
  {
    Date: "27.01.2015",
    price: 0,
  },
  {
    Date: "28.01.2015",
    price: 0,
  },
  {
    Date: "29.01.2015",
    price: 0,
  },
  {
    Date: "30.01.2015",
    price: 0,
  },
  {
    Date: "31.01.2015",
    price: 0,
  },
  {
    Date: "01.02.2015",
    price: 0,
  },
  {
    Date: "02.02.2015",
    price: 0,
  },
  {
    Date: "03.02.2015",
    price: 0,
  },
  {
    Date: "04.02.2015",
    price: 0,
  },
  {
    Date: "05.02.2015",
    price: 0,
  },
  {
    Date: "06.02.2015",
    price: 0,
  },
  {
    Date: "07.02.2015",
    price: 0,
  },
  {
    Date: "08.02.2015",
    price: 0,
  },
  {
    Date: "09.02.2015",
    price: 0,
  },
  {
    Date: "10.02.2015",
    price: 0,
  },
  {
    Date: "11.02.2015",
    price: 0,
  },
  {
    Date: "12.02.2015",
    price: 0,
  },
  {
    Date: "13.02.2015",
    price: 0,
  },
  {
    Date: "14.02.2015",
    price: 0,
  },
  {
    Date: "15.02.2015",
    price: 0,
  },
  {
    Date: "16.02.2015",
    price: 0,
  },
  {
    Date: "17.02.2015",
    price: 0,
  },
  {
    Date: "18.02.2015",
    price: 0,
  },
  {
    Date: "19.02.2015",
    price: 0,
  },
  {
    Date: "20.02.2015",
    price: 0,
  },
  {
    Date: "21.02.2015",
    price: 0,
  },
  {
    Date: "22.02.2015",
    price: 0,
  },
  {
    Date: "23.02.2015",
    price: 0,
  },
  {
    Date: "24.02.2015",
    price: 0,
  },
  {
    Date: "25.02.2015",
    price: 0,
  },
  {
    Date: "26.02.2015",
    price: 0,
  },
  {
    Date: "27.02.2015",
    price: 0,
  },
  {
    Date: "28.02.2015",
    price: 0,
  },
  {
    Date: "01.03.2015",
    price: 0,
  },
  {
    Date: "02.03.2015",
    price: 0,
  },
  {
    Date: "03.03.2015",
    price: 0,
  },
  {
    Date: "04.03.2015",
    price: 0,
  },
  {
    Date: "05.03.2015",
    price: 0,
  },
  {
    Date: "06.03.2015",
    price: 0,
  },
  {
    Date: "07.03.2015",
    price: 0,
  },
  {
    Date: "08.03.2015",
    price: 0,
  },
  {
    Date: "09.03.2015",
    price: 0,
  },
  {
    Date: "10.03.2015",
    price: 0,
  },
  {
    Date: "11.03.2015",
    price: 0,
  },
  {
    Date: "12.03.2015",
    price: 0,
  },
  {
    Date: "13.03.2015",
    price: 0,
  },
  {
    Date: "14.03.2015",
    price: 0,
  },
  {
    Date: "15.03.2015",
    price: 0,
  },
  {
    Date: "16.03.2015",
    price: 0,
  },
  {
    Date: "17.03.2015",
    price: 0,
  },
  {
    Date: "18.03.2015",
    price: 0,
  },
  {
    Date: "19.03.2015",
    price: 0,
  },
  {
    Date: "20.03.2015",
    price: 0,
  },
  {
    Date: "21.03.2015",
    price: 0,
  },
  {
    Date: "22.03.2015",
    price: 0,
  },
  {
    Date: "23.03.2015",
    price: 0,
  },
  {
    Date: "24.03.2015",
    price: 0,
  },
  {
    Date: "25.03.2015",
    price: 0,
  },
  {
    Date: "26.03.2015",
    price: 0,
  },
  {
    Date: "27.03.2015",
    price: 0,
  },
  {
    Date: "28.03.2015",
    price: 0,
  },
  {
    Date: "29.03.2015",
    price: 0,
  },
  {
    Date: "30.03.2015",
    price: 0,
  },
  {
    Date: "31.03.2015",
    price: 0,
  },
  {
    Date: "01.04.2015",
    price: 0,
  },
  {
    Date: "02.04.2015",
    price: 0,
  },
  {
    Date: "03.04.2015",
    price: 0,
  },
  {
    Date: "04.04.2015",
    price: 0,
  },
  {
    Date: "05.04.2015",
    price: 0,
  },
  {
    Date: "06.04.2015",
    price: 0,
  },
  {
    Date: "07.04.2015",
    price: 0,
  },
  {
    Date: "08.04.2015",
    price: 0,
  },
  {
    Date: "09.04.2015",
    price: 0,
  },
  {
    Date: "10.04.2015",
    price: 0,
  },
  {
    Date: "11.04.2015",
    price: 0,
  },
  {
    Date: "12.04.2015",
    price: 0,
  },
  {
    Date: "13.04.2015",
    price: 0,
  },
  {
    Date: "14.04.2015",
    price: 0,
  },
  {
    Date: "15.04.2015",
    price: 0,
  },
  {
    Date: "16.04.2015",
    price: 0,
  },
  {
    Date: "17.04.2015",
    price: 0,
  },
  {
    Date: "18.04.2015",
    price: 0,
  },
  {
    Date: "19.04.2015",
    price: 0,
  },
  {
    Date: "20.04.2015",
    price: 0,
  },
  {
    Date: "21.04.2015",
    price: 0,
  },
  {
    Date: "22.04.2015",
    price: 0,
  },
  {
    Date: "23.04.2015",
    price: 0,
  },
  {
    Date: "24.04.2015",
    price: 0,
  },
  {
    Date: "25.04.2015",
    price: 0,
  },
  {
    Date: "26.04.2015",
    price: 0,
  },
  {
    Date: "27.04.2015",
    price: 0,
  },
  {
    Date: "28.04.2015",
    price: 0,
  },
  {
    Date: "29.04.2015",
    price: 0,
  },
  {
    Date: "30.04.2015",
    price: 0,
  },
  {
    Date: "01.05.2015",
    price: 0,
  },
  {
    Date: "02.05.2015",
    price: 0,
  },
  {
    Date: "03.05.2015",
    price: 0,
  },
  {
    Date: "04.05.2015",
    price: 0,
  },
  {
    Date: "05.05.2015",
    price: 0,
  },
  {
    Date: "06.05.2015",
    price: 0,
  },
  {
    Date: "07.05.2015",
    price: 0,
  },
  {
    Date: "08.05.2015",
    price: 0,
  },
  {
    Date: "09.05.2015",
    price: 0,
  },
  {
    Date: "10.05.2015",
    price: 0,
  },
  {
    Date: "11.05.2015",
    price: 0,
  },
  {
    Date: "12.05.2015",
    price: 0,
  },
  {
    Date: "13.05.2015",
    price: 0,
  },
  {
    Date: "14.05.2015",
    price: 0,
  },
  {
    Date: "15.05.2015",
    price: 0,
  },
  {
    Date: "16.05.2015",
    price: 0,
  },
  {
    Date: "17.05.2015",
    price: 0,
  },
  {
    Date: "18.05.2015",
    price: 0,
  },
  {
    Date: "19.05.2015",
    price: 0,
  },
  {
    Date: "20.05.2015",
    price: 0,
  },
  {
    Date: "21.05.2015",
    price: 0,
  },
  {
    Date: "22.05.2015",
    price: 0,
  },
  {
    Date: "23.05.2015",
    price: 0,
  },
  {
    Date: "24.05.2015",
    price: 0,
  },
  {
    Date: "25.05.2015",
    price: 0,
  },
  {
    Date: "26.05.2015",
    price: 0,
  },
  {
    Date: "27.05.2015",
    price: 0,
  },
  {
    Date: "28.05.2015",
    price: 0,
  },
  {
    Date: "29.05.2015",
    price: 0,
  },
  {
    Date: "30.05.2015",
    price: 0,
  },
  {
    Date: "31.05.2015",
    price: 0,
  },
  {
    Date: "01.06.2015",
    price: 0,
  },
  {
    Date: "02.06.2015",
    price: 0,
  },
  {
    Date: "03.06.2015",
    price: 0,
  },
  {
    Date: "04.06.2015",
    price: 0,
  },
  {
    Date: "05.06.2015",
    price: 0,
  },
  {
    Date: "06.06.2015",
    price: 0,
  },
  {
    Date: "07.06.2015",
    price: 0,
  },
  {
    Date: "08.06.2015",
    price: 0,
  },
  {
    Date: "09.06.2015",
    price: 0,
  },
  {
    Date: "10.06.2015",
    price: 0,
  },
  {
    Date: "11.06.2015",
    price: 0,
  },
  {
    Date: "12.06.2015",
    price: 0,
  },
  {
    Date: "13.06.2015",
    price: 0,
  },
  {
    Date: "14.06.2015",
    price: 0,
  },
  {
    Date: "15.06.2015",
    price: 0,
  },
  {
    Date: "16.06.2015",
    price: 0,
  },
  {
    Date: "17.06.2015",
    price: 0,
  },
  {
    Date: "18.06.2015",
    price: 0,
  },
  {
    Date: "19.06.2015",
    price: 0,
  },
  {
    Date: "20.06.2015",
    price: 0,
  },
  {
    Date: "21.06.2015",
    price: 0,
  },
  {
    Date: "22.06.2015",
    price: 0,
  },
  {
    Date: "23.06.2015",
    price: 0,
  },
  {
    Date: "24.06.2015",
    price: 0,
  },
  {
    Date: "25.06.2015",
    price: 0,
  },
  {
    Date: "26.06.2015",
    price: 0,
  },
  {
    Date: "27.06.2015",
    price: 0,
  },
  {
    Date: "28.06.2015",
    price: 0,
  },
  {
    Date: "29.06.2015",
    price: 0,
  },
  {
    Date: "30.06.2015",
    price: 0,
  },
  {
    Date: "01.07.2015",
    price: 0,
  },
  {
    Date: "02.07.2015",
    price: 0,
  },
  {
    Date: "03.07.2015",
    price: 0,
  },
  {
    Date: "04.07.2015",
    price: 0,
  },
  {
    Date: "05.07.2015",
    price: 0,
  },
  {
    Date: "06.07.2015",
    price: 0,
  },
  {
    Date: "07.07.2015",
    price: 0,
  },
  {
    Date: "08.07.2015",
    price: 0,
  },
  {
    Date: "09.07.2015",
    price: 0,
  },
  {
    Date: "10.07.2015",
    price: 0,
  },
  {
    Date: "11.07.2015",
    price: 0,
  },
  {
    Date: "12.07.2015",
    price: 0,
  },
  {
    Date: "13.07.2015",
    price: 0,
  },
  {
    Date: "14.07.2015",
    price: 0,
  },
  {
    Date: "15.07.2015",
    price: 0,
  },
  {
    Date: "16.07.2015",
    price: 0,
  },
  {
    Date: "17.07.2015",
    price: 0,
  },
  {
    Date: "18.07.2015",
    price: 0,
  },
  {
    Date: "19.07.2015",
    price: 0,
  },
  {
    Date: "20.07.2015",
    price: 0,
  },
  {
    Date: "21.07.2015",
    price: 0,
  },
  {
    Date: "22.07.2015",
    price: 0,
  },
  {
    Date: "23.07.2015",
    price: 0,
  },
  {
    Date: "24.07.2015",
    price: 0,
  },
  {
    Date: "25.07.2015",
    price: 0,
  },
  {
    Date: "26.07.2015",
    price: 0,
  },
  {
    Date: "27.07.2015",
    price: 0,
  },
  {
    Date: "28.07.2015",
    price: 0,
  },
  {
    Date: "29.07.2015",
    price: 0,
  },
  {
    Date: "30.07.2015",
    price: 0,
  },
  {
    Date: "31.07.2015",
    price: 0,
  },
  {
    Date: "01.08.2015",
    price: 0,
  },
  {
    Date: "02.08.2015",
    price: 0,
  },
  {
    Date: "03.08.2015",
    price: 0,
  },
  {
    Date: "04.08.2015",
    price: 0,
  },
  {
    Date: "05.08.2015",
    price: 0,
  },
  {
    Date: "06.08.2015",
    price: 0,
  },
  {
    Date: "07.08.2015",
    price: 0,
  },
  {
    Date: "08.08.2015",
    price: 0,
  },
  {
    Date: "09.08.2015",
    price: 0,
  },
  {
    Date: "10.08.2015",
    price: 0,
  },
  {
    Date: "11.08.2015",
    price: 0,
  },
  {
    Date: "12.08.2015",
    price: 0,
  },
  {
    Date: "13.08.2015",
    price: 0,
  },
  {
    Date: "14.08.2015",
    price: 0,
  },
  {
    Date: "15.08.2015",
    price: 0,
  },
  {
    Date: "16.08.2015",
    price: 0,
  },
  {
    Date: "17.08.2015",
    price: 0,
  },
  {
    Date: "18.08.2015",
    price: 0,
  },
  {
    Date: "19.08.2015",
    price: 0,
  },
  {
    Date: "20.08.2015",
    price: 0,
  },
  {
    Date: "21.08.2015",
    price: 0,
  },
  {
    Date: "22.08.2015",
    price: 0,
  },
  {
    Date: "23.08.2015",
    price: 0,
  },
  {
    Date: "24.08.2015",
    price: 0,
  },
  {
    Date: "25.08.2015",
    price: 0,
  },
  {
    Date: "26.08.2015",
    price: 0,
  },
  {
    Date: "27.08.2015",
    price: 0,
  },
  {
    Date: "28.08.2015",
    price: 0,
  },
  {
    Date: "29.08.2015",
    price: 0,
  },
  {
    Date: "30.08.2015",
    price: 0,
  },
  {
    Date: "31.08.2015",
    price: 0,
  },
  {
    Date: "01.09.2015",
    price: 0,
  },
  {
    Date: "02.09.2015",
    price: 0,
  },
  {
    Date: "03.09.2015",
    price: 0,
  },
  {
    Date: "04.09.2015",
    price: 0,
  },
  {
    Date: "05.09.2015",
    price: 0,
  },
  {
    Date: "06.09.2015",
    price: 0,
  },
  {
    Date: "07.09.2015",
    price: 0,
  },
  {
    Date: "08.09.2015",
    price: 0,
  },
  {
    Date: "09.09.2015",
    price: 0,
  },
  {
    Date: "10.09.2015",
    price: 0,
  },
  {
    Date: "11.09.2015",
    price: 0,
  },
  {
    Date: "12.09.2015",
    price: 0,
  },
  {
    Date: "13.09.2015",
    price: 0,
  },
  {
    Date: "14.09.2015",
    price: 0,
  },
  {
    Date: "15.09.2015",
    price: 0,
  },
  {
    Date: "16.09.2015",
    price: 0,
  },
  {
    Date: "17.09.2015",
    price: 0,
  },
  {
    Date: "18.09.2015",
    price: 0,
  },
  {
    Date: "19.09.2015",
    price: 0,
  },
  {
    Date: "20.09.2015",
    price: 0,
  },
  {
    Date: "21.09.2015",
    price: 0,
  },
  {
    Date: "22.09.2015",
    price: 0,
  },
  {
    Date: "23.09.2015",
    price: 0,
  },
  {
    Date: "24.09.2015",
    price: 0,
  },
  {
    Date: "25.09.2015",
    price: 0,
  },
  {
    Date: "26.09.2015",
    price: 0,
  },
  {
    Date: "27.09.2015",
    price: 0,
  },
  {
    Date: "28.09.2015",
    price: 0,
  },
  {
    Date: "29.09.2015",
    price: 0,
  },
  {
    Date: "30.09.2015",
    price: 0,
  },
  {
    Date: "01.10.2015",
    price: 0,
  },
  {
    Date: "02.10.2015",
    price: 0,
  },
  {
    Date: "03.10.2015",
    price: 0,
  },
  {
    Date: "04.10.2015",
    price: 0,
  },
  {
    Date: "05.10.2015",
    price: 0,
  },
  {
    Date: "06.10.2015",
    price: 0,
  },
  {
    Date: "07.10.2015",
    price: 0,
  },
  {
    Date: "08.10.2015",
    price: 0,
  },
  {
    Date: "09.10.2015",
    price: 0,
  },
  {
    Date: "10.10.2015",
    price: 0,
  },
  {
    Date: "11.10.2015",
    price: 0,
  },
  {
    Date: "12.10.2015",
    price: 0,
  },
  {
    Date: "13.10.2015",
    price: 0,
  },
  {
    Date: "14.10.2015",
    price: 0,
  },
  {
    Date: "15.10.2015",
    price: 0,
  },
  {
    Date: "16.10.2015",
    price: 0,
  },
  {
    Date: "17.10.2015",
    price: 0,
  },
  {
    Date: "18.10.2015",
    price: 0,
  },
  {
    Date: "19.10.2015",
    price: 0,
  },
  {
    Date: "20.10.2015",
    price: 0,
  },
  {
    Date: "21.10.2015",
    price: 0,
  },
  {
    Date: "22.10.2015",
    price: 0,
  },
  {
    Date: "23.10.2015",
    price: 0,
  },
  {
    Date: "24.10.2015",
    price: 0,
  },
  {
    Date: "25.10.2015",
    price: 0,
  },
  {
    Date: "26.10.2015",
    price: 0,
  },
  {
    Date: "27.10.2015",
    price: 0,
  },
  {
    Date: "28.10.2015",
    price: 0,
  },
  {
    Date: "29.10.2015",
    price: 0,
  },
  {
    Date: "30.10.2015",
    price: 0,
  },
  {
    Date: "31.10.2015",
    price: 0,
  },
  {
    Date: "01.11.2015",
    price: 0,
  },
  {
    Date: "02.11.2015",
    price: 0,
  },
  {
    Date: "03.11.2015",
    price: 0,
  },
  {
    Date: "04.11.2015",
    price: 0,
  },
  {
    Date: "05.11.2015",
    price: 0,
  },
  {
    Date: "06.11.2015",
    price: 0,
  },
  {
    Date: "07.11.2015",
    price: 0,
  },
  {
    Date: "08.11.2015",
    price: 0,
  },
  {
    Date: "09.11.2015",
    price: 0,
  },
  {
    Date: "10.11.2015",
    price: 0,
  },
  {
    Date: "11.11.2015",
    price: 0,
  },
  {
    Date: "12.11.2015",
    price: 0,
  },
  {
    Date: "13.11.2015",
    price: 0,
  },
  {
    Date: "14.11.2015",
    price: 0,
  },
  {
    Date: "15.11.2015",
    price: 0,
  },
  {
    Date: "16.11.2015",
    price: 0,
  },
  {
    Date: "17.11.2015",
    price: 0,
  },
  {
    Date: "18.11.2015",
    price: 0,
  },
  {
    Date: "19.11.2015",
    price: 0,
  },
  {
    Date: "20.11.2015",
    price: 0,
  },
  {
    Date: "21.11.2015",
    price: 0,
  },
  {
    Date: "22.11.2015",
    price: 0,
  },
  {
    Date: "23.11.2015",
    price: 0,
  },
  {
    Date: "24.11.2015",
    price: 0,
  },
  {
    Date: "25.11.2015",
    price: 0,
  },
  {
    Date: "26.11.2015",
    price: 0,
  },
  {
    Date: "27.11.2015",
    price: 0,
  },
  {
    Date: "28.11.2015",
    price: 0,
  },
  {
    Date: "29.11.2015",
    price: 0,
  },
  {
    Date: "30.11.2015",
    price: 0,
  },
  {
    Date: "01.12.2015",
    price: 0,
  },
  {
    Date: "02.12.2015",
    price: 0,
  },
  {
    Date: "03.12.2015",
    price: 0,
  },
  {
    Date: "04.12.2015",
    price: 0,
  },
  {
    Date: "05.12.2015",
    price: 0,
  },
  {
    Date: "06.12.2015",
    price: 0,
  },
  {
    Date: "07.12.2015",
    price: 0,
  },
  {
    Date: "08.12.2015",
    price: 0,
  },
  {
    Date: "09.12.2015",
    price: 0,
  },
  {
    Date: "10.12.2015",
    price: 0,
  },
  {
    Date: "11.12.2015",
    price: 0,
  },
  {
    Date: "12.12.2015",
    price: 0,
  },
  {
    Date: "13.12.2015",
    price: 0,
  },
  {
    Date: "14.12.2015",
    price: 0,
  },
  {
    Date: "15.12.2015",
    price: 0,
  },
  {
    Date: "16.12.2015",
    price: 0,
  },
  {
    Date: "17.12.2015",
    price: 0,
  },
  {
    Date: "18.12.2015",
    price: 0,
  },
  {
    Date: "19.12.2015",
    price: 0,
  },
  {
    Date: "20.12.2015",
    price: 0,
  },
  {
    Date: "21.12.2015",
    price: 0,
  },
  {
    Date: "22.12.2015",
    price: 0,
  },
  {
    Date: "23.12.2015",
    price: 0,
  },
  {
    Date: "24.12.2015",
    price: 0,
  },
  {
    Date: "25.12.2015",
    price: 0,
  },
  {
    Date: "26.12.2015",
    price: 0,
  },
  {
    Date: "27.12.2015",
    price: 0,
  },
  {
    Date: "28.12.2015",
    price: 0,
  },
  {
    Date: "29.12.2015",
    price: 0,
  },
  {
    Date: "30.12.2015",
    price: 0,
  },
  {
    Date: "31.12.2015",
    price: 0,
  },
  {
    Date: "01.01.2016",
    price: 0,
  },
  {
    Date: "02.01.2016",
    price: 0,
  },
  {
    Date: "03.01.2016",
    price: 0,
  },
  {
    Date: "04.01.2016",
    price: 0,
  },
  {
    Date: "05.01.2016",
    price: 0,
  },
  {
    Date: "06.01.2016",
    price: 0,
  },
  {
    Date: "07.01.2016",
    price: 0,
  },
  {
    Date: "08.01.2016",
    price: 0,
  },
  {
    Date: "09.01.2016",
    price: 0,
  },
  {
    Date: "10.01.2016",
    price: 0,
  },
  {
    Date: "11.01.2016",
    price: 0,
  },
  {
    Date: "12.01.2016",
    price: 0,
  },
  {
    Date: "13.01.2016",
    price: 0,
  },
  {
    Date: "14.01.2016",
    price: 0,
  },
  {
    Date: "15.01.2016",
    price: 0,
  },
  {
    Date: "16.01.2016",
    price: 0,
  },
  {
    Date: "17.01.2016",
    price: 0,
  },
  {
    Date: "18.01.2016",
    price: 0,
  },
  {
    Date: "19.01.2016",
    price: 0,
  },
  {
    Date: "20.01.2016",
    price: 0,
  },
  {
    Date: "21.01.2016",
    price: 0,
  },
  {
    Date: "22.01.2016",
    price: 0,
  },
  {
    Date: "23.01.2016",
    price: 0,
  },
  {
    Date: "24.01.2016",
    price: 0,
  },
  {
    Date: "25.01.2016",
    price: 0,
  },
  {
    Date: "26.01.2016",
    price: 0,
  },
  {
    Date: "27.01.2016",
    price: 0,
  },
  {
    Date: "28.01.2016",
    price: 0,
  },
  {
    Date: "29.01.2016",
    price: 0,
  },
  {
    Date: "30.01.2016",
    price: 0,
  },
  {
    Date: "31.01.2016",
    price: 0,
  },
  {
    Date: "01.02.2016",
    price: 0,
  },
  {
    Date: "02.02.2016",
    price: 0,
  },
  {
    Date: "03.02.2016",
    price: 0,
  },
  {
    Date: "04.02.2016",
    price: 0,
  },
  {
    Date: "05.02.2016",
    price: 0,
  },
  {
    Date: "06.02.2016",
    price: 0,
  },
  {
    Date: "07.02.2016",
    price: 0,
  },
  {
    Date: "08.02.2016",
    price: 0,
  },
  {
    Date: "09.02.2016",
    price: 0,
  },
  {
    Date: "10.02.2016",
    price: 0,
  },
  {
    Date: "11.02.2016",
    price: 0,
  },
  {
    Date: "12.02.2016",
    price: 0,
  },
  {
    Date: "13.02.2016",
    price: 0,
  },
  {
    Date: "14.02.2016",
    price: 0,
  },
  {
    Date: "15.02.2016",
    price: 0,
  },
  {
    Date: "16.02.2016",
    price: 0,
  },
  {
    Date: "17.02.2016",
    price: 0,
  },
  {
    Date: "18.02.2016",
    price: 0,
  },
  {
    Date: "19.02.2016",
    price: 0,
  },
  {
    Date: "20.02.2016",
    price: 0,
  },
  {
    Date: "21.02.2016",
    price: 0,
  },
  {
    Date: "22.02.2016",
    price: 0,
  },
  {
    Date: "23.02.2016",
    price: 0,
  },
  {
    Date: "24.02.2016",
    price: 0,
  },
  {
    Date: "25.02.2016",
    price: 0,
  },
  {
    Date: "26.02.2016",
    price: 0,
  },
  {
    Date: "27.02.2016",
    price: 0,
  },
  {
    Date: "28.02.2016",
    price: 0,
  },
  {
    Date: "29.02.2016",
    price: 0,
  },
  {
    Date: "01.03.2016",
    price: 0,
  },
  {
    Date: "02.03.2016",
    price: 0,
  },
  {
    Date: "03.03.2016",
    price: 0,
  },
  {
    Date: "04.03.2016",
    price: 0,
  },
  {
    Date: "05.03.2016",
    price: 0,
  },
  {
    Date: "06.03.2016",
    price: 0,
  },
  {
    Date: "07.03.2016",
    price: 0,
  },
  {
    Date: "08.03.2016",
    price: 0,
  },
  {
    Date: "09.03.2016",
    price: 0,
  },
  {
    Date: "10.03.2016",
    price: 0,
  },
  {
    Date: "11.03.2016",
    price: 0,
  },
  {
    Date: "12.03.2016",
    price: 0,
  },
  {
    Date: "13.03.2016",
    price: 0,
  },
  {
    Date: "14.03.2016",
    price: 0,
  },
  {
    Date: "15.03.2016",
    price: 0,
  },
  {
    Date: "16.03.2016",
    price: 0,
  },
  {
    Date: "17.03.2016",
    price: 0,
  },
  {
    Date: "18.03.2016",
    price: 0,
  },
  {
    Date: "19.03.2016",
    price: 0,
  },
  {
    Date: "20.03.2016",
    price: 0,
  },
  {
    Date: "21.03.2016",
    price: 0,
  },
  {
    Date: "22.03.2016",
    price: 0,
  },
  {
    Date: "23.03.2016",
    price: 0,
  },
  {
    Date: "24.03.2016",
    price: 0,
  },
  {
    Date: "25.03.2016",
    price: 0,
  },
  {
    Date: "26.03.2016",
    price: 0,
  },
  {
    Date: "27.03.2016",
    price: 0,
  },
  {
    Date: "28.03.2016",
    price: 0,
  },
  {
    Date: "29.03.2016",
    price: 0,
  },
  {
    Date: "30.03.2016",
    price: 0,
  },
  {
    Date: "31.03.2016",
    price: 0,
  },
  {
    Date: "01.04.2016",
    price: 0,
  },
  {
    Date: "02.04.2016",
    price: 0,
  },
  {
    Date: "03.04.2016",
    price: 0,
  },
  {
    Date: "04.04.2016",
    price: 0,
  },
  {
    Date: "05.04.2016",
    price: 0,
  },
  {
    Date: "06.04.2016",
    price: 0,
  },
  {
    Date: "07.04.2016",
    price: 0,
  },
  {
    Date: "08.04.2016",
    price: 0,
  },
  {
    Date: "09.04.2016",
    price: 0,
  },
  {
    Date: "10.04.2016",
    price: 0,
  },
  {
    Date: "11.04.2016",
    price: 0,
  },
  {
    Date: "12.04.2016",
    price: 0,
  },
  {
    Date: "13.04.2016",
    price: 0,
  },
  {
    Date: "14.04.2016",
    price: 0,
  },
  {
    Date: "15.04.2016",
    price: 0,
  },
  {
    Date: "16.04.2016",
    price: 0,
  },
  {
    Date: "17.04.2016",
    price: 0,
  },
  {
    Date: "18.04.2016",
    price: 0,
  },
  {
    Date: "19.04.2016",
    price: 0,
  },
  {
    Date: "20.04.2016",
    price: 0,
  },
  {
    Date: "21.04.2016",
    price: 0,
  },
  {
    Date: "22.04.2016",
    price: 0,
  },
  {
    Date: "23.04.2016",
    price: 0,
  },
  {
    Date: "24.04.2016",
    price: 0,
  },
  {
    Date: "25.04.2016",
    price: 0,
  },
  {
    Date: "26.04.2016",
    price: 0,
  },
  {
    Date: "27.04.2016",
    price: 0,
  },
  {
    Date: "28.04.2016",
    price: 0,
  },
  {
    Date: "29.04.2016",
    price: 0,
  },
  {
    Date: "30.04.2016",
    price: 0,
  },
  {
    Date: "01.05.2016",
    price: 0,
  },
  {
    Date: "02.05.2016",
    price: 0,
  },
  {
    Date: "03.05.2016",
    price: 0,
  },
  {
    Date: "04.05.2016",
    price: 0,
  },
  {
    Date: "05.05.2016",
    price: 0,
  },
  {
    Date: "06.05.2016",
    price: 0,
  },
  {
    Date: "07.05.2016",
    price: 0,
  },
  {
    Date: "08.05.2016",
    price: 0,
  },
  {
    Date: "09.05.2016",
    price: 0,
  },
  {
    Date: "10.05.2016",
    price: 0,
  },
  {
    Date: "11.05.2016",
    price: 0,
  },
  {
    Date: "12.05.2016",
    price: 0,
  },
  {
    Date: "13.05.2016",
    price: 0,
  },
  {
    Date: "14.05.2016",
    price: 0,
  },
  {
    Date: "15.05.2016",
    price: 0,
  },
  {
    Date: "16.05.2016",
    price: 0,
  },
  {
    Date: "17.05.2016",
    price: 0,
  },
  {
    Date: "18.05.2016",
    price: 0,
  },
  {
    Date: "19.05.2016",
    price: 0,
  },
  {
    Date: "20.05.2016",
    price: 0,
  },
  {
    Date: "21.05.2016",
    price: 0,
  },
  {
    Date: "22.05.2016",
    price: 0,
  },
  {
    Date: "23.05.2016",
    price: 0,
  },
  {
    Date: "24.05.2016",
    price: 0,
  },
  {
    Date: "25.05.2016",
    price: 0,
  },
  {
    Date: "26.05.2016",
    price: 0,
  },
  {
    Date: "27.05.2016",
    price: 0,
  },
  {
    Date: "28.05.2016",
    price: 0,
  },
  {
    Date: "29.05.2016",
    price: 0,
  },
  {
    Date: "30.05.2016",
    price: 0,
  },
  {
    Date: "31.05.2016",
    price: 0,
  },
  {
    Date: "01.06.2016",
    price: 0,
  },
  {
    Date: "02.06.2016",
    price: 0,
  },
  {
    Date: "03.06.2016",
    price: 0,
  },
  {
    Date: "04.06.2016",
    price: 0,
  },
  {
    Date: "05.06.2016",
    price: 0,
  },
  {
    Date: "06.06.2016",
    price: 0,
  },
  {
    Date: "07.06.2016",
    price: 0,
  },
  {
    Date: "08.06.2016",
    price: 0,
  },
  {
    Date: "09.06.2016",
    price: 0,
  },
  {
    Date: "10.06.2016",
    price: 0,
  },
  {
    Date: "11.06.2016",
    price: 0,
  },
  {
    Date: "12.06.2016",
    price: 0,
  },
  {
    Date: "13.06.2016",
    price: 0,
  },
  {
    Date: "14.06.2016",
    price: 0,
  },
  {
    Date: "15.06.2016",
    price: 0,
  },
  {
    Date: "16.06.2016",
    price: 0,
  },
  {
    Date: "17.06.2016",
    price: 0,
  },
  {
    Date: "18.06.2016",
    price: 0,
  },
  {
    Date: "19.06.2016",
    price: 0,
  },
  {
    Date: "20.06.2016",
    price: 0,
  },
  {
    Date: "21.06.2016",
    price: 0,
  },
  {
    Date: "22.06.2016",
    price: 0,
  },
  {
    Date: "23.06.2016",
    price: 0,
  },
  {
    Date: "24.06.2016",
    price: 0,
  },
  {
    Date: "25.06.2016",
    price: 0,
  },
  {
    Date: "26.06.2016",
    price: 0,
  },
  {
    Date: "27.06.2016",
    price: 0,
  },
  {
    Date: "28.06.2016",
    price: 0,
  },
  {
    Date: "29.06.2016",
    price: 0,
  },
  {
    Date: "30.06.2016",
    price: 0,
  },
  {
    Date: "01.07.2016",
    price: 0,
  },
  {
    Date: "02.07.2016",
    price: 0,
  },
  {
    Date: "03.07.2016",
    price: 0,
  },
  {
    Date: "04.07.2016",
    price: 0,
  },
  {
    Date: "05.07.2016",
    price: 0,
  },
  {
    Date: "06.07.2016",
    price: 0,
  },
  {
    Date: "07.07.2016",
    price: 0,
  },
  {
    Date: "08.07.2016",
    price: 0,
  },
  {
    Date: "09.07.2016",
    price: 0,
  },
  {
    Date: "10.07.2016",
    price: 0,
  },
  {
    Date: "11.07.2016",
    price: 0,
  },
  {
    Date: "12.07.2016",
    price: 0,
  },
  {
    Date: "13.07.2016",
    price: 0,
  },
  {
    Date: "14.07.2016",
    price: 0,
  },
  {
    Date: "15.07.2016",
    price: 0,
  },
  {
    Date: "16.07.2016",
    price: 0,
  },
  {
    Date: "17.07.2016",
    price: 0,
  },
  {
    Date: "18.07.2016",
    price: 0,
  },
  {
    Date: "19.07.2016",
    price: 0,
  },
  {
    Date: "20.07.2016",
    price: 0,
  },
  {
    Date: "21.07.2016",
    price: 0,
  },
  {
    Date: "22.07.2016",
    price: 0,
  },
  {
    Date: "23.07.2016",
    price: 0,
  },
  {
    Date: "24.07.2016",
    price: 0,
  },
  {
    Date: "25.07.2016",
    price: 0,
  },
  {
    Date: "26.07.2016",
    price: 0,
  },
  {
    Date: "27.07.2016",
    price: 0,
  },
  {
    Date: "28.07.2016",
    price: 0,
  },
  {
    Date: "29.07.2016",
    price: 0,
  },
  {
    Date: "30.07.2016",
    price: 0,
  },
  {
    Date: "31.07.2016",
    price: 0,
  },
  {
    Date: "01.08.2016",
    price: 0,
  },
  {
    Date: "02.08.2016",
    price: 0,
  },
  {
    Date: "03.08.2016",
    price: 0,
  },
  {
    Date: "04.08.2016",
    price: 0,
  },
  {
    Date: "05.08.2016",
    price: 0,
  },
  {
    Date: "06.08.2016",
    price: 0,
  },
  {
    Date: "07.08.2016",
    price: 0,
  },
  {
    Date: "08.08.2016",
    price: 0,
  },
  {
    Date: "09.08.2016",
    price: 0,
  },
  {
    Date: "10.08.2016",
    price: 0,
  },
  {
    Date: "11.08.2016",
    price: 0,
  },
  {
    Date: "12.08.2016",
    price: 0,
  },
  {
    Date: "13.08.2016",
    price: 0,
  },
  {
    Date: "14.08.2016",
    price: 0,
  },
  {
    Date: "15.08.2016",
    price: 0,
  },
  {
    Date: "16.08.2016",
    price: 0,
  },
  {
    Date: "17.08.2016",
    price: 0,
  },
  {
    Date: "18.08.2016",
    price: 0,
  },
  {
    Date: "19.08.2016",
    price: 0,
  },
  {
    Date: "20.08.2016",
    price: 0,
  },
  {
    Date: "21.08.2016",
    price: 0,
  },
  {
    Date: "22.08.2016",
    price: 0,
  },
  {
    Date: "23.08.2016",
    price: 0,
  },
  {
    Date: "24.08.2016",
    price: 0,
  },
  {
    Date: "25.08.2016",
    price: 0,
  },
  {
    Date: "26.08.2016",
    price: 0,
  },
  {
    Date: "27.08.2016",
    price: 0,
  },
  {
    Date: "28.08.2016",
    price: 0,
  },
  {
    Date: "29.08.2016",
    price: 0,
  },
  {
    Date: "30.08.2016",
    price: 0,
  },
  {
    Date: "31.08.2016",
    price: 0,
  },
  {
    Date: "01.09.2016",
    price: 0,
  },
  {
    Date: "02.09.2016",
    price: 0,
  },
  {
    Date: "03.09.2016",
    price: 0,
  },
  {
    Date: "04.09.2016",
    price: 0,
  },
  {
    Date: "05.09.2016",
    price: 0,
  },
  {
    Date: "06.09.2016",
    price: 0,
  },
  {
    Date: "07.09.2016",
    price: 0,
  },
  {
    Date: "08.09.2016",
    price: 0,
  },
  {
    Date: "09.09.2016",
    price: 0,
  },
  {
    Date: "10.09.2016",
    price: 0,
  },
  {
    Date: "11.09.2016",
    price: 0,
  },
  {
    Date: "12.09.2016",
    price: 0,
  },
  {
    Date: "13.09.2016",
    price: 0,
  },
  {
    Date: "14.09.2016",
    price: 0,
  },
  {
    Date: "15.09.2016",
    price: 0,
  },
  {
    Date: "16.09.2016",
    price: 0,
  },
  {
    Date: "17.09.2016",
    price: 0,
  },
  {
    Date: "18.09.2016",
    price: 0,
  },
  {
    Date: "19.09.2016",
    price: 0,
  },
  {
    Date: "20.09.2016",
    price: 0,
  },
  {
    Date: "21.09.2016",
    price: 0,
  },
  {
    Date: "22.09.2016",
    price: 0,
  },
  {
    Date: "23.09.2016",
    price: 0,
  },
  {
    Date: "24.09.2016",
    price: 0,
  },
  {
    Date: "25.09.2016",
    price: 0,
  },
  {
    Date: "26.09.2016",
    price: 0,
  },
  {
    Date: "27.09.2016",
    price: 0,
  },
  {
    Date: "28.09.2016",
    price: 0,
  },
  {
    Date: "29.09.2016",
    price: 0,
  },
  {
    Date: "30.09.2016",
    price: 0,
  },
  {
    Date: "01.10.2016",
    price: 0,
  },
  {
    Date: "02.10.2016",
    price: 0,
  },
  {
    Date: "03.10.2016",
    price: 0,
  },
  {
    Date: "04.10.2016",
    price: 0,
  },
  {
    Date: "05.10.2016",
    price: 0,
  },
  {
    Date: "06.10.2016",
    price: 0,
  },
  {
    Date: "07.10.2016",
    price: 0,
  },
  {
    Date: "08.10.2016",
    price: 0,
  },
  {
    Date: "09.10.2016",
    price: 0,
  },
  {
    Date: "10.10.2016",
    price: 0,
  },
  {
    Date: "11.10.2016",
    price: 0,
  },
  {
    Date: "12.10.2016",
    price: 0,
  },
  {
    Date: "13.10.2016",
    price: 0,
  },
  {
    Date: "14.10.2016",
    price: 0,
  },
  {
    Date: "15.10.2016",
    price: 0,
  },
  {
    Date: "16.10.2016",
    price: 0,
  },
  {
    Date: "17.10.2016",
    price: 0,
  },
  {
    Date: "18.10.2016",
    price: 0,
  },
  {
    Date: "19.10.2016",
    price: 0,
  },
  {
    Date: "20.10.2016",
    price: 0,
  },
  {
    Date: "21.10.2016",
    price: 0,
  },
  {
    Date: "22.10.2016",
    price: 0,
  },
  {
    Date: "23.10.2016",
    price: 0,
  },
  {
    Date: "24.10.2016",
    price: 0,
  },
  {
    Date: "25.10.2016",
    price: 0,
  },
  {
    Date: "26.10.2016",
    price: 0,
  },
  {
    Date: "27.10.2016",
    price: 0,
  },
  {
    Date: "28.10.2016",
    price: 0,
  },
  {
    Date: "29.10.2016",
    price: 0,
  },
  {
    Date: "30.10.2016",
    price: 0,
  },
  {
    Date: "31.10.2016",
    price: 0,
  },
  {
    Date: "01.11.2016",
    price: 0,
  },
  {
    Date: "02.11.2016",
    price: 0,
  },
  {
    Date: "03.11.2016",
    price: 0,
  },
  {
    Date: "04.11.2016",
    price: 0,
  },
  {
    Date: "05.11.2016",
    price: 0,
  },
  {
    Date: "06.11.2016",
    price: 0,
  },
  {
    Date: "07.11.2016",
    price: 0,
  },
  {
    Date: "08.11.2016",
    price: 0,
  },
  {
    Date: "09.11.2016",
    price: 0,
  },
  {
    Date: "10.11.2016",
    price: 0,
  },
  {
    Date: "11.11.2016",
    price: 0,
  },
  {
    Date: "12.11.2016",
    price: 0,
  },
  {
    Date: "13.11.2016",
    price: 0,
  },
  {
    Date: "14.11.2016",
    price: 0,
  },
  {
    Date: "15.11.2016",
    price: 0,
  },
  {
    Date: "16.11.2016",
    price: 0,
  },
  {
    Date: "17.11.2016",
    price: 0,
  },
  {
    Date: "18.11.2016",
    price: 0,
  },
  {
    Date: "19.11.2016",
    price: 0,
  },
  {
    Date: "20.11.2016",
    price: 0,
  },
  {
    Date: "21.11.2016",
    price: 0,
  },
  {
    Date: "22.11.2016",
    price: 0,
  },
  {
    Date: "23.11.2016",
    price: 0,
  },
  {
    Date: "24.11.2016",
    price: 0,
  },
  {
    Date: "25.11.2016",
    price: 0,
  },
  {
    Date: "26.11.2016",
    price: 0,
  },
  {
    Date: "27.11.2016",
    price: 0,
  },
  {
    Date: "28.11.2016",
    price: 0,
  },
  {
    Date: "29.11.2016",
    price: 0,
  },
  {
    Date: "30.11.2016",
    price: 0,
  },
  {
    Date: "01.12.2016",
    price: 0,
  },
  {
    Date: "02.12.2016",
    price: 0,
  },
  {
    Date: "03.12.2016",
    price: 0,
  },
  {
    Date: "04.12.2016",
    price: 0,
  },
  {
    Date: "05.12.2016",
    price: 0,
  },
  {
    Date: "06.12.2016",
    price: 0,
  },
  {
    Date: "07.12.2016",
    price: 0,
  },
  {
    Date: "08.12.2016",
    price: 0,
  },
  {
    Date: "09.12.2016",
    price: 0,
  },
  {
    Date: "10.12.2016",
    price: 0,
  },
  {
    Date: "11.12.2016",
    price: 0,
  },
  {
    Date: "12.12.2016",
    price: 0,
  },
  {
    Date: "13.12.2016",
    price: 0,
  },
  {
    Date: "14.12.2016",
    price: 0,
  },
  {
    Date: "15.12.2016",
    price: 0,
  },
  {
    Date: "16.12.2016",
    price: 0,
  },
  {
    Date: "17.12.2016",
    price: 0,
  },
  {
    Date: "18.12.2016",
    price: 0,
  },
  {
    Date: "19.12.2016",
    price: 0,
  },
  {
    Date: "20.12.2016",
    price: 0,
  },
  {
    Date: "21.12.2016",
    price: 0,
  },
  {
    Date: "22.12.2016",
    price: 0,
  },
  {
    Date: "23.12.2016",
    price: 0,
  },
  {
    Date: "24.12.2016",
    price: 0,
  },
  {
    Date: "25.12.2016",
    price: 0,
  },
  {
    Date: "26.12.2016",
    price: 0,
  },
  {
    Date: "27.12.2016",
    price: 0,
  },
  {
    Date: "28.12.2016",
    price: 0,
  },
  {
    Date: "29.12.2016",
    price: 0,
  },
  {
    Date: "30.12.2016",
    price: 0,
  },
  {
    Date: "31.12.2016",
    price: 0,
  },
  {
    Date: "01.01.2017",
    price: 0,
  },
  {
    Date: "02.01.2017",
    price: 0,
  },
  {
    Date: "03.01.2017",
    price: 0,
  },
  {
    Date: "04.01.2017",
    price: 0,
  },
  {
    Date: "05.01.2017",
    price: 0,
  },
  {
    Date: "06.01.2017",
    price: 0,
  },
  {
    Date: "07.01.2017",
    price: 0,
  },
  {
    Date: "08.01.2017",
    price: 0,
  },
  {
    Date: "09.01.2017",
    price: 0,
  },
  {
    Date: "10.01.2017",
    price: 0,
  },
  {
    Date: "11.01.2017",
    price: 0,
  },
  {
    Date: "12.01.2017",
    price: 0,
  },
  {
    Date: "13.01.2017",
    price: 0,
  },
  {
    Date: "14.01.2017",
    price: 0,
  },
  {
    Date: "15.01.2017",
    price: 0,
  },
  {
    Date: "16.01.2017",
    price: 0,
  },
  {
    Date: "17.01.2017",
    price: 0,
  },
  {
    Date: "18.01.2017",
    price: 0,
  },
  {
    Date: "19.01.2017",
    price: 0,
  },
  {
    Date: "20.01.2017",
    price: 0,
  },
  {
    Date: "21.01.2017",
    price: 0,
  },
  {
    Date: "22.01.2017",
    price: 0,
  },
  {
    Date: "23.01.2017",
    price: 0,
  },
  {
    Date: "24.01.2017",
    price: 0,
  },
  {
    Date: "25.01.2017",
    price: 0,
  },
  {
    Date: "26.01.2017",
    price: 0,
  },
  {
    Date: "27.01.2017",
    price: 0,
  },
  {
    Date: "28.01.2017",
    price: 0,
  },
  {
    Date: "29.01.2017",
    price: 0,
  },
  {
    Date: "30.01.2017",
    price: 0,
  },
  {
    Date: "31.01.2017",
    price: 0,
  },
  {
    Date: "01.02.2017",
    price: 0,
  },
  {
    Date: "02.02.2017",
    price: 0,
  },
  {
    Date: "03.02.2017",
    price: 0,
  },
  {
    Date: "04.02.2017",
    price: 0,
  },
  {
    Date: "05.02.2017",
    price: 0,
  },
  {
    Date: "06.02.2017",
    price: 0,
  },
  {
    Date: "07.02.2017",
    price: 0,
  },
  {
    Date: "08.02.2017",
    price: 0,
  },
  {
    Date: "09.02.2017",
    price: 0,
  },
  {
    Date: "10.02.2017",
    price: 0,
  },
  {
    Date: "11.02.2017",
    price: 0,
  },
  {
    Date: "12.02.2017",
    price: 0,
  },
  {
    Date: "13.02.2017",
    price: 0,
  },
  {
    Date: "14.02.2017",
    price: 0,
  },
  {
    Date: "15.02.2017",
    price: 0,
  },
  {
    Date: "16.02.2017",
    price: 0,
  },
  {
    Date: "17.02.2017",
    price: 0,
  },
  {
    Date: "18.02.2017",
    price: 0,
  },
  {
    Date: "19.02.2017",
    price: 0,
  },
  {
    Date: "20.02.2017",
    price: 0,
  },
  {
    Date: "21.02.2017",
    price: 0,
  },
  {
    Date: "22.02.2017",
    price: 0,
  },
  {
    Date: "23.02.2017",
    price: 0,
  },
  {
    Date: "24.02.2017",
    price: 0,
  },
  {
    Date: "25.02.2017",
    price: 0,
  },
  {
    Date: "26.02.2017",
    price: 0,
  },
  {
    Date: "27.02.2017",
    price: 0,
  },
  {
    Date: "28.02.2017",
    price: 0,
  },
  {
    Date: "01.03.2017",
    price: 0,
  },
  {
    Date: "02.03.2017",
    price: 0,
  },
  {
    Date: "03.03.2017",
    price: 0,
  },
  {
    Date: "04.03.2017",
    price: 0,
  },
  {
    Date: "05.03.2017",
    price: 0,
  },
  {
    Date: "06.03.2017",
    price: 0,
  },
  {
    Date: "07.03.2017",
    price: 0,
  },
  {
    Date: "08.03.2017",
    price: 0,
  },
  {
    Date: "09.03.2017",
    price: 0,
  },
  {
    Date: "10.03.2017",
    price: 0,
  },
  {
    Date: "11.03.2017",
    price: 0,
  },
  {
    Date: "12.03.2017",
    price: 0,
  },
  {
    Date: "13.03.2017",
    price: 0,
  },
  {
    Date: "14.03.2017",
    price: 0,
  },
  {
    Date: "15.03.2017",
    price: 0,
  },
  {
    Date: "16.03.2017",
    price: 0,
  },
  {
    Date: "17.03.2017",
    price: 0,
  },
  {
    Date: "18.03.2017",
    price: 0,
  },
  {
    Date: "19.03.2017",
    price: 0,
  },
  {
    Date: "20.03.2017",
    price: 0,
  },
  {
    Date: "21.03.2017",
    price: 0,
  },
  {
    Date: "22.03.2017",
    price: 0,
  },
  {
    Date: "23.03.2017",
    price: 0,
  },
  {
    Date: "24.03.2017",
    price: 0,
  },
  {
    Date: "25.03.2017",
    price: 0,
  },
  {
    Date: "26.03.2017",
    price: 0,
  },
  {
    Date: "27.03.2017",
    price: 0,
  },
  {
    Date: "28.03.2017",
    price: 0,
  },
  {
    Date: "29.03.2017",
    price: 0,
  },
  {
    Date: "30.03.2017",
    price: 0,
  },
  {
    Date: "31.03.2017",
    price: 0,
  },
  {
    Date: "01.04.2017",
    price: 0,
  },
  {
    Date: "02.04.2017",
    price: 0,
  },
  {
    Date: "03.04.2017",
    price: 0,
  },
  {
    Date: "04.04.2017",
    price: 0,
  },
  {
    Date: "05.04.2017",
    price: 0,
  },
  {
    Date: "06.04.2017",
    price: 0,
  },
  {
    Date: "07.04.2017",
    price: 0,
  },
  {
    Date: "08.04.2017",
    price: 0,
  },
  {
    Date: "09.04.2017",
    price: 0,
  },
  {
    Date: "10.04.2017",
    price: 0,
  },
  {
    Date: "11.04.2017",
    price: 0,
  },
  {
    Date: "12.04.2017",
    price: 0,
  },
  {
    Date: "13.04.2017",
    price: 0,
  },
  {
    Date: "14.04.2017",
    price: 0,
  },
  {
    Date: "15.04.2017",
    price: 0,
  },
  {
    Date: "16.04.2017",
    price: 0,
  },
  {
    Date: "17.04.2017",
    price: 0,
  },
  {
    Date: "18.04.2017",
    price: 0,
  },
  {
    Date: "19.04.2017",
    price: 0,
  },
  {
    Date: "20.04.2017",
    price: 0,
  },
  {
    Date: "21.04.2017",
    price: 0,
  },
  {
    Date: "22.04.2017",
    price: 0,
  },
  {
    Date: "23.04.2017",
    price: 0,
  },
  {
    Date: "24.04.2017",
    price: 0,
  },
  {
    Date: "25.04.2017",
    price: 0,
  },
  {
    Date: "26.04.2017",
    price: 0,
  },
  {
    Date: "27.04.2017",
    price: 0,
  },
  {
    Date: "28.04.2017",
    price: 0,
  },
  {
    Date: "29.04.2017",
    price: 0,
  },
  {
    Date: "30.04.2017",
    price: 0,
  },
  {
    Date: "01.05.2017",
    price: 0,
  },
  {
    Date: "02.05.2017",
    price: 0,
  },
  {
    Date: "03.05.2017",
    price: 0,
  },
  {
    Date: "04.05.2017",
    price: 0,
  },
  {
    Date: "05.05.2017",
    price: 0,
  },
  {
    Date: "06.05.2017",
    price: 0,
  },
  {
    Date: "07.05.2017",
    price: 0,
  },
  {
    Date: "08.05.2017",
    price: 0,
  },
  {
    Date: "09.05.2017",
    price: 0,
  },
  {
    Date: "10.05.2017",
    price: 0,
  },
  {
    Date: "11.05.2017",
    price: 0,
  },
  {
    Date: "12.05.2017",
    price: 0,
  },
  {
    Date: "13.05.2017",
    price: 0,
  },
  {
    Date: "14.05.2017",
    price: 0,
  },
  {
    Date: "15.05.2017",
    price: 0,
  },
  {
    Date: "16.05.2017",
    price: 0,
  },
  {
    Date: "17.05.2017",
    price: 0,
  },
  {
    Date: "18.05.2017",
    price: 0,
  },
  {
    Date: "19.05.2017",
    price: 0,
  },
  {
    Date: "20.05.2017",
    price: 0,
  },
  {
    Date: "21.05.2017",
    price: 0,
  },
  {
    Date: "22.05.2017",
    price: 0,
  },
  {
    Date: "23.05.2017",
    price: 0,
  },
  {
    Date: "24.05.2017",
    price: 0,
  },
  {
    Date: "25.05.2017",
    price: 0,
  },
  {
    Date: "26.05.2017",
    price: 0,
  },
  {
    Date: "27.05.2017",
    price: 0,
  },
  {
    Date: "28.05.2017",
    price: 0,
  },
  {
    Date: "29.05.2017",
    price: 0,
  },
  {
    Date: "30.05.2017",
    price: 0,
  },
  {
    Date: "31.05.2017",
    price: 0,
  },
  {
    Date: "01.06.2017",
    price: 0,
  },
  {
    Date: "02.06.2017",
    price: 0,
  },
  {
    Date: "03.06.2017",
    price: 0,
  },
  {
    Date: "04.06.2017",
    price: 0,
  },
  {
    Date: "05.06.2017",
    price: 0,
  },
  {
    Date: "06.06.2017",
    price: 0,
  },
  {
    Date: "07.06.2017",
    price: 0,
  },
  {
    Date: "08.06.2017",
    price: 0,
  },
  {
    Date: "09.06.2017",
    price: 0,
  },
  {
    Date: "10.06.2017",
    price: 0,
  },
  {
    Date: "11.06.2017",
    price: 0,
  },
  {
    Date: "12.06.2017",
    price: 0,
  },
  {
    Date: "13.06.2017",
    price: 0,
  },
  {
    Date: "14.06.2017",
    price: 0,
  },
  {
    Date: "15.06.2017",
    price: 0,
  },
  {
    Date: "16.06.2017",
    price: 0,
  },
  {
    Date: "17.06.2017",
    price: 0,
  },
  {
    Date: "18.06.2017",
    price: 0,
  },
  {
    Date: "19.06.2017",
    price: 0,
  },
  {
    Date: "20.06.2017",
    price: 0,
  },
  {
    Date: "21.06.2017",
    price: 0,
  },
  {
    Date: "22.06.2017",
    price: 0,
  },
  {
    Date: "23.06.2017",
    price: 0,
  },
  {
    Date: "24.06.2017",
    price: 0,
  },
  {
    Date: "25.06.2017",
    price: 0,
  },
  {
    Date: "26.06.2017",
    price: 0,
  },
  {
    Date: "27.06.2017",
    price: 0,
  },
  {
    Date: "28.06.2017",
    price: 0,
  },
  {
    Date: "29.06.2017",
    price: 0,
  },
  {
    Date: "30.06.2017",
    price: 0,
  },
  {
    Date: "01.07.2017",
    price: 0,
  },
  {
    Date: "02.07.2017",
    price: 0,
  },
  {
    Date: "03.07.2017",
    price: 0,
  },
  {
    Date: "04.07.2017",
    price: 0,
  },
  {
    Date: "05.07.2017",
    price: 0,
  },
  {
    Date: "06.07.2017",
    price: 0,
  },
  {
    Date: "07.07.2017",
    price: 0,
  },
  {
    Date: "08.07.2017",
    price: 0,
  },
  {
    Date: "09.07.2017",
    price: 0,
  },
  {
    Date: "10.07.2017",
    price: 0,
  },
  {
    Date: "11.07.2017",
    price: 0,
  },
  {
    Date: "12.07.2017",
    price: 0,
  },
  {
    Date: "13.07.2017",
    price: 0,
  },
  {
    Date: "14.07.2017",
    price: 0,
  },
  {
    Date: "15.07.2017",
    price: 0,
  },
  {
    Date: "16.07.2017",
    price: 0,
  },
  {
    Date: "17.07.2017",
    price: 0,
  },
  {
    Date: "18.07.2017",
    price: 0,
  },
  {
    Date: "19.07.2017",
    price: 0,
  },
  {
    Date: "20.07.2017",
    price: 0,
  },
  {
    Date: "21.07.2017",
    price: 0,
  },
  {
    Date: "22.07.2017",
    price: 0,
  },
  {
    Date: "23.07.2017",
    price: 0,
  },
  {
    Date: "24.07.2017",
    price: 0,
  },
  {
    Date: "25.07.2017",
    price: 0,
  },
  {
    Date: "26.07.2017",
    price: 0,
  },
  {
    Date: "27.07.2017",
    price: 0,
  },
  {
    Date: "28.07.2017",
    price: 0,
  },
  {
    Date: "29.07.2017",
    price: 0,
  },
  {
    Date: "30.07.2017",
    price: 0,
  },
  {
    Date: "31.07.2017",
    price: 0,
  },
  {
    Date: "01.08.2017",
    price: 0,
  },
  {
    Date: "02.08.2017",
    price: 0,
  },
  {
    Date: "03.08.2017",
    price: 0,
  },
  {
    Date: "04.08.2017",
    price: 0,
  },
  {
    Date: "05.08.2017",
    price: 0,
  },
  {
    Date: "06.08.2017",
    price: 0,
  },
  {
    Date: "07.08.2017",
    price: 0,
  },
  {
    Date: "08.08.2017",
    price: 0,
  },
  {
    Date: "09.08.2017",
    price: 0,
  },
  {
    Date: "10.08.2017",
    price: 0,
  },
  {
    Date: "11.08.2017",
    price: 0,
  },
  {
    Date: "12.08.2017",
    price: 0,
  },
  {
    Date: "13.08.2017",
    price: 0,
  },
  {
    Date: "14.08.2017",
    price: 0,
  },
  {
    Date: "15.08.2017",
    price: 0,
  },
  {
    Date: "16.08.2017",
    price: 0,
  },
  {
    Date: "17.08.2017",
    price: 0,
  },
  {
    Date: "18.08.2017",
    price: 0,
  },
  {
    Date: "19.08.2017",
    price: 0,
  },
  {
    Date: "20.08.2017",
    price: 0,
  },
  {
    Date: "21.08.2017",
    price: 0,
  },
  {
    Date: "22.08.2017",
    price: 0,
  },
  {
    Date: "23.08.2017",
    price: 0,
  },
  {
    Date: "24.08.2017",
    price: 0,
  },
  {
    Date: "25.08.2017",
    price: 0,
  },
  {
    Date: "26.08.2017",
    price: 0,
  },
  {
    Date: "27.08.2017",
    price: 0,
  },
  {
    Date: "28.08.2017",
    price: 0,
  },
  {
    Date: "29.08.2017",
    price: 0,
  },
  {
    Date: "30.08.2017",
    price: 0,
  },
  {
    Date: "31.08.2017",
    price: 0,
  },
  {
    Date: "01.09.2017",
    price: 0,
  },
  {
    Date: "02.09.2017",
    price: 0,
  },
  {
    Date: "03.09.2017",
    price: 0,
  },
  {
    Date: "04.09.2017",
    price: 0,
  },
  {
    Date: "05.09.2017",
    price: 0,
  },
  {
    Date: "06.09.2017",
    price: 0,
  },
  {
    Date: "07.09.2017",
    price: 0,
  },
  {
    Date: "08.09.2017",
    price: 0,
  },
  {
    Date: "09.09.2017",
    price: 0,
  },
  {
    Date: "10.09.2017",
    price: 0,
  },
  {
    Date: "11.09.2017",
    price: 0,
  },
  {
    Date: "12.09.2017",
    price: 0,
  },
  {
    Date: "13.09.2017",
    price: 0,
  },
  {
    Date: "14.09.2017",
    price: 0,
  },
  {
    Date: "15.09.2017",
    price: 0,
  },
  {
    Date: "16.09.2017",
    price: 0,
  },
  {
    Date: "17.09.2017",
    price: 0,
  },
  {
    Date: "18.09.2017",
    price: 0,
  },
  {
    Date: "19.09.2017",
    price: 0,
  },
  {
    Date: "20.09.2017",
    price: 0,
  },
  {
    Date: "21.09.2017",
    price: 0,
  },
  {
    Date: "22.09.2017",
    price: 0,
  },
  {
    Date: "23.09.2017",
    price: 0,
  },
  {
    Date: "24.09.2017",
    price: 0,
  },
  {
    Date: "25.09.2017",
    price: 0,
  },
  {
    Date: "26.09.2017",
    price: 0,
  },
  {
    Date: "27.09.2017",
    price: 0,
  },
  {
    Date: "28.09.2017",
    price: 0,
  },
  {
    Date: "29.09.2017",
    price: 0,
  },
  {
    Date: "30.09.2017",
    price: 0,
  },
  {
    Date: "01.10.2017",
    price: 0,
  },
  {
    Date: "02.10.2017",
    price: 0,
  },
  {
    Date: "03.10.2017",
    price: 0,
  },
  {
    Date: "04.10.2017",
    price: 0,
  },
  {
    Date: "05.10.2017",
    price: 0,
  },
  {
    Date: "06.10.2017",
    price: 0,
  },
  {
    Date: "07.10.2017",
    price: 0,
  },
  {
    Date: "08.10.2017",
    price: 0,
  },
  {
    Date: "09.10.2017",
    price: 0,
  },
  {
    Date: "10.10.2017",
    price: 0,
  },
  {
    Date: "11.10.2017",
    price: 0,
  },
  {
    Date: "12.10.2017",
    price: 0,
  },
  {
    Date: "13.10.2017",
    price: 0,
  },
  {
    Date: "14.10.2017",
    price: 0,
  },
  {
    Date: "15.10.2017",
    price: 0,
  },
  {
    Date: "16.10.2017",
    price: 0,
  },
  {
    Date: "17.10.2017",
    price: 0,
  },
  {
    Date: "18.10.2017",
    price: 0,
  },
  {
    Date: "19.10.2017",
    price: 0,
  },
  {
    Date: "20.10.2017",
    price: 0,
  },
  {
    Date: "21.10.2017",
    price: 0,
  },
  {
    Date: "22.10.2017",
    price: 0,
  },
  {
    Date: "23.10.2017",
    price: 0,
  },
  {
    Date: "24.10.2017",
    price: 0,
  },
  {
    Date: "25.10.2017",
    price: 0,
  },
  {
    Date: "26.10.2017",
    price: 0,
  },
  {
    Date: "27.10.2017",
    price: 0,
  },
  {
    Date: "28.10.2017",
    price: 0,
  },
  {
    Date: "29.10.2017",
    price: 0,
  },
  {
    Date: "30.10.2017",
    price: 0,
  },
  {
    Date: "31.10.2017",
    price: 0,
  },
  {
    Date: "01.11.2017",
    price: 0,
  },
  {
    Date: "02.11.2017",
    price: 0,
  },
  {
    Date: "03.11.2017",
    price: 0,
  },
  {
    Date: "04.11.2017",
    price: 0,
  },
  {
    Date: "05.11.2017",
    price: 0,
  },
  {
    Date: "06.11.2017",
    price: 0,
  },
  {
    Date: "07.11.2017",
    price: 0,
  },
  {
    Date: "08.11.2017",
    price: 0,
  },
  {
    Date: "09.11.2017",
    price: 308.644989,
  },
  {
    Date: "10.11.2017",
    price: 320.67099,
  },
  {
    Date: "11.11.2017",
    price: 298.585999,
  },
  {
    Date: "12.11.2017",
    price: 314.690002,
  },
  {
    Date: "13.11.2017",
    price: 307.024994,
  },
  {
    Date: "14.11.2017",
    price: 316.763,
  },
  {
    Date: "15.11.2017",
    price: 337.963989,
  },
  {
    Date: "16.11.2017",
    price: 333.442993,
  },
  {
    Date: "17.11.2017",
    price: 330.166992,
  },
  {
    Date: "18.11.2017",
    price: 331.980011,
  },
  {
    Date: "19.11.2017",
    price: 347.401001,
  },
  {
    Date: "20.11.2017",
    price: 354.093994,
  },
  {
    Date: "21.11.2017",
    price: 367.442993,
  },
  {
    Date: "22.11.2017",
    price: 360.312012,
  },
  {
    Date: "23.11.2017",
    price: 381.438995,
  },
  {
    Date: "24.11.2017",
    price: 412.501007,
  },
  {
    Date: "25.11.2017",
    price: 475.675995,
  },
  {
    Date: "26.11.2017",
    price: 465.973999,
  },
  {
    Date: "27.11.2017",
    price: 471.531006,
  },
  {
    Date: "28.11.2017",
    price: 480.518005,
  },
  {
    Date: "29.11.2017",
    price: 473.281006,
  },
  {
    Date: "30.11.2017",
    price: 431.214996,
  },
  {
    Date: "01.12.2017",
    price: 445.209015,
  },
  {
    Date: "02.12.2017",
    price: 466.851013,
  },
  {
    Date: "03.12.2017",
    price: 463.704987,
  },
  {
    Date: "04.12.2017",
    price: 466.053986,
  },
  {
    Date: "05.12.2017",
    price: 470.294006,
  },
  {
    Date: "06.12.2017",
    price: 462.604004,
  },
  {
    Date: "07.12.2017",
    price: 426.368988,
  },
  {
    Date: "08.12.2017",
    price: 434.989014,
  },
  {
    Date: "09.12.2017",
    price: 457.343994,
  },
  {
    Date: "10.12.2017",
    price: 472.789001,
  },
  {
    Date: "11.12.2017",
    price: 440.358002,
  },
  {
    Date: "12.12.2017",
    price: 522.286011,
  },
  {
    Date: "13.12.2017",
    price: 644.906006,
  },
  {
    Date: "14.12.2017",
    price: 700.593994,
  },
  {
    Date: "15.12.2017",
    price: 696.375977,
  },
  {
    Date: "16.12.2017",
    price: 686.192017,
  },
  {
    Date: "17.12.2017",
    price: 696.237,
  },
  {
    Date: "18.12.2017",
    price: 721.731995,
  },
  {
    Date: "19.12.2017",
    price: 793.901001,
  },
  {
    Date: "20.12.2017",
    price: 827.515991,
  },
  {
    Date: "21.12.2017",
    price: 820.236023,
  },
  {
    Date: "22.12.2017",
    price: 822.643005,
  },
  {
    Date: "23.12.2017",
    price: 681.315979,
  },
  {
    Date: "24.12.2017",
    price: 721.768982,
  },
  {
    Date: "25.12.2017",
    price: 698.869995,
  },
  {
    Date: "26.12.2017",
    price: 763.369995,
  },
  {
    Date: "27.12.2017",
    price: 774.969971,
  },
  {
    Date: "28.12.2017",
    price: 762.208008,
  },
  {
    Date: "29.12.2017",
    price: 740.388977,
  },
  {
    Date: "30.12.2017",
    price: 753.815002,
  },
  {
    Date: "31.12.2017",
    price: 712.211975,
  },
  {
    Date: "01.01.2018",
    price: 755.757019,
  },
  {
    Date: "02.01.2018",
    price: 772.346008,
  },
  {
    Date: "03.01.2018",
    price: 886,
  },
  {
    Date: "04.01.2018",
    price: 961.713013,
  },
  {
    Date: "05.01.2018",
    price: 975.75,
  },
  {
    Date: "06.01.2018",
    price: 995.153992,
  },
  {
    Date: "07.01.2018",
    price: 1043.01001,
  },
  {
    Date: "08.01.2018",
    price: 1158.26001,
  },
  {
    Date: "09.01.2018",
    price: 1146,
  },
  {
    Date: "10.01.2018",
    price: 1300.339966,
  },
  {
    Date: "11.01.2018",
    price: 1268.089966,
  },
  {
    Date: "12.01.2018",
    price: 1158.290039,
  },
  {
    Date: "13.01.2018",
    price: 1270.469971,
  },
  {
    Date: "14.01.2018",
    price: 1397.47998,
  },
  {
    Date: "15.01.2018",
    price: 1365.209961,
  },
  {
    Date: "16.01.2018",
    price: 1292.630005,
  },
  {
    Date: "17.01.2018",
    price: 1061.339966,
  },
  {
    Date: "18.01.2018",
    price: 1016.440002,
  },
  {
    Date: "19.01.2018",
    price: 1028.819946,
  },
  {
    Date: "20.01.2018",
    price: 1044.949951,
  },
  {
    Date: "21.01.2018",
    price: 1155.680054,
  },
  {
    Date: "22.01.2018",
    price: 1055.349976,
  },
  {
    Date: "23.01.2018",
    price: 1004.169983,
  },
  {
    Date: "24.01.2018",
    price: 987.47699,
  },
  {
    Date: "25.01.2018",
    price: 1063.219971,
  },
  {
    Date: "26.01.2018",
    price: 1052.699951,
  },
  {
    Date: "27.01.2018",
    price: 1055.75,
  },
  {
    Date: "28.01.2018",
    price: 1111.780029,
  },
  {
    Date: "29.01.2018",
    price: 1246.699951,
  },
  {
    Date: "30.01.2018",
    price: 1184.130005,
  },
  {
    Date: "31.01.2018",
    price: 1071.089966,
  },
  {
    Date: "01.02.2018",
    price: 1119.369995,
  },
  {
    Date: "02.02.2018",
    price: 1035.77002,
  },
  {
    Date: "03.02.2018",
    price: 919.210999,
  },
  {
    Date: "04.02.2018",
    price: 964.666992,
  },
  {
    Date: "05.02.2018",
    price: 834.958008,
  },
  {
    Date: "06.02.2018",
    price: 707.737,
  },
  {
    Date: "07.02.2018",
    price: 790.427979,
  },
  {
    Date: "08.02.2018",
    price: 755.843018,
  },
  {
    Date: "09.02.2018",
    price: 818.47998,
  },
  {
    Date: "10.02.2018",
    price: 882.46698,
  },
  {
    Date: "11.02.2018",
    price: 859.288025,
  },
  {
    Date: "12.02.2018",
    price: 817.507996,
  },
  {
    Date: "13.02.2018",
    price: 869.29303,
  },
  {
    Date: "14.02.2018",
    price: 844.278992,
  },
  {
    Date: "15.02.2018",
    price: 923.728027,
  },
  {
    Date: "16.02.2018",
    price: 934.786011,
  },
  {
    Date: "17.02.2018",
    price: 944.747986,
  },
  {
    Date: "18.02.2018",
    price: 973.348999,
  },
  {
    Date: "19.02.2018",
    price: 921.66803,
  },
  {
    Date: "20.02.2018",
    price: 943.567017,
  },
  {
    Date: "21.02.2018",
    price: 894.13501,
  },
  {
    Date: "22.02.2018",
    price: 849.262024,
  },
  {
    Date: "23.02.2018",
    price: 811.585022,
  },
  {
    Date: "24.02.2018",
    price: 861.593018,
  },
  {
    Date: "25.02.2018",
    price: 839.458984,
  },
  {
    Date: "26.02.2018",
    price: 845.278015,
  },
  {
    Date: "27.02.2018",
    price: 870.36499,
  },
  {
    Date: "28.02.2018",
    price: 877.934021,
  },
  {
    Date: "01.03.2018",
    price: 856.012024,
  },
  {
    Date: "02.03.2018",
    price: 871.763977,
  },
  {
    Date: "03.03.2018",
    price: 856.710999,
  },
  {
    Date: "04.03.2018",
    price: 856.185974,
  },
  {
    Date: "05.03.2018",
    price: 866.846008,
  },
  {
    Date: "06.03.2018",
    price: 853.817017,
  },
  {
    Date: "07.03.2018",
    price: 816.270996,
  },
  {
    Date: "08.03.2018",
    price: 752.570007,
  },
  {
    Date: "09.03.2018",
    price: 702.197021,
  },
  {
    Date: "10.03.2018",
    price: 730.159973,
  },
  {
    Date: "11.03.2018",
    price: 685.312012,
  },
  {
    Date: "12.03.2018",
    price: 724.406006,
  },
  {
    Date: "13.03.2018",
    price: 698.153015,
  },
  {
    Date: "14.03.2018",
    price: 691.218994,
  },
  {
    Date: "15.03.2018",
    price: 614.838989,
  },
  {
    Date: "16.03.2018",
    price: 611.778015,
  },
  {
    Date: "17.03.2018",
    price: 601.677979,
  },
  {
    Date: "18.03.2018",
    price: 551.640015,
  },
  {
    Date: "19.03.2018",
    price: 546.625977,
  },
  {
    Date: "20.03.2018",
    price: 556.721985,
  },
  {
    Date: "21.03.2018",
    price: 559.099976,
  },
  {
    Date: "22.03.2018",
    price: 562.103027,
  },
  {
    Date: "23.03.2018",
    price: 539.864014,
  },
  {
    Date: "24.03.2018",
    price: 542.572021,
  },
  {
    Date: "25.03.2018",
    price: 522.703003,
  },
  {
    Date: "26.03.2018",
    price: 524.286987,
  },
  {
    Date: "27.03.2018",
    price: 489.589996,
  },
  {
    Date: "28.03.2018",
    price: 450.290985,
  },
  {
    Date: "29.03.2018",
    price: 448.075012,
  },
  {
    Date: "30.03.2018",
    price: 385.904999,
  },
  {
    Date: "31.03.2018",
    price: 395.003998,
  },
  {
    Date: "01.04.2018",
    price: 397.253998,
  },
  {
    Date: "02.04.2018",
    price: 379.699005,
  },
  {
    Date: "03.04.2018",
    price: 387.312012,
  },
  {
    Date: "04.04.2018",
    price: 416.484985,
  },
  {
    Date: "05.04.2018",
    price: 379.946991,
  },
  {
    Date: "06.04.2018",
    price: 382.730988,
  },
  {
    Date: "07.04.2018",
    price: 370.380005,
  },
  {
    Date: "08.04.2018",
    price: 385.743011,
  },
  {
    Date: "09.04.2018",
    price: 400.856995,
  },
  {
    Date: "10.04.2018",
    price: 399.411011,
  },
  {
    Date: "11.04.2018",
    price: 415.023987,
  },
  {
    Date: "12.04.2018",
    price: 430.161011,
  },
  {
    Date: "13.04.2018",
    price: 493.160004,
  },
  {
    Date: "14.04.2018",
    price: 492.579987,
  },
  {
    Date: "15.04.2018",
    price: 502.880005,
  },
  {
    Date: "16.04.2018",
    price: 532.073975,
  },
  {
    Date: "17.04.2018",
    price: 511.144989,
  },
  {
    Date: "18.04.2018",
    price: 503.30899,
  },
  {
    Date: "19.04.2018",
    price: 524.038025,
  },
  {
    Date: "20.04.2018",
    price: 567.989014,
  },
  {
    Date: "21.04.2018",
    price: 616.004028,
  },
  {
    Date: "22.04.2018",
    price: 606.119019,
  },
  {
    Date: "23.04.2018",
    price: 621.198975,
  },
  {
    Date: "24.04.2018",
    price: 643.39801,
  },
  {
    Date: "25.04.2018",
    price: 707.062012,
  },
  {
    Date: "26.04.2018",
    price: 618.078979,
  },
  {
    Date: "27.04.2018",
    price: 662.109009,
  },
  {
    Date: "28.04.2018",
    price: 644.645996,
  },
  {
    Date: "29.04.2018",
    price: 683.911987,
  },
  {
    Date: "30.04.2018",
    price: 689.760986,
  },
  {
    Date: "01.05.2018",
    price: 670.463013,
  },
  {
    Date: "02.05.2018",
    price: 674.075012,
  },
  {
    Date: "03.05.2018",
    price: 686.591003,
  },
  {
    Date: "04.05.2018",
    price: 776.775024,
  },
  {
    Date: "05.05.2018",
    price: 784.583008,
  },
  {
    Date: "06.05.2018",
    price: 816.088013,
  },
  {
    Date: "07.05.2018",
    price: 793.338989,
  },
  {
    Date: "08.05.2018",
    price: 755.008972,
  },
  {
    Date: "09.05.2018",
    price: 752.901978,
  },
  {
    Date: "10.05.2018",
    price: 752.578979,
  },
  {
    Date: "11.05.2018",
    price: 727.013,
  },
  {
    Date: "12.05.2018",
    price: 679.877014,
  },
  {
    Date: "13.05.2018",
    price: 687.174988,
  },
  {
    Date: "14.05.2018",
    price: 732.732971,
  },
  {
    Date: "15.05.2018",
    price: 731.143005,
  },
  {
    Date: "16.05.2018",
    price: 708.086975,
  },
  {
    Date: "17.05.2018",
    price: 708.718018,
  },
  {
    Date: "18.05.2018",
    price: 672.10199,
  },
  {
    Date: "19.05.2018",
    price: 695.072021,
  },
  {
    Date: "20.05.2018",
    price: 697.922974,
  },
  {
    Date: "21.05.2018",
    price: 717.192993,
  },
  {
    Date: "22.05.2018",
    price: 700.177979,
  },
  {
    Date: "23.05.2018",
    price: 646.669983,
  },
  {
    Date: "24.05.2018",
    price: 584.536011,
  },
  {
    Date: "25.05.2018",
    price: 602.140015,
  },
  {
    Date: "26.05.2018",
    price: 587.426025,
  },
  {
    Date: "27.05.2018",
    price: 588.52002,
  },
  {
    Date: "28.05.2018",
    price: 573.044983,
  },
  {
    Date: "29.05.2018",
    price: 516.14801,
  },
  {
    Date: "30.05.2018",
    price: 566.830017,
  },
  {
    Date: "31.05.2018",
    price: 558.497009,
  },
  {
    Date: "01.06.2018",
    price: 578.671997,
  },
  {
    Date: "02.06.2018",
    price: 580.429016,
  },
  {
    Date: "03.06.2018",
    price: 591.258972,
  },
  {
    Date: "04.06.2018",
    price: 619.437012,
  },
  {
    Date: "05.06.2018",
    price: 593.406006,
  },
  {
    Date: "06.06.2018",
    price: 610.262024,
  },
  {
    Date: "07.06.2018",
    price: 607.687012,
  },
  {
    Date: "08.06.2018",
    price: 605.442993,
  },
  {
    Date: "09.06.2018",
    price: 600.905029,
  },
  {
    Date: "10.06.2018",
    price: 594.344971,
  },
  {
    Date: "11.06.2018",
    price: 524.856995,
  },
  {
    Date: "12.06.2018",
    price: 532.710022,
  },
  {
    Date: "13.06.2018",
    price: 498.018005,
  },
  {
    Date: "14.06.2018",
    price: 478.381012,
  },
  {
    Date: "15.06.2018",
    price: 520.47998,
  },
  {
    Date: "16.06.2018",
    price: 490.414001,
  },
  {
    Date: "17.06.2018",
    price: 499.459991,
  },
  {
    Date: "18.06.2018",
    price: 499.382996,
  },
  {
    Date: "19.06.2018",
    price: 519.02301,
  },
  {
    Date: "20.06.2018",
    price: 538.505981,
  },
  {
    Date: "21.06.2018",
    price: 536.447021,
  },
  {
    Date: "22.06.2018",
    price: 527.19397,
  },
  {
    Date: "23.06.2018",
    price: 466.273987,
  },
  {
    Date: "24.06.2018",
    price: 474.768005,
  },
  {
    Date: "25.06.2018",
    price: 455.936005,
  },
  {
    Date: "26.06.2018",
    price: 460.733002,
  },
  {
    Date: "27.06.2018",
    price: 432.243988,
  },
  {
    Date: "28.06.2018",
    price: 442.290009,
  },
  {
    Date: "29.06.2018",
    price: 422.587006,
  },
  {
    Date: "30.06.2018",
    price: 436.209015,
  },
  {
    Date: "01.07.2018",
    price: 455.242004,
  },
  {
    Date: "02.07.2018",
    price: 453.824005,
  },
  {
    Date: "03.07.2018",
    price: 475.385986,
  },
  {
    Date: "04.07.2018",
    price: 464.151001,
  },
  {
    Date: "05.07.2018",
    price: 467.286987,
  },
  {
    Date: "06.07.2018",
    price: 474.356995,
  },
  {
    Date: "07.07.2018",
    price: 474.057007,
  },
  {
    Date: "08.07.2018",
    price: 492.067993,
  },
  {
    Date: "09.07.2018",
    price: 488.877991,
  },
  {
    Date: "10.07.2018",
    price: 476.161987,
  },
  {
    Date: "11.07.2018",
    price: 434.515015,
  },
  {
    Date: "12.07.2018",
    price: 446.502014,
  },
  {
    Date: "13.07.2018",
    price: 430.740997,
  },
  {
    Date: "14.07.2018",
    price: 434.510986,
  },
  {
    Date: "15.07.2018",
    price: 435.882996,
  },
  {
    Date: "16.07.2018",
    price: 450.424988,
  },
  {
    Date: "17.07.2018",
    price: 480.076996,
  },
  {
    Date: "18.07.2018",
    price: 500.838013,
  },
  {
    Date: "19.07.2018",
    price: 480.627991,
  },
  {
    Date: "20.07.2018",
    price: 469.307007,
  },
  {
    Date: "21.07.2018",
    price: 450.678986,
  },
  {
    Date: "22.07.2018",
    price: 462.437988,
  },
  {
    Date: "23.07.2018",
    price: 459.438995,
  },
  {
    Date: "24.07.2018",
    price: 451.135986,
  },
  {
    Date: "25.07.2018",
    price: 479.906006,
  },
  {
    Date: "26.07.2018",
    price: 472.330994,
  },
  {
    Date: "27.07.2018",
    price: 464.009003,
  },
  {
    Date: "28.07.2018",
    price: 469.678009,
  },
  {
    Date: "29.07.2018",
    price: 466.915009,
  },
  {
    Date: "30.07.2018",
    price: 466.826996,
  },
  {
    Date: "31.07.2018",
    price: 457.244995,
  },
  {
    Date: "01.08.2018",
    price: 433.868988,
  },
  {
    Date: "02.08.2018",
    price: 420.806,
  },
  {
    Date: "03.08.2018",
    price: 412.566986,
  },
  {
    Date: "04.08.2018",
    price: 418.237,
  },
  {
    Date: "05.08.2018",
    price: 407.346008,
  },
  {
    Date: "06.08.2018",
    price: 410.566986,
  },
  {
    Date: "07.08.2018",
    price: 406.800995,
  },
  {
    Date: "08.08.2018",
    price: 379.891998,
  },
  {
    Date: "09.08.2018",
    price: 356.971008,
  },
  {
    Date: "10.08.2018",
    price: 365.776001,
  },
  {
    Date: "11.08.2018",
    price: 334.264008,
  },
  {
    Date: "12.08.2018",
    price: 320.822998,
  },
  {
    Date: "13.08.2018",
    price: 320.209991,
  },
  {
    Date: "14.08.2018",
    price: 286.36499,
  },
  {
    Date: "15.08.2018",
    price: 280.386993,
  },
  {
    Date: "16.08.2018",
    price: 282.740997,
  },
  {
    Date: "17.08.2018",
    price: 287.683014,
  },
  {
    Date: "18.08.2018",
    price: 316.790985,
  },
  {
    Date: "19.08.2018",
    price: 295.670013,
  },
  {
    Date: "20.08.2018",
    price: 301.381012,
  },
  {
    Date: "21.08.2018",
    price: 273.330994,
  },
  {
    Date: "22.08.2018",
    price: 281.967987,
  },
  {
    Date: "23.08.2018",
    price: 271.747986,
  },
  {
    Date: "24.08.2018",
    price: 278.110992,
  },
  {
    Date: "25.08.2018",
    price: 283.281006,
  },
  {
    Date: "26.08.2018",
    price: 279.524994,
  },
  {
    Date: "27.08.2018",
    price: 275.350006,
  },
  {
    Date: "28.08.2018",
    price: 286.648987,
  },
  {
    Date: "29.08.2018",
    price: 296.162994,
  },
  {
    Date: "30.08.2018",
    price: 289.752991,
  },
  {
    Date: "31.08.2018",
    price: 284.119995,
  },
  {
    Date: "01.09.2018",
    price: 283.496002,
  },
  {
    Date: "02.09.2018",
    price: 295.453003,
  },
  {
    Date: "03.09.2018",
    price: 295.181,
  },
  {
    Date: "04.09.2018",
    price: 289.296997,
  },
  {
    Date: "05.09.2018",
    price: 286.045013,
  },
  {
    Date: "06.09.2018",
    price: 231.647995,
  },
  {
    Date: "07.09.2018",
    price: 229.535004,
  },
  {
    Date: "08.09.2018",
    price: 217.912003,
  },
  {
    Date: "09.09.2018",
    price: 198.384995,
  },
  {
    Date: "10.09.2018",
    price: 197.850998,
  },
  {
    Date: "11.09.2018",
    price: 198.177002,
  },
  {
    Date: "12.09.2018",
    price: 185.423004,
  },
  {
    Date: "13.09.2018",
    price: 183.679001,
  },
  {
    Date: "14.09.2018",
    price: 212.669006,
  },
  {
    Date: "15.09.2018",
    price: 209.813995,
  },
  {
    Date: "16.09.2018",
    price: 222.802994,
  },
  {
    Date: "17.09.2018",
    price: 221.582001,
  },
  {
    Date: "18.09.2018",
    price: 197.095001,
  },
  {
    Date: "19.09.2018",
    price: 209.472,
  },
  {
    Date: "20.09.2018",
    price: 210.287994,
  },
  {
    Date: "21.09.2018",
    price: 225.251007,
  },
  {
    Date: "22.09.2018",
    price: 247.337006,
  },
  {
    Date: "23.09.2018",
    price: 240.992004,
  },
  {
    Date: "24.09.2018",
    price: 244.843994,
  },
  {
    Date: "25.09.2018",
    price: 228.326996,
  },
  {
    Date: "26.09.2018",
    price: 218.647003,
  },
  {
    Date: "27.09.2018",
    price: 215.440002,
  },
  {
    Date: "28.09.2018",
    price: 229.041,
  },
  {
    Date: "29.09.2018",
    price: 221.705002,
  },
  {
    Date: "30.09.2018",
    price: 231.328995,
  },
  {
    Date: "01.10.2018",
    price: 233.220001,
  },
  {
    Date: "02.10.2018",
    price: 231.100006,
  },
  {
    Date: "03.10.2018",
    price: 226.406998,
  },
  {
    Date: "04.10.2018",
    price: 220.445999,
  },
  {
    Date: "05.10.2018",
    price: 222.266998,
  },
  {
    Date: "06.10.2018",
    price: 227.552002,
  },
  {
    Date: "07.10.2018",
    price: 225.434998,
  },
  {
    Date: "08.10.2018",
    price: 226.509003,
  },
  {
    Date: "09.10.2018",
    price: 229.710007,
  },
  {
    Date: "10.10.2018",
    price: 227.615005,
  },
  {
    Date: "11.10.2018",
    price: 225.610001,
  },
  {
    Date: "12.10.2018",
    price: 188.707001,
  },
  {
    Date: "13.10.2018",
    price: 196.363998,
  },
  {
    Date: "14.10.2018",
    price: 199.690994,
  },
  {
    Date: "15.10.2018",
    price: 195.270004,
  },
  {
    Date: "16.10.2018",
    price: 209.628006,
  },
  {
    Date: "17.10.2018",
    price: 210.216995,
  },
  {
    Date: "18.10.2018",
    price: 207.404007,
  },
  {
    Date: "19.10.2018",
    price: 203.257004,
  },
  {
    Date: "20.10.2018",
    price: 203.514999,
  },
  {
    Date: "21.10.2018",
    price: 205.389999,
  },
  {
    Date: "22.10.2018",
    price: 205.173996,
  },
  {
    Date: "23.10.2018",
    price: 204.016006,
  },
  {
    Date: "24.10.2018",
    price: 204.126999,
  },
  {
    Date: "25.10.2018",
    price: 203.867996,
  },
  {
    Date: "26.10.2018",
    price: 202.335999,
  },
  {
    Date: "27.10.2018",
    price: 203.356003,
  },
  {
    Date: "28.10.2018",
    price: 204.509995,
  },
  {
    Date: "29.10.2018",
    price: 205.173996,
  },
  {
    Date: "30.10.2018",
    price: 197.203003,
  },
  {
    Date: "31.10.2018",
    price: 197.649002,
  },
  {
    Date: "01.11.2018",
    price: 197.537003,
  },
  {
    Date: "02.11.2018",
    price: 198.981995,
  },
  {
    Date: "03.11.2018",
    price: 200.740005,
  },
  {
    Date: "04.11.2018",
    price: 200.158005,
  },
  {
    Date: "05.11.2018",
    price: 207.100006,
  },
  {
    Date: "06.11.2018",
    price: 209.473999,
  },
  {
    Date: "07.11.2018",
    price: 218.899002,
  },
  {
    Date: "08.11.2018",
    price: 217.326996,
  },
  {
    Date: "09.11.2018",
    price: 211.987,
  },
  {
    Date: "10.11.2018",
    price: 209.975006,
  },
  {
    Date: "11.11.2018",
    price: 212.479004,
  },
  {
    Date: "12.11.2018",
    price: 211.513,
  },
  {
    Date: "13.11.2018",
    price: 210.149002,
  },
  {
    Date: "14.11.2018",
    price: 206.533997,
  },
  {
    Date: "15.11.2018",
    price: 181.899002,
  },
  {
    Date: "16.11.2018",
    price: 180.865005,
  },
  {
    Date: "17.11.2018",
    price: 175.360001,
  },
  {
    Date: "18.11.2018",
    price: 174.175003,
  },
  {
    Date: "19.11.2018",
    price: 177.179001,
  },
  {
    Date: "20.11.2018",
    price: 148.811005,
  },
  {
    Date: "21.11.2018",
    price: 131.141998,
  },
  {
    Date: "22.11.2018",
    price: 136.811005,
  },
  {
    Date: "23.11.2018",
    price: 126.418999,
  },
  {
    Date: "24.11.2018",
    price: 123.304001,
  },
  {
    Date: "25.11.2018",
    price: 113.125999,
  },
  {
    Date: "26.11.2018",
    price: 116.343002,
  },
  {
    Date: "27.11.2018",
    price: 107.913002,
  },
  {
    Date: "28.11.2018",
    price: 110.197998,
  },
  {
    Date: "29.11.2018",
    price: 122.72155,
  },
  {
    Date: "30.11.2018",
    price: 117.728096,
  },
  {
    Date: "01.12.2018",
    price: 113.397758,
  },
  {
    Date: "02.12.2018",
    price: 118.26815,
  },
  {
    Date: "03.12.2018",
    price: 116.378761,
  },
  {
    Date: "04.12.2018",
    price: 108.803162,
  },
  {
    Date: "05.12.2018",
    price: 110.335518,
  },
  {
    Date: "06.12.2018",
    price: 102.450592,
  },
  {
    Date: "07.12.2018",
    price: 91.649834,
  },
  {
    Date: "08.12.2018",
    price: 93.41008,
  },
  {
    Date: "09.12.2018",
    price: 92.035431,
  },
  {
    Date: "10.12.2018",
    price: 94.992172,
  },
  {
    Date: "11.12.2018",
    price: 91.578934,
  },
  {
    Date: "12.12.2018",
    price: 88.610703,
  },
  {
    Date: "13.12.2018",
    price: 90.655228,
  },
  {
    Date: "14.12.2018",
    price: 86.630356,
  },
  {
    Date: "15.12.2018",
    price: 84.279694,
  },
  {
    Date: "16.12.2018",
    price: 84.469406,
  },
  {
    Date: "17.12.2018",
    price: 85.382111,
  },
  {
    Date: "18.12.2018",
    price: 95.10804,
  },
  {
    Date: "19.12.2018",
    price: 101.683846,
  },
  {
    Date: "20.12.2018",
    price: 101.090439,
  },
  {
    Date: "21.12.2018",
    price: 115.835403,
  },
  {
    Date: "22.12.2018",
    price: 109.45594,
  },
  {
    Date: "23.12.2018",
    price: 117.273895,
  },
  {
    Date: "24.12.2018",
    price: 130.641663,
  },
  {
    Date: "25.12.2018",
    price: 140.997589,
  },
  {
    Date: "26.12.2018",
    price: 129.888824,
  },
  {
    Date: "27.12.2018",
    price: 131.923584,
  },
  {
    Date: "28.12.2018",
    price: 116.898201,
  },
  {
    Date: "29.12.2018",
    price: 138.468781,
  },
  {
    Date: "30.12.2018",
    price: 137.627457,
  },
  {
    Date: "31.12.2018",
    price: 140.031067,
  },
  {
    Date: "01.01.2019",
    price: 133.418152,
  },
  {
    Date: "02.01.2019",
    price: 141.519516,
  },
  {
    Date: "03.01.2019",
    price: 155.196045,
  },
  {
    Date: "04.01.2019",
    price: 148.912888,
  },
  {
    Date: "05.01.2019",
    price: 154.337418,
  },
  {
    Date: "06.01.2019",
    price: 155.80423,
  },
  {
    Date: "07.01.2019",
    price: 157.809494,
  },
  {
    Date: "08.01.2019",
    price: 151.967545,
  },
  {
    Date: "09.01.2019",
    price: 150.554688,
  },
  {
    Date: "10.01.2019",
    price: 150.843506,
  },
  {
    Date: "11.01.2019",
    price: 127.813965,
  },
  {
    Date: "12.01.2019",
    price: 127.528084,
  },
  {
    Date: "13.01.2019",
    price: 125.907227,
  },
  {
    Date: "14.01.2019",
    price: 116.979095,
  },
  {
    Date: "15.01.2019",
    price: 129.172684,
  },
  {
    Date: "16.01.2019",
    price: 121.809242,
  },
  {
    Date: "17.01.2019",
    price: 123.57354,
  },
  {
    Date: "18.01.2019",
    price: 123.654655,
  },
  {
    Date: "19.01.2019",
    price: 121.614426,
  },
  {
    Date: "20.01.2019",
    price: 124.632401,
  },
  {
    Date: "21.01.2019",
    price: 119.636459,
  },
  {
    Date: "22.01.2019",
    price: 117.110756,
  },
  {
    Date: "23.01.2019",
    price: 118.713814,
  },
  {
    Date: "24.01.2019",
    price: 117.462784,
  },
  {
    Date: "25.01.2019",
    price: 117.594955,
  },
  {
    Date: "26.01.2019",
    price: 116.373528,
  },
  {
    Date: "27.01.2019",
    price: 116.510315,
  },
  {
    Date: "28.01.2019",
    price: 113.285233,
  },
  {
    Date: "29.01.2019",
    price: 106.721954,
  },
  {
    Date: "30.01.2019",
    price: 105.407204,
  },
  {
    Date: "31.01.2019",
    price: 108.900772,
  },
  {
    Date: "01.02.2019",
    price: 107.147682,
  },
  {
    Date: "02.02.2019",
    price: 107.466415,
  },
  {
    Date: "03.02.2019",
    price: 110.490318,
  },
  {
    Date: "04.02.2019",
    price: 107.625824,
  },
  {
    Date: "05.02.2019",
    price: 107.628166,
  },
  {
    Date: "06.02.2019",
    price: 107.569305,
  },
  {
    Date: "07.02.2019",
    price: 104.835777,
  },
  {
    Date: "08.02.2019",
    price: 104.64505,
  },
  {
    Date: "09.02.2019",
    price: 119.214111,
  },
  {
    Date: "10.02.2019",
    price: 119.408966,
  },
  {
    Date: "11.02.2019",
    price: 124.871536,
  },
  {
    Date: "12.02.2019",
    price: 121.178574,
  },
  {
    Date: "13.02.2019",
    price: 122.544289,
  },
  {
    Date: "14.02.2019",
    price: 122.416985,
  },
  {
    Date: "15.02.2019",
    price: 121.453697,
  },
  {
    Date: "16.02.2019",
    price: 122.396454,
  },
  {
    Date: "17.02.2019",
    price: 123.182083,
  },
  {
    Date: "18.02.2019",
    price: 133.032547,
  },
  {
    Date: "19.02.2019",
    price: 146.398819,
  },
  {
    Date: "20.02.2019",
    price: 145.172729,
  },
  {
    Date: "21.02.2019",
    price: 149.352325,
  },
  {
    Date: "22.02.2019",
    price: 146.073257,
  },
  {
    Date: "23.02.2019",
    price: 148.67662,
  },
  {
    Date: "24.02.2019",
    price: 158.902405,
  },
  {
    Date: "25.02.2019",
    price: 135.499725,
  },
  {
    Date: "26.02.2019",
    price: 139.646088,
  },
  {
    Date: "27.02.2019",
    price: 138.029572,
  },
  {
    Date: "28.02.2019",
    price: 136.283081,
  },
  {
    Date: "01.03.2019",
    price: 136.836243,
  },
  {
    Date: "02.03.2019",
    price: 136.350082,
  },
  {
    Date: "03.03.2019",
    price: 134.786118,
  },
  {
    Date: "04.03.2019",
    price: 132.157883,
  },
  {
    Date: "05.03.2019",
    price: 127.792953,
  },
  {
    Date: "06.03.2019",
    price: 137.95874,
  },
  {
    Date: "07.03.2019",
    price: 138.942383,
  },
  {
    Date: "08.03.2019",
    price: 138.311356,
  },
  {
    Date: "09.03.2019",
    price: 134.887558,
  },
  {
    Date: "10.03.2019",
    price: 138.180496,
  },
  {
    Date: "11.03.2019",
    price: 136.849533,
  },
  {
    Date: "12.03.2019",
    price: 134.012848,
  },
  {
    Date: "13.03.2019",
    price: 134.611771,
  },
  {
    Date: "14.03.2019",
    price: 133.203903,
  },
  {
    Date: "15.03.2019",
    price: 133.567154,
  },
  {
    Date: "16.03.2019",
    price: 137.911957,
  },
  {
    Date: "17.03.2019",
    price: 142.238297,
  },
  {
    Date: "18.03.2019",
    price: 140.104858,
  },
  {
    Date: "19.03.2019",
    price: 139.254013,
  },
  {
    Date: "20.03.2019",
    price: 140.484711,
  },
  {
    Date: "21.03.2019",
    price: 140.478485,
  },
  {
    Date: "22.03.2019",
    price: 136.562256,
  },
  {
    Date: "23.03.2019",
    price: 137.039871,
  },
  {
    Date: "24.03.2019",
    price: 138.315353,
  },
  {
    Date: "25.03.2019",
    price: 137.08136,
  },
  {
    Date: "26.03.2019",
    price: 135.054321,
  },
  {
    Date: "27.03.2019",
    price: 135.454361,
  },
  {
    Date: "28.03.2019",
    price: 141.005844,
  },
  {
    Date: "29.03.2019",
    price: 139.343201,
  },
  {
    Date: "30.03.2019",
    price: 142.359146,
  },
  {
    Date: "31.03.2019",
    price: 142.139221,
  },
  {
    Date: "01.04.2019",
    price: 141.465485,
  },
  {
    Date: "02.04.2019",
    price: 141.839523,
  },
  {
    Date: "03.04.2019",
    price: 164.008636,
  },
  {
    Date: "04.04.2019",
    price: 161.431763,
  },
  {
    Date: "05.04.2019",
    price: 158.020004,
  },
  {
    Date: "06.04.2019",
    price: 165.511246,
  },
  {
    Date: "07.04.2019",
    price: 165.977249,
  },
  {
    Date: "08.04.2019",
    price: 174.449677,
  },
  {
    Date: "09.04.2019",
    price: 180.222122,
  },
  {
    Date: "10.04.2019",
    price: 176.067841,
  },
  {
    Date: "11.04.2019",
    price: 177.342758,
  },
  {
    Date: "12.04.2019",
    price: 165.335205,
  },
  {
    Date: "13.04.2019",
    price: 164.622849,
  },
  {
    Date: "14.04.2019",
    price: 164.476089,
  },
  {
    Date: "15.04.2019",
    price: 167.897476,
  },
  {
    Date: "16.04.2019",
    price: 161.52655,
  },
  {
    Date: "17.04.2019",
    price: 167.553696,
  },
  {
    Date: "18.04.2019",
    price: 166.913208,
  },
  {
    Date: "19.04.2019",
    price: 173.787888,
  },
  {
    Date: "20.04.2019",
    price: 173.718811,
  },
  {
    Date: "21.04.2019",
    price: 173.715408,
  },
  {
    Date: "22.04.2019",
    price: 170.017487,
  },
  {
    Date: "23.04.2019",
    price: 172.007217,
  },
  {
    Date: "24.04.2019",
    price: 171.349533,
  },
  {
    Date: "25.04.2019",
    price: 165.914734,
  },
  {
    Date: "26.04.2019",
    price: 154.579437,
  },
  {
    Date: "27.04.2019",
    price: 156.270859,
  },
  {
    Date: "28.04.2019",
    price: 158.499771,
  },
  {
    Date: "29.04.2019",
    price: 157.291061,
  },
  {
    Date: "30.04.2019",
    price: 155.172775,
  },
  {
    Date: "01.05.2019",
    price: 162.186554,
  },
  {
    Date: "02.05.2019",
    price: 160.853577,
  },
  {
    Date: "03.05.2019",
    price: 162.075165,
  },
  {
    Date: "04.05.2019",
    price: 167.887222,
  },
  {
    Date: "05.05.2019",
    price: 164.015259,
  },
  {
    Date: "06.05.2019",
    price: 163.337982,
  },
  {
    Date: "07.05.2019",
    price: 172.427277,
  },
  {
    Date: "08.05.2019",
    price: 169.904205,
  },
  {
    Date: "09.05.2019",
    price: 170.951874,
  },
  {
    Date: "10.05.2019",
    price: 170.312973,
  },
  {
    Date: "11.05.2019",
    price: 173.139313,
  },
  {
    Date: "12.05.2019",
    price: 194.163025,
  },
  {
    Date: "13.05.2019",
    price: 187.417923,
  },
  {
    Date: "14.05.2019",
    price: 196.738281,
  },
  {
    Date: "15.05.2019",
    price: 217.010117,
  },
  {
    Date: "16.05.2019",
    price: 246.941956,
  },
  {
    Date: "17.05.2019",
    price: 263.848358,
  },
  {
    Date: "18.05.2019",
    price: 243.911301,
  },
  {
    Date: "19.05.2019",
    price: 234.449463,
  },
  {
    Date: "20.05.2019",
    price: 261.267487,
  },
  {
    Date: "21.05.2019",
    price: 251.647842,
  },
  {
    Date: "22.05.2019",
    price: 254.995316,
  },
  {
    Date: "23.05.2019",
    price: 244.665115,
  },
  {
    Date: "24.05.2019",
    price: 245.987091,
  },
  {
    Date: "25.05.2019",
    price: 249.694046,
  },
  {
    Date: "26.05.2019",
    price: 251.724792,
  },
  {
    Date: "27.05.2019",
    price: 267.141876,
  },
  {
    Date: "28.05.2019",
    price: 272.590485,
  },
  {
    Date: "29.05.2019",
    price: 271.779053,
  },
  {
    Date: "30.05.2019",
    price: 269.55188,
  },
  {
    Date: "31.05.2019",
    price: 256.015259,
  },
  {
    Date: "01.06.2019",
    price: 268.43335,
  },
  {
    Date: "02.06.2019",
    price: 265.512726,
  },
  {
    Date: "03.06.2019",
    price: 270.088562,
  },
  {
    Date: "04.06.2019",
    price: 252.511642,
  },
  {
    Date: "05.06.2019",
    price: 241.49704,
  },
  {
    Date: "06.06.2019",
    price: 246.925247,
  },
  {
    Date: "07.06.2019",
    price: 249.335281,
  },
  {
    Date: "08.06.2019",
    price: 251.249313,
  },
  {
    Date: "09.06.2019",
    price: 245.540482,
  },
  {
    Date: "10.06.2019",
    price: 232.830139,
  },
  {
    Date: "11.06.2019",
    price: 247.862122,
  },
  {
    Date: "12.06.2019",
    price: 245.925491,
  },
  {
    Date: "13.06.2019",
    price: 260.913513,
  },
  {
    Date: "14.06.2019",
    price: 256.179657,
  },
  {
    Date: "15.06.2019",
    price: 263.934143,
  },
  {
    Date: "16.06.2019",
    price: 268.901154,
  },
  {
    Date: "17.06.2019",
    price: 269.090576,
  },
  {
    Date: "18.06.2019",
    price: 274.320251,
  },
  {
    Date: "19.06.2019",
    price: 265.056854,
  },
  {
    Date: "20.06.2019",
    price: 269.407867,
  },
  {
    Date: "21.06.2019",
    price: 271.743103,
  },
  {
    Date: "22.06.2019",
    price: 295.115082,
  },
  {
    Date: "23.06.2019",
    price: 309.416687,
  },
  {
    Date: "24.06.2019",
    price: 307.722137,
  },
  {
    Date: "25.06.2019",
    price: 310.678619,
  },
  {
    Date: "26.06.2019",
    price: 317.992584,
  },
  {
    Date: "27.06.2019",
    price: 336.9599,
  },
  {
    Date: "28.06.2019",
    price: 294.143036,
  },
  {
    Date: "29.06.2019",
    price: 311.284424,
  },
  {
    Date: "30.06.2019",
    price: 319.584045,
  },
  {
    Date: "01.07.2019",
    price: 290.268494,
  },
  {
    Date: "02.07.2019",
    price: 293.537262,
  },
  {
    Date: "03.07.2019",
    price: 291.764618,
  },
  {
    Date: "04.07.2019",
    price: 303.025085,
  },
  {
    Date: "05.07.2019",
    price: 284.38092,
  },
  {
    Date: "06.07.2019",
    price: 287.893829,
  },
  {
    Date: "07.07.2019",
    price: 287.603607,
  },
  {
    Date: "08.07.2019",
    price: 305.734772,
  },
  {
    Date: "09.07.2019",
    price: 313.325165,
  },
  {
    Date: "10.07.2019",
    price: 308.851288,
  },
  {
    Date: "11.07.2019",
    price: 289.945984,
  },
  {
    Date: "12.07.2019",
    price: 268.692261,
  },
  {
    Date: "13.07.2019",
    price: 276.289124,
  },
  {
    Date: "14.07.2019",
    price: 269.281616,
  },
  {
    Date: "15.07.2019",
    price: 227.965057,
  },
  {
    Date: "16.07.2019",
    price: 229.748657,
  },
  {
    Date: "17.07.2019",
    price: 199.066788,
  },
  {
    Date: "18.07.2019",
    price: 211.444611,
  },
  {
    Date: "19.07.2019",
    price: 226.6577,
  },
  {
    Date: "20.07.2019",
    price: 221.412659,
  },
  {
    Date: "21.07.2019",
    price: 229.167694,
  },
  {
    Date: "22.07.2019",
    price: 225.698654,
  },
  {
    Date: "23.07.2019",
    price: 217.568466,
  },
  {
    Date: "24.07.2019",
    price: 212.553818,
  },
  {
    Date: "25.07.2019",
    price: 216.969116,
  },
  {
    Date: "26.07.2019",
    price: 219.628128,
  },
  {
    Date: "27.07.2019",
    price: 219.61763,
  },
  {
    Date: "28.07.2019",
    price: 207.413773,
  },
  {
    Date: "29.07.2019",
    price: 211.123657,
  },
  {
    Date: "30.07.2019",
    price: 211.339203,
  },
  {
    Date: "31.07.2019",
    price: 210.492416,
  },
  {
    Date: "01.08.2019",
    price: 218.554596,
  },
  {
    Date: "02.08.2019",
    price: 217.88446,
  },
  {
    Date: "03.08.2019",
    price: 217.895554,
  },
  {
    Date: "04.08.2019",
    price: 222.580811,
  },
  {
    Date: "05.08.2019",
    price: 222.650879,
  },
  {
    Date: "06.08.2019",
    price: 234.24559,
  },
  {
    Date: "07.08.2019",
    price: 225.925293,
  },
  {
    Date: "08.08.2019",
    price: 226.518478,
  },
  {
    Date: "09.08.2019",
    price: 220.908646,
  },
  {
    Date: "10.08.2019",
    price: 210.530167,
  },
  {
    Date: "11.08.2019",
    price: 206.732239,
  },
  {
    Date: "12.08.2019",
    price: 216.055908,
  },
  {
    Date: "13.08.2019",
    price: 211.342697,
  },
  {
    Date: "14.08.2019",
    price: 208.603989,
  },
  {
    Date: "15.08.2019",
    price: 186.683502,
  },
  {
    Date: "16.08.2019",
    price: 188.644257,
  },
  {
    Date: "17.08.2019",
    price: 185.531662,
  },
  {
    Date: "18.08.2019",
    price: 185.841095,
  },
  {
    Date: "19.08.2019",
    price: 194.55603,
  },
  {
    Date: "20.08.2019",
    price: 202.813141,
  },
  {
    Date: "21.08.2019",
    price: 196.621689,
  },
  {
    Date: "22.08.2019",
    price: 186.941238,
  },
  {
    Date: "23.08.2019",
    price: 191.106873,
  },
  {
    Date: "24.08.2019",
    price: 194.671631,
  },
  {
    Date: "25.08.2019",
    price: 191.234787,
  },
  {
    Date: "26.08.2019",
    price: 186.73555,
  },
  {
    Date: "27.08.2019",
    price: 188.950058,
  },
  {
    Date: "28.08.2019",
    price: 187.495499,
  },
  {
    Date: "29.08.2019",
    price: 173.958694,
  },
  {
    Date: "30.08.2019",
    price: 169.489288,
  },
  {
    Date: "31.08.2019",
    price: 168.887894,
  },
  {
    Date: "01.09.2019",
    price: 172.458405,
  },
  {
    Date: "02.09.2019",
    price: 171.697601,
  },
  {
    Date: "03.09.2019",
    price: 178.357758,
  },
  {
    Date: "04.09.2019",
    price: 179.491989,
  },
  {
    Date: "05.09.2019",
    price: 175.966278,
  },
  {
    Date: "06.09.2019",
    price: 174.172226,
  },
  {
    Date: "07.09.2019",
    price: 169.955276,
  },
  {
    Date: "08.09.2019",
    price: 178.282593,
  },
  {
    Date: "09.09.2019",
    price: 181.362488,
  },
  {
    Date: "10.09.2019",
    price: 181.212845,
  },
  {
    Date: "11.09.2019",
    price: 179.839493,
  },
  {
    Date: "12.09.2019",
    price: 178.803772,
  },
  {
    Date: "13.09.2019",
    price: 180.975586,
  },
  {
    Date: "14.09.2019",
    price: 181.321579,
  },
  {
    Date: "15.09.2019",
    price: 188.068436,
  },
  {
    Date: "16.09.2019",
    price: 189.689331,
  },
  {
    Date: "17.09.2019",
    price: 197.114761,
  },
  {
    Date: "18.09.2019",
    price: 208.050598,
  },
  {
    Date: "19.09.2019",
    price: 211.247238,
  },
  {
    Date: "20.09.2019",
    price: 221.069626,
  },
  {
    Date: "21.09.2019",
    price: 217.897385,
  },
  {
    Date: "22.09.2019",
    price: 215.486557,
  },
  {
    Date: "23.09.2019",
    price: 211.713211,
  },
  {
    Date: "24.09.2019",
    price: 201.842087,
  },
  {
    Date: "25.09.2019",
    price: 168.133575,
  },
  {
    Date: "26.09.2019",
    price: 170.756912,
  },
  {
    Date: "27.09.2019",
    price: 166.651703,
  },
  {
    Date: "28.09.2019",
    price: 174.680832,
  },
  {
    Date: "29.09.2019",
    price: 174.612747,
  },
  {
    Date: "30.09.2019",
    price: 170.461349,
  },
  {
    Date: "01.10.2019",
    price: 180.209122,
  },
  {
    Date: "02.10.2019",
    price: 177.22406,
  },
  {
    Date: "03.10.2019",
    price: 180.703705,
  },
  {
    Date: "04.10.2019",
    price: 175.238434,
  },
  {
    Date: "05.10.2019",
    price: 176.94632,
  },
  {
    Date: "06.10.2019",
    price: 176.36438,
  },
  {
    Date: "07.10.2019",
    price: 172.940338,
  },
  {
    Date: "08.10.2019",
    price: 181.113358,
  },
  {
    Date: "09.10.2019",
    price: 182.036301,
  },
  {
    Date: "10.10.2019",
    price: 193.191071,
  },
  {
    Date: "11.10.2019",
    price: 191.801071,
  },
  {
    Date: "12.10.2019",
    price: 182.534027,
  },
  {
    Date: "13.10.2019",
    price: 180.861313,
  },
  {
    Date: "14.10.2019",
    price: 182.028473,
  },
  {
    Date: "15.10.2019",
    price: 186.977051,
  },
  {
    Date: "16.10.2019",
    price: 181.343735,
  },
  {
    Date: "17.10.2019",
    price: 175.906891,
  },
  {
    Date: "18.10.2019",
    price: 177.988556,
  },
  {
    Date: "19.10.2019",
    price: 173.649445,
  },
  {
    Date: "20.10.2019",
    price: 172.974762,
  },
  {
    Date: "21.10.2019",
    price: 175.52475,
  },
  {
    Date: "22.10.2019",
    price: 174.90506,
  },
  {
    Date: "23.10.2019",
    price: 172.262802,
  },
  {
    Date: "24.10.2019",
    price: 162.514114,
  },
  {
    Date: "25.10.2019",
    price: 162.189713,
  },
  {
    Date: "26.10.2019",
    price: 181.667068,
  },
  {
    Date: "27.10.2019",
    price: 179.932632,
  },
  {
    Date: "28.10.2019",
    price: 184.397507,
  },
  {
    Date: "29.10.2019",
    price: 182.67038,
  },
  {
    Date: "30.10.2019",
    price: 190.336395,
  },
  {
    Date: "31.10.2019",
    price: 184.797623,
  },
  {
    Date: "01.11.2019",
    price: 183.803696,
  },
  {
    Date: "02.11.2019",
    price: 184.018158,
  },
  {
    Date: "03.11.2019",
    price: 183.994827,
  },
  {
    Date: "04.11.2019",
    price: 182.319031,
  },
  {
    Date: "05.11.2019",
    price: 186.309311,
  },
  {
    Date: "06.11.2019",
    price: 189.113495,
  },
  {
    Date: "07.11.2019",
    price: 191.504303,
  },
  {
    Date: "08.11.2019",
    price: 187.924271,
  },
  {
    Date: "09.11.2019",
    price: 184.311722,
  },
  {
    Date: "10.11.2019",
    price: 184.943359,
  },
  {
    Date: "11.11.2019",
    price: 189.50293,
  },
  {
    Date: "12.11.2019",
    price: 185.564835,
  },
  {
    Date: "13.11.2019",
    price: 186.942444,
  },
  {
    Date: "14.11.2019",
    price: 188.272507,
  },
  {
    Date: "15.11.2019",
    price: 186.058167,
  },
  {
    Date: "16.11.2019",
    price: 180.4991,
  },
  {
    Date: "17.11.2019",
    price: 183.35083,
  },
  {
    Date: "18.11.2019",
    price: 185.041458,
  },
  {
    Date: "19.11.2019",
    price: 180.502457,
  },
  {
    Date: "20.11.2019",
    price: 177.415283,
  },
  {
    Date: "21.11.2019",
    price: 175.673599,
  },
  {
    Date: "22.11.2019",
    price: 161.50589,
  },
  {
    Date: "23.11.2019",
    price: 150.212219,
  },
  {
    Date: "24.11.2019",
    price: 153.430527,
  },
  {
    Date: "25.11.2019",
    price: 142.697006,
  },
  {
    Date: "26.11.2019",
    price: 146.404312,
  },
  {
    Date: "27.11.2019",
    price: 148.39682,
  },
  {
    Date: "28.11.2019",
    price: 153.069244,
  },
  {
    Date: "29.11.2019",
    price: 151.7686,
  },
  {
    Date: "30.11.2019",
    price: 155.286407,
  },
  {
    Date: "01.12.2019",
    price: 152.491928,
  },
  {
    Date: "02.12.2019",
    price: 151.175476,
  },
  {
    Date: "03.12.2019",
    price: 149.058243,
  },
  {
    Date: "04.12.2019",
    price: 147.918381,
  },
  {
    Date: "05.12.2019",
    price: 146.686493,
  },
  {
    Date: "06.12.2019",
    price: 149.396484,
  },
  {
    Date: "07.12.2019",
    price: 149.197601,
  },
  {
    Date: "08.12.2019",
    price: 148.743683,
  },
  {
    Date: "09.12.2019",
    price: 151.224396,
  },
  {
    Date: "10.12.2019",
    price: 148.179855,
  },
  {
    Date: "11.12.2019",
    price: 146.320648,
  },
  {
    Date: "12.12.2019",
    price: 143.615662,
  },
  {
    Date: "13.12.2019",
    price: 145.655685,
  },
  {
    Date: "14.12.2019",
    price: 144.953415,
  },
  {
    Date: "15.12.2019",
    price: 142.86499,
  },
  {
    Date: "16.12.2019",
    price: 143.139526,
  },
  {
    Date: "17.12.2019",
    price: 133.647186,
  },
  {
    Date: "18.12.2019",
    price: 122.656837,
  },
  {
    Date: "19.12.2019",
    price: 133.05278,
  },
  {
    Date: "20.12.2019",
    price: 129.384369,
  },
  {
    Date: "21.12.2019",
    price: 129.095963,
  },
  {
    Date: "22.12.2019",
    price: 128.098129,
  },
  {
    Date: "23.12.2019",
    price: 132.306992,
  },
  {
    Date: "24.12.2019",
    price: 128.629257,
  },
  {
    Date: "25.12.2019",
    price: 129.068497,
  },
  {
    Date: "26.12.2019",
    price: 126.486328,
  },
  {
    Date: "27.12.2019",
    price: 126.368797,
  },
  {
    Date: "28.12.2019",
    price: 127.213013,
  },
  {
    Date: "29.12.2019",
    price: 128.266876,
  },
  {
    Date: "30.12.2019",
    price: 134.796524,
  },
  {
    Date: "31.12.2019",
    price: 132.612274,
  },
  {
    Date: "01.01.2020",
    price: 129.630661,
  },
  {
    Date: "02.01.2020",
    price: 130.820038,
  },
  {
    Date: "03.01.2020",
    price: 127.411263,
  },
  {
    Date: "04.01.2020",
    price: 134.168518,
  },
  {
    Date: "05.01.2020",
    price: 135.072098,
  },
  {
    Date: "06.01.2020",
    price: 136.305542,
  },
  {
    Date: "07.01.2020",
    price: 144.311996,
  },
  {
    Date: "08.01.2020",
    price: 143.487823,
  },
  {
    Date: "09.01.2020",
    price: 141.155777,
  },
  {
    Date: "10.01.2020",
    price: 138.967041,
  },
  {
    Date: "11.01.2020",
    price: 143.409103,
  },
  {
    Date: "12.01.2020",
    price: 143.032547,
  },
  {
    Date: "13.01.2020",
    price: 146.220139,
  },
  {
    Date: "14.01.2020",
    price: 144.251053,
  },
  {
    Date: "15.01.2020",
    price: 165.734619,
  },
  {
    Date: "16.01.2020",
    price: 166.332825,
  },
  {
    Date: "17.01.2020",
    price: 164.48938,
  },
  {
    Date: "18.01.2020",
    price: 170.747726,
  },
  {
    Date: "19.01.2020",
    price: 175.46759,
  },
  {
    Date: "20.01.2020",
    price: 166.904129,
  },
  {
    Date: "21.01.2020",
    price: 167.060471,
  },
  {
    Date: "22.01.2020",
    price: 169.608536,
  },
  {
    Date: "23.01.2020",
    price: 168.2957,
  },
  {
    Date: "24.01.2020",
    price: 162.899368,
  },
  {
    Date: "25.01.2020",
    price: 163.067291,
  },
  {
    Date: "26.01.2020",
    price: 161.176819,
  },
  {
    Date: "27.01.2020",
    price: 168.00885,
  },
  {
    Date: "28.01.2020",
    price: 170.884857,
  },
  {
    Date: "29.01.2020",
    price: 176.347885,
  },
  {
    Date: "30.01.2020",
    price: 174.917709,
  },
  {
    Date: "31.01.2020",
    price: 184.736908,
  },
  {
    Date: "01.02.2020",
    price: 180.11377,
  },
  {
    Date: "02.02.2020",
    price: 183.532501,
  },
  {
    Date: "03.02.2020",
    price: 188.607407,
  },
  {
    Date: "04.02.2020",
    price: 189.861725,
  },
  {
    Date: "05.02.2020",
    price: 189.299103,
  },
  {
    Date: "06.02.2020",
    price: 204.1297,
  },
  {
    Date: "07.02.2020",
    price: 212.315887,
  },
  {
    Date: "08.02.2020",
    price: 222.510971,
  },
  {
    Date: "09.02.2020",
    price: 222.982239,
  },
  {
    Date: "10.02.2020",
    price: 228.549133,
  },
  {
    Date: "11.02.2020",
    price: 223.384933,
  },
  {
    Date: "12.02.2020",
    price: 235.898224,
  },
  {
    Date: "13.02.2020",
    price: 265.052704,
  },
  {
    Date: "14.02.2020",
    price: 268.023285,
  },
  {
    Date: "15.02.2020",
    price: 284.56131,
  },
  {
    Date: "16.02.2020",
    price: 264.904053,
  },
  {
    Date: "17.02.2020",
    price: 259.890564,
  },
  {
    Date: "18.02.2020",
    price: 266.50882,
  },
  {
    Date: "19.02.2020",
    price: 282.036285,
  },
  {
    Date: "20.02.2020",
    price: 259.819855,
  },
  {
    Date: "21.02.2020",
    price: 257.891113,
  },
  {
    Date: "22.02.2020",
    price: 265.551575,
  },
  {
    Date: "23.02.2020",
    price: 262.278412,
  },
  {
    Date: "24.02.2020",
    price: 273.705078,
  },
  {
    Date: "25.02.2020",
    price: 265.283386,
  },
  {
    Date: "26.02.2020",
    price: 247.740204,
  },
  {
    Date: "27.02.2020",
    price: 225.687042,
  },
  {
    Date: "28.02.2020",
    price: 226.98761,
  },
  {
    Date: "29.02.2020",
    price: 226.83345,
  },
  {
    Date: "01.03.2020",
    price: 219.752686,
  },
  {
    Date: "02.03.2020",
    price: 218.711624,
  },
  {
    Date: "03.03.2020",
    price: 230.523972,
  },
  {
    Date: "04.03.2020",
    price: 224.565338,
  },
  {
    Date: "05.03.2020",
    price: 224.641891,
  },
  {
    Date: "06.03.2020",
    price: 229.168427,
  },
  {
    Date: "07.03.2020",
    price: 243.750198,
  },
  {
    Date: "08.03.2020",
    price: 237.780685,
  },
  {
    Date: "09.03.2020",
    price: 201.318497,
  },
  {
    Date: "10.03.2020",
    price: 202.863953,
  },
  {
    Date: "11.03.2020",
    price: 200.768036,
  },
  {
    Date: "12.03.2020",
    price: 194.738922,
  },
  {
    Date: "13.03.2020",
    price: 112.689995,
  },
  {
    Date: "14.03.2020",
    price: 133.582474,
  },
  {
    Date: "15.03.2020",
    price: 123.246063,
  },
  {
    Date: "16.03.2020",
    price: 124.996117,
  },
  {
    Date: "17.03.2020",
    price: 110.406784,
  },
  {
    Date: "18.03.2020",
    price: 113.857643,
  },
  {
    Date: "19.03.2020",
    price: 114.839828,
  },
  {
    Date: "20.03.2020",
    price: 136.649277,
  },
  {
    Date: "21.03.2020",
    price: 133.101761,
  },
  {
    Date: "22.03.2020",
    price: 132.851135,
  },
  {
    Date: "23.03.2020",
    price: 123.365982,
  },
  {
    Date: "24.03.2020",
    price: 135.194138,
  },
  {
    Date: "25.03.2020",
    price: 138.914963,
  },
  {
    Date: "26.03.2020",
    price: 136.190674,
  },
  {
    Date: "27.03.2020",
    price: 138.369934,
  },
  {
    Date: "28.03.2020",
    price: 134.032745,
  },
  {
    Date: "29.03.2020",
    price: 131.015335,
  },
  {
    Date: "30.03.2020",
    price: 125.577896,
  },
  {
    Date: "31.03.2020",
    price: 132.820923,
  },
  {
    Date: "01.04.2020",
    price: 133.61232,
  },
  {
    Date: "02.04.2020",
    price: 135.732178,
  },
  {
    Date: "03.04.2020",
    price: 142.110458,
  },
  {
    Date: "04.04.2020",
    price: 142.215179,
  },
  {
    Date: "05.04.2020",
    price: 145.261017,
  },
  {
    Date: "06.04.2020",
    price: 143.608215,
  },
  {
    Date: "07.04.2020",
    price: 169.147446,
  },
  {
    Date: "08.04.2020",
    price: 165.240326,
  },
  {
    Date: "09.04.2020",
    price: 172.761261,
  },
  {
    Date: "10.04.2020",
    price: 170.829269,
  },
  {
    Date: "11.04.2020",
    price: 158.538986,
  },
  {
    Date: "12.04.2020",
    price: 158.232391,
  },
  {
    Date: "13.04.2020",
    price: 160.720673,
  },
  {
    Date: "14.04.2020",
    price: 156.355957,
  },
  {
    Date: "15.04.2020",
    price: 157.565643,
  },
  {
    Date: "16.04.2020",
    price: 153.200424,
  },
  {
    Date: "17.04.2020",
    price: 172.279419,
  },
  {
    Date: "18.04.2020",
    price: 171.618073,
  },
  {
    Date: "19.04.2020",
    price: 186.861984,
  },
  {
    Date: "20.04.2020",
    price: 181.480225,
  },
  {
    Date: "21.04.2020",
    price: 172.017715,
  },
  {
    Date: "22.04.2020",
    price: 172.670395,
  },
  {
    Date: "23.04.2020",
    price: 182.620178,
  },
  {
    Date: "24.04.2020",
    price: 185.222107,
  },
  {
    Date: "25.04.2020",
    price: 189.207397,
  },
  {
    Date: "26.04.2020",
    price: 195.413589,
  },
  {
    Date: "27.04.2020",
    price: 197.475723,
  },
  {
    Date: "28.04.2020",
    price: 197.273514,
  },
  {
    Date: "29.04.2020",
    price: 198.465195,
  },
  {
    Date: "30.04.2020",
    price: 216.909134,
  },
  {
    Date: "01.05.2020",
    price: 207.901733,
  },
  {
    Date: "02.05.2020",
    price: 214.230087,
  },
  {
    Date: "03.05.2020",
    price: 215.352066,
  },
  {
    Date: "04.05.2020",
    price: 210.890854,
  },
  {
    Date: "05.05.2020",
    price: 208.013,
  },
  {
    Date: "06.05.2020",
    price: 206.481354,
  },
  {
    Date: "07.05.2020",
    price: 203.912857,
  },
  {
    Date: "08.05.2020",
    price: 212.198242,
  },
  {
    Date: "09.05.2020",
    price: 213.142166,
  },
  {
    Date: "10.05.2020",
    price: 211.5522,
  },
  {
    Date: "11.05.2020",
    price: 188.632187,
  },
  {
    Date: "12.05.2020",
    price: 185.877335,
  },
  {
    Date: "13.05.2020",
    price: 189.3741,
  },
  {
    Date: "14.05.2020",
    price: 198.89151,
  },
  {
    Date: "15.05.2020",
    price: 202.955399,
  },
  {
    Date: "16.05.2020",
    price: 195.613388,
  },
  {
    Date: "17.05.2020",
    price: 200.608871,
  },
  {
    Date: "18.05.2020",
    price: 207.179779,
  },
  {
    Date: "19.05.2020",
    price: 214.604935,
  },
  {
    Date: "20.05.2020",
    price: 213.446243,
  },
  {
    Date: "21.05.2020",
    price: 210.12915,
  },
  {
    Date: "22.05.2020",
    price: 199.837112,
  },
  {
    Date: "23.05.2020",
    price: 207.194489,
  },
  {
    Date: "24.05.2020",
    price: 208.716064,
  },
  {
    Date: "25.05.2020",
    price: 201.982651,
  },
  {
    Date: "26.05.2020",
    price: 205.259567,
  },
  {
    Date: "27.05.2020",
    price: 201.893005,
  },
  {
    Date: "28.05.2020",
    price: 208.885437,
  },
  {
    Date: "29.05.2020",
    price: 219.925049,
  },
  {
    Date: "30.05.2020",
    price: 220.717209,
  },
  {
    Date: "31.05.2020",
    price: 242.351379,
  },
  {
    Date: "01.06.2020",
    price: 230.86026,
  },
  {
    Date: "02.06.2020",
    price: 246.828186,
  },
  {
    Date: "03.06.2020",
    price: 237.395218,
  },
  {
    Date: "04.06.2020",
    price: 244.105286,
  },
  {
    Date: "05.06.2020",
    price: 244.349594,
  },
  {
    Date: "06.06.2020",
    price: 241.201355,
  },
  {
    Date: "07.06.2020",
    price: 241.908081,
  },
  {
    Date: "08.06.2020",
    price: 245.178574,
  },
  {
    Date: "09.06.2020",
    price: 246.175018,
  },
  {
    Date: "10.06.2020",
    price: 244.822067,
  },
  {
    Date: "11.06.2020",
    price: 247.548538,
  },
  {
    Date: "12.06.2020",
    price: 231.625458,
  },
  {
    Date: "13.06.2020",
    price: 237.544617,
  },
  {
    Date: "14.06.2020",
    price: 238.968185,
  },
  {
    Date: "15.06.2020",
    price: 234.058304,
  },
  {
    Date: "16.06.2020",
    price: 229.762299,
  },
  {
    Date: "17.06.2020",
    price: 234.492371,
  },
  {
    Date: "18.06.2020",
    price: 232.898697,
  },
  {
    Date: "19.06.2020",
    price: 231.954971,
  },
  {
    Date: "20.06.2020",
    price: 226.976364,
  },
  {
    Date: "21.06.2020",
    price: 229.216141,
  },
  {
    Date: "22.06.2020",
    price: 229.003372,
  },
  {
    Date: "23.06.2020",
    price: 242.537018,
  },
  {
    Date: "24.06.2020",
    price: 244.185928,
  },
  {
    Date: "25.06.2020",
    price: 235.70285,
  },
  {
    Date: "26.06.2020",
    price: 232.877487,
  },
  {
    Date: "27.06.2020",
    price: 229.631485,
  },
  {
    Date: "28.06.2020",
    price: 222.91449,
  },
  {
    Date: "29.06.2020",
    price: 225.361435,
  },
  {
    Date: "30.06.2020",
    price: 227.96843,
  },
  {
    Date: "01.07.2020",
    price: 226.134583,
  },
  {
    Date: "02.07.2020",
    price: 231.28891,
  },
  {
    Date: "03.07.2020",
    price: 229.318954,
  },
  {
    Date: "04.07.2020",
    price: 225.288483,
  },
  {
    Date: "05.07.2020",
    price: 228.976196,
  },
  {
    Date: "06.07.2020",
    price: 227.685013,
  },
  {
    Date: "07.07.2020",
    price: 240.972595,
  },
  {
    Date: "08.07.2020",
    price: 239.159973,
  },
  {
    Date: "09.07.2020",
    price: 246.748291,
  },
  {
    Date: "10.07.2020",
    price: 242.868011,
  },
  {
    Date: "11.07.2020",
    price: 241.044785,
  },
  {
    Date: "12.07.2020",
    price: 239.459641,
  },
  {
    Date: "13.07.2020",
    price: 242.18187,
  },
  {
    Date: "14.07.2020",
    price: 239.975616,
  },
  {
    Date: "15.07.2020",
    price: 240.143646,
  },
  {
    Date: "16.07.2020",
    price: 238.450912,
  },
  {
    Date: "17.07.2020",
    price: 233.691391,
  },
  {
    Date: "18.07.2020",
    price: 232.855682,
  },
  {
    Date: "19.07.2020",
    price: 235.458298,
  },
  {
    Date: "20.07.2020",
    price: 238.494873,
  },
  {
    Date: "21.07.2020",
    price: 236.302505,
  },
  {
    Date: "22.07.2020",
    price: 245.037262,
  },
  {
    Date: "23.07.2020",
    price: 262.388641,
  },
  {
    Date: "24.07.2020",
    price: 274.722687,
  },
  {
    Date: "25.07.2020",
    price: 279.026367,
  },
  {
    Date: "26.07.2020",
    price: 303.692383,
  },
  {
    Date: "27.07.2020",
    price: 309.657928,
  },
  {
    Date: "28.07.2020",
    price: 321.829742,
  },
  {
    Date: "29.07.2020",
    price: 316.555359,
  },
  {
    Date: "30.07.2020",
    price: 318.144989,
  },
  {
    Date: "31.07.2020",
    price: 334.633728,
  },
  {
    Date: "01.08.2020",
    price: 345.798615,
  },
  {
    Date: "02.08.2020",
    price: 385.549866,
  },
  {
    Date: "03.08.2020",
    price: 371.13385,
  },
  {
    Date: "04.08.2020",
    price: 386.156464,
  },
  {
    Date: "05.08.2020",
    price: 389.710815,
  },
  {
    Date: "06.08.2020",
    price: 401.583862,
  },
  {
    Date: "07.08.2020",
    price: 395.226868,
  },
  {
    Date: "08.08.2020",
    price: 379.551575,
  },
  {
    Date: "09.08.2020",
    price: 395.305237,
  },
  {
    Date: "10.08.2020",
    price: 391.041504,
  },
  {
    Date: "11.08.2020",
    price: 395.894714,
  },
  {
    Date: "12.08.2020",
    price: 380.063812,
  },
  {
    Date: "13.08.2020",
    price: 390.838104,
  },
  {
    Date: "14.08.2020",
    price: 428.677277,
  },
  {
    Date: "15.08.2020",
    price: 437.562988,
  },
  {
    Date: "16.08.2020",
    price: 433.350586,
  },
  {
    Date: "17.08.2020",
    price: 433.973755,
  },
  {
    Date: "18.08.2020",
    price: 429.669617,
  },
  {
    Date: "19.08.2020",
    price: 423.738586,
  },
  {
    Date: "20.08.2020",
    price: 406.758911,
  },
  {
    Date: "21.08.2020",
    price: 416.148773,
  },
  {
    Date: "22.08.2020",
    price: 389.031097,
  },
  {
    Date: "23.08.2020",
    price: 395.562836,
  },
  {
    Date: "24.08.2020",
    price: 391.678986,
  },
  {
    Date: "25.08.2020",
    price: 408.071686,
  },
  {
    Date: "26.08.2020",
    price: 383.977448,
  },
  {
    Date: "27.08.2020",
    price: 386.609863,
  },
  {
    Date: "28.08.2020",
    price: 382.629578,
  },
  {
    Date: "29.08.2020",
    price: 395.687592,
  },
  {
    Date: "30.08.2020",
    price: 399.616699,
  },
  {
    Date: "31.08.2020",
    price: 428.509003,
  },
  {
    Date: "01.09.2020",
    price: 434.874451,
  },
  {
    Date: "02.09.2020",
    price: 477.007874,
  },
  {
    Date: "03.09.2020",
    price: 440.23999,
  },
  {
    Date: "04.09.2020",
    price: 384.671631,
  },
  {
    Date: "05.09.2020",
    price: 388.038391,
  },
  {
    Date: "06.09.2020",
    price: 335.334564,
  },
  {
    Date: "07.09.2020",
    price: 353.450256,
  },
  {
    Date: "08.09.2020",
    price: 353.202271,
  },
  {
    Date: "09.09.2020",
    price: 337.824921,
  },
  {
    Date: "10.09.2020",
    price: 351.429321,
  },
  {
    Date: "11.09.2020",
    price: 368.1185,
  },
  {
    Date: "12.09.2020",
    price: 374.595398,
  },
  {
    Date: "13.09.2020",
    price: 387.519287,
  },
  {
    Date: "14.09.2020",
    price: 365.699585,
  },
  {
    Date: "15.09.2020",
    price: 377.154022,
  },
  {
    Date: "16.09.2020",
    price: 364.743988,
  },
  {
    Date: "17.09.2020",
    price: 365.865784,
  },
  {
    Date: "18.09.2020",
    price: 389.337494,
  },
  {
    Date: "19.09.2020",
    price: 384.041656,
  },
  {
    Date: "20.09.2020",
    price: 385.597992,
  },
  {
    Date: "21.09.2020",
    price: 371.400146,
  },
  {
    Date: "22.09.2020",
    price: 341.723816,
  },
  {
    Date: "23.09.2020",
    price: 344.589905,
  },
  {
    Date: "24.09.2020",
    price: 320.985016,
  },
  {
    Date: "25.09.2020",
    price: 349.411438,
  },
  {
    Date: "26.09.2020",
    price: 352.350891,
  },
  {
    Date: "27.09.2020",
    price: 354.9599,
  },
  {
    Date: "28.09.2020",
    price: 357.925934,
  },
  {
    Date: "29.09.2020",
    price: 354.363007,
  },
  {
    Date: "30.09.2020",
    price: 360.038513,
  },
  {
    Date: "01.10.2020",
    price: 360.309143,
  },
  {
    Date: "02.10.2020",
    price: 352.949677,
  },
  {
    Date: "03.10.2020",
    price: 346.218628,
  },
  {
    Date: "04.10.2020",
    price: 346.602234,
  },
  {
    Date: "05.10.2020",
    price: 352.736816,
  },
  {
    Date: "06.10.2020",
    price: 353.956482,
  },
  {
    Date: "07.10.2020",
    price: 340.943085,
  },
  {
    Date: "08.10.2020",
    price: 342.044647,
  },
  {
    Date: "09.10.2020",
    price: 351.112885,
  },
  {
    Date: "10.10.2020",
    price: 365.402466,
  },
  {
    Date: "11.10.2020",
    price: 370.928436,
  },
  {
    Date: "12.10.2020",
    price: 374.798737,
  },
  {
    Date: "13.10.2020",
    price: 387.142944,
  },
  {
    Date: "14.10.2020",
    price: 381.971466,
  },
  {
    Date: "15.10.2020",
    price: 379.19223,
  },
  {
    Date: "16.10.2020",
    price: 377.8685,
  },
  {
    Date: "17.10.2020",
    price: 366.015717,
  },
  {
    Date: "18.10.2020",
    price: 368.727539,
  },
  {
    Date: "19.10.2020",
    price: 378.469635,
  },
  {
    Date: "20.10.2020",
    price: 379.719696,
  },
  {
    Date: "21.10.2020",
    price: 369.059418,
  },
  {
    Date: "22.10.2020",
    price: 391.488617,
  },
  {
    Date: "23.10.2020",
    price: 414.051331,
  },
  {
    Date: "24.10.2020",
    price: 409.767242,
  },
  {
    Date: "25.10.2020",
    price: 412.457642,
  },
  {
    Date: "26.10.2020",
    price: 406.217987,
  },
  {
    Date: "27.10.2020",
    price: 393.888184,
  },
  {
    Date: "28.10.2020",
    price: 403.997101,
  },
  {
    Date: "29.10.2020",
    price: 388.651062,
  },
  {
    Date: "30.10.2020",
    price: 386.730255,
  },
  {
    Date: "31.10.2020",
    price: 382.820038,
  },
  {
    Date: "01.11.2020",
    price: 386.590332,
  },
  {
    Date: "02.11.2020",
    price: 396.355988,
  },
  {
    Date: "03.11.2020",
    price: 383.156036,
  },
  {
    Date: "04.11.2020",
    price: 387.60321,
  },
  {
    Date: "05.11.2020",
    price: 402.142944,
  },
  {
    Date: "06.11.2020",
    price: 414.066711,
  },
  {
    Date: "07.11.2020",
    price: 454.722565,
  },
  {
    Date: "08.11.2020",
    price: 435.718811,
  },
  {
    Date: "09.11.2020",
    price: 453.574158,
  },
  {
    Date: "10.11.2020",
    price: 444.166382,
  },
  {
    Date: "11.11.2020",
    price: 449.679657,
  },
  {
    Date: "12.11.2020",
    price: 462.959534,
  },
  {
    Date: "13.11.2020",
    price: 461.005493,
  },
  {
    Date: "14.11.2020",
    price: 474.626434,
  },
  {
    Date: "15.11.2020",
    price: 460.149902,
  },
  {
    Date: "16.11.2020",
    price: 447.55899,
  },
  {
    Date: "17.11.2020",
    price: 464.406647,
  },
  {
    Date: "18.11.2020",
    price: 480.346832,
  },
  {
    Date: "19.11.2020",
    price: 479.481018,
  },
  {
    Date: "20.11.2020",
    price: 471.63147,
  },
  {
    Date: "21.11.2020",
    price: 509.744598,
  },
  {
    Date: "22.11.2020",
    price: 549.486633,
  },
  {
    Date: "23.11.2020",
    price: 558.059509,
  },
  {
    Date: "24.11.2020",
    price: 608.522766,
  },
  {
    Date: "25.11.2020",
    price: 603.902039,
  },
  {
    Date: "26.11.2020",
    price: 570.514893,
  },
  {
    Date: "27.11.2020",
    price: 519.108093,
  },
  {
    Date: "28.11.2020",
    price: 517.597351,
  },
  {
    Date: "29.11.2020",
    price: 538.264587,
  },
  {
    Date: "30.11.2020",
    price: 575.75708,
  },
  {
    Date: "01.12.2020",
    price: 615.070313,
  },
  {
    Date: "02.12.2020",
    price: 587.261597,
  },
  {
    Date: "03.12.2020",
    price: 598.459229,
  },
  {
    Date: "04.12.2020",
    price: 616.722778,
  },
  {
    Date: "05.12.2020",
    price: 569.347656,
  },
  {
    Date: "06.12.2020",
    price: 596.568665,
  },
  {
    Date: "07.12.2020",
    price: 601.797119,
  },
  {
    Date: "08.12.2020",
    price: 591.900818,
  },
  {
    Date: "09.12.2020",
    price: 554.792908,
  },
  {
    Date: "10.12.2020",
    price: 573.504028,
  },
  {
    Date: "11.12.2020",
    price: 559.679199,
  },
  {
    Date: "12.12.2020",
    price: 545.578552,
  },
  {
    Date: "13.12.2020",
    price: 568.609863,
  },
  {
    Date: "14.12.2020",
    price: 589.782471,
  },
  {
    Date: "15.12.2020",
    price: 586.02179,
  },
  {
    Date: "16.12.2020",
    price: 589.378662,
  },
  {
    Date: "17.12.2020",
    price: 636.154175,
  },
  {
    Date: "18.12.2020",
    price: 642.916992,
  },
  {
    Date: "19.12.2020",
    price: 654.624207,
  },
  {
    Date: "20.12.2020",
    price: 659.185059,
  },
  {
    Date: "21.12.2020",
    price: 638.315186,
  },
  {
    Date: "22.12.2020",
    price: 609.420532,
  },
  {
    Date: "23.12.2020",
    price: 634.824585,
  },
  {
    Date: "24.12.2020",
    price: 584.13562,
  },
  {
    Date: "25.12.2020",
    price: 611.554565,
  },
  {
    Date: "26.12.2020",
    price: 626.498047,
  },
  {
    Date: "27.12.2020",
    price: 635.887146,
  },
  {
    Date: "28.12.2020",
    price: 683.205811,
  },
  {
    Date: "29.12.2020",
    price: 730.358704,
  },
  {
    Date: "30.12.2020",
    price: 731.472839,
  },
  {
    Date: "31.12.2020",
    price: 751.626648,
  },
  {
    Date: "01.01.2021",
    price: 737.708374,
  },
  {
    Date: "02.01.2021",
    price: 730.402649,
  },
  {
    Date: "03.01.2021",
    price: 774.511841,
  },
  {
    Date: "04.01.2021",
    price: 977.058838,
  },
  {
    Date: "05.01.2021",
    price: 1041.498779,
  },
  {
    Date: "06.01.2021",
    price: 1101.005005,
  },
  {
    Date: "07.01.2021",
    price: 1208.078369,
  },
  {
    Date: "08.01.2021",
    price: 1225.967896,
  },
  {
    Date: "09.01.2021",
    price: 1223.740479,
  },
  {
    Date: "10.01.2021",
    price: 1280.871094,
  },
  {
    Date: "11.01.2021",
    price: 1261.622925,
  },
  {
    Date: "12.01.2021",
    price: 1088.526733,
  },
  {
    Date: "13.01.2021",
    price: 1043.740967,
  },
  {
    Date: "14.01.2021",
    price: 1130.231201,
  },
  {
    Date: "15.01.2021",
    price: 1221.877197,
  },
  {
    Date: "16.01.2021",
    price: 1171.443115,
  },
  {
    Date: "17.01.2021",
    price: 1233.453369,
  },
  {
    Date: "18.01.2021",
    price: 1230.313232,
  },
  {
    Date: "19.01.2021",
    price: 1257.43457,
  },
  {
    Date: "20.01.2021",
    price: 1375.248413,
  },
  {
    Date: "21.01.2021",
    price: 1382.684448,
  },
  {
    Date: "22.01.2021",
    price: 1118.889038,
  },
  {
    Date: "23.01.2021",
    price: 1235.267944,
  },
  {
    Date: "24.01.2021",
    price: 1231.210571,
  },
  {
    Date: "25.01.2021",
    price: 1390.639893,
  },
  {
    Date: "26.01.2021",
    price: 1323.741699,
  },
  {
    Date: "27.01.2021",
    price: 1358.333374,
  },
  {
    Date: "28.01.2021",
    price: 1251.279785,
  },
  {
    Date: "29.01.2021",
    price: 1369.086792,
  },
  {
    Date: "30.01.2021",
    price: 1382.231934,
  },
  {
    Date: "31.01.2021",
    price: 1376.823608,
  },
  {
    Date: "01.02.2021",
    price: 1314.855225,
  },
  {
    Date: "02.02.2021",
    price: 1369.505127,
  },
  {
    Date: "03.02.2021",
    price: 1514.769653,
  },
  {
    Date: "04.02.2021",
    price: 1661.170166,
  },
  {
    Date: "05.02.2021",
    price: 1594.793335,
  },
  {
    Date: "06.02.2021",
    price: 1717.797485,
  },
  {
    Date: "07.02.2021",
    price: 1677.605713,
  },
  {
    Date: "08.02.2021",
    price: 1613.642212,
  },
  {
    Date: "09.02.2021",
    price: 1746.926147,
  },
  {
    Date: "10.02.2021",
    price: 1768.039795,
  },
  {
    Date: "11.02.2021",
    price: 1743.714233,
  },
  {
    Date: "12.02.2021",
    price: 1783.489136,
  },
  {
    Date: "13.02.2021",
    price: 1843.986816,
  },
  {
    Date: "14.02.2021",
    price: 1814.372314,
  },
  {
    Date: "15.02.2021",
    price: 1804.676758,
  },
  {
    Date: "16.02.2021",
    price: 1778.945557,
  },
  {
    Date: "17.02.2021",
    price: 1781.350098,
  },
  {
    Date: "18.02.2021",
    price: 1848.206299,
  },
  {
    Date: "19.02.2021",
    price: 1938.859863,
  },
  {
    Date: "20.02.2021",
    price: 1959.902954,
  },
  {
    Date: "21.02.2021",
    price: 1918.673096,
  },
  {
    Date: "22.02.2021",
    price: 1935.557861,
  },
  {
    Date: "23.02.2021",
    price: 1781.409058,
  },
  {
    Date: "24.02.2021",
    price: 1571.47644,
  },
  {
    Date: "25.02.2021",
    price: 1625.393921,
  },
  {
    Date: "26.02.2021",
    price: 1478.65332,
  },
  {
    Date: "27.02.2021",
    price: 1446.929443,
  },
  {
    Date: "28.02.2021",
    price: 1459.860474,
  },
  {
    Date: "01.03.2021",
    price: 1417.151123,
  },
  {
    Date: "02.03.2021",
    price: 1564.063477,
  },
  {
    Date: "03.03.2021",
    price: 1491.451172,
  },
  {
    Date: "04.03.2021",
    price: 1574.623779,
  },
  {
    Date: "05.03.2021",
    price: 1541.541748,
  },
  {
    Date: "06.03.2021",
    price: 1532.372803,
  },
  {
    Date: "07.03.2021",
    price: 1655.392456,
  },
  {
    Date: "08.03.2021",
    price: 1724.229248,
  },
  {
    Date: "09.03.2021",
    price: 1835.148071,
  },
  {
    Date: "10.03.2021",
    price: 1868.489014,
  },
  {
    Date: "11.03.2021",
    price: 1798.033936,
  },
  {
    Date: "12.03.2021",
    price: 1826.546753,
  },
  {
    Date: "13.03.2021",
    price: 1772.166382,
  },
  {
    Date: "14.03.2021",
    price: 1923.86377,
  },
  {
    Date: "15.03.2021",
    price: 1854.08667,
  },
  {
    Date: "16.03.2021",
    price: 1792.413818,
  },
  {
    Date: "17.03.2021",
    price: 1807.056152,
  },
  {
    Date: "18.03.2021",
    price: 1823.158447,
  },
  {
    Date: "19.03.2021",
    price: 1782.568726,
  },
  {
    Date: "20.03.2021",
    price: 1817.522705,
  },
  {
    Date: "21.03.2021",
    price: 1812.606567,
  },
  {
    Date: "22.03.2021",
    price: 1788.362183,
  },
  {
    Date: "23.03.2021",
    price: 1690.871826,
  },
  {
    Date: "24.03.2021",
    price: 1678.002563,
  },
  {
    Date: "25.03.2021",
    price: 1593.123291,
  },
  {
    Date: "26.03.2021",
    price: 1595.210083,
  },
  {
    Date: "27.03.2021",
    price: 1703.036133,
  },
  {
    Date: "28.03.2021",
    price: 1716.40564,
  },
  {
    Date: "29.03.2021",
    price: 1691.263184,
  },
  {
    Date: "30.03.2021",
    price: 1819.466309,
  },
  {
    Date: "31.03.2021",
    price: 1846.098267,
  },
  {
    Date: "01.04.2021",
    price: 1919.157227,
  },
  {
    Date: "02.04.2021",
    price: 1976.932739,
  },
  {
    Date: "03.04.2021",
    price: 2142.895996,
  },
  {
    Date: "04.04.2021",
    price: 2027.671265,
  },
  {
    Date: "05.04.2021",
    price: 2093.260742,
  },
  {
    Date: "06.04.2021",
    price: 2109.493164,
  },
  {
    Date: "07.04.2021",
    price: 2117.728516,
  },
  {
    Date: "08.04.2021",
    price: 1969.133179,
  },
  {
    Date: "09.04.2021",
    price: 2088.772217,
  },
  {
    Date: "10.04.2021",
    price: 2071.111572,
  },
  {
    Date: "11.04.2021",
    price: 2136.156982,
  },
  {
    Date: "12.04.2021",
    price: 2157.361816,
  },
  {
    Date: "13.04.2021",
    price: 2139.364258,
  },
  {
    Date: "14.04.2021",
    price: 2299.3479,
  },
  {
    Date: "15.04.2021",
    price: 2436.034668,
  },
  {
    Date: "16.04.2021",
    price: 2516.601807,
  },
  {
    Date: "17.04.2021",
    price: 2429.980957,
  },
  {
    Date: "18.04.2021",
    price: 2346.452393,
  },
  {
    Date: "19.04.2021",
    price: 2238.032715,
  },
  {
    Date: "20.04.2021",
    price: 2161.939453,
  },
  {
    Date: "21.04.2021",
    price: 2331.160156,
  },
  {
    Date: "22.04.2021",
    price: 2357.871094,
  },
  {
    Date: "23.04.2021",
    price: 2401.256348,
  },
  {
    Date: "24.04.2021",
    price: 2367.199219,
  },
  {
    Date: "25.04.2021",
    price: 2214.413818,
  },
  {
    Date: "26.04.2021",
    price: 2319.478027,
  },
  {
    Date: "27.04.2021",
    price: 2534.03125,
  },
  {
    Date: "28.04.2021",
    price: 2664.685547,
  },
  {
    Date: "29.04.2021",
    price: 2748.649658,
  },
  {
    Date: "30.04.2021",
    price: 2757.734131,
  },
  {
    Date: "01.05.2021",
    price: 2772.838379,
  },
  {
    Date: "02.05.2021",
    price: 2945.560059,
  },
  {
    Date: "03.05.2021",
    price: 2951.175781,
  },
  {
    Date: "04.05.2021",
    price: 3431.131592,
  },
  {
    Date: "05.05.2021",
    price: 3240.554688,
  },
  {
    Date: "06.05.2021",
    price: 3524.930908,
  },
  {
    Date: "07.05.2021",
    price: 3490.105225,
  },
  {
    Date: "08.05.2021",
    price: 3481.988037,
  },
  {
    Date: "09.05.2021",
    price: 3911.463135,
  },
  {
    Date: "10.05.2021",
    price: 3924.41333,
  },
  {
    Date: "11.05.2021",
    price: 3948.271973,
  },
  {
    Date: "12.05.2021",
    price: 4174.635742,
  },
  {
    Date: "13.05.2021",
    price: 3828.918457,
  },
  {
    Date: "14.05.2021",
    price: 3720.122314,
  },
  {
    Date: "15.05.2021",
    price: 4075.95166,
  },
  {
    Date: "16.05.2021",
    price: 3641.830811,
  },
  {
    Date: "17.05.2021",
    price: 3581.343018,
  },
  {
    Date: "18.05.2021",
    price: 3276.872314,
  },
  {
    Date: "19.05.2021",
    price: 3382.657227,
  },
  {
    Date: "20.05.2021",
    price: 2439.638672,
  },
  {
    Date: "21.05.2021",
    price: 2772.341309,
  },
  {
    Date: "22.05.2021",
    price: 2436.014648,
  },
  {
    Date: "23.05.2021",
    price: 2298.367188,
  },
  {
    Date: "24.05.2021",
    price: 2099.936035,
  },
  {
    Date: "25.05.2021",
    price: 2649.033203,
  },
  {
    Date: "26.05.2021",
    price: 2707.052734,
  },
  {
    Date: "27.05.2021",
    price: 2888.752441,
  },
  {
    Date: "28.05.2021",
    price: 2742.468994,
  },
  {
    Date: "29.05.2021",
    price: 2414.067139,
  },
  {
    Date: "30.05.2021",
    price: 2278.288818,
  },
  {
    Date: "31.05.2021",
    price: 2387.198486,
  },
  {
    Date: "01.06.2021",
    price: 2707.560547,
  },
  {
    Date: "02.06.2021",
    price: 2634.456055,
  },
  {
    Date: "03.06.2021",
    price: 2708.376221,
  },
  {
    Date: "04.06.2021",
    price: 2857.165527,
  },
  {
    Date: "05.06.2021",
    price: 2691.619385,
  },
  {
    Date: "06.06.2021",
    price: 2629.748779,
  },
  {
    Date: "07.06.2021",
    price: 2713.047363,
  },
  {
    Date: "08.06.2021",
    price: 2594.602295,
  },
  {
    Date: "09.06.2021",
    price: 2510.199219,
  },
  {
    Date: "10.06.2021",
    price: 2611.142578,
  },
  {
    Date: "11.06.2021",
    price: 2472.858887,
  },
  {
    Date: "12.06.2021",
    price: 2354.752197,
  },
  {
    Date: "13.06.2021",
    price: 2372.690186,
  },
  {
    Date: "14.06.2021",
    price: 2508.770508,
  },
  {
    Date: "15.06.2021",
    price: 2587.762695,
  },
  {
    Date: "16.06.2021",
    price: 2544.864746,
  },
  {
    Date: "17.06.2021",
    price: 2367.306396,
  },
  {
    Date: "18.06.2021",
    price: 2374.586914,
  },
  {
    Date: "19.06.2021",
    price: 2235.15918,
  },
  {
    Date: "20.06.2021",
    price: 2171.337891,
  },
  {
    Date: "21.06.2021",
    price: 2245.317871,
  },
  {
    Date: "22.06.2021",
    price: 1886.667603,
  },
  {
    Date: "23.06.2021",
    price: 1878.625,
  },
  {
    Date: "24.06.2021",
    price: 1968.957397,
  },
  {
    Date: "25.06.2021",
    price: 1989.21582,
  },
  {
    Date: "26.06.2021",
    price: 1810.884277,
  },
  {
    Date: "27.06.2021",
    price: 1830.996948,
  },
  {
    Date: "28.06.2021",
    price: 1981.386475,
  },
  {
    Date: "29.06.2021",
    price: 2083.44873,
  },
  {
    Date: "30.06.2021",
    price: 2164.216309,
  },
  {
    Date: "01.07.2021",
    price: 2274.397461,
  },
  {
    Date: "02.07.2021",
    price: 2109.892578,
  },
  {
    Date: "03.07.2021",
    price: 2150.834961,
  },
  {
    Date: "04.07.2021",
    price: 2226.550293,
  },
  {
    Date: "05.07.2021",
    price: 2321.922852,
  },
  {
    Date: "06.07.2021",
    price: 2197.919434,
  },
  {
    Date: "07.07.2021",
    price: 2323.208496,
  },
  {
    Date: "08.07.2021",
    price: 2317.949219,
  },
  {
    Date: "09.07.2021",
    price: 2115.573975,
  },
  {
    Date: "10.07.2021",
    price: 2146.999756,
  },
  {
    Date: "11.07.2021",
    price: 2110.875732,
  },
  {
    Date: "12.07.2021",
    price: 2140.506836,
  },
  {
    Date: "13.07.2021",
    price: 2034.098389,
  },
  {
    Date: "14.07.2021",
    price: 1941.167847,
  },
  {
    Date: "15.07.2021",
    price: 1994.712036,
  },
  {
    Date: "16.07.2021",
    price: 1916.598389,
  },
  {
    Date: "17.07.2021",
    price: 1876.881348,
  },
  {
    Date: "18.07.2021",
    price: 1900.194336,
  },
  {
    Date: "19.07.2021",
    price: 1893.053589,
  },
  {
    Date: "20.07.2021",
    price: 1819.082031,
  },
  {
    Date: "21.07.2021",
    price: 1786.276245,
  },
  {
    Date: "22.07.2021",
    price: 1994.822876,
  },
  {
    Date: "23.07.2021",
    price: 2025.105713,
  },
  {
    Date: "24.07.2021",
    price: 2123.961182,
  },
  {
    Date: "25.07.2021",
    price: 2187.145508,
  },
  {
    Date: "26.07.2021",
    price: 2191.309814,
  },
  {
    Date: "27.07.2021",
    price: 2230.197021,
  },
  {
    Date: "28.07.2021",
    price: 2302.081299,
  },
  {
    Date: "29.07.2021",
    price: 2299.011963,
  },
  {
    Date: "30.07.2021",
    price: 2382.545166,
  },
  {
    Date: "31.07.2021",
    price: 2461.575684,
  },
  {
    Date: "01.08.2021",
    price: 2530.462891,
  },
  {
    Date: "02.08.2021",
    price: 2557.774658,
  },
  {
    Date: "03.08.2021",
    price: 2609.413086,
  },
  {
    Date: "04.08.2021",
    price: 2508.544922,
  },
  {
    Date: "05.08.2021",
    price: 2725.669678,
  },
  {
    Date: "06.08.2021",
    price: 2827.503418,
  },
  {
    Date: "07.08.2021",
    price: 2891.70752,
  },
  {
    Date: "08.08.2021",
    price: 3161.232666,
  },
  {
    Date: "09.08.2021",
    price: 3012.885742,
  },
  {
    Date: "10.08.2021",
    price: 3163.050049,
  },
  {
    Date: "11.08.2021",
    price: 3142.830322,
  },
  {
    Date: "12.08.2021",
    price: 3164.175781,
  },
  {
    Date: "13.08.2021",
    price: 3049.001221,
  },
  {
    Date: "14.08.2021",
    price: 3322.762695,
  },
  {
    Date: "15.08.2021",
    price: 3266.276123,
  },
  {
    Date: "16.08.2021",
    price: 3309.422119,
  },
  {
    Date: "17.08.2021",
    price: 3149.380371,
  },
  {
    Date: "18.08.2021",
    price: 3011.963623,
  },
  {
    Date: "19.08.2021",
    price: 3019.126953,
  },
  {
    Date: "20.08.2021",
    price: 3182.162598,
  },
  {
    Date: "21.08.2021",
    price: 3286.931641,
  },
  {
    Date: "22.08.2021",
    price: 3226.227295,
  },
  {
    Date: "23.08.2021",
    price: 3241.357422,
  },
  {
    Date: "24.08.2021",
    price: 3324.855469,
  },
  {
    Date: "25.08.2021",
    price: 3174.269775,
  },
  {
    Date: "26.08.2021",
    price: 3228.747314,
  },
  {
    Date: "27.08.2021",
    price: 3096.406006,
  },
  {
    Date: "28.08.2021",
    price: 3275.104492,
  },
  {
    Date: "29.08.2021",
    price: 3246.770996,
  },
  {
    Date: "30.08.2021",
    price: 3227.192627,
  },
  {
    Date: "31.08.2021",
    price: 3227.758057,
  },
  {
    Date: "01.09.2021",
    price: 3430.762451,
  },
  {
    Date: "02.09.2021",
    price: 3825.027832,
  },
  {
    Date: "03.09.2021",
    price: 3787.486572,
  },
  {
    Date: "04.09.2021",
    price: 3937.910156,
  },
  {
    Date: "05.09.2021",
    price: 3886.331055,
  },
  {
    Date: "06.09.2021",
    price: 3951.520752,
  },
  {
    Date: "07.09.2021",
    price: 3926.527588,
  },
  {
    Date: "08.09.2021",
    price: 3428.378174,
  },
  {
    Date: "09.09.2021",
    price: 3452.54834,
  },
  {
    Date: "10.09.2021",
    price: 3425.564941,
  },
  {
    Date: "11.09.2021",
    price: 3209.030518,
  },
  {
    Date: "12.09.2021",
    price: 3270.291748,
  },
  {
    Date: "13.09.2021",
    price: 3407.471924,
  },
  {
    Date: "14.09.2021",
    price: 3286.316406,
  },
  {
    Date: "15.09.2021",
    price: 3431.217285,
  },
  {
    Date: "16.09.2021",
    price: 3613.07373,
  },
  {
    Date: "17.09.2021",
    price: 3569.568115,
  },
  {
    Date: "18.09.2021",
    price: 3397.422852,
  },
  {
    Date: "19.09.2021",
    price: 3433.287842,
  },
  {
    Date: "20.09.2021",
    price: 3329.670654,
  },
  {
    Date: "21.09.2021",
    price: 2977.310547,
  },
  {
    Date: "22.09.2021",
    price: 2763.209229,
  },
  {
    Date: "23.09.2021",
    price: 3077.974365,
  },
  {
    Date: "24.09.2021",
    price: 3154.562012,
  },
  {
    Date: "25.09.2021",
    price: 2930.884766,
  },
  {
    Date: "26.09.2021",
    price: 2926.343018,
  },
  {
    Date: "27.09.2021",
    price: 3065.837891,
  },
  {
    Date: "28.09.2021",
    price: 2928.963379,
  },
  {
    Date: "29.09.2021",
    price: 2809.297607,
  },
  {
    Date: "30.09.2021",
    price: 2852.558838,
  },
  {
    Date: "01.10.2021",
    price: 3001.129395,
  },
  {
    Date: "02.10.2021",
    price: 3308.870361,
  },
  {
    Date: "03.10.2021",
    price: 3390.767578,
  },
  {
    Date: "04.10.2021",
    price: 3418.776367,
  },
  {
    Date: "05.10.2021",
    price: 3381.784424,
  },
  {
    Date: "06.10.2021",
    price: 3516.570313,
  },
  {
    Date: "07.10.2021",
    price: 3576.814453,
  },
  {
    Date: "08.10.2021",
    price: 3587.832764,
  },
  {
    Date: "09.10.2021",
    price: 3559.997803,
  },
  {
    Date: "10.10.2021",
    price: 3575.020508,
  },
  {
    Date: "11.10.2021",
    price: 3419.726807,
  },
  {
    Date: "12.10.2021",
    price: 3546.477783,
  },
  {
    Date: "13.10.2021",
    price: 3492.753662,
  },
  {
    Date: "14.10.2021",
    price: 3604.958984,
  },
  {
    Date: "15.10.2021",
    price: 3790.154297,
  },
  {
    Date: "16.10.2021",
    price: 3865.666504,
  },
  {
    Date: "17.10.2021",
    price: 3829.858887,
  },
  {
    Date: "18.10.2021",
    price: 3847.72998,
  },
  {
    Date: "19.10.2021",
    price: 3747.162842,
  },
  {
    Date: "20.10.2021",
    price: 3877.730957,
  },
  {
    Date: "21.10.2021",
    price: 4161.713379,
  },
  {
    Date: "22.10.2021",
    price: 4055.686523,
  },
  {
    Date: "23.10.2021",
    price: 3971.356445,
  },
  {
    Date: "24.10.2021",
    price: 4171.855957,
  },
  {
    Date: "25.10.2021",
    price: 4084.425781,
  },
  {
    Date: "26.10.2021",
    price: 4217.337891,
  },
  {
    Date: "27.10.2021",
    price: 4132.17334,
  },
  {
    Date: "28.10.2021",
    price: 3924.81543,
  },
  {
    Date: "29.10.2021",
    price: 4288.686523,
  },
  {
    Date: "30.10.2021",
    price: 4414.243652,
  },
  {
    Date: "31.10.2021",
    price: 4322.744141,
  },
  {
    Date: "01.11.2021",
    price: 4288.217285,
  },
  {
    Date: "02.11.2021",
    price: 4322.500977,
  },
  {
    Date: "03.11.2021",
    price: 4589.68457,
  },
  {
    Date: "04.11.2021",
    price: 4604.678711,
  },
  {
    Date: "05.11.2021",
    price: 4537.42334,
  },
  {
    Date: "06.11.2021",
    price: 4482.647949,
  },
  {
    Date: "07.11.2021",
    price: 4523.981934,
  },
  {
    Date: "08.11.2021",
    price: 4619.649414,
  },
  {
    Date: "09.11.2021",
    price: 4810.071289,
  },
  {
    Date: "10.11.2021",
    price: 4733.362793,
  },
  {
    Date: "11.11.2021",
    price: 4635.453613,
  },
  {
    Date: "12.11.2021",
    price: 4724.306641,
  },
  {
    Date: "13.11.2021",
    price: 4666.719238,
  },
  {
    Date: "14.11.2021",
    price: 4648.632813,
  },
  {
    Date: "15.11.2021",
    price: 4627.09082,
  },
  {
    Date: "16.11.2021",
    price: 4570.478027,
  },
  {
    Date: "17.11.2021",
    price: 4213.910645,
  },
  {
    Date: "18.11.2021",
    price: 4287.802246,
  },
  {
    Date: "19.11.2021",
    price: 3995.729492,
  },
  {
    Date: "20.11.2021",
    price: 4298.350586,
  },
  {
    Date: "21.11.2021",
    price: 4412.195313,
  },
  {
    Date: "22.11.2021",
    price: 4266.509277,
  },
  {
    Date: "23.11.2021",
    price: 4089.68042,
  },
  {
    Date: "24.11.2021",
    price: 4340.036133,
  },
  {
    Date: "25.11.2021",
    price: 4271.394531,
  },
  {
    Date: "26.11.2021",
    price: 4522.208984,
  },
  {
    Date: "27.11.2021",
    price: 4042.999023,
  },
  {
    Date: "28.11.2021",
    price: 4101.648926,
  },
  {
    Date: "29.11.2021",
    price: 4296.946777,
  },
  {
    Date: "30.11.2021",
    price: 4447.768066,
  },
  {
    Date: "01.12.2021",
    price: 4623.679688,
  },
  {
    Date: "02.12.2021",
    price: 4586.333008,
  },
  {
    Date: "03.12.2021",
    price: 4514.355957,
  },
  {
    Date: "04.12.2021",
    price: 4227.762207,
  },
  {
    Date: "05.12.2021",
    price: 4119.628906,
  },
  {
    Date: "06.12.2021",
    price: 4199,
  },
  {
    Date: "07.12.2021",
    price: 4358.586914,
  },
  {
    Date: "08.12.2021",
    price: 4311.674316,
  },
  {
    Date: "09.12.2021",
    price: 4433.024902,
  },
  {
    Date: "10.12.2021",
    price: 4113.588379,
  },
  {
    Date: "11.12.2021",
    price: 3909.667725,
  },
  {
    Date: "12.12.2021",
    price: 4084.811279,
  },
  {
    Date: "13.12.2021",
    price: 4136.359863,
  },
  {
    Date: "14.12.2021",
    price: 3782.822754,
  },
  {
    Date: "15.12.2021",
    price: 3862.251465,
  },
  {
    Date: "16.12.2021",
    price: 4020.415039,
  },
  {
    Date: "17.12.2021",
    price: 3959.012451,
  },
  {
    Date: "18.12.2021",
    price: 3880.291504,
  },
  {
    Date: "19.12.2021",
    price: 3960.872314,
  },
  {
    Date: "20.12.2021",
    price: 3923.695801,
  },
  {
    Date: "21.12.2021",
    price: 3938.463867,
  },
  {
    Date: "22.12.2021",
    price: 4018.695801,
  },
  {
    Date: "23.12.2021",
    price: 3981.96167,
  },
  {
    Date: "24.12.2021",
    price: 4111.345215,
  },
  {
    Date: "25.12.2021",
    price: 4049.781982,
  },
  {
    Date: "26.12.2021",
    price: 4094.151611,
  },
  {
    Date: "27.12.2021",
    price: 4064.746338,
  },
  {
    Date: "28.12.2021",
    price: 4037.538086,
  },
  {
    Date: "29.12.2021",
    price: 3797.436279,
  },
  {
    Date: "30.12.2021",
    price: 3632.219727,
  },
  {
    Date: "31.12.2021",
    price: 3713.430176,
  },
  {
    Date: "01.01.2022",
    price: 3683.047119,
  },
  {
    Date: "02.01.2022",
    price: 3769.29834,
  },
  {
    Date: "03.01.2022",
    price: 3829.535645,
  },
  {
    Date: "04.01.2022",
    price: 3761.361572,
  },
  {
    Date: "05.01.2022",
    price: 3794.269043,
  },
  {
    Date: "06.01.2022",
    price: 3549.708984,
  },
  {
    Date: "07.01.2022",
    price: 3417.837891,
  },
  {
    Date: "08.01.2022",
    price: 3193.502441,
  },
  {
    Date: "09.01.2022",
    price: 3091.696289,
  },
  {
    Date: "10.01.2022",
    price: 3157.570557,
  },
  {
    Date: "11.01.2022",
    price: 3082.990967,
  },
  {
    Date: "12.01.2022",
    price: 3238.449951,
  },
  {
    Date: "13.01.2022",
    price: 3372.10498,
  },
  {
    Date: "14.01.2022",
    price: 3248.648682,
  },
  {
    Date: "15.01.2022",
    price: 3309.844238,
  },
  {
    Date: "16.01.2022",
    price: 3330.387207,
  },
  {
    Date: "17.01.2022",
    price: 3350.947266,
  },
  {
    Date: "18.01.2022",
    price: 3212.287598,
  },
  {
    Date: "19.01.2022",
    price: 3163.850342,
  },
  {
    Date: "20.01.2022",
    price: 3095.271729,
  },
  {
    Date: "21.01.2022",
    price: 3002.956787,
  },
  {
    Date: "22.01.2022",
    price: 2561.145264,
  },
  {
    Date: "23.01.2022",
    price: 2406.924316,
  },
  {
    Date: "24.01.2022",
    price: 2535.891113,
  },
  {
    Date: "25.01.2022",
    price: 2440.393555,
  },
  {
    Date: "26.01.2022",
    price: 2455.579102,
  },
  {
    Date: "27.01.2022",
    price: 2467.188477,
  },
  {
    Date: "28.01.2022",
    price: 2421.646973,
  },
  {
    Date: "29.01.2022",
    price: 2546.590576,
  },
  {
    Date: "30.01.2022",
    price: 2598.564941,
  },
  {
    Date: "31.01.2022",
    price: 2603.263428,
  },
  {
    Date: "01.02.2022",
    price: 2687.898926,
  },
  {
    Date: "02.02.2022",
    price: 2791.958984,
  },
  {
    Date: "03.02.2022",
    price: 2682.226074,
  },
  {
    Date: "04.02.2022",
    price: 2681.057617,
  },
  {
    Date: "05.02.2022",
    price: 2984.446045,
  },
  {
    Date: "06.02.2022",
    price: 3014.959717,
  },
  {
    Date: "07.02.2022",
    price: 3057.422119,
  },
  {
    Date: "08.02.2022",
    price: 3143.008545,
  },
  {
    Date: "09.02.2022",
    price: 3121.182617,
  },
  {
    Date: "10.02.2022",
    price: 3240.113037,
  },
  {
    Date: "11.02.2022",
    price: 3077.413086,
  },
  {
    Date: "12.02.2022",
    price: 2927.386475,
  },
  {
    Date: "13.02.2022",
    price: 2916.789551,
  },
  {
    Date: "14.02.2022",
    price: 2880.187744,
  },
  {
    Date: "15.02.2022",
    price: 2933.729004,
  },
  {
    Date: "16.02.2022",
    price: 3180.446777,
  },
  {
    Date: "17.02.2022",
    price: 3126.858398,
  },
  {
    Date: "18.02.2022",
    price: 2884.340576,
  },
  {
    Date: "19.02.2022",
    price: 2784.872803,
  },
  {
    Date: "20.02.2022",
    price: 2763.756592,
  },
  {
    Date: "21.02.2022",
    price: 2627.665527,
  },
  {
    Date: "22.02.2022",
    price: 2572.898682,
  },
  {
    Date: "23.02.2022",
    price: 2639.447021,
  },
  {
    Date: "24.02.2022",
    price: 2588.16626,
  },
  {
    Date: "25.02.2022",
    price: 2598.436279,
  },
  {
    Date: "26.02.2022",
    price: 2764.989502,
  },
  {
    Date: "27.02.2022",
    price: 2780.504395,
  },
  {
    Date: "28.02.2022",
    price: 2621.172119,
  },
  {
    Date: "01.03.2022",
    price: 2919.775879,
  },
  {
    Date: "02.03.2022",
    price: 2972.471924,
  },
  {
    Date: "03.03.2022",
    price: 2950.156738,
  },
  {
    Date: "04.03.2022",
    price: 2834.987305,
  },
  {
    Date: "05.03.2022",
    price: 2618.473633,
  },
  {
    Date: "06.03.2022",
    price: 2664.943604,
  },
  {
    Date: "07.03.2022",
    price: 2555.297607,
  },
  {
    Date: "08.03.2022",
    price: 2497.721436,
  },
  {
    Date: "09.03.2022",
    price: 2577.165283,
  },
  {
    Date: "10.03.2022",
    price: 2729.116455,
  },
  {
    Date: "11.03.2022",
    price: 2608.27124,
  },
  {
    Date: "12.03.2022",
    price: 2559.660645,
  },
  {
    Date: "13.03.2022",
    price: 2573.488037,
  },
  {
    Date: "14.03.2022",
    price: 2518.486328,
  },
  {
    Date: "15.03.2022",
    price: 2590.668945,
  },
  {
    Date: "16.03.2022",
    price: 2620.028564,
  },
  {
    Date: "17.03.2022",
    price: 2771.964111,
  },
  {
    Date: "18.03.2022",
    price: 2814.43457,
  },
  {
    Date: "19.03.2022",
    price: 2944.72168,
  },
  {
    Date: "20.03.2022",
    price: 2946.547607,
  },
  {
    Date: "21.03.2022",
    price: 2860.103271,
  },
  {
    Date: "22.03.2022",
    price: 2897.77417,
  },
  {
    Date: "23.03.2022",
    price: 2973.14502,
  },
  {
    Date: "24.03.2022",
    price: 3031.060791,
  },
  {
    Date: "25.03.2022",
    price: 3108.448975,
  },
  {
    Date: "26.03.2022",
    price: 3106.405273,
  },
  {
    Date: "27.03.2022",
    price: 3143.795654,
  },
  {
    Date: "28.03.2022",
    price: 3292.32251,
  },
  {
    Date: "29.03.2022",
    price: 3335.02124,
  },
  {
    Date: "30.03.2022",
    price: 3401.526123,
  },
  {
    Date: "31.03.2022",
    price: 3385.289307,
  },
  {
    Date: "01.04.2022",
    price: 3282.576172,
  },
  {
    Date: "02.04.2022",
    price: 3449.788574,
  },
  {
    Date: "03.04.2022",
    price: 3444.810547,
  },
  {
    Date: "04.04.2022",
    price: 3522.36499,
  },
  {
    Date: "05.04.2022",
    price: 3521.239746,
  },
  {
    Date: "06.04.2022",
    price: 3411.672119,
  },
  {
    Date: "07.04.2022",
    price: 3172.197266,
  },
  {
    Date: "08.04.2022",
    price: 3233.272461,
  },
  {
    Date: "09.04.2022",
    price: 3191.976074,
  },
  {
    Date: "10.04.2022",
    price: 3261.291504,
  },
  {
    Date: "11.04.2022",
    price: 3209.576904,
  },
  {
    Date: "12.04.2022",
    price: 2981.420654,
  },
  {
    Date: "13.04.2022",
    price: 3029.87793,
  },
  {
    Date: "14.04.2022",
    price: 3117.821777,
  },
  {
    Date: "15.04.2022",
    price: 3020.134521,
  },
  {
    Date: "16.04.2022",
    price: 3041.123291,
  },
  {
    Date: "17.04.2022",
    price: 3061.881836,
  },
  {
    Date: "18.04.2022",
    price: 2993.483887,
  },
  {
    Date: "19.04.2022",
    price: 3057.570313,
  },
  {
    Date: "20.04.2022",
    price: 3103.935059,
  },
  {
    Date: "21.04.2022",
    price: 3077.829346,
  },
  {
    Date: "22.04.2022",
    price: 2986.938721,
  },
  {
    Date: "23.04.2022",
    price: 2964.802246,
  },
  {
    Date: "24.04.2022",
    price: 2937.347168,
  },
  {
    Date: "25.04.2022",
    price: 2922.990234,
  },
  {
    Date: "26.04.2022",
    price: 3008.946289,
  },
  {
    Date: "27.04.2022",
    price: 2808.645996,
  },
  {
    Date: "28.04.2022",
    price: 2888.849854,
  },
  {
    Date: "29.04.2022",
    price: 2936.776611,
  },
  {
    Date: "30.04.2022",
    price: 2815.533447,
  },
  {
    Date: "01.05.2022",
    price: 2729.994141,
  },
  {
    Date: "02.05.2022",
    price: 2827.614014,
  },
  {
    Date: "03.05.2022",
    price: 2857.152344,
  },
  {
    Date: "04.05.2022",
    price: 2783.131104,
  },
  {
    Date: "05.05.2022",
    price: 2940.226563,
  },
  {
    Date: "06.05.2022",
    price: 2748.931641,
  },
  {
    Date: "07.05.2022",
    price: 2694.991943,
  },
  {
    Date: "08.05.2022",
    price: 2636.121826,
  },
  {
    Date: "09.05.2022",
    price: 2518.508301,
  },
  {
    Date: "10.05.2022",
    price: 2242.650391,
  },
  {
    Date: "11.05.2022",
    price: 2342.75415,
  },
  {
    Date: "12.05.2022",
    price: 2072.504639,
  },
  {
    Date: "13.05.2022",
    price: 1960.122559,
  },
  {
    Date: "14.05.2022",
    price: 2014.28064,
  },
  {
    Date: "15.05.2022",
    price: 2056.183105,
  },
  {
    Date: "16.05.2022",
    price: 2145.836914,
  },
  {
    Date: "17.05.2022",
    price: 2022.882324,
  },
  {
    Date: "18.05.2022",
    price: 2090.459961,
  },
  {
    Date: "19.05.2022",
    price: 1916.149536,
  },
  {
    Date: "20.05.2022",
    price: 2018.000122,
  },
  {
    Date: "21.05.2022",
    price: 1961.017944,
  },
  {
    Date: "22.05.2022",
    price: 1974.670654,
  },
  {
    Date: "23.05.2022",
    price: 2042.344727,
  },
  {
    Date: "24.05.2022",
    price: 1972.390869,
  },
  {
    Date: "25.05.2022",
    price: 1978.677002,
  },
  {
    Date: "26.05.2022",
    price: 1945.033325,
  },
  {
    Date: "27.05.2022",
    price: 1802.543823,
  },
  {
    Date: "28.05.2022",
    price: 1724.635986,
  },
  {
    Date: "29.05.2022",
    price: 1792.184448,
  },
  {
    Date: "30.05.2022",
    price: 1811.885986,
  },
  {
    Date: "31.05.2022",
    price: 1996.408081,
  },
  {
    Date: "01.06.2022",
    price: 1942.050659,
  },
  {
    Date: "02.06.2022",
    price: 1822.412109,
  },
  {
    Date: "03.06.2022",
    price: 1834.13501,
  },
  {
    Date: "04.06.2022",
    price: 1775.220825,
  },
  {
    Date: "05.06.2022",
    price: 1801.81897,
  },
  {
    Date: "06.06.2022",
    price: 1805.635986,
  },
  {
    Date: "07.06.2022",
    price: 1859.33374,
  },
  {
    Date: "08.06.2022",
    price: 1814.100708,
  },
  {
    Date: "09.06.2022",
    price: 1793.512817,
  },
  {
    Date: "10.06.2022",
    price: 1789.689941,
  },
  {
    Date: "11.06.2022",
    price: 1665.217896,
  },
  {
    Date: "12.06.2022",
    price: 1530.189697,
  },
  {
    Date: "13.06.2022",
    price: 1443.835449,
  },
  {
    Date: "14.06.2022",
    price: 1204.555298,
  },
  {
    Date: "15.06.2022",
    price: 1211.365967,
  },
  {
    Date: "16.06.2022",
    price: 1233.603516,
  },
  {
    Date: "17.06.2022",
    price: 1067.987671,
  },
  {
    Date: "18.06.2022",
    price: 1086.37793,
  },
  {
    Date: "19.06.2022",
    price: 993.400635,
  },
  {
    Date: "20.06.2022",
    price: 1127.65625,
  },
  {
    Date: "21.06.2022",
    price: 1127.511841,
  },
  {
    Date: "22.06.2022",
    price: 1125.372925,
  },
  {
    Date: "23.06.2022",
    price: 1051.329346,
  },
  {
    Date: "24.06.2022",
    price: 1143.204712,
  },
  {
    Date: "25.06.2022",
    price: 1226.724731,
  },
  {
    Date: "26.06.2022",
    price: 1242.987549,
  },
  {
    Date: "27.06.2022",
    price: 1199.713135,
  },
  {
    Date: "28.06.2022",
    price: 1193.254028,
  },
  {
    Date: "29.06.2022",
    price: 1144.524414,
  },
  {
    Date: "30.06.2022",
    price: 1099.353149,
  },
  {
    Date: "01.07.2022",
    price: 1068.316772,
  },
  {
    Date: "02.07.2022",
    price: 1060.121216,
  },
  {
    Date: "03.07.2022",
    price: 1066.467407,
  },
  {
    Date: "04.07.2022",
    price: 1073.794312,
  },
  {
    Date: "05.07.2022",
    price: 1149.005371,
  },
];
