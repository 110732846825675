import styled from "styled-components";

export const ChartContainer = styled.div`
  width: 95%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* @media (max-width: 1024px) {
    //width: 100%;
    margin-top: 0;
    height: 70%;
  } */
`;
