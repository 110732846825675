export const historyElements = [
  {
    contentText:
      "You've just gone back in time, September 17, 2014 to be exact. The world of cryptocurrencies are in their infancy.",
  },
  {
    contentText:
      "You have $100 in your account and another chance to invest well in cryptocurrencies.",
  },
  {
    contentText:
      "Your goal is to be a millionaire, invest wisely, another opportunity like this will not happen again ;)",
  },
];
